@charset "UTF-8";
.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

/**
*
* Layout
*
*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select {
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  margin: 0; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring, .cart.table-wrapper .actions-toolbar > .action:-moz-focusring, .action-gift:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons, body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .action-edit:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

/**
*
* Colors
*
*/
/**
*
*  Globals
*
*/
/**
*
* Form Element
*
*/
/**
*
* Navigation
*
*/
/**
*
* Modal
*
*/
/**
*
* Page
*
*/
/**
*
* Pager
*
*/
/**
*
* Filter
*
*/
/**
*
* Checkout
*
*/
/**
*
* Header
*
*/
/**
*
* Dropdown list
*
*/
/**
*
* Magento_Swatches module
*
*/
/**
*
* Footer
*
*/
/**
*
* Footer
*
*/
/**
*
*Toolbar
*
*/
body {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

nav ul,
nav ol {
  list-style: none none; }

img {
  border: 0; }

img,
object,
video,
embed {
  height: auto;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

html {
  font-size: 62.5%;
  font-size-adjust: 100%; }

body {
  color: #434343;
  font-family: "Myriad Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }

p {
  margin-top: 0; }

p {
  margin-bottom: 1rem; }

abbr[title] {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help; }

b,
strong {
  font-weight: 700; }

em,
i {
  font-style: italic; }

mark {
  background: #f0f0f0;
  color: black; }

small,
.small {
  font-size: 12px; }

hr {
  border: 0;
  border-top: 1px solid #d1d1d1;
  margin-bottom: 20px;
  margin-top: 20px; }

sub,
sup {
  font-size: 71.42857%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dfn {
  font-style: italic; }

h1 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 0;
  margin-bottom: 2rem; }

h2 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

h3 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

h4 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h5 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h6 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  color: #3c3c3c;
  font-family: "Myriad Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1; }

a,
.alink {
  color: #1c5275;
  text-decoration: none; }
  a:visited,
  .alink:visited {
    color: #1c5275;
    text-decoration: none; }
  a:hover,
  .alink:hover {
    color: #006bb4;
    text-decoration: underline; }
  a:active,
  .alink:active {
    color: #2196f3;
    text-decoration: underline; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 2.5rem; }
  ul > li,
  ol > li {
    margin-top: 0;
    margin-bottom: 1rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

dl {
  margin-bottom: 20px;
  margin-top: 0; }

dt {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0; }

dd {
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 0; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  background: #f0f0f0;
  color: #1a1a1a;
  padding: 2px 4px;
  font-size: 1.2rem;
  white-space: nowrap; }

kbd {
  background: #f0f0f0;
  color: #1a1a1a;
  padding: 2px 4px;
  font-size: 1.2rem; }

pre {
  background: #f0f0f0;
  border: 1px solid #d1d1d1;
  color: #1a1a1a;
  line-height: 1.42857;
  margin: 0 0 10px;
  padding: 10px;
  font-size: 1.2rem;
  display: block;
  word-wrap: break-word; }
  pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap; }

blockquote {
  border-left: 0 solid #d1d1d1;
  margin: 0 0 20px 40px;
  padding: 0;
  color: #434343;
  font-family: "Myriad Pro Regular", sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    color: #3c3c3c;
    line-height: 1.42857;
    font-size: 1rem;
    display: block; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: "— "; }
  blockquote cite {
    font-style: normal; }

blockquote:before, blockquote:after {
  content: ''; }

q {
  quotes: none; }
  q:before, q:after {
    content: '';
    content: none; }

cite {
  font-style: normal; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%; }
  table th {
    text-align: left; }
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    vertical-align: top; }
  table > thead > tr > th,
  table > thead > tr > td {
    vertical-align: bottom; }
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    padding: 8px 10px; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  background-image: none;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-bottom-width: 1px;
  transition: color background-color border-color 250ms ease;
  color: #1a1a1a;
  cursor: pointer;
  display: inline-block;
  font-family: "Myriad Pro Regular", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle; }
  button:focus, .cart.table-wrapper .actions-toolbar > .action:focus, .action-gift:focus, button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #1a1a1a; }
  button:hover, .cart.table-wrapper .actions-toolbar > .action:hover, .action-gift:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #555; }
  button.disabled, .cart.table-wrapper .actions-toolbar > .disabled.action, .disabled.action-gift, button[disabled], .cart.table-wrapper .actions-toolbar > .action[disabled], .action-gift[disabled],
  fieldset[disabled] button,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .action,
  fieldset[disabled] .action-gift {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner {
    border: 0;
    padding: 0; }

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  background: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  input[type="text"]:disabled,
  input[type="password"]:disabled,
  input[type="url"]:disabled,
  input[type="tel"]:disabled,
  input[type="search"]:disabled,
  input[type="number"]:disabled,
  input[type="datetime"]:disabled,
  input[type="email"]:disabled {
    opacity: 0.5; }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="datetime"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder {
    color: #c2c2c2; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="datetime"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder {
    color: #c2c2c2; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="datetime"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder {
    color: #c2c2c2; }

input[type="number"] {
  -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type="search"] {
  -webkit-appearance: none; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none; }

select {
  background: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 5px 10px 4px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  select:disabled {
    opacity: 0.5; }

select[multiple="multiple"] {
  height: auto;
  background-image: none; }

textarea {
  background: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 14px;
  height: auto;
  line-height: 1.42857;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  resize: vertical; }
  textarea:disabled {
    opacity: 0.5; }
  textarea::-moz-placeholder {
    color: #c2c2c2; }
  textarea::-webkit-input-placeholder {
    color: #c2c2c2; }
  textarea:-ms-input-placeholder {
    color: #c2c2c2; }

input.mage-error,
select.mage-error,
textarea.mage-error {
  border-color: #ed8380; }

div.mage-error[generated] {
  color: #e02b27;
  font-size: 1.2rem; }

input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

form {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

address {
  font-style: normal; }

*:focus {
  box-shadow: none;
  outline: 0; }

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  box-shadow: 0 0 3px 1px #68a8e0; }

/* Gridlex */
/**
 * Adding namespacing for Gridlex
 */
[class~="p-grid"],
[class*="p-grid-"],
[class*="p-grid_"] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem; }

[class~="p-col"],
[class*="p-col-"],
[class*="p-col_"] {
  box-sizing: border-box;
  padding: 0 0.5rem 1rem;
  max-width: 100%; }

[class~="p-col"],
[class*="p-col_"] {
  flex: 1 1 0%; }

[class*="p-col-"] {
  flex: none; }

[class~="p-grid"][class~="p-col"],
[class~="p-grid"][class*="p-col-"],
[class~="p-grid"][class*="p-col_"],
[class*="p-grid-"][class~="p-col"],
[class*="p-grid-"][class*="p-col-"],
[class*="p-grid-"][class*="p-col_"],
[class*="p-grid_"][class~="p-col"],
[class*="p-grid_"][class*="p-col-"],
[class*="p-grid_"][class*="p-col_"] {
  margin: 0;
  padding: 0; }

/************************
    HELPERS SUFFIXES
*************************/
[class*="p-grid-"][class*="-noGutter"] {
  margin: 0; }
  [class*="p-grid-"][class*="-noGutter"] > [class~="p-col"],
  [class*="p-grid-"][class*="-noGutter"] > [class*="p-col-"] {
    padding: 0; }

[class*="p-grid-"][class*="-noWrap"] {
  flex-wrap: nowrap; }

[class*="p-grid-"][class*="-center"] {
  justify-content: center; }

[class*="p-grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto; }

[class*="p-grid-"][class*="-top"] {
  align-items: flex-start; }

[class*="p-grid-"][class*="-middle"] {
  align-items: center; }

[class*="p-grid-"][class*="-bottom"] {
  align-items: flex-end; }

[class*="p-grid-"][class*="-reverse"] {
  flex-direction: row-reverse; }

[class*="p-grid-"][class*="-column"] {
  flex-direction: column; }
  [class*="p-grid-"][class*="-column"] > [class*="p-col-"] {
    flex-basis: auto; }

[class*="p-grid-"][class*="-column-reverse"] {
  flex-direction: column-reverse; }

[class*="p-grid-"][class*="-spaceBetween"] {
  justify-content: space-between; }

[class*="p-grid-"][class*="-spaceAround"] {
  justify-content: space-around; }

[class*="p-grid-"][class*="-equalHeight"] > [class~="p-col"],
[class*="p-grid-"][class*="-equalHeight"] > [class*="p-col-"],
[class*="p-grid-"][class*="-equalHeight"] > [class*="p-col_"] {
  align-self: stretch; }
  [class*="p-grid-"][class*="-equalHeight"] > [class~="p-col"] > *,
  [class*="p-grid-"][class*="-equalHeight"] > [class*="p-col-"] > *,
  [class*="p-grid-"][class*="-equalHeight"] > [class*="p-col_"] > * {
    height: 100%; }

[class*="p-grid-"][class*="-noBottom"] > [class~="p-col"],
[class*="p-grid-"][class*="-noBottom"] > [class*="p-col-"],
[class*="p-grid-"][class*="-noBottom"] > [class*="p-col_"] {
  padding-bottom: 0; }

[class*="p-col-"][class*="-top"] {
  align-self: flex-start; }

[class*="p-col-"][class*="-middle"] {
  align-self: center; }

[class*="p-col-"][class*="-bottom"] {
  align-self: flex-end; }

[class*="p-col-"][class*="-first"] {
  order: -1; }

[class*="p-col-"][class*="-last"] {
  order: 1; }

/************************
    GRID BY NUMBER
*************************/
[class*="p-grid-1"] > [class~="p-col"],
[class*="p-grid-1"] > [class*="p-col-"],
[class*="p-grid-1"] > [class*="p-col_"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="p-grid-2"] > [class~="p-col"],
[class*="p-grid-2"] > [class*="p-col-"],
[class*="p-grid-2"] > [class*="p-col_"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*="p-grid-3"] > [class~="p-col"],
[class*="p-grid-3"] > [class*="p-col-"],
[class*="p-grid-3"] > [class*="p-col_"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*="p-grid-4"] > [class~="p-col"],
[class*="p-grid-4"] > [class*="p-col-"],
[class*="p-grid-4"] > [class*="p-col_"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*="p-grid-5"] > [class~="p-col"],
[class*="p-grid-5"] > [class*="p-col-"],
[class*="p-grid-5"] > [class*="p-col_"] {
  flex-basis: 20%;
  max-width: 20%; }

[class*="p-grid-6"] > [class~="p-col"],
[class*="p-grid-6"] > [class*="p-col-"],
[class*="p-grid-6"] > [class*="p-col_"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*="p-grid-7"] > [class~="p-col"],
[class*="p-grid-7"] > [class*="p-col-"],
[class*="p-grid-7"] > [class*="p-col_"] {
  flex-basis: 14.28571%;
  max-width: 14.28571%; }

[class*="p-grid-8"] > [class~="p-col"],
[class*="p-grid-8"] > [class*="p-col-"],
[class*="p-grid-8"] > [class*="p-col_"] {
  flex-basis: 12.5%;
  max-width: 12.5%; }

[class*="p-grid-9"] > [class~="p-col"],
[class*="p-grid-9"] > [class*="p-col-"],
[class*="p-grid-9"] > [class*="p-col_"] {
  flex-basis: 11.11111%;
  max-width: 11.11111%; }

[class*="p-grid-10"] > [class~="p-col"],
[class*="p-grid-10"] > [class*="p-col-"],
[class*="p-grid-10"] > [class*="p-col_"] {
  flex-basis: 10%;
  max-width: 10%; }

[class*="p-grid-11"] > [class~="p-col"],
[class*="p-grid-11"] > [class*="p-col-"],
[class*="p-grid-11"] > [class*="p-col_"] {
  flex-basis: 9.09091%;
  max-width: 9.09091%; }

[class*="p-grid-12"] > [class~="p-col"],
[class*="p-grid-12"] > [class*="p-col-"],
[class*="p-grid-12"] > [class*="p-col_"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

@media (max-width: 1024px) {
  [class*="_lg-1"] > [class~="p-col"],
  [class*="_lg-1"] > [class*="p-col-"],
  [class*="_lg-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_lg-2"] > [class~="p-col"],
  [class*="_lg-2"] > [class*="p-col-"],
  [class*="_lg-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_lg-3"] > [class~="p-col"],
  [class*="_lg-3"] > [class*="p-col-"],
  [class*="_lg-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_lg-4"] > [class~="p-col"],
  [class*="_lg-4"] > [class*="p-col-"],
  [class*="_lg-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_lg-5"] > [class~="p-col"],
  [class*="_lg-5"] > [class*="p-col-"],
  [class*="_lg-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_lg-6"] > [class~="p-col"],
  [class*="_lg-6"] > [class*="p-col-"],
  [class*="_lg-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_lg-7"] > [class~="p-col"],
  [class*="_lg-7"] > [class*="p-col-"],
  [class*="_lg-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_lg-8"] > [class~="p-col"],
  [class*="_lg-8"] > [class*="p-col-"],
  [class*="_lg-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_lg-9"] > [class~="p-col"],
  [class*="_lg-9"] > [class*="p-col-"],
  [class*="_lg-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_lg-10"] > [class~="p-col"],
  [class*="_lg-10"] > [class*="p-col-"],
  [class*="_lg-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_lg-11"] > [class~="p-col"],
  [class*="_lg-11"] > [class*="p-col-"],
  [class*="_lg-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_lg-12"] > [class~="p-col"],
  [class*="_lg-12"] > [class*="p-col-"],
  [class*="_lg-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 768px) {
  [class*="_md-1"] > [class~="p-col"],
  [class*="_md-1"] > [class*="p-col-"],
  [class*="_md-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_md-2"] > [class~="p-col"],
  [class*="_md-2"] > [class*="p-col-"],
  [class*="_md-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_md-3"] > [class~="p-col"],
  [class*="_md-3"] > [class*="p-col-"],
  [class*="_md-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_md-4"] > [class~="p-col"],
  [class*="_md-4"] > [class*="p-col-"],
  [class*="_md-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_md-5"] > [class~="p-col"],
  [class*="_md-5"] > [class*="p-col-"],
  [class*="_md-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_md-6"] > [class~="p-col"],
  [class*="_md-6"] > [class*="p-col-"],
  [class*="_md-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_md-7"] > [class~="p-col"],
  [class*="_md-7"] > [class*="p-col-"],
  [class*="_md-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_md-8"] > [class~="p-col"],
  [class*="_md-8"] > [class*="p-col-"],
  [class*="_md-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_md-9"] > [class~="p-col"],
  [class*="_md-9"] > [class*="p-col-"],
  [class*="_md-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_md-10"] > [class~="p-col"],
  [class*="_md-10"] > [class*="p-col-"],
  [class*="_md-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_md-11"] > [class~="p-col"],
  [class*="_md-11"] > [class*="p-col-"],
  [class*="_md-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_md-12"] > [class~="p-col"],
  [class*="_md-12"] > [class*="p-col-"],
  [class*="_md-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 640px) {
  [class*="_sm-1"] > [class~="p-col"],
  [class*="_sm-1"] > [class*="p-col-"],
  [class*="_sm-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_sm-2"] > [class~="p-col"],
  [class*="_sm-2"] > [class*="p-col-"],
  [class*="_sm-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_sm-3"] > [class~="p-col"],
  [class*="_sm-3"] > [class*="p-col-"],
  [class*="_sm-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_sm-4"] > [class~="p-col"],
  [class*="_sm-4"] > [class*="p-col-"],
  [class*="_sm-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_sm-5"] > [class~="p-col"],
  [class*="_sm-5"] > [class*="p-col-"],
  [class*="_sm-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_sm-6"] > [class~="p-col"],
  [class*="_sm-6"] > [class*="p-col-"],
  [class*="_sm-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_sm-7"] > [class~="p-col"],
  [class*="_sm-7"] > [class*="p-col-"],
  [class*="_sm-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_sm-8"] > [class~="p-col"],
  [class*="_sm-8"] > [class*="p-col-"],
  [class*="_sm-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_sm-9"] > [class~="p-col"],
  [class*="_sm-9"] > [class*="p-col-"],
  [class*="_sm-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_sm-10"] > [class~="p-col"],
  [class*="_sm-10"] > [class*="p-col-"],
  [class*="_sm-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_sm-11"] > [class~="p-col"],
  [class*="_sm-11"] > [class*="p-col-"],
  [class*="_sm-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_sm-12"] > [class~="p-col"],
  [class*="_sm-12"] > [class*="p-col-"],
  [class*="_sm-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 480px) {
  [class*="_xs-1"] > [class~="p-col"],
  [class*="_xs-1"] > [class*="p-col-"],
  [class*="_xs-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_xs-2"] > [class~="p-col"],
  [class*="_xs-2"] > [class*="p-col-"],
  [class*="_xs-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_xs-3"] > [class~="p-col"],
  [class*="_xs-3"] > [class*="p-col-"],
  [class*="_xs-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_xs-4"] > [class~="p-col"],
  [class*="_xs-4"] > [class*="p-col-"],
  [class*="_xs-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_xs-5"] > [class~="p-col"],
  [class*="_xs-5"] > [class*="p-col-"],
  [class*="_xs-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_xs-6"] > [class~="p-col"],
  [class*="_xs-6"] > [class*="p-col-"],
  [class*="_xs-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_xs-7"] > [class~="p-col"],
  [class*="_xs-7"] > [class*="p-col-"],
  [class*="_xs-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_xs-8"] > [class~="p-col"],
  [class*="_xs-8"] > [class*="p-col-"],
  [class*="_xs-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_xs-9"] > [class~="p-col"],
  [class*="_xs-9"] > [class*="p-col-"],
  [class*="_xs-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_xs-10"] > [class~="p-col"],
  [class*="_xs-10"] > [class*="p-col-"],
  [class*="_xs-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_xs-11"] > [class~="p-col"],
  [class*="_xs-11"] > [class*="p-col-"],
  [class*="_xs-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_xs-12"] > [class~="p-col"],
  [class*="_xs-12"] > [class*="p-col-"],
  [class*="_xs-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
[class~="p-grid"] > [class*="p-col-1"],
[class*="p-grid-"] > [class*="p-col-1"],
[class*="p-grid_"] > [class*="p-col-1"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

[class~="p-grid"] > [class*="p-col-2"],
[class*="p-grid-"] > [class*="p-col-2"],
[class*="p-grid_"] > [class*="p-col-2"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class~="p-grid"] > [class*="p-col-3"],
[class*="p-grid-"] > [class*="p-col-3"],
[class*="p-grid_"] > [class*="p-col-3"] {
  flex-basis: 25%;
  max-width: 25%; }

[class~="p-grid"] > [class*="p-col-4"],
[class*="p-grid-"] > [class*="p-col-4"],
[class*="p-grid_"] > [class*="p-col-4"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class~="p-grid"] > [class*="p-col-5"],
[class*="p-grid-"] > [class*="p-col-5"],
[class*="p-grid_"] > [class*="p-col-5"] {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

[class~="p-grid"] > [class*="p-col-6"],
[class*="p-grid-"] > [class*="p-col-6"],
[class*="p-grid_"] > [class*="p-col-6"] {
  flex-basis: 50%;
  max-width: 50%; }

[class~="p-grid"] > [class*="p-col-7"],
[class*="p-grid-"] > [class*="p-col-7"],
[class*="p-grid_"] > [class*="p-col-7"] {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

[class~="p-grid"] > [class*="p-col-8"],
[class*="p-grid-"] > [class*="p-col-8"],
[class*="p-grid_"] > [class*="p-col-8"] {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

[class~="p-grid"] > [class*="p-col-9"],
[class*="p-grid-"] > [class*="p-col-9"],
[class*="p-grid_"] > [class*="p-col-9"] {
  flex-basis: 75%;
  max-width: 75%; }

[class~="p-grid"] > [class*="p-col-10"],
[class*="p-grid-"] > [class*="p-col-10"],
[class*="p-grid_"] > [class*="p-col-10"] {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

[class~="p-grid"] > [class*="p-col-11"],
[class*="p-grid-"] > [class*="p-col-11"],
[class*="p-grid_"] > [class*="p-col-11"] {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

[class~="p-grid"] > [class*="p-col-12"],
[class*="p-grid-"] > [class*="p-col-12"],
[class*="p-grid_"] > [class*="p-col-12"] {
  flex-basis: 100%;
  max-width: 100%; }

[class~="p-grid"] > [data-push-left*="off-0"],
[class*="p-grid-"] > [data-push-left*="off-0"],
[class*="p-grid_"] > [data-push-left*="off-0"] {
  margin-left: 0; }

[class~="p-grid"] > [data-push-left*="off-1"],
[class*="p-grid-"] > [data-push-left*="off-1"],
[class*="p-grid_"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%; }

[class~="p-grid"] > [data-push-left*="off-2"],
[class*="p-grid-"] > [data-push-left*="off-2"],
[class*="p-grid_"] > [data-push-left*="off-2"] {
  margin-left: 16.66667%; }

[class~="p-grid"] > [data-push-left*="off-3"],
[class*="p-grid-"] > [data-push-left*="off-3"],
[class*="p-grid_"] > [data-push-left*="off-3"] {
  margin-left: 25%; }

[class~="p-grid"] > [data-push-left*="off-4"],
[class*="p-grid-"] > [data-push-left*="off-4"],
[class*="p-grid_"] > [data-push-left*="off-4"] {
  margin-left: 33.33333%; }

[class~="p-grid"] > [data-push-left*="off-5"],
[class*="p-grid-"] > [data-push-left*="off-5"],
[class*="p-grid_"] > [data-push-left*="off-5"] {
  margin-left: 41.66667%; }

[class~="p-grid"] > [data-push-left*="off-6"],
[class*="p-grid-"] > [data-push-left*="off-6"],
[class*="p-grid_"] > [data-push-left*="off-6"] {
  margin-left: 50%; }

[class~="p-grid"] > [data-push-left*="off-7"],
[class*="p-grid-"] > [data-push-left*="off-7"],
[class*="p-grid_"] > [data-push-left*="off-7"] {
  margin-left: 58.33333%; }

[class~="p-grid"] > [data-push-left*="off-8"],
[class*="p-grid-"] > [data-push-left*="off-8"],
[class*="p-grid_"] > [data-push-left*="off-8"] {
  margin-left: 66.66667%; }

[class~="p-grid"] > [data-push-left*="off-9"],
[class*="p-grid-"] > [data-push-left*="off-9"],
[class*="p-grid_"] > [data-push-left*="off-9"] {
  margin-left: 75%; }

[class~="p-grid"] > [data-push-left*="off-10"],
[class*="p-grid-"] > [data-push-left*="off-10"],
[class*="p-grid_"] > [data-push-left*="off-10"] {
  margin-left: 83.33333%; }

[class~="p-grid"] > [data-push-left*="off-11"],
[class*="p-grid-"] > [data-push-left*="off-11"],
[class*="p-grid_"] > [data-push-left*="off-11"] {
  margin-left: 91.66667%; }

[class~="p-grid"] > [data-push-right*="off-0"],
[class*="p-grid-"] > [data-push-right*="off-0"],
[class*="p-grid_"] > [data-push-right*="off-0"] {
  margin-right: 0; }

[class~="p-grid"] > [data-push-right*="off-1"],
[class*="p-grid-"] > [data-push-right*="off-1"],
[class*="p-grid_"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%; }

[class~="p-grid"] > [data-push-right*="off-2"],
[class*="p-grid-"] > [data-push-right*="off-2"],
[class*="p-grid_"] > [data-push-right*="off-2"] {
  margin-right: 16.66667%; }

[class~="p-grid"] > [data-push-right*="off-3"],
[class*="p-grid-"] > [data-push-right*="off-3"],
[class*="p-grid_"] > [data-push-right*="off-3"] {
  margin-right: 25%; }

[class~="p-grid"] > [data-push-right*="off-4"],
[class*="p-grid-"] > [data-push-right*="off-4"],
[class*="p-grid_"] > [data-push-right*="off-4"] {
  margin-right: 33.33333%; }

[class~="p-grid"] > [data-push-right*="off-5"],
[class*="p-grid-"] > [data-push-right*="off-5"],
[class*="p-grid_"] > [data-push-right*="off-5"] {
  margin-right: 41.66667%; }

[class~="p-grid"] > [data-push-right*="off-6"],
[class*="p-grid-"] > [data-push-right*="off-6"],
[class*="p-grid_"] > [data-push-right*="off-6"] {
  margin-right: 50%; }

[class~="p-grid"] > [data-push-right*="off-7"],
[class*="p-grid-"] > [data-push-right*="off-7"],
[class*="p-grid_"] > [data-push-right*="off-7"] {
  margin-right: 58.33333%; }

[class~="p-grid"] > [data-push-right*="off-8"],
[class*="p-grid-"] > [data-push-right*="off-8"],
[class*="p-grid_"] > [data-push-right*="off-8"] {
  margin-right: 66.66667%; }

[class~="p-grid"] > [data-push-right*="off-9"],
[class*="p-grid-"] > [data-push-right*="off-9"],
[class*="p-grid_"] > [data-push-right*="off-9"] {
  margin-right: 75%; }

[class~="p-grid"] > [data-push-right*="off-10"],
[class*="p-grid-"] > [data-push-right*="off-10"],
[class*="p-grid_"] > [data-push-right*="off-10"] {
  margin-right: 83.33333%; }

[class~="p-grid"] > [data-push-right*="off-11"],
[class*="p-grid-"] > [data-push-right*="off-11"],
[class*="p-grid_"] > [data-push-right*="off-11"] {
  margin-right: 91.66667%; }

@media (max-width: 1024px) {
  [class~="p-grid"] > [class*="_lg-1"],
  [class*="p-grid-"] > [class*="_lg-1"],
  [class*="p-grid_"] > [class*="_lg-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_lg-2"],
  [class*="p-grid-"] > [class*="_lg-2"],
  [class*="p-grid_"] > [class*="_lg-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_lg-3"],
  [class*="p-grid-"] > [class*="_lg-3"],
  [class*="p-grid_"] > [class*="_lg-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_lg-4"],
  [class*="p-grid-"] > [class*="_lg-4"],
  [class*="p-grid_"] > [class*="_lg-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_lg-5"],
  [class*="p-grid-"] > [class*="_lg-5"],
  [class*="p-grid_"] > [class*="_lg-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_lg-6"],
  [class*="p-grid-"] > [class*="_lg-6"],
  [class*="p-grid_"] > [class*="_lg-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_lg-7"],
  [class*="p-grid-"] > [class*="_lg-7"],
  [class*="p-grid_"] > [class*="_lg-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_lg-8"],
  [class*="p-grid-"] > [class*="_lg-8"],
  [class*="p-grid_"] > [class*="_lg-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_lg-9"],
  [class*="p-grid-"] > [class*="_lg-9"],
  [class*="p-grid_"] > [class*="_lg-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_lg-10"],
  [class*="p-grid-"] > [class*="_lg-10"],
  [class*="p-grid_"] > [class*="_lg-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_lg-11"],
  [class*="p-grid-"] > [class*="_lg-11"],
  [class*="p-grid_"] > [class*="_lg-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_lg-12"],
  [class*="p-grid-"] > [class*="_lg-12"],
  [class*="p-grid_"] > [class*="_lg-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_lg-0"],
  [class*="p-grid-"] > [data-push-left*="_lg-0"],
  [class*="p-grid_"] > [data-push-left*="_lg-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_lg-1"],
  [class*="p-grid-"] > [data-push-left*="_lg-1"],
  [class*="p-grid_"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-2"],
  [class*="p-grid-"] > [data-push-left*="_lg-2"],
  [class*="p-grid_"] > [data-push-left*="_lg-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-3"],
  [class*="p-grid-"] > [data-push-left*="_lg-3"],
  [class*="p-grid_"] > [data-push-left*="_lg-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_lg-4"],
  [class*="p-grid-"] > [data-push-left*="_lg-4"],
  [class*="p-grid_"] > [data-push-left*="_lg-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-5"],
  [class*="p-grid-"] > [data-push-left*="_lg-5"],
  [class*="p-grid_"] > [data-push-left*="_lg-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-6"],
  [class*="p-grid-"] > [data-push-left*="_lg-6"],
  [class*="p-grid_"] > [data-push-left*="_lg-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_lg-7"],
  [class*="p-grid-"] > [data-push-left*="_lg-7"],
  [class*="p-grid_"] > [data-push-left*="_lg-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-8"],
  [class*="p-grid-"] > [data-push-left*="_lg-8"],
  [class*="p-grid_"] > [data-push-left*="_lg-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-9"],
  [class*="p-grid-"] > [data-push-left*="_lg-9"],
  [class*="p-grid_"] > [data-push-left*="_lg-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_lg-10"],
  [class*="p-grid-"] > [data-push-left*="_lg-10"],
  [class*="p-grid_"] > [data-push-left*="_lg-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-11"],
  [class*="p-grid-"] > [data-push-left*="_lg-11"],
  [class*="p-grid_"] > [data-push-left*="_lg-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-0"],
  [class*="p-grid-"] > [data-push-right*="_lg-0"],
  [class*="p-grid_"] > [data-push-right*="_lg-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_lg-1"],
  [class*="p-grid-"] > [data-push-right*="_lg-1"],
  [class*="p-grid_"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-2"],
  [class*="p-grid-"] > [data-push-right*="_lg-2"],
  [class*="p-grid_"] > [data-push-right*="_lg-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-3"],
  [class*="p-grid-"] > [data-push-right*="_lg-3"],
  [class*="p-grid_"] > [data-push-right*="_lg-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_lg-4"],
  [class*="p-grid-"] > [data-push-right*="_lg-4"],
  [class*="p-grid_"] > [data-push-right*="_lg-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-5"],
  [class*="p-grid-"] > [data-push-right*="_lg-5"],
  [class*="p-grid_"] > [data-push-right*="_lg-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-6"],
  [class*="p-grid-"] > [data-push-right*="_lg-6"],
  [class*="p-grid_"] > [data-push-right*="_lg-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_lg-7"],
  [class*="p-grid-"] > [data-push-right*="_lg-7"],
  [class*="p-grid_"] > [data-push-right*="_lg-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-8"],
  [class*="p-grid-"] > [data-push-right*="_lg-8"],
  [class*="p-grid_"] > [data-push-right*="_lg-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-9"],
  [class*="p-grid-"] > [data-push-right*="_lg-9"],
  [class*="p-grid_"] > [data-push-right*="_lg-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_lg-10"],
  [class*="p-grid-"] > [data-push-right*="_lg-10"],
  [class*="p-grid_"] > [data-push-right*="_lg-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-11"],
  [class*="p-grid-"] > [data-push-right*="_lg-11"],
  [class*="p-grid_"] > [data-push-right*="_lg-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_lg-first"],
  [class*="p-grid-"] [class*="_lg-first"],
  [class*="p-grid_"] [class*="_lg-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_lg-last"],
  [class*="p-grid-"] [class*="_lg-last"],
  [class*="p-grid_"] [class*="_lg-last"] {
    order: 1; } }

@media (max-width: 768px) {
  [class~="p-grid"] > [class*="_md-1"],
  [class*="p-grid-"] > [class*="_md-1"],
  [class*="p-grid_"] > [class*="_md-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_md-2"],
  [class*="p-grid-"] > [class*="_md-2"],
  [class*="p-grid_"] > [class*="_md-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_md-3"],
  [class*="p-grid-"] > [class*="_md-3"],
  [class*="p-grid_"] > [class*="_md-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_md-4"],
  [class*="p-grid-"] > [class*="_md-4"],
  [class*="p-grid_"] > [class*="_md-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_md-5"],
  [class*="p-grid-"] > [class*="_md-5"],
  [class*="p-grid_"] > [class*="_md-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_md-6"],
  [class*="p-grid-"] > [class*="_md-6"],
  [class*="p-grid_"] > [class*="_md-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_md-7"],
  [class*="p-grid-"] > [class*="_md-7"],
  [class*="p-grid_"] > [class*="_md-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_md-8"],
  [class*="p-grid-"] > [class*="_md-8"],
  [class*="p-grid_"] > [class*="_md-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_md-9"],
  [class*="p-grid-"] > [class*="_md-9"],
  [class*="p-grid_"] > [class*="_md-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_md-10"],
  [class*="p-grid-"] > [class*="_md-10"],
  [class*="p-grid_"] > [class*="_md-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_md-11"],
  [class*="p-grid-"] > [class*="_md-11"],
  [class*="p-grid_"] > [class*="_md-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_md-12"],
  [class*="p-grid-"] > [class*="_md-12"],
  [class*="p-grid_"] > [class*="_md-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_md-0"],
  [class*="p-grid-"] > [data-push-left*="_md-0"],
  [class*="p-grid_"] > [data-push-left*="_md-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_md-1"],
  [class*="p-grid-"] > [data-push-left*="_md-1"],
  [class*="p-grid_"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-2"],
  [class*="p-grid-"] > [data-push-left*="_md-2"],
  [class*="p-grid_"] > [data-push-left*="_md-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-3"],
  [class*="p-grid-"] > [data-push-left*="_md-3"],
  [class*="p-grid_"] > [data-push-left*="_md-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_md-4"],
  [class*="p-grid-"] > [data-push-left*="_md-4"],
  [class*="p-grid_"] > [data-push-left*="_md-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-5"],
  [class*="p-grid-"] > [data-push-left*="_md-5"],
  [class*="p-grid_"] > [data-push-left*="_md-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-6"],
  [class*="p-grid-"] > [data-push-left*="_md-6"],
  [class*="p-grid_"] > [data-push-left*="_md-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_md-7"],
  [class*="p-grid-"] > [data-push-left*="_md-7"],
  [class*="p-grid_"] > [data-push-left*="_md-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-8"],
  [class*="p-grid-"] > [data-push-left*="_md-8"],
  [class*="p-grid_"] > [data-push-left*="_md-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-9"],
  [class*="p-grid-"] > [data-push-left*="_md-9"],
  [class*="p-grid_"] > [data-push-left*="_md-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_md-10"],
  [class*="p-grid-"] > [data-push-left*="_md-10"],
  [class*="p-grid_"] > [data-push-left*="_md-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-11"],
  [class*="p-grid-"] > [data-push-left*="_md-11"],
  [class*="p-grid_"] > [data-push-left*="_md-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-0"],
  [class*="p-grid-"] > [data-push-right*="_md-0"],
  [class*="p-grid_"] > [data-push-right*="_md-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_md-1"],
  [class*="p-grid-"] > [data-push-right*="_md-1"],
  [class*="p-grid_"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-2"],
  [class*="p-grid-"] > [data-push-right*="_md-2"],
  [class*="p-grid_"] > [data-push-right*="_md-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-3"],
  [class*="p-grid-"] > [data-push-right*="_md-3"],
  [class*="p-grid_"] > [data-push-right*="_md-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_md-4"],
  [class*="p-grid-"] > [data-push-right*="_md-4"],
  [class*="p-grid_"] > [data-push-right*="_md-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-5"],
  [class*="p-grid-"] > [data-push-right*="_md-5"],
  [class*="p-grid_"] > [data-push-right*="_md-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-6"],
  [class*="p-grid-"] > [data-push-right*="_md-6"],
  [class*="p-grid_"] > [data-push-right*="_md-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_md-7"],
  [class*="p-grid-"] > [data-push-right*="_md-7"],
  [class*="p-grid_"] > [data-push-right*="_md-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-8"],
  [class*="p-grid-"] > [data-push-right*="_md-8"],
  [class*="p-grid_"] > [data-push-right*="_md-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-9"],
  [class*="p-grid-"] > [data-push-right*="_md-9"],
  [class*="p-grid_"] > [data-push-right*="_md-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_md-10"],
  [class*="p-grid-"] > [data-push-right*="_md-10"],
  [class*="p-grid_"] > [data-push-right*="_md-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-11"],
  [class*="p-grid-"] > [data-push-right*="_md-11"],
  [class*="p-grid_"] > [data-push-right*="_md-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_md-first"],
  [class*="p-grid-"] [class*="_md-first"],
  [class*="p-grid_"] [class*="_md-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_md-last"],
  [class*="p-grid-"] [class*="_md-last"],
  [class*="p-grid_"] [class*="_md-last"] {
    order: 1; } }

@media (max-width: 640px) {
  [class~="p-grid"] > [class*="_sm-1"],
  [class*="p-grid-"] > [class*="_sm-1"],
  [class*="p-grid_"] > [class*="_sm-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_sm-2"],
  [class*="p-grid-"] > [class*="_sm-2"],
  [class*="p-grid_"] > [class*="_sm-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_sm-3"],
  [class*="p-grid-"] > [class*="_sm-3"],
  [class*="p-grid_"] > [class*="_sm-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_sm-4"],
  [class*="p-grid-"] > [class*="_sm-4"],
  [class*="p-grid_"] > [class*="_sm-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_sm-5"],
  [class*="p-grid-"] > [class*="_sm-5"],
  [class*="p-grid_"] > [class*="_sm-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_sm-6"],
  [class*="p-grid-"] > [class*="_sm-6"],
  [class*="p-grid_"] > [class*="_sm-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_sm-7"],
  [class*="p-grid-"] > [class*="_sm-7"],
  [class*="p-grid_"] > [class*="_sm-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_sm-8"],
  [class*="p-grid-"] > [class*="_sm-8"],
  [class*="p-grid_"] > [class*="_sm-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_sm-9"],
  [class*="p-grid-"] > [class*="_sm-9"],
  [class*="p-grid_"] > [class*="_sm-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_sm-10"],
  [class*="p-grid-"] > [class*="_sm-10"],
  [class*="p-grid_"] > [class*="_sm-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_sm-11"],
  [class*="p-grid-"] > [class*="_sm-11"],
  [class*="p-grid_"] > [class*="_sm-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_sm-12"],
  [class*="p-grid-"] > [class*="_sm-12"],
  [class*="p-grid_"] > [class*="_sm-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_sm-0"],
  [class*="p-grid-"] > [data-push-left*="_sm-0"],
  [class*="p-grid_"] > [data-push-left*="_sm-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_sm-1"],
  [class*="p-grid-"] > [data-push-left*="_sm-1"],
  [class*="p-grid_"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-2"],
  [class*="p-grid-"] > [data-push-left*="_sm-2"],
  [class*="p-grid_"] > [data-push-left*="_sm-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-3"],
  [class*="p-grid-"] > [data-push-left*="_sm-3"],
  [class*="p-grid_"] > [data-push-left*="_sm-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_sm-4"],
  [class*="p-grid-"] > [data-push-left*="_sm-4"],
  [class*="p-grid_"] > [data-push-left*="_sm-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-5"],
  [class*="p-grid-"] > [data-push-left*="_sm-5"],
  [class*="p-grid_"] > [data-push-left*="_sm-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-6"],
  [class*="p-grid-"] > [data-push-left*="_sm-6"],
  [class*="p-grid_"] > [data-push-left*="_sm-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_sm-7"],
  [class*="p-grid-"] > [data-push-left*="_sm-7"],
  [class*="p-grid_"] > [data-push-left*="_sm-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-8"],
  [class*="p-grid-"] > [data-push-left*="_sm-8"],
  [class*="p-grid_"] > [data-push-left*="_sm-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-9"],
  [class*="p-grid-"] > [data-push-left*="_sm-9"],
  [class*="p-grid_"] > [data-push-left*="_sm-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_sm-10"],
  [class*="p-grid-"] > [data-push-left*="_sm-10"],
  [class*="p-grid_"] > [data-push-left*="_sm-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-11"],
  [class*="p-grid-"] > [data-push-left*="_sm-11"],
  [class*="p-grid_"] > [data-push-left*="_sm-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-0"],
  [class*="p-grid-"] > [data-push-right*="_sm-0"],
  [class*="p-grid_"] > [data-push-right*="_sm-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_sm-1"],
  [class*="p-grid-"] > [data-push-right*="_sm-1"],
  [class*="p-grid_"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-2"],
  [class*="p-grid-"] > [data-push-right*="_sm-2"],
  [class*="p-grid_"] > [data-push-right*="_sm-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-3"],
  [class*="p-grid-"] > [data-push-right*="_sm-3"],
  [class*="p-grid_"] > [data-push-right*="_sm-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_sm-4"],
  [class*="p-grid-"] > [data-push-right*="_sm-4"],
  [class*="p-grid_"] > [data-push-right*="_sm-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-5"],
  [class*="p-grid-"] > [data-push-right*="_sm-5"],
  [class*="p-grid_"] > [data-push-right*="_sm-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-6"],
  [class*="p-grid-"] > [data-push-right*="_sm-6"],
  [class*="p-grid_"] > [data-push-right*="_sm-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_sm-7"],
  [class*="p-grid-"] > [data-push-right*="_sm-7"],
  [class*="p-grid_"] > [data-push-right*="_sm-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-8"],
  [class*="p-grid-"] > [data-push-right*="_sm-8"],
  [class*="p-grid_"] > [data-push-right*="_sm-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-9"],
  [class*="p-grid-"] > [data-push-right*="_sm-9"],
  [class*="p-grid_"] > [data-push-right*="_sm-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_sm-10"],
  [class*="p-grid-"] > [data-push-right*="_sm-10"],
  [class*="p-grid_"] > [data-push-right*="_sm-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-11"],
  [class*="p-grid-"] > [data-push-right*="_sm-11"],
  [class*="p-grid_"] > [data-push-right*="_sm-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_sm-first"],
  [class*="p-grid-"] [class*="_sm-first"],
  [class*="p-grid_"] [class*="_sm-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_sm-last"],
  [class*="p-grid-"] [class*="_sm-last"],
  [class*="p-grid_"] [class*="_sm-last"] {
    order: 1; } }

@media (max-width: 480px) {
  [class~="p-grid"] > [class*="_xs-1"],
  [class*="p-grid-"] > [class*="_xs-1"],
  [class*="p-grid_"] > [class*="_xs-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_xs-2"],
  [class*="p-grid-"] > [class*="_xs-2"],
  [class*="p-grid_"] > [class*="_xs-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_xs-3"],
  [class*="p-grid-"] > [class*="_xs-3"],
  [class*="p-grid_"] > [class*="_xs-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_xs-4"],
  [class*="p-grid-"] > [class*="_xs-4"],
  [class*="p-grid_"] > [class*="_xs-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_xs-5"],
  [class*="p-grid-"] > [class*="_xs-5"],
  [class*="p-grid_"] > [class*="_xs-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_xs-6"],
  [class*="p-grid-"] > [class*="_xs-6"],
  [class*="p-grid_"] > [class*="_xs-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_xs-7"],
  [class*="p-grid-"] > [class*="_xs-7"],
  [class*="p-grid_"] > [class*="_xs-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_xs-8"],
  [class*="p-grid-"] > [class*="_xs-8"],
  [class*="p-grid_"] > [class*="_xs-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_xs-9"],
  [class*="p-grid-"] > [class*="_xs-9"],
  [class*="p-grid_"] > [class*="_xs-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_xs-10"],
  [class*="p-grid-"] > [class*="_xs-10"],
  [class*="p-grid_"] > [class*="_xs-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_xs-11"],
  [class*="p-grid-"] > [class*="_xs-11"],
  [class*="p-grid_"] > [class*="_xs-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_xs-12"],
  [class*="p-grid-"] > [class*="_xs-12"],
  [class*="p-grid_"] > [class*="_xs-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_xs-0"],
  [class*="p-grid-"] > [data-push-left*="_xs-0"],
  [class*="p-grid_"] > [data-push-left*="_xs-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_xs-1"],
  [class*="p-grid-"] > [data-push-left*="_xs-1"],
  [class*="p-grid_"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-2"],
  [class*="p-grid-"] > [data-push-left*="_xs-2"],
  [class*="p-grid_"] > [data-push-left*="_xs-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-3"],
  [class*="p-grid-"] > [data-push-left*="_xs-3"],
  [class*="p-grid_"] > [data-push-left*="_xs-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_xs-4"],
  [class*="p-grid-"] > [data-push-left*="_xs-4"],
  [class*="p-grid_"] > [data-push-left*="_xs-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-5"],
  [class*="p-grid-"] > [data-push-left*="_xs-5"],
  [class*="p-grid_"] > [data-push-left*="_xs-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-6"],
  [class*="p-grid-"] > [data-push-left*="_xs-6"],
  [class*="p-grid_"] > [data-push-left*="_xs-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_xs-7"],
  [class*="p-grid-"] > [data-push-left*="_xs-7"],
  [class*="p-grid_"] > [data-push-left*="_xs-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-8"],
  [class*="p-grid-"] > [data-push-left*="_xs-8"],
  [class*="p-grid_"] > [data-push-left*="_xs-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-9"],
  [class*="p-grid-"] > [data-push-left*="_xs-9"],
  [class*="p-grid_"] > [data-push-left*="_xs-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_xs-10"],
  [class*="p-grid-"] > [data-push-left*="_xs-10"],
  [class*="p-grid_"] > [data-push-left*="_xs-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-11"],
  [class*="p-grid-"] > [data-push-left*="_xs-11"],
  [class*="p-grid_"] > [data-push-left*="_xs-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-0"],
  [class*="p-grid-"] > [data-push-right*="_xs-0"],
  [class*="p-grid_"] > [data-push-right*="_xs-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_xs-1"],
  [class*="p-grid-"] > [data-push-right*="_xs-1"],
  [class*="p-grid_"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-2"],
  [class*="p-grid-"] > [data-push-right*="_xs-2"],
  [class*="p-grid_"] > [data-push-right*="_xs-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-3"],
  [class*="p-grid-"] > [data-push-right*="_xs-3"],
  [class*="p-grid_"] > [data-push-right*="_xs-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_xs-4"],
  [class*="p-grid-"] > [data-push-right*="_xs-4"],
  [class*="p-grid_"] > [data-push-right*="_xs-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-5"],
  [class*="p-grid-"] > [data-push-right*="_xs-5"],
  [class*="p-grid_"] > [data-push-right*="_xs-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-6"],
  [class*="p-grid-"] > [data-push-right*="_xs-6"],
  [class*="p-grid_"] > [data-push-right*="_xs-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_xs-7"],
  [class*="p-grid-"] > [data-push-right*="_xs-7"],
  [class*="p-grid_"] > [data-push-right*="_xs-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-8"],
  [class*="p-grid-"] > [data-push-right*="_xs-8"],
  [class*="p-grid_"] > [data-push-right*="_xs-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-9"],
  [class*="p-grid-"] > [data-push-right*="_xs-9"],
  [class*="p-grid_"] > [data-push-right*="_xs-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_xs-10"],
  [class*="p-grid-"] > [data-push-right*="_xs-10"],
  [class*="p-grid_"] > [data-push-right*="_xs-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-11"],
  [class*="p-grid-"] > [data-push-right*="_xs-11"],
  [class*="p-grid_"] > [data-push-right*="_xs-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_xs-first"],
  [class*="p-grid-"] [class*="_xs-first"],
  [class*="p-grid_"] [class*="_xs-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_xs-last"],
  [class*="p-grid-"] [class*="_xs-last"],
  [class*="p-grid_"] [class*="_xs-last"] {
    order: 1; } }

/************************
    HIDING COLS
*************************/
@media (max-width: 1024px) {
  [class*="lg-hidden"] {
    display: none; } }

@media (max-width: 768px) {
  [class*="md-hidden"] {
    display: none; } }

@media (max-width: 640px) {
  [class*="sm-hidden"] {
    display: none; } }

@media (max-width: 480px) {
  [class*="xs-hidden"] {
    display: none; } }

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
  text-align: center;
  margin-bottom: 10px; }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 10px; }
    .actions-toolbar > .primary .action:last-child,
    .actions-toolbar > .secondary .action:last-child {
      margin-bottom: 0; }
  .actions-toolbar > .primary:last-child,
  .actions-toolbar > .secondary:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 768px) {
  .actions-toolbar:before, .actions-toolbar:after {
    content: '';
    display: table; }
  .actions-toolbar:after {
    clear: both; }
  .actions-toolbar .primary {
    float: left; }
  .actions-toolbar .secondary {
    float: right; }
  .actions-toolbar .primary,
  .actions-toolbar .secondary {
    display: inline-block; }
    .actions-toolbar .primary a.action,
    .actions-toolbar .secondary a.action {
      display: inline-block; }
  .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .actions-toolbar > .secondary,
  .actions-toolbar > .primary {
    margin-bottom: 0; }
    .actions-toolbar > .secondary .action,
    .actions-toolbar > .primary .action {
      margin-bottom: 0;
      width: auto; } }

.breadcrumbs {
  margin: 0 0 20px; }
  .breadcrumbs .items {
    font-size: 1.2rem;
    color: #a3a3a3;
    margin: 0;
    padding: 0;
    list-style: none none; }
    .breadcrumbs .items > li {
      display: inline-block;
      vertical-align: top; }
  .breadcrumbs .item {
    margin: 0; }
  .breadcrumbs a {
    color: #3c3c3c;
    text-decoration: none; }
    .breadcrumbs a:visited {
      color: #3c3c3c;
      text-decoration: none; }
    .breadcrumbs a:hover {
      color: #3c3c3c;
      text-decoration: underline; }
    .breadcrumbs a:active {
      color: #3c3c3c;
      text-decoration: none; }
  .breadcrumbs strong {
    font-weight: 400; }
  .breadcrumbs .item:not(:last-child) {
    display: inline-block;
    text-decoration: none; }
    .breadcrumbs .item:not(:last-child):after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 24px;
      line-height: 18px;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
a.button,
button.button,
.cart.table-wrapper .actions-toolbar > .button.action,
.button.action-gift {
  -webkit-appearance: none;
  font-weight: 400;
  border-radius: 3px;
  padding: 0.7rem 1.5rem;
  font-size: 16px;
  text-transform: capitalize;
  background-color: #ffffff;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #e6e6e6;
  color: #1a1a1a;
  line-height: 1em;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  width: auto; }
  button:hover, .cart.table-wrapper .actions-toolbar > .action:hover, .action-gift:hover,
  a.button:hover,
  button.button:hover,
  .cart.table-wrapper .actions-toolbar > .button.action:hover,
  .button.action-gift:hover {
    background-color: #f2f2f2;
    text-decoration: none;
    border-width: 0 0 2px 0; }
  button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active,
  a.button:active,
  button.button:active,
  .cart.table-wrapper .actions-toolbar > .button.action:active,
  .button.action-gift:active {
    background-color: white; }
  button.action, .cart.table-wrapper .actions-toolbar > .action, .action.action-gift,
  a.button.action,
  button.button.action,
  .cart.table-wrapper .actions-toolbar > .button.action,
  .button.action.action-gift {
    background-color: #1c5275;
    border-color: #1c5275;
    color: #ffffff;
    border-width: 1px 1px 2px 1px; }
    button.action:hover, .cart.table-wrapper .actions-toolbar > .action:hover, .action.action-gift:hover,
    a.button.action:hover,
    button.button.action:hover,
    .cart.table-wrapper .actions-toolbar > .button.action:hover,
    .button.action.action-gift:hover {
      background-color: #1c5275; }
    button.action:active, .cart.table-wrapper .actions-toolbar > .action:active, .action.action-gift:active,
    a.button.action:active,
    button.button.action:active,
    .cart.table-wrapper .actions-toolbar > .button.action:active,
    .button.action.action-gift:active {
      background-color: #1c5275; }
  button.primary, .cart.table-wrapper .actions-toolbar > .primary.action, .primary.action-gift,
  a.button.primary,
  button.button.primary,
  .cart.table-wrapper .actions-toolbar > .button.primary.action,
  .button.primary.action-gift {
    background-color: #1c5275;
    border-color: #12354c;
    color: #ffffff; }
    button.primary:hover, .cart.table-wrapper .actions-toolbar > .primary.action:hover, .primary.action-gift:hover,
    a.button.primary:hover,
    button.button.primary:hover,
    .cart.table-wrapper .actions-toolbar > .button.primary.action:hover,
    .button.primary.action-gift:hover {
      background-color: #174460; }
    button.primary:active, .cart.table-wrapper .actions-toolbar > .primary.action:active, .primary.action-gift:active,
    a.button.primary:active,
    button.button.primary:active,
    .cart.table-wrapper .actions-toolbar > .button.primary.action:active,
    .button.primary.action-gift:active {
      background-color: #21608a; }
  button.tocart, .cart.table-wrapper .actions-toolbar > .tocart.action, .tocart.action-gift, button.cart, .cart.table-wrapper .actions-toolbar > .cart.action, .cart.action-gift,
  a.button.tocart,
  a.button.cart,
  button.button.tocart,
  .cart.table-wrapper .actions-toolbar > .button.tocart.action,
  .button.tocart.action-gift,
  button.button.cart,
  .cart.table-wrapper .actions-toolbar > .button.cart.action,
  .button.cart.action-gift {
    background-color: #1c5275;
    border-color: #12354c;
    color: #ffffff; }
    button.tocart:hover, .cart.table-wrapper .actions-toolbar > .tocart.action:hover, .tocart.action-gift:hover, button.cart:hover, .cart.table-wrapper .actions-toolbar > .cart.action:hover, .cart.action-gift:hover,
    a.button.tocart:hover,
    a.button.cart:hover,
    button.button.tocart:hover,
    .cart.table-wrapper .actions-toolbar > .button.tocart.action:hover,
    .button.tocart.action-gift:hover,
    button.button.cart:hover,
    .cart.table-wrapper .actions-toolbar > .button.cart.action:hover,
    .button.cart.action-gift:hover {
      background-color: #174460; }
    button.tocart:active, .cart.table-wrapper .actions-toolbar > .tocart.action:active, .tocart.action-gift:active, button.cart:active, .cart.table-wrapper .actions-toolbar > .cart.action:active, .cart.action-gift:active,
    a.button.tocart:active,
    a.button.cart:active,
    button.button.tocart:active,
    .cart.table-wrapper .actions-toolbar > .button.tocart.action:active,
    .button.tocart.action-gift:active,
    button.button.cart:active,
    .cart.table-wrapper .actions-toolbar > .button.cart.action:active,
    .button.cart.action-gift:active {
      background-color: #21608a; }
    .products-grid button.tocart, .products-grid .cart.table-wrapper .actions-toolbar > .tocart.action, .cart.table-wrapper .products-grid .actions-toolbar > .tocart.action, .products-grid .tocart.action-gift, .products-grid button.cart, .products-grid .cart.table-wrapper .actions-toolbar > .cart.action, .cart.table-wrapper .products-grid .actions-toolbar > .cart.action, .products-grid .cart.action-gift, .products-grid
    a.button.tocart, .products-grid
    a.button.cart, .products-grid button.button.tocart, .products-grid .cart.table-wrapper .actions-toolbar > .button.tocart.action, .cart.table-wrapper .products-grid .actions-toolbar > .button.tocart.action, .products-grid .button.tocart.action-gift, .products-grid button.button.cart, .products-grid .cart.table-wrapper .actions-toolbar > .button.cart.action, .cart.table-wrapper .products-grid .actions-toolbar > .button.cart.action, .products-grid .button.cart.action-gift {
      background-color: #ffffff;
      border-color: #1c5275;
      color: #1c5275;
      border-width: 1px 1px 2px 1px; }
      .products-grid button.tocart:hover, .products-grid .cart.table-wrapper .actions-toolbar > .tocart.action:hover, .cart.table-wrapper .products-grid .actions-toolbar > .tocart.action:hover, .products-grid .tocart.action-gift:hover, .products-grid button.tocart:active, .products-grid .cart.table-wrapper .actions-toolbar > .tocart.action:active, .cart.table-wrapper .products-grid .actions-toolbar > .tocart.action:active, .products-grid .tocart.action-gift:active, .products-grid button.cart:hover, .products-grid .cart.table-wrapper .actions-toolbar > .cart.action:hover, .cart.table-wrapper .products-grid .actions-toolbar > .cart.action:hover, .products-grid .cart.action-gift:hover, .products-grid button.cart:active, .products-grid .cart.table-wrapper .actions-toolbar > .cart.action:active, .cart.table-wrapper .products-grid .actions-toolbar > .cart.action:active, .products-grid .cart.action-gift:active, .products-grid
      a.button.tocart:hover, .products-grid
      a.button.tocart:active, .products-grid
      a.button.cart:hover, .products-grid
      a.button.cart:active, .products-grid button.button.tocart:hover, .products-grid .cart.table-wrapper .actions-toolbar > .button.tocart.action:hover, .cart.table-wrapper .products-grid .actions-toolbar > .button.tocart.action:hover, .products-grid .button.tocart.action-gift:hover, .products-grid button.button.tocart:active, .products-grid .cart.table-wrapper .actions-toolbar > .button.tocart.action:active, .cart.table-wrapper .products-grid .actions-toolbar > .button.tocart.action:active, .products-grid .button.tocart.action-gift:active, .products-grid button.button.cart:hover, .products-grid .cart.table-wrapper .actions-toolbar > .button.cart.action:hover, .cart.table-wrapper .products-grid .actions-toolbar > .button.cart.action:hover, .products-grid .button.cart.action-gift:hover, .products-grid button.button.cart:active, .products-grid .cart.table-wrapper .actions-toolbar > .button.cart.action:active, .cart.table-wrapper .products-grid .actions-toolbar > .button.cart.action:active, .products-grid .button.cart.action-gift:active {
        background-color: #ffffff;
        color: #174460;
        border-color: #174460; }

.abs-reset-list, .bundle-options-container .block-bundle-summary .bundle.items, .prices-tier, .cart-container .checkout-methods-items, .opc-progress-bar, .checkout-agreements-items, .items, .block-wishlist-management .wishlist-select-items, .paypal-review .items-qty, .order-details-items .items-qty, .footer.content ul, .block-banners .banner-items,
.block-banners-inline .banner-items, .block-event .slider-panel .slider {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .abs-reset-list > li, .bundle-options-container .block-bundle-summary .bundle.items > li, .prices-tier > li, .cart-container .checkout-methods-items > li, .opc-progress-bar > li, .checkout-agreements-items > li, .items > li, .block-wishlist-management .wishlist-select-items > li, .paypal-review .items-qty > li, .order-details-items .items-qty > li, .footer.content ul > li, .block-banners .banner-items > li, .block-banners-inline .banner-items > li, .block-event .slider-panel .slider > li {
    margin: 0; }

.abs-action-link-button, .abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist, .block-event .slider-panel .slider .item .action.event {
  background-image: none;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-bottom-width: 1px;
  transition: color background-color border-color 250ms ease;
  color: #1a1a1a;
  cursor: pointer;
  display: inline-block;
  font-family: "Myriad Pro Regular", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  border-radius: 0; }
  .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #1a1a1a; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #555; }
  .abs-action-link-button.disabled, .disabled.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .disabled.action, .product-info-main .disabled.action.tocompare,
  .product-options-bottom .disabled.action.tocompare, .product-info-main .disabled.action.towishlist,
  .product-options-bottom .disabled.action.towishlist,
  .block-bundle-summary .disabled.action.towishlist, .block-event .slider-panel .slider .item .disabled.action.event, .abs-action-link-button[disabled], .abs-action-addto-product[disabled], .bundle-options-container .block-bundle-summary .product-addto-links > .action[disabled], .product-info-main .action.tocompare[disabled],
  .product-options-bottom .action.tocompare[disabled], .product-info-main .action.towishlist[disabled],
  .product-options-bottom .action.towishlist[disabled],
  .block-bundle-summary .action.towishlist[disabled], .block-event .slider-panel .slider .item .action.event[disabled],
  fieldset[disabled] .abs-action-link-button,
  fieldset[disabled] .abs-action-addto-product,
  fieldset[disabled] .bundle-options-container .block-bundle-summary .product-addto-links > .action,
  .bundle-options-container .block-bundle-summary fieldset[disabled] .product-addto-links > .action,
  fieldset[disabled] .product-info-main .action.tocompare,
  .product-info-main fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-options-bottom .action.tocompare,
  .product-options-bottom fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-info-main .action.towishlist,
  .product-info-main fieldset[disabled] .action.towishlist,
  fieldset[disabled] .product-options-bottom .action.towishlist,
  .product-options-bottom fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-bundle-summary .action.towishlist,
  .block-bundle-summary fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-event .slider-panel .slider .item .action.event,
  .block-event .slider-panel .slider .item fieldset[disabled] .action.event {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active, .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus {
    text-decoration: none; }

.abs-product-options-list dt, .cart.table-wrapper .item-options dt, .block-giftregistry-shared-items .product .item-options dt, .multicheckout .item-options dt {
  clear: left;
  float: left;
  margin: 0 10px 5px 0; }
  .abs-product-options-list dt:after, .cart.table-wrapper .item-options dt:after, .block-giftregistry-shared-items .product .item-options dt:after, .multicheckout .item-options dt:after {
    content: ': '; }

.abs-product-options-list dd, .cart.table-wrapper .item-options dd, .block-giftregistry-shared-items .product .item-options dd, .multicheckout .item-options dd {
  display: inline-block;
  float: left;
  margin: 0 0 5px; }

@media only screen and (min-width: 768px) {
  .abs-product-options-list-desktop dt, .block-giftregistry-shared .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0; }
    .abs-product-options-list-desktop dt:after, .block-giftregistry-shared .item-options dt:after {
      content: ': '; }
  .abs-product-options-list-desktop dd, .block-giftregistry-shared .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px; } }

.abs-button-responsive, .actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .abs-button-desktop {
    width: auto; } }

@media only screen and (max-width: 767px) {
  .abs-button-responsive-smaller, .product-info-main .box-tocart .actions .action.tocart {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .abs-blocks-2columns, .column .block-addbysku .block-content .box, .login-container .block, .account .column.main .block:not(.widget) .block-content .box, .block-addresses-list .items.addresses > .item, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    width: 48.8%; }
    .abs-blocks-2columns:nth-child(odd), .column .block-addbysku .block-content .box:nth-child(odd), .login-container .block:nth-child(odd), .account .column.main .block:not(.widget) .block-content .box:nth-child(odd), .block-addresses-list .items.addresses > .item:nth-child(odd), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(odd), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns:nth-child(even), .column .block-addbysku .block-content .box:nth-child(even), .login-container .block:nth-child(even), .account .column.main .block:not(.widget) .block-content .box:nth-child(even), .block-addresses-list .items.addresses > .item:nth-child(even), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(even), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(even) {
      float: right; } }

@media only screen and (min-width: 640px) {
  .abs-blocks-2columns-s {
    width: 48.8%; }
    .abs-blocks-2columns-s:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns-s:nth-child(even) {
      float: right; } }

.abs-reset-image-wrapper, .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper, .minicart-items .product-image-wrapper, .block-giftregistry-shared-items .product-image-wrapper {
  height: auto;
  padding: 0 !important; }
  .abs-reset-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo, .block-giftregistry-shared-items .product-image-wrapper .product-image-photo {
    position: static; }

.abs-adaptive-images {
  display: block;
  height: auto;
  max-width: 100%; }

.abs-adaptive-images-centered, .product.media .product.photo .photo.image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%; }

.abs-login-block-title, .login-container .block .block-title {
  font-size: 1.8rem;
  border-bottom: 1px solid #999;
  margin-bottom: 15px;
  padding-bottom: 12px; }
  .abs-login-block-title strong, .login-container .block .block-title strong {
    font-weight: 500; }

.abs-block-title, .abs-account-blocks .block-title, .account .column.main .block:not(.widget) .block-title, .multicheckout .block-title, .paypal-review .block .block-title, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title, .sales-guest-view .column.main .block:not(.widget) .block-title, .block-compare .block-title, .block-giftregistry-results .block-title, .block-giftregistry-shared-items .block-title, .block-wishlist-search-results .block-title, .block-reorder .block-title, .widget .block-title, .block-wishlist .block-title {
  margin-bottom: 15px; }
  .abs-block-title > strong, .abs-account-blocks .block-title > strong, .account .column.main .block:not(.widget) .block-title > strong, .multicheckout .block-title > strong, .paypal-review .block .block-title > strong, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong, .sales-guest-view .column.main .block:not(.widget) .block-title > strong, .block-compare .block-title > strong, .block-giftregistry-results .block-title > strong, .block-giftregistry-shared-items .block-title > strong, .block-wishlist-search-results .block-title > strong, .block-reorder .block-title > strong, .widget .block-title > strong, .block-wishlist .block-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }

.abs-account-blocks .block-title > .action, .account .column.main .block:not(.widget) .block-title > .action, .multicheckout .block-title > .action, .paypal-review .block .block-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action, .sales-guest-view .column.main .block:not(.widget) .block-title > .action {
  margin-left: 15px; }

.abs-account-blocks .box-title, .account .column.main .block:not(.widget) .box-title, .multicheckout .box-title, .paypal-review .block .box-title, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title, .sales-guest-view .column.main .block:not(.widget) .box-title {
  display: inline-block;
  margin: 0 0 5px; }
  .abs-account-blocks .box-title > span, .account .column.main .block:not(.widget) .box-title > span, .multicheckout .box-title > span, .paypal-review .block .box-title > span, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > span, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span, .sales-guest-view .column.main .block:not(.widget) .box-title > span {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .abs-account-blocks .box-title > .action, .account .column.main .block:not(.widget) .box-title > .action, .multicheckout .box-title > .action, .paypal-review .block .box-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action, .sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px; }

.abs-account-blocks .block-content p:last-child, .account .column.main .block:not(.widget) .block-content p:last-child, .multicheckout .block-content p:last-child, .paypal-review .block .block-content p:last-child, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content p:last-child, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child, .sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
  margin-bottom: 0; }

.abs-account-blocks .block-content .box, .account .column.main .block:not(.widget) .block-content .box, .multicheckout .block-content .box, .paypal-review .block .block-content .box, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box, .sales-guest-view .column.main .block:not(.widget) .block-content .box {
  margin-bottom: 20px; }

.abs-dropdown-simple, .giftregisty-dropdown, .wishlist-dropdown {
  display: inline-block;
  position: relative; }
  .abs-dropdown-simple:before, .giftregisty-dropdown:before, .wishlist-dropdown:before, .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    content: '';
    display: table; }
  .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    clear: both; }
  .abs-dropdown-simple .action.toggle, .giftregisty-dropdown .action.toggle, .wishlist-dropdown .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .abs-dropdown-simple .action.toggle:after, .giftregisty-dropdown .action.toggle:after, .wishlist-dropdown .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0 0 0 5px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .abs-dropdown-simple .action.toggle:hover:after, .giftregisty-dropdown .action.toggle:hover:after, .wishlist-dropdown .action.toggle:hover:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle:active:after, .giftregisty-dropdown .action.toggle:active:after, .wishlist-dropdown .action.toggle:active:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle.active, .giftregisty-dropdown .action.toggle.active, .wishlist-dropdown .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-dropdown-simple .action.toggle.active:after, .giftregisty-dropdown .action.toggle.active:after, .wishlist-dropdown .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0 0 0 5px;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .abs-dropdown-simple .action.toggle.active:hover:after, .giftregisty-dropdown .action.toggle.active:hover:after, .wishlist-dropdown .action.toggle.active:hover:after {
        color: inherit; }
      .abs-dropdown-simple .action.toggle.active:active:after, .giftregisty-dropdown .action.toggle.active:active:after, .wishlist-dropdown .action.toggle.active:active:after {
        color: inherit; }
  .abs-dropdown-simple ul.dropdown, .giftregisty-dropdown ul.dropdown, .wishlist-dropdown ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-dropdown-simple ul.dropdown li, .giftregisty-dropdown ul.dropdown li, .wishlist-dropdown ul.dropdown li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .abs-dropdown-simple ul.dropdown li:hover, .giftregisty-dropdown ul.dropdown li:hover, .wishlist-dropdown ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before, .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #ffffff transparent;
      z-index: 99; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      left: 10px;
      top: -12px; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      left: 9px;
      top: -14px; }
  .abs-dropdown-simple.active, .active.giftregisty-dropdown, .active.wishlist-dropdown {
    overflow: visible; }
    .abs-dropdown-simple.active ul.dropdown, .active.giftregisty-dropdown ul.dropdown, .active.wishlist-dropdown ul.dropdown {
      display: block; }

.abs-input-qty, .column .block-addbysku .qty .qty, .bundle-options-container .input-text.qty, .cart.table-wrapper .col.qty .input-text, .account .table-return-items .qty .input-text, .table-giftregistry-items .field.qty .qty, .block-giftregistry-shared-items .qty .qty, .table.grouped .control .qty, .block-wishlist-info-items .input-text.qty, .multicheckout .table-wrapper .col .qty .input-text, .account .table-wrapper .data.table.wishlist .box-tocart .qty, .products-grid.wishlist .product-item .box-tocart input.qty, .sidebar .block-addbysku .fieldset .fields .field.qty .qty {
  text-align: center;
  width: 47px; }

.abs-margin-for-blocks-and-widgets, .block, .block-banners,
.block-banners-inline {
  margin-bottom: 40px; }

.abs-remove-button-for-blocks, .sidebar .action.delete, .table-comparison .cell.remove .action.delete, .block-compare .action.delete, .filter-current .action.remove, .filtered .item .action.remove {
  display: inline-block;
  text-decoration: none; }
  .abs-remove-button-for-blocks > span, .sidebar .action.delete > span, .table-comparison .cell.remove .action.delete > span, .block-compare .action.delete > span, .filter-current .action.remove > span, .filtered .item .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-remove-button-for-blocks:before, .sidebar .action.delete:before, .table-comparison .cell.remove .action.delete:before, .block-compare .action.delete:before, .filter-current .action.remove:before, .filtered .item .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-remove-button-for-blocks:hover:before, .sidebar .action.delete:hover:before, .table-comparison .cell.remove .action.delete:hover:before, .block-compare .action.delete:hover:before, .filter-current .action.remove:hover:before, .filtered .item .action.remove:hover:before {
    color: #303030; }
  .abs-remove-button-for-blocks:active:before, .sidebar .action.delete:active:before, .table-comparison .cell.remove .action.delete:active:before, .block-compare .action.delete:active:before, .filter-current .action.remove:active:before, .filtered .item .action.remove:active:before {
    color: #303030; }

.abs-product-link, .product.name a {
  font-weight: 400; }
  .abs-product-link > a, .product.name a > a {
    color: #1a1a1a;
    text-decoration: none; }
    .abs-product-link > a:visited, .product.name a > a:visited {
      color: #1a1a1a;
      text-decoration: underline; }
    .abs-product-link > a:hover, .product.name a > a:hover {
      color: #1a1a1a;
      text-decoration: underline; }
    .abs-product-link > a:active, .product.name a > a:active {
      color: #1a1a1a;
      text-decoration: underline; }

.abs-reset-left-margin, .form.giftregistry.items .actions-toolbar, .form-new-agreement .actions-toolbar, .fieldset .review-legend.legend, .form.wishlist.items .actions-toolbar {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .abs-reset-left-margin-desktop, .bundle-options-container .legend.title, .column.main .cart-summary .actions-toolbar, .cart.table-wrapper .item-actions .actions-toolbar, .gift-summary .actions-toolbar,
  .cart.table-wrapper .gift-summary .actions-toolbar, .column.main .block-giftregistry-shared-items .actions-toolbar, .form-new-agreement .fieldset .legend, .form-new-agreement .actions-toolbar, .column.main .paypal-review .actions-toolbar, .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
    margin-left: 0; } }

@media only screen and (min-width: 640px) {
  .abs-reset-left-margin-desktop-s, .column:not(.sidebar-main) .multicheckout .actions-toolbar, .multicheckout .block-shipping .box-shipping-method .fieldset .legend {
    margin-left: 0; } }

.abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove {
  left: 10px;
  margin-left: 70%;
  position: absolute;
  top: 31px;
  width: auto; }

@media only screen and (min-width: 768px) {
  .abs-action-remove-desktop, .abs-add-fields-desktop .fieldset .additional .action.remove, .form-giftregistry-share .fieldset .additional .action.remove, .form-giftregistry-edit .fieldset .additional .action.remove, .form-add-invitations .fieldset .additional .action.remove, .form-create-return .fieldset .additional .action.remove, .form.send.friend .fieldset .additional .action.remove {
    margin-left: 75.8%;
    top: 6px; } }

.abs-add-fields .fieldset, .form-giftregistry-share .fieldset, .form-giftregistry-edit .fieldset, .form-add-invitations .fieldset, .form-create-return .fieldset, .form.send.friend .fieldset {
  margin-bottom: 50px; }
  .abs-add-fields .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 70%; }
  .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: left; }
    .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
      margin-top: 30px; }
  .abs-add-fields .fieldset .fields .actions-toolbar, .form-giftregistry-share .fieldset .fields .actions-toolbar, .form-giftregistry-edit .fieldset .fields .actions-toolbar, .form-add-invitations .fieldset .fields .actions-toolbar, .form-create-return .fieldset .fields .actions-toolbar, .form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0; }

.abs-add-fields .message.notice, .form-giftregistry-share .message.notice, .form-giftregistry-edit .message.notice, .form-add-invitations .message.notice, .form-create-return .message.notice, .form.send.friend .message.notice {
  margin: 30px 0 0; }

.abs-add-fields .additional, .form-giftregistry-share .additional, .form-giftregistry-edit .additional, .form-add-invitations .additional, .form-create-return .additional, .form.send.friend .additional {
  margin-top: 55px;
  position: relative; }

@media only screen and (min-width: 768px) {
  .abs-add-fields-desktop .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 50%; } }

@media only screen and (min-width: 768px) {
  .abs-margin-for-forms-desktop, .column:not(.sidebar-additional) form .actions-toolbar,
  .column:not(.sidebar-main) form .actions-toolbar, .fieldset .legend, .login-container .fieldset:after {
    margin-left: 25.8%; } }

@media only screen and (min-width: 640px) {
  .abs-margin-for-forms-desktop-s {
    margin-left: 25.8%; } }

.abs-hidden, .order-details-items .order-items .order-gift-message:not(.expanded-content) {
  height: 0;
  visibility: hidden; }

.abs-visually-hidden, .fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, .product-item-actions .actions-secondary > .action span, .table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span, .cart.table-wrapper .col.qty .label, .minicart-wrapper .action.showcart .counter-label, .checkout-index-index .page-title-wrapper,
.checkout-onepage-success .page-title-wrapper, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .account .column.main .limiter > .label, .field.street .field.additional .label, .account-nav .title, .page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, .page-product-giftcard .fieldset.giftcard > .legend, .opc-wrapper .form-giftcard-account .field .label, .filter-options-content .filter-count-label, .block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .page-multiple-wishlist .page-title-wrapper .page-title, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block.newsletter .label, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label, .action.skip:not(:focus), .page-header .switcher .label,
.page-footer .switcher .label, .products-grid.wishlist .product-item .comment-box .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@media only screen and (max-width: 639px) {
  .abs-visually-hidden-mobile, .table-giftregistry-items .col.product:before {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (max-width: 767px) {
  .abs-visually-hidden-mobile-m {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 640px) {
  .abs-visually-hidden-desktop-s {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 768px) {
  .abs-visually-hidden-desktop, .modes-label, .block-search .label, .dashboard-welcome-toggler, .block-collapsible-nav .title {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

.abs-visually-hidden-reset, .shipping-policy-block.field-tooltip .field-tooltip-action span {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.abs-add-clearfix:before, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):before, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):before, .form-create-return .fieldset .actions-toolbar:not(:first-child):before, .form.send.friend .fieldset .actions-toolbar:not(:first-child):before, .payment-method-braintree .hosted-date-wrap:before, .toolbar:before, .sidebar .product-items-images:before, .cart.table-wrapper .actions-toolbar:before, .cart.table-wrapper .item-options:before, .minicart-items .product-item > .product:before, .checkout-container:before, .opc-estimated-wrapper:before, .opc-wrapper .field.addresses:before, .page-product-downloadable .product-add-form:before, .gift-options:before, .gift-item-block .content:before, .order-details-items .order-gift-message:before,
.order-details-items .block-order-details-gift-message:before, .block-giftregistry-shared-items .product .item-options:before, .gift-wrapping-list:before, .opc-wrapper .data.table .gift-wrapping:before,
.table-order-items .gift-wrapping:before,
.table-order-review .gift-wrapping:before, .order-options .product:before, .map-popup .map-info-price:before, .multicheckout .item-options:before, .multicheckout .block:before, .multicheckout .block-content:before, .customer-review .product-details:before, .abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .checkout-container:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after {
  content: '';
  display: table; }

.abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .checkout-container:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after {
  clear: both; }

@media only screen and (min-width: 768px) {
  .abs-add-clearfix-desktop:before, .abs-pager-toolbar:before, .account .toolbar:before, .toolbar-giftregistry-results:before, .toolbar-wishlist-results:before, .column .block-addbysku .block-content:before, .block-cart-failed .block-content:before, .cart-container:before, .login-container:before, .account .column.main .block:not(.widget) .block-content:before, .block-addresses-list .items.addresses:before, .block-giftregistry-shared .item-options:before, .gift-wrapping .nested:before, .table .gift-wrapping .content:before, .block-wishlist-management:before, .paypal-review .block-content:before, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:before, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
  .sales-guest-view .column.main .block:not(.widget) .block-content:before, .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after {
    clear: both; } }

@media only screen and (min-width: 640px) {
  .abs-add-clearfix-desktop-s:before, .multicheckout .block-billing:before, .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    clear: both; } }

@media only screen and (max-width: 639px) {
  .abs-add-clearfix-mobile:before, .abs-checkout-order-review tbody tr:before, .multicheckout.order-review .data.table tbody tr:before, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before, .table-giftregistry-items .col.product:before, .account .toolbar:before, .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .abs-add-clearfix-mobile-m:before, .form-create-return .fieldset > .actions-toolbar:before, .abs-add-clearfix-mobile-m:after, .form-create-return .fieldset > .actions-toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile-m:after, .form-create-return .fieldset > .actions-toolbar:after {
    clear: both; } }

.abs-add-box-sizing, .abs-field-date .control, .field.date .control, .abs-field-tooltip, .field .control._with-tooltip, .columns .column.main, .columns .sidebar-main, .columns .sidebar-additional, .column .block-addbysku .sku, .bundle-options-container .block-bundle-summary, .sidebar .product-items-images .product-item, .search-autocomplete, .opc-wrapper .shipping-address-item, .shipping-policy-block.field-tooltip .field-tooltip-content, .opc-block-summary, .sidebar .block-addbysku .fieldset .fields .field.sku, .block-event .slider-panel .slider .item .action.show {
  box-sizing: border-box; }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop, .column.main, .sidebar-main, .sidebar-additional {
    box-sizing: border-box; } }

@media only screen and (min-width: 640px) {
  .abs-add-box-sizing-desktop-s, .multicheckout .block-shipping .box, .multicheckout .block-billing .box-billing-address, .multicheckout .block-billing .box-billing-method {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop-m {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-revert-field-type-desktop .fieldset > .field, .product-add-form .fieldset > .field,
  .abs-revert-field-type-desktop .fieldset .fields > .field,
  .product-add-form .fieldset .fields > .field {
    margin: 0 0 20px; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label, .product-add-form .fieldset > .field:not(.choice) > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label,
    .product-add-form .fieldset .fields > .field:not(.choice) > .label {
      box-sizing: content-box;
      float: none;
      width: auto;
      text-align: left;
      padding: 0; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control, .product-add-form .fieldset > .field:not(.choice) > .control,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control,
    .product-add-form .fieldset .fields > .field:not(.choice) > .control {
      float: none;
      width: auto; }
    .abs-revert-field-type-desktop .fieldset > .field > .label, .product-add-form .fieldset > .field > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field > .label,
    .product-add-form .fieldset .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .abs-revert-field-type-desktop .fieldset > .field.choice:before, .product-add-form .fieldset > .field.choice:before, .abs-revert-field-type-desktop .fieldset > .field.no-label:before, .product-add-form .fieldset > .field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
    .product-add-form .fieldset .fields > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before,
    .product-add-form .fieldset .fields > .field.no-label:before {
      display: none; }
    .abs-revert-field-type-desktop .fieldset > .field:not(:first-child):last-of-type, .product-add-form .fieldset > .field:not(:first-child):last-of-type,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(:first-child):last-of-type,
    .product-add-form .fieldset .fields > .field:not(:first-child):last-of-type {
      margin-bottom: 0; } }

.abs-navigation-icon {
  display: block;
  text-decoration: none; }
  .abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-navigation-icon:after {
    position: absolute;
    right: 5px;
    top: 0; }

.abs-split-button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .abs-split-button:before, .abs-split-button:after {
    content: '';
    display: table; }
  .abs-split-button:after {
    clear: both; }
  .abs-split-button .action.split {
    float: left;
    margin: 0; }
  .abs-split-button .action.toggle {
    float: right;
    margin: 0; }
  .abs-split-button button.action.split, .abs-split-button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .abs-split-button .actions-toolbar > .action.split, .abs-split-button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .abs-split-button button + .action.toggle, .abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .abs-split-button .actions-toolbar > .action + .action.toggle, .abs-split-button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .abs-split-button .action.toggle {
    padding: 4px 5px;
    display: inline-block;
    text-decoration: none; }
    .abs-split-button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .abs-split-button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .abs-split-button .action.toggle:hover:after {
      color: inherit; }
    .abs-split-button .action.toggle:active:after {
      color: inherit; }
    .abs-split-button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-split-button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .abs-split-button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .abs-split-button .action.toggle.active:hover:after {
        color: inherit; }
      .abs-split-button .action.toggle.active:active:after {
        color: inherit; }
  .abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-split-button .items li {
      margin: 0;
      padding: 3px 5px; }
      .abs-split-button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-split-button .items:before, .abs-split-button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-split-button .items:before {
      border: 6px solid;
      border-color: transparent transparent #ffffff transparent;
      z-index: 99; }
    .abs-split-button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-split-button .items:before {
      right: 10px;
      top: -12px; }
    .abs-split-button .items:after {
      right: 9px;
      top: -14px; }
  .abs-split-button.active {
    overflow: visible; }
    .abs-split-button.active .items {
      display: block; }

.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem; }

.abs-actions-addto-gridlist, .product-item-actions .actions-secondary > .action, .table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split, .product-items .action.towishlist,
.products.list.items .action.towishlist,
.table-comparison .action.towishlist {
  display: inline-block;
  text-decoration: none; }
  .abs-actions-addto-gridlist > span, .product-item-actions .actions-secondary > .action > span, .table-comparison .wishlist.split.button > .action.split > span, .product-items .wishlist.split.button > .action.split > span, .product-items .action.towishlist > span, .products.list.items .action.towishlist > span, .table-comparison .action.towishlist > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-actions-addto-gridlist:before, .product-item-actions .actions-secondary > .action:before, .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before, .product-items .action.towishlist:before,
  .products.list.items .action.towishlist:before,
  .table-comparison .action.towishlist:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
    line-height: 24px;
    color: #5e5e5e;
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-actions-addto-gridlist:hover:before, .product-item-actions .actions-secondary > .action:hover:before, .table-comparison .wishlist.split.button > .action.split:hover:before,
  .product-items .wishlist.split.button > .action.split:hover:before, .product-items .action.towishlist:hover:before,
  .products.list.items .action.towishlist:hover:before,
  .table-comparison .action.towishlist:hover:before {
    color: #6b6b6b; }

.abs-button-l, .bundle-actions .action.primary, .bundle-options-container .block-bundle-summary .box-tocart .action.primary, .product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart, .cart-container .checkout-methods-items .action.primary, .block-minicart .block-content > .actions > .primary .action.primary, .methods-shipping .actions-toolbar .action.primary, .block-authentication .action.action-register,
.block-authentication .action.action-login, .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary, .form-address-edit .actions-toolbar .action.primary, .multicheckout .action.primary {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem; }

.abs-action-button-as-link, .abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove, .cart.table-wrapper .action.help.map, .opc-wrapper .edit-address-link, .opc-block-shipping-information .shipping-information-title .action-edit, .checkout-payment-method .checkout-billing-address .action-cancel, .checkout-agreements-block .action-show, .cart-summary .block.giftcard .action.check, .gift-options .actions-toolbar .action-cancel, .table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action, .paypal-button-widget .paypal-button {
  line-height: 1.42857;
  padding: 0;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  border-radius: 0;
  font-size: inherit;
  font-weight: 400; }
  .abs-action-button-as-link:visited, .abs-action-remove:visited, .abs-add-fields .action.remove:visited, .form-giftregistry-share .action.remove:visited, .form-giftregistry-edit .action.remove:visited, .form-add-invitations .action.remove:visited, .form-create-return .action.remove:visited, .form.send.friend .action.remove:visited, .cart.table-wrapper .action.help.map:visited, .opc-wrapper .edit-address-link:visited, .opc-block-shipping-information .shipping-information-title .action-edit:visited, .checkout-payment-method .checkout-billing-address .action-cancel:visited, .checkout-agreements-block .action-show:visited, .cart-summary .block.giftcard .action.check:visited, .gift-options .actions-toolbar .action-cancel:visited, .table-comparison .wishlist.split.button > .action:visited,
  .product-items .wishlist.split.button > .action:visited, .paypal-button-widget .paypal-button:visited {
    color: #1c5275;
    text-decoration: none; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #006bb4;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active {
    color: #2196f3;
    text-decoration: underline; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #006bb4; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover, .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:focus, .abs-action-remove:focus, .abs-add-fields .action.remove:focus, .form-giftregistry-share .action.remove:focus, .form-giftregistry-edit .action.remove:focus, .form-add-invitations .action.remove:focus, .form-create-return .action.remove:focus, .form.send.friend .action.remove:focus, .cart.table-wrapper .action.help.map:focus, .opc-wrapper .edit-address-link:focus, .opc-block-shipping-information .shipping-information-title .action-edit:focus, .checkout-payment-method .checkout-billing-address .action-cancel:focus, .checkout-agreements-block .action-show:focus, .cart-summary .block.giftcard .action.check:focus, .gift-options .actions-toolbar .action-cancel:focus, .table-comparison .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:focus, .paypal-button-widget .paypal-button:focus {
    background: none;
    border: 0; }
  .abs-action-button-as-link.disabled, .disabled.abs-action-remove, .abs-add-fields .disabled.action.remove, .form-giftregistry-share .disabled.action.remove, .form-giftregistry-edit .disabled.action.remove, .form-add-invitations .disabled.action.remove, .form-create-return .disabled.action.remove, .form.send.friend .disabled.action.remove, .cart.table-wrapper .disabled.action.help.map, .opc-wrapper .disabled.edit-address-link, .opc-block-shipping-information .shipping-information-title .disabled.action-edit, .checkout-payment-method .checkout-billing-address .disabled.action-cancel, .checkout-agreements-block .disabled.action-show, .cart-summary .block.giftcard .disabled.action.check, .gift-options .actions-toolbar .disabled.action-cancel, .table-comparison .wishlist.split.button > .disabled.action,
  .product-items .wishlist.split.button > .disabled.action, .paypal-button-widget .disabled.paypal-button, .abs-action-button-as-link[disabled], .abs-action-remove[disabled], .abs-add-fields .action.remove[disabled], .form-giftregistry-share .action.remove[disabled], .form-giftregistry-edit .action.remove[disabled], .form-add-invitations .action.remove[disabled], .form-create-return .action.remove[disabled], .form.send.friend .action.remove[disabled], .cart.table-wrapper .action.help.map[disabled], .opc-wrapper .edit-address-link[disabled], .opc-block-shipping-information .shipping-information-title .action-edit[disabled], .checkout-payment-method .checkout-billing-address .action-cancel[disabled], .checkout-agreements-block .action-show[disabled], .cart-summary .block.giftcard .action.check[disabled], .gift-options .actions-toolbar .action-cancel[disabled], .table-comparison .wishlist.split.button > .action[disabled],
  .product-items .wishlist.split.button > .action[disabled], .paypal-button-widget .paypal-button[disabled],
  fieldset[disabled] .abs-action-button-as-link,
  fieldset[disabled] .abs-action-remove,
  fieldset[disabled] .abs-add-fields .action.remove,
  .abs-add-fields fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-share .action.remove,
  .form-giftregistry-share fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-edit .action.remove,
  .form-giftregistry-edit fieldset[disabled] .action.remove,
  fieldset[disabled] .form-add-invitations .action.remove,
  .form-add-invitations fieldset[disabled] .action.remove,
  fieldset[disabled] .form-create-return .action.remove,
  .form-create-return fieldset[disabled] .action.remove,
  fieldset[disabled] .form.send.friend .action.remove,
  .form.send.friend fieldset[disabled] .action.remove,
  fieldset[disabled] .cart.table-wrapper .action.help.map,
  .cart.table-wrapper fieldset[disabled] .action.help.map,
  fieldset[disabled] .opc-wrapper .edit-address-link,
  .opc-wrapper fieldset[disabled] .edit-address-link,
  fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
  .opc-block-shipping-information .shipping-information-title fieldset[disabled] .action-edit,
  fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
  .checkout-payment-method .checkout-billing-address fieldset[disabled] .action-cancel,
  fieldset[disabled] .checkout-agreements-block .action-show,
  .checkout-agreements-block fieldset[disabled] .action-show,
  fieldset[disabled] .cart-summary .block.giftcard .action.check,
  .cart-summary .block.giftcard fieldset[disabled] .action.check,
  fieldset[disabled] .gift-options .actions-toolbar .action-cancel,
  .gift-options .actions-toolbar fieldset[disabled] .action-cancel,
  fieldset[disabled] .table-comparison .wishlist.split.button > .action,
  .table-comparison fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .product-items .wishlist.split.button > .action,
  .product-items fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .paypal-button-widget .paypal-button,
  .paypal-button-widget fieldset[disabled] .paypal-button {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:not(:focus), .abs-action-remove:not(:focus), .abs-add-fields .action.remove:not(:focus), .form-giftregistry-share .action.remove:not(:focus), .form-giftregistry-edit .action.remove:not(:focus), .form-add-invitations .action.remove:not(:focus), .form-create-return .action.remove:not(:focus), .form.send.friend .action.remove:not(:focus), .cart.table-wrapper .action.help.map:not(:focus), .opc-wrapper .edit-address-link:not(:focus), .opc-block-shipping-information .shipping-information-title .action-edit:not(:focus), .checkout-payment-method .checkout-billing-address .action-cancel:not(:focus), .checkout-agreements-block .action-show:not(:focus), .cart-summary .block.giftcard .action.check:not(:focus), .gift-options .actions-toolbar .action-cancel:not(:focus), .table-comparison .wishlist.split.button > .action:not(:focus),
  .product-items .wishlist.split.button > .action:not(:focus), .paypal-button-widget .paypal-button:not(:focus) {
    box-shadow: none; }

.abs-revert-secondary-color, .block-compare .action.compare, .summary .actions-toolbar > .primary button, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action, .summary .actions-toolbar > .primary .action-gift, .cart-summary .form.giftregistry .fieldset .action.primary, .paypal-review .block .actions-toolbar .action.primary {
  background: #ffffff;
  border: 1px solid #cdcdcd;
  color: #1a1a1a; }
  .abs-revert-secondary-color:focus, .block-compare .action.compare:focus, .summary .actions-toolbar > .primary button:focus, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action:focus, .summary .actions-toolbar > .primary .action-gift:focus, .cart-summary .form.giftregistry .fieldset .action.primary:focus, .paypal-review .block .actions-toolbar .action.primary:focus, .abs-revert-secondary-color:active, .block-compare .action.compare:active, .summary .actions-toolbar > .primary button:active, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action:active, .summary .actions-toolbar > .primary .action-gift:active, .cart-summary .form.giftregistry .fieldset .action.primary:active, .paypal-review .block .actions-toolbar .action.primary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #1a1a1a; }
  .abs-revert-secondary-color:hover, .block-compare .action.compare:hover, .summary .actions-toolbar > .primary button:hover, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action:hover, .summary .actions-toolbar > .primary .action-gift:hover, .cart-summary .form.giftregistry .fieldset .action.primary:hover, .paypal-review .block .actions-toolbar .action.primary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }

.abs-revert-secondary-size, .cart-summary .form.giftregistry .fieldset .action.primary {
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 7px 15px; }

.abs-box-tocart, .bundle-actions {
  margin: 10px 0; }

.abs-adjustment-incl-excl-tax .price-including-tax, .bundle-options-container .price-notice .price-including-tax, .product-options-wrapper .field .price-notice .price-including-tax, .summary .price-including-tax, .gift-summary .price-including-tax, .gift-options .price-including-tax,
.abs-adjustment-incl-excl-tax .price-excluding-tax,
.bundle-options-container .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.summary .price-excluding-tax,
.gift-summary .price-excluding-tax,
.gift-options .price-excluding-tax,
.abs-adjustment-incl-excl-tax .weee,
.bundle-options-container .price-notice .weee,
.product-options-wrapper .field .price-notice .weee,
.summary .weee,
.gift-summary .weee,
.gift-options .weee {
  font-size: 1.4rem;
  display: inline-block;
  white-space: nowrap; }

.abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax, .summary .price-including-tax + .price-excluding-tax, .gift-summary .price-including-tax + .price-excluding-tax, .gift-options .price-including-tax + .price-excluding-tax {
  display: inline-block;
  font-size: 1.1rem; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:before, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:before, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before, .summary .price-including-tax + .price-excluding-tax:before, .gift-summary .price-including-tax + .price-excluding-tax:before, .gift-options .price-including-tax + .price-excluding-tax:before {
    content: "(" attr(data-label) ": "; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:after, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:after, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after, .summary .price-including-tax + .price-excluding-tax:after, .gift-summary .price-including-tax + .price-excluding-tax:after, .gift-options .price-including-tax + .price-excluding-tax:after {
    content: ')'; }

.abs-tax-total, .price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
  cursor: pointer;
  padding-right: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 10px;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px; }
  .abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
  .price-excluding-tax .cart-tax-total-expanded:after {
    content: ""; }

.abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after {
  content: ""; }

.abs-methods-shipping-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 15px; }

@media only screen and (max-width: 639px) {
  .abs-checkout-order-review tbody tr:not(:last-child), .multicheckout.order-review .data.table tbody tr:not(:last-child) {
    border-bottom: 1px solid #d1d1d1; }
  .abs-checkout-order-review tbody tr .col.item:before, .multicheckout.order-review .data.table tbody tr .col.item:before {
    display: none; }
  .abs-checkout-order-review tbody tr .col.qty, .multicheckout.order-review .data.table tbody tr .col.qty, .abs-checkout-order-review tbody tr .col.price, .multicheckout.order-review .data.table tbody tr .col.price, .abs-checkout-order-review tbody tr .col.subtotal, .multicheckout.order-review .data.table tbody tr .col.subtotal {
    box-sizing: border-box;
    float: left;
    text-align: center;
    white-space: nowrap;
    width: 33%; }
    .abs-checkout-order-review tbody tr .col.qty:before, .multicheckout.order-review .data.table tbody tr .col.qty:before, .abs-checkout-order-review tbody tr .col.price:before, .multicheckout.order-review .data.table tbody tr .col.price:before, .abs-checkout-order-review tbody tr .col.subtotal:before, .multicheckout.order-review .data.table tbody tr .col.subtotal:before {
      content: attr(data-th) ":";
      display: block;
      font-weight: 700;
      padding-bottom: 10px; }
  .abs-checkout-order-review tbody tr .product-item-name, .multicheckout.order-review .data.table tbody tr .product-item-name {
    margin: 0; } }

.abs-colon:after, .minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after, .order-details-items .order-gift-message .label:after,
.order-details-items .block-order-details-gift-message .label:after, .block-wishlist-management .wishlist-select .wishlist-name-label:after {
  content: ': '; }

.abs-icon-add, .abs-dropdown-items-new .action.new, .wishlist.split.button .action.new, .wishlist-dropdown .action.new {
  display: inline-block;
  text-decoration: none; }
  .abs-icon-add:before, .abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }

@media only screen and (max-width: 767px) {
  .abs-icon-add-mobile, .block-wishlist-management .wishlist-add.item .add {
    display: block;
    text-decoration: none; }
    .abs-icon-add-mobile:before, .block-wishlist-management .wishlist-add.item .add:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 10px;
      line-height: 10px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      margin: 0 5px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; } }

.abs-dropdown-items-new .items .item:last-child:hover, .wishlist.split.button .items .item:last-child:hover, .wishlist-dropdown .items .item:last-child:hover {
  background: #e8e8e8; }

.abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
  margin-left: -17px;
  margin-right: 5px; }

@media only screen and (max-width: 767px) {
  .abs-toggling-title-mobile, .block-wishlist-management .wishlist-select .wishlist-name, .block-collapsible-nav .title {
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    display: block;
    text-decoration: none; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after, .block-collapsible-nav .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: inherit;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after, .block-collapsible-nav .title:after {
      position: absolute;
      right: 10px;
      top: 0; }
    .abs-toggling-title-mobile.active:after, .block-wishlist-management .wishlist-select .active.wishlist-name:after, .block-collapsible-nav .active.title:after {
      content: ""; } }

.abs-no-display, .abs-sidebar-totals .table-caption, .cart-totals .table-caption, .opc-block-summary .table-totals .table-caption, .sidebar .subtitle, .summary .block .fieldset.estimate > .legend,
.summary .block .fieldset.estimate > .legend + br, .minicart-items .product-item-details .weee[data-label] .label, .page-product-giftcard .fieldset.giftcard > .legend + br, .order-details-items .order-items .order-gift-message .action.close, .multicheckout .block .methods-shipping .item-content .fieldset > .legend + br, .multicheckout .hidden, [class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle, .sidebar-main .block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle, .no-display {
  display: none; }

@media only screen and (max-width: 639px) {
  .abs-no-display-s, .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th, .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: none; } }

@media only screen and (min-width: 768px) {
  .abs-no-display-desktop, .opc-estimated-wrapper {
    display: none; } }

.abs-status, .return-status, .order-status {
  display: inline-block;
  margin-bottom: 20px; }

@media only screen and (max-width: 767px) {
  .abs-pager-toolbar-mobile .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-wishlist-results .toolbar-amount,
  .abs-pager-toolbar-mobile .limiter,
  .account .toolbar .limiter,
  .toolbar-wishlist-results .limiter,
  .abs-pager-toolbar-mobile .pages,
  .account .toolbar .pages,
  .toolbar-wishlist-results .pages {
    float: none;
    margin-bottom: 25px; } }

@media only screen and (max-width: 639px) {
  .abs-pager-toolbar-mobile-s .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount,
  .abs-pager-toolbar-mobile-s .limiter,
  .toolbar-giftregistry-results .limiter,
  .abs-pager-toolbar-mobile-s .pages,
  .toolbar-giftregistry-results .pages {
    margin-bottom: 25px; } }

@media only screen and (min-width: 768px) {
  .abs-pager-toolbar, .account .toolbar, .toolbar-giftregistry-results, .toolbar-wishlist-results {
    margin-bottom: 20px;
    position: relative; }
    .abs-pager-toolbar .limiter, .account .toolbar .limiter, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .limiter {
      float: right;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount, .toolbar-wishlist-results .toolbar-amount {
      float: left;
      line-height: normal;
      padding: 7px 0 0;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .pages, .account .toolbar .pages, .toolbar-giftregistry-results .pages, .toolbar-wishlist-results .pages {
      position: absolute;
      width: 100%;
      z-index: 0; } }

.abs-block-items-counter, .block-compare .counter, .block-wishlist .counter {
  color: #868686;
  font-size: 1.2rem;
  white-space: nowrap; }

.abs-shopping-cart-items .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue {
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-bottom-width: 1px;
  transition: color background-color border-color 250ms ease;
  color: #1a1a1a;
  cursor: pointer;
  display: inline-block;
  font-family: "Myriad Pro Regular", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 7px 15px 7px 0;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active, .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus {
    text-decoration: none; }
  .abs-shopping-cart-items .action.continue:before, .block-cart-failed .action.continue:before, .cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.continue:hover:before, .block-cart-failed .action.continue:hover:before, .cart-container .form-cart .action.continue:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:active:before, .block-cart-failed .action.continue:active:before, .cart-container .form-cart .action.continue:active:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #1a1a1a; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #555; }
  .abs-shopping-cart-items .action.continue.disabled, .block-cart-failed .action.continue.disabled, .cart-container .form-cart .action.continue.disabled, .abs-shopping-cart-items .action.continue[disabled], .block-cart-failed .action.continue[disabled], .cart-container .form-cart .action.continue[disabled],
  fieldset[disabled] .abs-shopping-cart-items .action.continue,
  fieldset[disabled] .block-cart-failed .action.continue,
  fieldset[disabled] .cart-container .form-cart .action.continue,
  .cart-container fieldset[disabled] .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }

.abs-shopping-cart-items .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update {
  display: inline-block;
  text-decoration: none;
  padding-left: 5px; }
  .abs-shopping-cart-items .action.update:before, .block-cart-failed .action.update:before, .cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.update:hover:before, .block-cart-failed .action.update:hover:before, .cart-container .form-cart .action.update:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.update:active:before, .block-cart-failed .action.update:active:before, .cart-container .form-cart .action.update:active:before {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .abs-shopping-cart-items-mobile .actions, .cart-container .form-cart .actions {
    text-align: center; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.continue, .cart-container .form-cart .action.continue, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    margin: 0 auto 10px; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    display: block; } }

@media only screen and (min-width: 768px) {
  .abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .form-cart, .cart-container .cart-gift-item {
    float: left;
    position: relative;
    width: 73%; }
    .abs-shopping-cart-items-desktop .actions, .block-cart-failed .actions, .cart-container .form-cart .actions, .cart-container .cart-gift-item .actions {
      text-align: right; }
    .abs-shopping-cart-items-desktop .action.clear, .block-cart-failed .action.clear, .cart-container .form-cart .action.clear, .cart-container .cart-gift-item .action.clear, .abs-shopping-cart-items-desktop .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update, .cart-container .cart-gift-item .action.update {
      margin-left: 10px; }
    .abs-shopping-cart-items-desktop .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue, .cart-container .cart-gift-item .action.continue {
      float: left; } }

.abs-field-date .control, .field.date .control {
  position: relative; }

.abs-field-date-input, .abs-field-date input, .field.date input, .form-giftregistry-search .fields-specific-options .datetime-picker {
  margin-right: 10px;
  width: calc(100% - 50px); }

.abs-field-tooltip, .field .control._with-tooltip {
  position: relative; }
  .abs-field-tooltip input, .field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 36px); }

.abs-checkout-tooltip-content-position-top, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content, .shipping-policy-block.field-tooltip .field-tooltip-content {
  right: -10px;
  top: 40px;
  left: auto; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999; }
  .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px; }

@media only screen and (max-width: 767px) {
  .abs-checkout-tooltip-content-position-top-mobile, .field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      margin-top: -21px;
      right: 10px;
      left: auto;
      top: 0; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before {
      border-bottom-color: #999; }
    .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border-bottom-color: #f4f4f4;
      top: 1px; } }

.abs-checkout-title, .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  color: #434343;
  font-weight: 300;
  font-size: 2.6rem; }

.abs-sidebar-totals .mark, .cart-totals .mark, .opc-block-summary .table-totals .mark {
  font-weight: 400;
  padding-left: 4px; }
  .abs-sidebar-totals .mark strong, .cart-totals .mark strong, .opc-block-summary .table-totals .mark strong {
    font-weight: 400; }

.abs-sidebar-totals .amount, .cart-totals .amount, .opc-block-summary .table-totals .amount {
  padding-right: 4px;
  text-align: right;
  white-space: nowrap; }
  .abs-sidebar-totals .amount strong, .cart-totals .amount strong, .opc-block-summary .table-totals .amount strong {
    font-weight: 400; }

.abs-sidebar-totals .grand .mark, .cart-totals .grand .mark, .opc-block-summary .table-totals .grand .mark,
.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-top: 20px; }


.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-right: 4px;
  text-align: right; }
  .abs-sidebar-totals .grand .amount strong, .cart-totals .grand .amount strong, .opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700; }

.abs-sidebar-totals .msrp, .cart-totals .msrp, .opc-block-summary .table-totals .msrp {
  margin-bottom: 10px; }

.abs-sidebar-totals .totals-tax-summary .mark, .cart-totals .totals-tax-summary .mark, .opc-block-summary .table-totals .totals-tax-summary .mark,
.abs-sidebar-totals .totals-tax-summary .amount,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .amount {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer; }

.abs-sidebar-totals .totals-tax-summary .amount .price, .cart-totals .totals-tax-summary .amount .price, .opc-block-summary .table-totals .totals-tax-summary .amount .price {
  display: block;
  text-decoration: none;
  padding-right: 25px;
  position: relative; }
  .abs-sidebar-totals .totals-tax-summary .amount .price > span, .cart-totals .totals-tax-summary .amount .price > span, .opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px; }

.abs-sidebar-totals .totals-tax-summary.expanded .mark, .cart-totals .totals-tax-summary.expanded .mark, .opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.abs-sidebar-totals .totals-tax-summary.expanded .amount,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
  border-bottom: 0; }

.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after, .cart-totals .totals-tax-summary.expanded .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
  content: ""; }

.abs-sidebar-totals .totals-tax-details, .cart-totals .totals-tax-details, .opc-block-summary .table-totals .totals-tax-details {
  display: none;
  border-bottom: 1px solid #d1d1d1; }
  .abs-sidebar-totals .totals-tax-details.shown, .cart-totals .totals-tax-details.shown, .opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row; }

.abs-discount-block > .title, .summary .block > .title, .paypal-review-discount .block > .title {
  border-top: 1px solid #d1d1d1;
  cursor: pointer;
  font-weight: 600;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 7px 30px 7px 5px;
  position: relative; }
  .abs-discount-block > .title:after, .summary .block > .title:after, .paypal-review-discount .block > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .abs-discount-block > .title:after, .summary .block > .title:after, .paypal-review-discount .block > .title:after {
    position: absolute;
    right: -5px;
    top: -5px; }
  .column.main .abs-discount-block > .title strong, .column.main .summary .block > .title strong, .summary .column.main .block > .title strong, .column.main .paypal-review-discount .block > .title strong, .paypal-review-discount .column.main .block > .title strong {
    font-size: 1.8rem;
    font-weight: 400; }

.abs-discount-block > .content, .summary .block > .content, .paypal-review-discount .block > .content {
  display: none; }

.abs-discount-block.active > .title:after, .summary .active.block > .title:after, .paypal-review-discount .active.block > .title:after {
  content: ""; }

.abs-discount-block.active > .content, .summary .active.block > .content, .paypal-review-discount .active.block > .content {
  display: block; }

.fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
  letter-spacing: -0.31em; }
  .fieldset > * {
    letter-spacing: normal; }
  .fieldset > .legend {
    margin: 0 0 25px;
    padding: 0;
    box-sizing: border-box;
    float: left;
    line-height: 1.2;
    font-size: 2rem; }
    .fieldset > .legend + br {
      clear: both;
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
  .fieldset:last-child {
    margin-bottom: 0; }
  .fieldset > .field,
  .fieldset > .fields > .field {
    margin: 0 0 20px; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .fieldset > .field:last-child,
    .fieldset > .fields > .field:last-child {
      margin-bottom: 0; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      font-weight: 700; }
      .fieldset > .field > .label + br,
      .fieldset > .fields > .field > .label + br {
        display: none; }
    .fieldset > .field .choice input,
    .fieldset > .fields > .field .choice input {
      vertical-align: top; }
    .fieldset > .field .fields.group:before, .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:before,
    .fieldset > .fields > .field .fields.group:after {
      content: '';
      display: table; }
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
      clear: both; }
    .fieldset > .field .fields.group .field,
    .fieldset > .fields > .field .fields.group .field {
      box-sizing: border-box;
      float: left; }
    .fieldset > .field .fields.group.group-2 .field,
    .fieldset > .fields > .field .fields.group.group-2 .field {
      width: 50% !important; }
    .fieldset > .field .fields.group.group-3 .field,
    .fieldset > .fields > .field .fields.group.group-3 .field {
      width: 33.3% !important; }
    .fieldset > .field .fields.group.group-4 .field,
    .fieldset > .fields > .field .fields.group.group-4 .field {
      width: 25% !important; }
    .fieldset > .field .fields.group.group-5 .field,
    .fieldset > .fields > .field .fields.group.group-5 .field {
      width: 20% !important; }
    .fieldset > .field .addon,
    .fieldset > .fields > .field .addon {
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 0;
      width: 100%; }
      .fieldset > .field .addon textarea,
      .fieldset > .field .addon select,
      .fieldset > .field .addon input,
      .fieldset > .fields > .field .addon textarea,
      .fieldset > .fields > .field .addon select,
      .fieldset > .fields > .field .addon input {
        order: 2;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .field .addon .addafter,
      .fieldset > .fields > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addafter {
        background: #ffffff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: "Myriad Pro Regular", sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto; }
        .fieldset > .field .addon .addbefore:disabled,
        .fieldset > .field .addon .addafter:disabled,
        .fieldset > .fields > .field .addon .addbefore:disabled,
        .fieldset > .fields > .field .addon .addafter:disabled {
          opacity: 0.5; }
        .fieldset > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .field .addon .addafter::-moz-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .fields > .field .addon .addafter::-moz-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .field .addon .addafter::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .field .addon .addafter:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
          color: #c2c2c2; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addbefore {
        float: left;
        order: 1; }
    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
      margin-top: 10px; }
    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
      content: '*';
      color: #e02b27;
      font-size: 1.2rem;
      margin: 0 0 0 5px; }
    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
      font-size: 1.2rem;
      margin: 3px 0 0;
      padding: 0;
      display: inline-block;
      text-decoration: none; }
      .fieldset > .field .note:before,
      .fieldset > .fields > .field .note:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: 12px;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
    .fieldset > .field.choice .label,
    .fieldset > .fields > .field.choice .label {
      display: inline;
      font-weight: normal; }
    .column:not(.main) .fieldset > .field .label, .column:not(.main)
    .fieldset > .fields > .field .label {
      font-weight: normal; }
    .fieldset > .field .field.choice,
    .fieldset > .fields > .field .field.choice {
      margin-bottom: 10px; }
      .fieldset > .field .field.choice:last-child,
      .fieldset > .fields > .field .field.choice:last-child {
        margin-bottom: 0; }
    .fieldset > .field input[type=file],
    .fieldset > .fields > .field input[type=file] {
      margin: 5px 0; }

.legend + .fieldset,
.legend + div {
  clear: both; }

.legend strong {
  margin-left: 5px; }

fieldset.field {
  border: 0;
  padding: 0; }

.field.date .time-picker {
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap; }

.field .message.warning {
  margin-top: 10px; }

.field-error,
div.mage-error[generated] {
  margin-top: 7px; }

.field-error {
  color: #e02b27;
  font-size: 1.2rem; }

.field .tooltip {
  position: relative; }
  .field .tooltip .tooltip-content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #3c3c3c;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-left: 5px;
    left: 100%;
    top: 0; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .field .tooltip .tooltip-content:after {
      border-width: 5px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:before {
      border-width: 6px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      right: 100%; }
    .field .tooltip .tooltip-content:after {
      border-right-color: #ffffff;
      margin-top: -5px;
      top: 15px; }
    .field .tooltip .tooltip-content:before {
      border-right-color: #bbb;
      margin-top: -6px;
      top: 15px; }
  .field .tooltip .tooltip-toggle {
    cursor: help; }
  .field .tooltip .tooltip-toggle:hover + .tooltip-content,
  .field .tooltip .tooltip-toggle:focus + .tooltip-content,
  .field .tooltip:hover .tooltip-content {
    display: block; }
  .field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal; }

input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
  display: block; }

._has-datepicker ~ .ui-datepicker-trigger {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: block;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus, ._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger.disabled, ._has-datepicker ~ .ui-datepicker-trigger[disabled],
  fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  ._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  ._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 40px;
    color: #868686;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0; }

.sidebar .fieldset {
  margin: 0; }
  .sidebar .fieldset > .field:not(.choice):not(:last-child),
  .sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px; }
  .sidebar .fieldset > .field:not(.choice) .label,
  .sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%; }
  .sidebar .fieldset > .field:not(.choice) .control,
  .sidebar .fieldset .fields > .field .control {
    width: 100%; }

@media only screen and (min-width: 768px) {
  .fieldset > .field {
    margin: 0 0 20px;
    box-sizing: border-box;
    margin: 0 0 29px; }
    .fieldset > .field > .label {
      margin: 0; }
    .fieldset > .field:before, .fieldset > .field:after {
      content: '';
      display: table; }
    .fieldset > .field:after {
      clear: both; }
    .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
      padding: 6px 15px 0 0;
      width: 25.8%;
      box-sizing: border-box;
      content: ' ';
      float: left;
      height: 1px; }
    .fieldset > .field .description {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .label {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .control {
      width: 74.2%;
      float: left; } }

@font-face {
  font-family: "Material Icons";
  src: url("../fonts/MaterialIcons-Regular.eot");
  src: url("../fonts/MaterialIcons-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MaterialIcons-Regular.woff2") format("woff2"), url("../fonts/MaterialIcons-Regular.woff") format("woff"), url("../fonts/MaterialIcons-Regular.ttf") format("truetype"), url("../fonts/MaterialIcons-Regular.svg#Material Icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.columns {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }
  .columns:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden; }
  .columns .column.main {
    padding-bottom: 40px;
    flex-basis: 100%;
    flex-grow: 1;
    order: 1;
    width: 100%; }
  .columns .sidebar-main {
    flex-grow: 1;
    flex-basis: 100%;
    order: 1; }
  .columns .sidebar-additional {
    flex-grow: 1;
    flex-basis: 100%;
    order: 2; }

@media only screen and (max-width: 767px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-main,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container {
    padding-left: 15px;
    padding-right: 15px; }
  .account .page-main,
  .cms-privacy-policy .page-main {
    padding-top: 41px;
    position: relative; } }

@media only screen and (min-width: 768px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container,
  .page-main {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto; }
  .page-main {
    width: 100%;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */ }
    .ie9 .page-main {
      width: auto; }
  .columns {
    display: block; }
  .column.main {
    min-height: 300px; }
    .page-layout-1column .column.main {
      width: 100%;
      order: 2; }
    .page-layout-3columns .column.main {
      width: 58.33333%;
      display: inline-block;
      order: 2; }
    .page-layout-2columns-left .column.main {
      width: 79.16667%;
      float: right;
      order: 2; }
    .page-layout-2columns-right .column.main {
      width: 79.16667%;
      float: left;
      order: 1; }
  .sidebar-main {
    padding-right: 2%; }
    .page-layout-3columns .sidebar-main {
      width: 20.83333%;
      float: left;
      order: 1; }
    .page-layout-2columns-left .sidebar-main {
      width: 20.83333%;
      float: left;
      order: 1; }
    .page-layout-2columns-right .sidebar-main {
      width: 20.83333%;
      float: left;
      order: 1; }
  .page-layout-2columns-right .sidebar-main {
    padding-left: 2%;
    padding-right: 0; }
  .sidebar-additional {
    clear: right;
    padding-left: 2%; }
    .page-layout-3columns .sidebar-additional {
      width: 20.83333%;
      float: right;
      order: 3; }
    .page-layout-2columns-left .sidebar-additional {
      width: 20.83333%;
      float: right;
      order: 2; }
    .page-layout-2columns-right .sidebar-additional {
      width: 20.83333%;
      float: right;
      order: 2; }
  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0;
    padding-right: 2%; }
  .panel.header {
    padding: 10px 20px; } }

.load.indicator {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute; }
  .load.indicator:before {
    background: transparent url("../images/loader-2.gif") no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
  .load.indicator > span {
    display: none; }

.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5); }
  .loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
  .loading-mask .loader > p {
    display: none; }

body > .loading-mask {
  z-index: 9999; }

._block-content-loading {
  position: relative; }

.message.info {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.info a {
    color: #1c5275; }
    .message.info a:hover {
      color: #006bb4; }
    .message.info a:active {
      color: #006bb4; }
  .message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.error {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fae5e5;
  color: #e02b27;
  padding-left: 40px;
  position: relative; }
  .message.error a {
    color: #1c5275; }
    .message.error a:hover {
      color: #006bb4; }
    .message.error a:active {
      color: #006bb4; }
  .message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.warning {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.warning a {
    color: #1c5275; }
    .message.warning a:hover {
      color: #006bb4; }
    .message.warning a:active {
      color: #006bb4; }
  .message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.notice {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.notice a {
    color: #1c5275; }
    .message.notice a:hover {
      color: #006bb4; }
    .message.notice a:active {
      color: #006bb4; }
  .message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.success {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #e5efe5;
  color: #006400;
  padding-left: 40px;
  position: relative; }
  .message.success a {
    color: #1c5275; }
    .message.success a:hover {
      color: #006bb4; }
    .message.success a:active {
      color: #006bb4; }
  .message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #006400;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.panel.header .links,
.panel.header .switcher {
  display: none; }

.nav-sections {
  background: #a6a6a6; }

.nav-toggle {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  font-size: 0;
  left: 15px;
  position: absolute;
  top: 15px;
  z-index: 14; }
  .nav-toggle:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: inherit;
    color: #69707d;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .nav-toggle:hover:before {
    color: #5d646f; }
  .nav-toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

@media only screen and (max-width: 767px) {
  .navigation {
    padding: 0; }
    .navigation .level0.level-top a, .navigation .level0.level-top span {
      font-weight: 500;
      color: #474747; }
    .navigation .parent .level-top {
      position: relative;
      display: block;
      text-decoration: none; }
      .navigation .parent .level-top:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: #a2a2a2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .navigation .parent .level-top:after {
        position: absolute;
        right: 7px;
        top: -8px; }
      .navigation .parent .level-top.ui-state-active:after {
        content: ""; }
  .nav-sections {
    -webkit-overflow-scrolling: touch;
    transition: left 0.3s;
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - 54px));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - 54px);
    background-color: #f5f5f5; }
    .nav-sections .switcher {
      font-size: 1rem;
      font-weight: 500;
      margin: 0;
      padding: 0.8rem 3.5rem 0.8rem 2rem; }
      .nav-sections .switcher .label {
        display: block;
        margin-bottom: 5px; }
      .nav-sections .switcher:last-child {
        border-bottom: 1px solid #d1d1d1; }
    .nav-sections .switcher-trigger .action {
      display: block;
      text-decoration: none; }
      .nav-sections .switcher-trigger .action:before {
        display: none; }
      .nav-sections .switcher-trigger .action:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: #a2a2a2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
    .nav-sections .switcher-trigger strong {
      position: relative; }
      .nav-sections .switcher-trigger strong:after {
        position: absolute;
        right: -40px;
        top: -16px; }
    .nav-sections .switcher-trigger.active strong:after {
      content: ""; }
    .nav-sections .switcher-dropdown {
      margin: 0;
      padding: 0;
      list-style: none none;
      padding: 10px 0; }
    .nav-sections .header.links {
      margin: 0;
      padding: 0;
      list-style: none none;
      border-bottom: 1px solid #d1d1d1; }
      .nav-sections .header.links li {
        font-weight: 500;
        font-size: 1rem;
        margin: 0; }
        .nav-sections .header.links li.greet.welcome {
          font-weight: 400;
          padding: 0.8rem 20px; }
        .nav-sections .header.links li > a {
          border-top: 1px solid #d1d1d1; }
      .nav-sections .header.links a,
      .nav-sections .header.links a:hover {
        color: #575757;
        text-decoration: none;
        display: block;
        font-weight: 500;
        padding: 0.8rem 20px; }
      .nav-sections .header.links .header.links {
        border: 0; }
  .nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%; }
    .nav-before-open .page-wrapper {
      transition: left 0.3s;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: relative; }
    .nav-before-open body {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%; }
  .nav-open .page-wrapper {
    left: 80%;
    left: calc(100% - 54px); }
  .nav-open .nav-sections {
    box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
    left: 0;
    z-index: 99; }
  .nav-open .nav-toggle:after {
    background: rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1; }
  .nav-sections-items {
    position: relative;
    z-index: 1; }
    .nav-sections-items:before, .nav-sections-items:after {
      content: '';
      display: table; }
    .nav-sections-items:after {
      clear: both; }
  .nav-sections-item-title {
    background: #999999;
    border: solid #8c8c8c;
    border-width: 0 0 1px 1px;
    box-sizing: border-box;
    float: left;
    height: 71px;
    padding-top: 24px;
    text-align: center;
    width: 33.33%; }
    .nav-sections-item-title.active {
      background: transparent;
      border-bottom: 0; }
    .nav-sections-item-title .nav-sections-item-switch {
      color: #474747; }
      .nav-sections-item-title .nav-sections-item-switch:hover {
        text-decoration: none; }
  .nav-sections-item-content {
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    margin-top: 71px;
    width: 100%;
    padding: 25px 0; }
    .nav-sections-item-content:before, .nav-sections-item-content:after {
      content: '';
      display: table; }
    .nav-sections-item-content:after {
      clear: both; }
    .nav-sections-item-content.active {
      display: block; }
  .navigation {
    background: #a6a6a6;
    box-sizing: border-box; }
    .navigation ul {
      margin: 0;
      padding: 0; }
    .navigation li {
      margin: 0; }
    .navigation a {
      display: block;
      padding: 10px 0 10px 15px; }
    .navigation a,
    .navigation a:hover {
      color: #575757;
      text-decoration: none; }
    .navigation .level0 {
      border-top: 1px solid #d1d1d1;
      font-size: 1.6rem; }
      .navigation .level0 > .level-top {
        font-weight: 700;
        padding: 8px 40px 8px 15px;
        text-transform: uppercase;
        word-wrap: break-word; }
      .navigation .level0.active .all-category .ui-state-focus {
        border-color: #2196f3;
        border-style: solid;
        border-width: 0 0 0 8px;
        padding-left: 7px;
        display: inline-block; }
      .navigation .level0 > .level1 {
        font-weight: 600; }
      .navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active) {
        border-color: #2196f3;
        border-style: solid;
        border-width: 0 0 0 8px; }
        .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
          margin-left: -8px; }
    .navigation li.level0:last-child {
      border-bottom: 1px solid #d1d1d1; }
    .navigation .submenu > li {
      word-wrap: break-word; }
    .navigation .submenu:not(:first-child) {
      font-weight: 400;
      line-height: 1.3;
      left: auto !important;
      overflow-x: hidden;
      padding: 0;
      position: relative;
      top: auto !important;
      transition: left 0.3s ease-out; }
      .navigation .submenu:not(:first-child) > li > a {
        padding-left: 15px; }
      .navigation .submenu:not(:first-child) > li:last-child {
        margin-bottom: 0; }
      .navigation .submenu:not(:first-child) ul {
        display: block;
        padding-left: 15px; }
        .navigation .submenu:not(:first-child) ul > li {
          margin: 0; }
          .navigation .submenu:not(:first-child) ul > li a {
            color: #575757;
            display: block;
            line-height: normal; }
      .navigation .submenu:not(:first-child).expanded {
        display: block !important;
        padding-right: 0;
        top: 0 !important; }
      .navigation .submenu:not(:first-child) .active > a {
        border-color: #2196f3;
        border-style: solid;
        border-width: 0 0 0 8px;
        padding-left: 7px; }
      .navigation .submenu:not(:first-child) .level1.active > a {
        padding-left: 7px; } }

@media only screen and (min-width: 768px) {
  .nav-toggle {
    display: none; }
  .nav-sections {
    margin: 0 auto 0 0;
    flex-shrink: 0;
    flex-basis: auto;
    margin-bottom: 0; }
  .nav-sections-item-title {
    display: none; }
  .nav-sections-item-content {
    display: block !important; }
  .nav-sections-item-content > * {
    display: none; }
  .nav-sections-item-content > .navigation {
    display: block; }
  .navigation {
    background: #a6a6a6;
    font-weight: 700;
    height: inherit;
    left: auto;
    overflow: inherit;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3; }
    .navigation:empty {
      display: none; }
    .navigation ul {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      position: relative; }
    .navigation li.level0 {
      border-top: none; }
    .navigation .level0 {
      margin: 0 10px 0 0;
      display: inline-block;
      position: relative; }
      .navigation .level0:last-child {
        margin-right: 0;
        padding-right: 0; }
      .navigation .level0 > .level-top {
        color: #575757;
        line-height: 53px;
        padding: 0 12px;
        text-decoration: none;
        box-sizing: border-box;
        position: relative; }
        .navigation .level0 > .level-top:hover, .navigation .level0 > .level-top.ui-state-focus {
          color: #3c3c3c;
          text-decoration: none; }
      .navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top {
        border-color: #2196f3;
        border-style: solid;
        border-width: 0 0 3px;
        color: #3c3c3c;
        text-decoration: none;
        display: inline-block; }
      .navigation .level0.parent:hover > .submenu {
        overflow: visible !important; }
      .navigation .level0 .submenu {
        background: #ffffff;
        border: 1px solid #d1d1d1;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
        font-weight: 700;
        min-width: 230px;
        padding: 15px 0;
        display: none;
        left: 0;
        margin: 0 !important;
        padding: 0;
        position: absolute;
        z-index: 1;
        margin-top: 11px; }
        .navigation .level0 .submenu > ul {
          margin-top: 11px; }
          .navigation .level0 .submenu > ul:before, .navigation .level0 .submenu > ul:after {
            content: '';
            display: block;
            overflow: hidden;
            position: absolute; }
          .navigation .level0 .submenu > ul:before {
            color: #ffffff;
            left: 20px;
            top: -20px;
            border: 10px solid transparent;
            height: 0;
            width: 0;
            border-bottom-color: #ffffff;
            z-index: 4; }
          .navigation .level0 .submenu > ul:after {
            border: 11px solid transparent;
            height: 0;
            width: 0;
            border-bottom-color: #d1d1d1;
            color: #d1d1d1;
            left: 19px;
            top: -22px;
            z-index: 3; }
        .navigation .level0 .submenu a {
          display: block;
          line-height: inherit;
          color: #575757;
          padding: 8px 20px; }
          .navigation .level0 .submenu a:hover, .navigation .level0 .submenu a.ui-state-focus {
            background: #999;
            color: #3c3c3c;
            text-decoration: none; }
        .navigation .level0 .submenu .active > a {
          border-color: #2196f3;
          border-style: solid;
          border-width: 0 0 0 3px; }
        .navigation .level0 .submenu .submenu {
          top: 0 !important;
          left: 100% !important; }
        .navigation .level0 .submenu .submenu-reverse {
          left: auto !important;
          right: 100%; }
      .navigation .level0.more {
        position: relative;
        display: inline-block;
        text-decoration: none; }
        .navigation .level0.more:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 26px;
          line-height: inherit;
          color: #a2a2a2;
          content: "";
          font-family: "Material Icons";
          vertical-align: middle;
          display: inline-block;
          font-weight: normal;
          speak: none;
          text-align: center; }
        .navigation .level0.more:before {
          display: none; }
        .navigation .level0.more:after {
          cursor: pointer;
          padding: 8px 12px;
          position: relative;
          z-index: 1; }
        .navigation .level0.more:hover > .submenu {
          overflow: visible !important; }
        .navigation .level0.more li {
          display: block; }
  .panel.header {
    display: flex; }
    .panel.header .header.links {
      display: inline-block; }
      .panel.header .header.links .customer-welcome .action.switch {
        color: inherit;
        vertical-align: top; }
        .panel.header .header.links .customer-welcome .action.switch:before {
          font-size: 18px;
          line-height: 18px; }
    .panel.header .switcher {
      display: inline-block;
      margin-left: auto;
      line-height: 14px; }
    .panel.header #switcher-language-trigger {
      font-size: 11px; }
      .panel.header #switcher-language-trigger:before {
        float: right;
        font-size: 18px;
        line-height: 17px; } }

.pages > .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pages .items {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none none;
  display: inline-block;
  font-weight: 700; }

.pages .item {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  margin: 0 2px 0 0;
  display: inline-block; }
  .pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.pages a.page {
  color: #1c5275;
  display: inline-block;
  padding: 0 4px;
  text-decoration: none; }
  .pages a.page:visited {
    color: #1c5275; }
  .pages a.page:hover {
    color: #006bb4;
    text-decoration: none; }
  .pages a.page:active {
    color: #2196f3; }

.pages strong.page {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  color: #3c3c3c;
  display: inline-block;
  font-weight: 700;
  padding: 0 4px; }

.pages .action {
  border: 1px solid #d1d1d1;
  color: #868686;
  display: inline-block;
  padding: 0;
  text-decoration: none; }
  .pages .action:visited {
    color: #868686; }
  .pages .action:hover {
    color: #868686;
    text-decoration: none; }
  .pages .action:active {
    color: #868686; }
  .pages .action.next {
    display: inline-block;
    text-decoration: none; }
    .pages .action.next:visited:before {
      color: #868686; }
    .pages .action.next:active:before {
      color: #868686; }
    .pages .action.next > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.next:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #868686;
      content: "";
      font-family: "Material Icons";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .pages .action.next:hover:before {
      color: #868686; }
    .pages .action.next:active:before {
      color: #868686; }
  .pages .action.previous {
    display: inline-block;
    text-decoration: none; }
    .pages .action.previous:visited:before {
      color: #868686; }
    .pages .action.previous:active:before {
      color: #868686; }
    .pages .action.previous > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.previous:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #868686;
      content: "";
      font-family: "Material Icons";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .pages .action.previous:hover:before {
      color: #868686; }
    .pages .action.previous:active:before {
      color: #868686; }

.pages .action {
  width: 34px; }
  .pages .action.previous {
    margin-right: 12px; }
  .pages .action.next {
    margin-left: 12px; }

.ui-dialog.popup .action.close {
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 1001;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .ui-dialog.popup .action.close:hover:before {
    color: inherit; }
  .ui-dialog.popup .action.close:active:before {
    color: inherit; }
  .ui-dialog.popup .action.close:focus, .ui-dialog.popup .action.close:active {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close:hover {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close.disabled, .ui-dialog.popup .action.close[disabled],
  fieldset[disabled] .ui-dialog.popup .action.close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.popup-pointer {
  margin-left: -14px;
  position: absolute;
  top: -14px; }
  .popup-pointer:before, .popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid; }
  .popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99; }
  .popup-pointer:after {
    left: 1px;
    top: 0;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98; }

@media only screen and (min-width: 640px) {
  .ui-dialog.popup {
    width: 420px; } }

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
  display: inline; }

.price-including-tax,
.price-excluding-tax {
  display: block;
  font-size: 1.8rem;
  line-height: 1; }
  .price-including-tax .price,
  .price-excluding-tax .price {
    font-weight: 700; }

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
  display: block;
  font-size: 1.8rem; }
  .price-including-tax + .price-excluding-tax:before,
  .weee[data-label]:before {
    content: attr(data-label) ": ";
    font-size: 1.1rem; }
  .price-including-tax + .price-excluding-tax .price,
  .weee[data-label] .price {
    font-size: 1.1rem; }

.product.data.items {
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }
  .product.data.items > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .product.data.items > .item.title > .switch {
      display: block; }
  .product.data.items > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .product.data.items > .item.content:before, .product.data.items > .item.content:after {
      content: '';
      display: table; }
    .product.data.items > .item.content:after {
      clear: both; }
    .product.data.items > .item.content.active {
      display: block; }
  .product.data.items > .item.title {
    margin: 0 0 5px; }
  .product.data.items > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #868686;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #868686;
    text-decoration: none; }
    .product.data.items > .item.title > .switch:visited {
      color: #868686;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:hover {
      color: #868686;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:active {
      color: #434343;
      text-decoration: none; }
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .product.data.items > .item.content {
    background: white;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

.table-wrapper {
  margin-bottom: 20px; }

table > caption {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.table:not(.cart):not(.totals) {
  border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td,
  .table:not(.cart):not(.totals) > tbody > tr > th,
  .table:not(.cart):not(.totals) > tbody > tr > td,
  .table:not(.cart):not(.totals) > tfoot > tr > th,
  .table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid #d1d1d1; }
  .table:not(.cart):not(.totals) tfoot > tr:first-child th,
  .table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid #d1d1d1;
    padding-top: 20px; }
  .table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right; }

@media only screen and (max-width: 639px) {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    position: relative; }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
      border: none;
      display: block; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block; }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
          display: block; }
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
            border-bottom: none;
            display: block;
            padding: 5px 0; }
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
              padding-right: 10px;
              content: attr(data-th) ": ";
              display: inline-block;
              color: #1a1a1a;
              font-weight: 700; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
        padding-top: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
        padding-bottom: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px; }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
      display: block; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block; }
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
          padding-top: 20px; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%; }
  .data-table-definition-list thead {
    display: none; }
  .data-table-definition-list tbody th {
    padding-bottom: 0; }
  .data-table-definition-list tbody th,
  .data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0; } }

.tooltip.wrapper {
  position: relative; }
  .tooltip.wrapper .tooltip.content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #3c3c3c;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .tooltip.wrapper .tooltip.content:after {
      border-width: 5px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:before {
      border-width: 6px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      bottom: 100%; }
    .tooltip.wrapper .tooltip.content:after {
      border-bottom-color: #ffffff;
      left: 15px;
      margin-left: -5px; }
    .tooltip.wrapper .tooltip.content:before {
      border-bottom-color: #bbb;
      left: 15px;
      margin-left: -6px; }
  .tooltip.wrapper .tooltip.toggle {
    cursor: help; }
  .tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
  .tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
  .tooltip.wrapper:hover .tooltip.content {
    display: block; }
  .tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0; }
  .tooltip.wrapper .tooltip.content dd {
    white-space: normal; }
  .tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px; }
  .tooltip.wrapper .tooltip.content .label {
    margin-top: 10px; }
    .tooltip.wrapper .tooltip.content .label:first-child {
      margin-top: 0; }
  .tooltip.wrapper .tooltip.content .values {
    margin: 0; }

.ui-tooltip {
  position: absolute;
  z-index: 9999; }

@media only screen and (max-width: 479px) {
  .tooltip.wrapper .tooltip.content {
    min-width: 10rem; } }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/light/opensans-300.eot");
  src: url("../fonts/opensans/light/opensans-300.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/light/opensans-300.woff2") format("woff2"), url("../fonts/opensans/light/opensans-300.woff") format("woff"), url("../fonts/opensans/light/opensans-300.ttf") format("truetype"), url("../fonts/opensans/light/opensans-300.svg#Open Sans") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/regular/opensans-400.eot");
  src: url("../fonts/opensans/regular/opensans-400.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/regular/opensans-400.woff2") format("woff2"), url("../fonts/opensans/regular/opensans-400.woff") format("woff"), url("../fonts/opensans/regular/opensans-400.ttf") format("truetype"), url("../fonts/opensans/regular/opensans-400.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/semibold/opensans-600.eot");
  src: url("../fonts/opensans/semibold/opensans-600.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/semibold/opensans-600.woff2") format("woff2"), url("../fonts/opensans/semibold/opensans-600.woff") format("woff"), url("../fonts/opensans/semibold/opensans-600.ttf") format("truetype"), url("../fonts/opensans/semibold/opensans-600.svg#Open Sans") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/bold/opensans-700.eot");
  src: url("../fonts/opensans/bold/opensans-700.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/bold/opensans-700.woff2") format("woff2"), url("../fonts/opensans/bold/opensans-700.woff") format("woff"), url("../fonts/opensans/bold/opensans-700.ttf") format("truetype"), url("../fonts/opensans/bold/opensans-700.svg#Open Sans") format("svg");
  font-weight: 700;
  font-style: normal; }

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    margin-bottom: 40px; } }

.items {
  margin: 0;
  padding: 0;
  list-style: none none; }

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.modals-overlay {
  z-index: 1000; }

.modal-slide,
.modal-popup {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease; }
  .modal-slide._show,
  .modal-popup._show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease; }
    .modal-slide._show .modal-inner-wrap,
    .modal-popup._show .modal-inner-wrap {
      transform: translate(0, 0); }
  .modal-slide .modal-inner-wrap,
  .modal-popup .modal-inner-wrap {
    background-color: #ffffff;
    box-shadow: none;
    opacity: 1;
    pointer-events: auto; }

.modal-slide {
  left: 14.8rem;
  z-index: 900; }
  .modal-slide._show .modal-inner-wrap {
    transform: translateX(0); }
  .modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: auto; }
  .modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column; }
  .modal-slide._inner-scroll .modal-header,
  .modal-slide._inner-scroll .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-slide._inner-scroll .modal-content {
    overflow-y: auto; }
  .modal-slide._inner-scroll .modal-footer {
    margin-top: auto; }
  .modal-slide .modal-header,
  .modal-slide .modal-content,
  .modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem; }
  .modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem; }

.modal-popup {
  z-index: 900;
  left: 0;
  overflow-y: auto; }
  .modal-popup._show .modal-inner-wrap {
    transform: translateY(0); }
  .modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-200%);
    transition: transform 0.2s ease; }
  .modal-popup._inner-scroll {
    overflow-y: visible; }
    .ie11 .modal-popup._inner-scroll,
    .ie10 .modal-popup._inner-scroll,
    .ie9 .modal-popup._inner-scroll {
      overflow-y: auto; }
    .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%; }
      .ie11 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie10 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
        max-height: none; }
    .modal-popup._inner-scroll .modal-content {
      overflow-y: auto; }
  .modal-popup .modal-header,
  .modal-popup .modal-content,
  .modal-popup .modal-footer {
    padding-left: 3rem;
    padding-right: 3rem; }
  .modal-popup .modal-header,
  .modal-popup .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-popup .modal-header {
    padding-bottom: 1.2rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 3rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer-actions {
    text-align: right; }

@media only screen and (max-width: 767px) {
  .modal-popup.modal-slide {
    left: 14.8rem;
    z-index: 900; }
    .modal-popup.modal-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .modal-popup.modal-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .modal-popup.modal-slide .modal-inner-wrap {
      margin: 0;
      max-height: none; } }

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0; }
  .modal-custom .action-close:focus, .modal-custom .action-close:active,
  .modal-popup .action-close:focus,
  .modal-popup .action-close:active,
  .modal-slide .action-close:focus,
  .modal-slide .action-close:active {
    background: none;
    border: none; }
  .modal-custom .action-close:hover,
  .modal-popup .action-close:hover,
  .modal-slide .action-close:hover {
    background: none;
    border: none; }
  .modal-custom .action-close.disabled, .modal-custom .action-close[disabled],
  fieldset[disabled] .modal-custom .action-close,
  .modal-popup .action-close.disabled,
  .modal-popup .action-close[disabled],
  fieldset[disabled]
  .modal-popup .action-close,
  .modal-slide .action-close.disabled,
  .modal-slide .action-close[disabled],
  fieldset[disabled]
  .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .modal-custom .action-close > span,
  .modal-popup .action-close > span,
  .modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .modal-custom .action-close:before,
  .modal-popup .action-close:before,
  .modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: #3c3c3c;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: inherit; }
  .modal-custom .action-close:active:before,
  .modal-popup .action-close:active:before,
  .modal-slide .action-close:active:before {
    color: inherit; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: #232323; }

.modal-custom .action-close {
  margin: 25px; }

.modal-popup {
  pointer-events: none; }
  .modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    padding-bottom: 10px;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word; }
  .modal-popup .action-close {
    padding: 10px; }

.modal-slide .action-close {
  padding: 1.1rem 2rem; }

.modal-slide .page-main-actions {
  margin-bottom: calc(2.1rem - 15px);
  margin-top: 2.1rem; }

.modals-overlay {
  background-color: rgba(51, 51, 51, 0.55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000; }

@media only screen and (max-width: 767px) {
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
    left: 14.8rem;
    z-index: 900; }
    .custom-slide._show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease; }
      .custom-slide._show .modal-inner-wrap {
        transform: translate(0, 0); }
    .custom-slide .modal-inner-wrap {
      background-color: #ffffff;
      box-shadow: none;
      opacity: 1;
      pointer-events: auto; }
    .custom-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .custom-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto; }
    .custom-slide .modal-inner-wrap {
      background-color: #f4f4f4;
      box-sizing: border-box;
      height: auto;
      min-height: 100%; }
  body._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw; }
    body._has-modal-custom .modal-custom-overlay {
      background-color: rgba(51, 51, 51, 0.55); } }

@media only screen and (max-width: 767px) {
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #f4f4f4; }
  .modal-popup.modal-slide._inner-scroll._show {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    height: auto;
    min-height: 100%; }
  .modal-popup .modal-title {
    font-size: 14px;
    font-weight: 700; } }

@media only screen and (min-width: 768px) {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #c1c1c1;
    text-align: right; } }

.column .block-addbysku .fieldset {
  margin: 0; }
  .column .block-addbysku .fieldset .fields {
    position: relative; }
    .column .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
    .column .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 4px; }

.column .block-addbysku .sku {
  margin-right: -155px;
  padding-right: 160px;
  width: 100%; }

.column .block-addbysku .skus > .label {
  margin-top: 10px; }

.column .block-addbysku .qty .qty + .mage-error {
  width: 80px; }

.column .block-addbysku .block-content .actions-toolbar {
  clear: both;
  margin: 0 0 20px;
  text-align: left; }
  .column .block-addbysku .block-content .actions-toolbar:before, .column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table; }
  .column .block-addbysku .block-content .actions-toolbar:after {
    clear: both; }
  .column .block-addbysku .block-content .actions-toolbar .primary {
    float: left; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block; }
    .column .block-addbysku .block-content .actions-toolbar .primary a.action,
    .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
      display: inline-block; }
  .column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none; }
  .column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left; }

.column .block-addbysku .reset {
  margin: 10px 0; }

.column .block-addbysku .note {
  display: block;
  margin: 0 0 20px; }

.block-cart-failed {
  margin-top: 40px; }
  .block-cart-failed .block-title {
    font-size: 2.6rem; }
    .block-cart-failed .block-title strong {
      font-weight: 300; }
  .block-cart-failed .actions {
    text-align: center; }
    .block-cart-failed .actions .action {
      margin-bottom: 10px; }

@media only screen and (max-width: 639px) {
  .column .block-addbysku .sku {
    margin-right: -85px;
    padding-right: 90px; }
  .column .block-addbysku .action.remove {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400; }
    .column .block-addbysku .action.remove > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .column .block-addbysku .action.remove:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 1px 0 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .column .block-addbysku .action.remove:hover:before {
      color: inherit; }
    .column .block-addbysku .action.remove:active:before {
      color: inherit; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; } }

@media only screen and (min-width: 640px) {
  .column .block-addbysku .action.remove {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857;
    margin: 0;
    padding: 0;
    color: #1c5275;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    margin-top: 2px; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .column .block-addbysku .action.remove:visited {
      color: #1c5275;
      text-decoration: none; }
    .column .block-addbysku .action.remove:hover {
      color: #006bb4;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:active {
      color: #2196f3;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:hover {
      color: #006bb4; }
    .column .block-addbysku .action.remove:hover, .column .block-addbysku .action.remove:active, .column .block-addbysku .action.remove:focus {
      background: none;
      border: 0; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      color: #1c5275;
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      text-decoration: underline; } }

@media only screen and (min-width: 768px) {
  .block-cart-failed .actions {
    text-align: left; }
  .block-cart-failed .actions.primary {
    float: right; } }

.payment-method-braintree .hosted-control {
  background: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  width: 225px; }
  .payment-method-braintree .hosted-control:disabled {
    opacity: 0.5; }
  .payment-method-braintree .hosted-control::-moz-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control::-webkit-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control:-ms-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-focused {
    border-color: #006bb4; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    border-color: #e02b27; }
    .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
      height: auto;
      opacity: 1; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-valid {
    border-color: #006400; }
  .payment-method-braintree .hosted-control.hosted-cid {
    width: 5rem; }
  .payment-method-braintree .hosted-control.hosted-date {
    float: left;
    margin-bottom: 7px;
    width: 6rem; }
    .payment-method-braintree .hosted-control.hosted-date:first-of-type {
      margin-right: 50px; }
      .payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
        content: '/';
        display: inline;
        padding-bottom: inherit;
        padding-top: inherit;
        position: absolute;
        right: -25px;
        top: 5px; }

.payment-method-braintree .field-tooltip {
  right: 0; }

.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 1.2rem;
  height: 0;
  margin-top: 7px;
  opacity: 0; }

.braintree-paypal-logo {
  margin-top: 10px;
  text-align: center; }

.braintree-paypal-account {
  padding: 15px 0 15px 50px;
  position: relative;
  display: inline-block; }
  .braintree-paypal-account:before {
    background-image: url("../Magento_Braintree/images/paypal-small.png");
    background-position-x: 0;
    background-position-y: 0;
    line-height: 16px;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    margin: ""; }
  .braintree-paypal-account:before {
    left: 17px;
    margin-top: -8px;
    position: absolute;
    top: 50%; }
  .braintree-paypal-account .payment-method-type {
    font-weight: 700; }
  .braintree-paypal-account .payment-method-description {
    color: #575757; }

.braintree-paypal-fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.action-braintree-paypal-logo {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .action-braintree-paypal-logo:focus, .action-braintree-paypal-logo:active {
    background: none;
    border: none; }
  .action-braintree-paypal-logo:hover {
    background: none;
    border: none; }
  .action-braintree-paypal-logo.disabled, .action-braintree-paypal-logo[disabled],
  fieldset[disabled] .action-braintree-paypal-logo {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .action-braintree-paypal-logo img {
    margin: 0;
    width: 114px; }

.payment-method .payment-method-content .actions-toolbar.braintree-paypal-actions {
  margin-left: 0; }

.account .table .col.paypal-account img {
  vertical-align: middle; }

.account .table .col.paypal-account + .actions {
  vertical-align: middle; }

@media only screen and (min-width: 768px) {
  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem; }
  .braintree-paypal-account {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0;
    display: inline-block;
    width: 50%; }
  .account .table-credit-cards .col.actions {
    width: 100px; } }

.bundle-options-container {
  clear: both;
  margin-bottom: 40px; }
  .bundle-options-container .legend.title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .bundle-options-container .product-add-form {
    display: none; }
  .bundle-options-container .product-options-wrapper {
    margin-bottom: 30px; }
  .bundle-options-container .action.back {
    margin-bottom: 30px; }
  .bundle-options-container .price-box .price {
    font-size: 18px;
    font-weight: 700; }
  .bundle-options-container .block-bundle-summary {
    background: #999;
    padding: 10px 20px; }
    .bundle-options-container .block-bundle-summary > .title > strong {
      font-weight: 300;
      line-height: 1.1;
      font-size: 2.6rem;
      margin-top: 2.5rem;
      margin-bottom: 2rem; }
    .bundle-options-container .block-bundle-summary .bundle-summary {
      margin-top: 30px; }
      .bundle-options-container .block-bundle-summary .bundle-summary.empty {
        display: none; }
      .bundle-options-container .block-bundle-summary .bundle-summary > .subtitle {
        font-weight: 300;
        line-height: 1.1;
        font-size: 1.8rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        display: block; }
    .bundle-options-container .block-bundle-summary .bundle.items > li {
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .box-tocart .actions {
      display: inline-block;
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .product-addto-links > .action {
      vertical-align: top; }
  .bundle-options-container .nested .field.qty {
    margin-top: 20px; }
    .bundle-options-container .nested .field.qty .label {
      font-weight: 700;
      margin: 0 0 5px;
      display: inline-block; }
  .bundle-options-container p.required {
    color: #e02b27; }
  .bundle-options-container .product-options-bottom {
    clear: left; }

@media only screen and (min-width: 768px) {
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%; }
  .bundle-options-container .block-bundle-summary {
    float: right;
    position: relative;
    width: 40%; }
  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
  .page-layout-3columns .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-left
  .bundle-options-container .block-bundle-summary,
  .page-layout-2columns-right
  .bundle-options-container .block-bundle-summary,
  .page-layout-3columns
  .bundle-options-container .block-bundle-summary {
    width: 48%; } }

.products {
  margin: 30px -0.5rem; }

.product.name a  {
  color: #282828 !important;
  font-size: 12px;
  font-weight: bold; }

a.product-item-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  overflow: hidden; }

a.product-item-link:visited,   a.product-item-link:hover  {
  color: #282828; }

.product-items {
  list-style: none;
  display: flex;
  flex-wrap: wrap; }

.product-item {
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid #ededed; }
  .products-grid .product-item {
    display: flex;
    width: 100%; }
  .product-item-name {
    color: #282828 !important;
    font-size: 12px;
    font-weight: bold;
    display: block;
    word-wrap: break-word;
    hyphens: auto; }
    .product-item-name > a {
      color: #1a1a1a;
      text-decoration: none; }
      .product-item-name > a:visited {
        color: #1a1a1a;
        text-decoration: underline; }
      .product-item-name > a:hover {
        color: #1a1a1a;
        text-decoration: underline; }
      .product-item-name > a:active {
        color: #1a1a1a;
        text-decoration: underline; }
  .product-item-info {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    text-align: center; }
    .page-products .product-item-info {
      width: 100%; }
  .product-item-photo {
    display: flex;
    justify-content: center;
    align-items: center; }
  .product-item-actions {
    display: none;
    margin: 1rem 0;
    margin-bottom: 0; }
    .product-item-actions .actions-secondary {
      display: flex;
      justify-content: center;
      align-items: center; }
      .product-item-actions .actions-secondary > .action {
        display: flex;
        justify-content: center;
        align-items: center; }
  .product-item-description {
    text-align: left;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    padding-right: 30%; }
  .product-item .product-reviews-summary {
    width: 100%; }
    .product-item .product-reviews-summary .rating-summary {
      margin: 0 4px 0 0; }
    .product-item .product-reviews-summary .reviews-actions {
      display: none;
      font-size: 12px;
      margin-top: 5px;
      text-transform: lowercase; }
  .product-item .price-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; }
    .product-item .price-box .price {
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      padding-left: 0.5rem; }
    .product-item .price-box .price-label {
      display: none;
      font-size: 12px; }
      .product-item .price-box .price-label:after {
        content: ':'; }
  .product-item .special-price .price,
  .product-item .minimal-price .price {
    color: #FF4444; }
  .product-item .special-price .price-wrapper,
  .product-item .minimal-price .price-wrapper {
    display: inline-block; }
  .product-item .special-price .price-including-tax + .price-excluding-tax,
  .product-item .minimal-price .price-including-tax + .price-excluding-tax {
    display: block; }
  .product-item .special-price {
    display: block; }
  .product-item .old-price .price {
    font-weight: 400;
    font-size: 10px; }
  .product-item .minimal-price .price-container {
    display: block; }
  .product-item .minimal-price-link {
    margin-top: 5px; }
    .product-item .minimal-price-link .price-label {
      color: #1c5275;
      font-size: 14px; }
    .product-item .minimal-price-link .price {
      font-weight: 400; }
  .product-item .minimal-price-link,
  .product-item .price-excluding-tax,
  .product-item .price-including-tax {
    display: block;
    white-space: nowrap; }
  .product-item .price-from,
  .product-item .price-to {
    margin: 0; }
  .product-item .price-to {
    display: none; }
  .product-item .action.tocompare:before {
    content: ""; }
  .product-item .tocart {
    white-space: nowrap;
    width: 100%; }

.price-container .price {
  font-size: 14px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
  margin-top: 5px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
  font-size: 11px; }

.price-container .weee:before {
  content: "(" attr(data-label) ": "; }

.price-container .weee:after {
  content: ')'; }

.price-container .weee + .price-excluding-tax:before {
  content: attr(data-label) ": "; }

.products-list .product-item {
  display: table;
  width: 100%; }
  .products-list .product-item-info {
    display: table-row; }
  .products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%; }
  .products-list .product-item-details {
    display: table-cell;
    vertical-align: top; }

@media only screen and (max-width: 639px) {
  .products-list .product-item {
    table-layout: fixed; }
    .products-list .product-item-photo {
      padding: 0 10px 10px 0;
      width: 30%; } }

@media only screen and (max-width: 767px) {
  .product-items {
    padding: 0; } }

@media only screen and (min-width: 640px) {
  .products-grid .product-item {
    margin-bottom: 1rem; }
  .product-item-actions {
    display: flex; }
    .products-grid .product-item-actions {
      margin: 10px 0;
      margin-bottom: 0; }
    .product-item-actions .actions-primary + .actions-secondary {
      display: flex;
      white-space: nowrap; }
      .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal; }
    .product-item-actions .actions-primary {
      display: flex; }
      .product-item-actions .actions-primary > form {
        width: 100%; }
  .products-grid .product-item {
    width: 33.3333%; }
  .page-products .products-grid .product-item,
  .page-layout-1column .products-grid .product-item,
  .page-layout-3columns .products-grid .product-item,
  .page-products.page-layout-1column .products-grid .product-item,
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 33.3333%; } }

@media only screen and (min-width: 768px) {
  .page-products .products-grid .product-item {
    width: 33.3333%; }
  .page-products.page-layout-1column .products-grid .product-item {
    width: calc(33% - 2rem);
    margin: 1rem; }
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .products-grid .product-item {
    width: 20%; }
  .page-layout-1column .products-grid .product-item {
    width: 16.6666%; }
  .page-layout-3columns .products-grid .product-item {
    width: 25%; }
  .page-products .products-grid .product-item {
    width: 25%; }
  .page-products.page-layout-1column .products-grid .product-item {
    margin-left: 0;
    width: calc((100% / 4) - 1rem); }
  .page-products.page-layout-1column .products-grid .products.list.items.product-items .item.product.product-item:nth-of-type(4n) {
    border-right-width: 0; }
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 33%; } }

.page-products .columns {
  padding-top: 60px;
  position: relative;
  z-index: 1; }

.toolbar {
  margin-bottom: 30px;
  text-align: center; }
  .toolbar select {
    background-color: #f0f0f0;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }
  .toolbar-amount {
    left: 0;
    line-height: 24px;
    margin: 0;
    padding: 7px 0;
    position: absolute;
    text-align: left;
    top: 0;
    vertical-align: middle; }
    .products.wrapper ~ .toolbar .toolbar-amount {
      display: none; }
  .page-with-filter .toolbar-amount {
    position: static; }
  .toolbar-products {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent; }
    .toolbar-products:last-of-type {
      flex-direction: column; }
      .toolbar-products:last-of-type .field.limiter {
        margin-top: 1rem; }
    .toolbar-products .toolbar-amount {
      margin: 0 auto; }
    .toolbar-products .pages {
      display: none; }
      .products.wrapper ~ .toolbar-products .pages {
        display: block; }
  .toolbar .pages {
    margin-bottom: 25px; }
  .toolbar .pages-items {
    border: 1px solid #ededed;
    border-radius: 3px; }
    .toolbar .pages-items .item .page {
      border-right: 1px solid #ededed;
      padding: 0.25rem 1.25rem; }
      .toolbar .pages-items .item .page:hover {
        background: #f9f9f9; }
    .toolbar .pages-items .item:last-of-type .page {
      border-right: none; }
  .toolbar .pages-item-previous + .item .page {
    border-left: 1px solid #ededed; }

.sorter {
  float: right; }
  .page-products .sorter {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .page-products .sorter {
        width: 50%; } }
  .products.wrapper ~ .toolbar .sorter {
    display: none; }
  .sorter-options {
    margin: 0 5px 0 7px;
    width: auto;
    border-color: #d1d1d1; }
  .sorter-label {
    display: none; }
  .sorter-action {
    display: inline-block;
    text-decoration: none; }
    .sorter-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 25px;
      line-height: inherit;
      color: #3c3c3c;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .sorter-action:hover:before {
      color: #414141; }
    .sorter-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .sorter-action.sort-desc:before {
      content: ""; }
    .sorter-action.sort-asc:before {
      content: ""; }

.modes {
  display: none; }

.limiter {
  display: none; }
  .limiter-options {
    margin: 0 5px 0 7px;
    width: auto; }
  .limiter-label {
    font-weight: 400; }
  .page-products .toolbar .limiter {
    display: none; }
  .limiter .control {
    display: inline-block; }

@media only screen and (max-width: 639px) {
  .toolbar-products {
    margin-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .page-products .columns {
    padding-top: 0;
    position: relative;
    z-index: 1; }
  .toolbar-amount {
    display: block;
    float: left;
    position: static; }
  .products.wrapper ~ .toolbar .pages {
    float: left;
    margin-bottom: 0; }
  .modes {
    display: inline-block;
    float: left;
    margin-right: 20px; }
    .products.wrapper ~ .toolbar .modes {
      display: none; }
  .modes-mode {
    background-color: #f0f0f0;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12);
    color: #868686;
    float: left;
    font-weight: 400;
    line-height: 1;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none; }
    .modes-mode:hover {
      color: #868686;
      background: #ebebeb; }
    .modes-mode.active {
      background: #dedede;
      color: #9e9e9e; }
    .modes-mode > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .modes-mode:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: inherit;
      color: #868686;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .modes-mode:hover:before {
      color: #868686; }
  .page-products .sorter {
    position: static; }
  .mode-list:before {
    content: ""; }
  .limiter {
    float: right; }
    .products.wrapper ~ .toolbar .limiter {
      display: block; }
      .page-layout-1column .products.wrapper ~ .toolbar .limiter {
        padding-right: 0; } }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__html, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: grabbing; }

.fotorama-gpu, .fotorama__caption, .fotorama--fullscreen, .fotorama__wrap--css3 .fotorama__html, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full, .fotorama__wrap--css3 .fotorama__nav, .fotorama__nav:before, .fotorama__nav:after, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__stage:before, .fotorama__stage:after, .fotorama__wrap--css3 .fotorama__stage__frame {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame, .fotorama__zoom-in:focus,
.fotorama__zoom-out:focus {
  outline: 0; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after {
  background-color: #006bb4;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage, .fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__shaft {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: unset;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__wrap {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  display: inline;
  display: inline-block;
  vertical-align: middle;
  zoom: 1; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  position: absolute;
  top: -99999px;
  left: -99999px;
  z-index: -1000; }

.fotorama-visible {
  position: relative;
  left: auto;
  top: auto;
  opacity: 1;
  z-index: auto; }

.fotorama-no-tap, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: unset; }

.fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out {
  background-image: url("../images/gallery.png");
  background-repeat: no-repeat; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__active {
  box-shadow: 0 0 0 transparent; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__arr {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  box-sizing: border-box;
  z-index: 900;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__arr:focus {
    box-shadow: none; }
    .fotorama__arr:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__arr--prev {
    left: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
      transform: translate3d(-120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
      transform: translate3d(-120px, 0, 0); }
  .fotorama__arr--next {
    right: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
      transform: translate3d(120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
      transform: translate3d(120px, 0, 0); }
  .fotorama__arr--disabled {
    display: none;
    opacity: 0.1;
    pointer-events: none;
    cursor: default; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__arr {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr {
    display: none !important; }
  .fotorama__wrap--css3 .fotorama__arr:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__stage .fotorama__arr--shown {
    display: block !important; }
  .fotorama__stage .fotorama__arr--hidden {
    display: none !important; }

.fotorama__arr .fotorama__arr__arr {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .ie9 .fotorama__arr .fotorama__arr__arr {
    margin: -40px 0 0 -40px; }

.fotorama__arr--prev .fotorama__arr__arr {
  background-position: -80px -80px; }

.fotorama__arr--next .fotorama__arr__arr {
  background-position: -160px -80px; }

.fotorama__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 1.5;
  z-index: 12; }
  .fotorama__caption a {
    color: #1a1a1a;
    text-decoration: none;
    border-bottom: 1px solid;
    border-color: fade(#1a1a1a, 0.5); }
    .fotorama__caption a:hover {
      color: #1a1a1a;
      border-color: fade(#1a1a1a, 0.5); }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #ffffff; }

.fotorama__dot {
  display: block;
  position: relative;
  top: 12px;
  left: 6px;
  width: 4px;
  height: 4px;
  border: 1px solid #858585;
  border-radius: 6px; }
  .fotorama__nav__frame--dot:focus .fotorama__dot {
    box-shadow: none; }
    .fotorama__nav__frame--dot:focus .fotorama__dot:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__dot:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; }
  .fotorama__active .fotorama__dot {
    background-color: #2196f3;
    border-color: #2196f3; }

.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }
  .fotorama--fullscreen {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: 0 !important;
    background: #ffffff;
    z-index: 1000 !important; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }

.fotorama__fullscreen-icon {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-position: 0 0;
  cursor: pointer;
  z-index: 1000; }
  .fotorama__fullscreen-icon:focus {
    box-shadow: none; }
    .fotorama__fullscreen-icon:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama--fullscreen-icons .fotorama__fullscreen-icon {
    display: none; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: inline-block;
    background-position: -80px 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__fullscreen-icon {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
    display: none !important; }
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    transform: translate3d(80px, -80px, 0); }
  .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
    transform: translate3d(80px, -80px, 0) !important; }
  .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active {
      background-color: rgba(213, 213, 213, 0.5); }

.gallery-placeholder .loading-mask {
  position: static;
  padding: 0 0 50%; }

.gallery-placeholder .loader img {
  position: absolute; }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__img {
  max-width: inherit;
  opacity: 0;
  border: none !important; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }
  .fotorama__img--full {
    display: none; }
  .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
    opacity: 0; }
  .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(-50%); }
  .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    position: absolute;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%); }
  .fotorama__stage__frame .fotorama__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: inherit;
    max-height: inherit; }
  .fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
    display: none; }
    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
      display: block; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    display: block;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: default;
    transition: 0.3s linear; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
      max-width: none;
      max-height: none;
      cursor: pointer;
      transition-property: width, height, bottom, right, top, left; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
      cursor: move;
      transition-property: none; }
  .fotorama__product-video--loaded .fotorama__img,
  .fotorama__product-video--loaded .fotorama__img--full {
    display: none !important; }

.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0; }

.magnifier-lens {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border: solid 1px #bbb;
  z-index: 800; }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-large {
  position: absolute;
  width: 32%;
  z-index: 100; }

.magnifier-preview {
  position: absolute;
  top: 215px;
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0; }
  .magnifier-preview:not(.hidden) {
    background-color: #ffffff; }
  .magnifier-preview img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: inherit; }

.magnify-lens {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  z-index: 1000; }

.magnify-opaque {
  opacity: 0.5; }

.magnify-hidden {
  display: none; }

.magnify-fullimage {
  display: none; }
  .fotorama__fullscreen .magnify-fullimage {
    display: inline-block; }

.fotorama__nav {
  position: relative;
  display: none;
  max-width: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }
  .fotorama__nav--dots {
    display: block;
    text-align: center; }
  .fotorama__nav--thumbs {
    display: block; }
  .fotorama__nav:before, .fotorama__nav:after {
    position: absolute;
    display: block;
    content: '';
    text-decoration: none;
    background-repeat: no-repeat;
    z-index: 10;
    pointer-events: none; }
  .fotorama__nav:before {
    left: -10px;
    top: -10px; }
  .fotorama__nav:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__nav:after,
  .fotorama__wrap--no-shadows .fotorama__nav:before,
  .fotorama__wrap--no-shadows .fotorama__nav:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__nav {
    max-width: 99999px !important; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0; }

.fotorama__nav__frame {
  position: relative;
  box-sizing: content-box; }
  .fotorama__nav--dots .fotorama__nav__frame {
    width: 18px;
    height: 30px; }
    .fotorama__nav--dots .fotorama__nav__frame--thumb {
      display: none; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame--dot {
      display: none; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
    width: 0;
    height: 0;
    border-width: 3px; }
    .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
      top: -3px;
      left: -3px;
      padding: 3px; }
  .fotorama__nav__frame:focus {
    box-shadow: none; }
    .fotorama__nav__frame:focus :after {
      top: -1px;
      left: -1px;
      padding: 1px; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama__nav__shaft {
  position: relative;
  top: 0;
  left: 0;
  text-align: left; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    width: 100%;
    background-color: white; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__select {
  cursor: auto; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--right:after {
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  background-size: 1px 100%, 5px 100%; }

.fotorama__nav.fotorama__shadows--top:before, .fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--bottom:after {
  left: 0;
  right: 0;
  width: auto;
  height: 10px;
  background-size: 100% 1px, 100% 5px; }

.fotorama__nav.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--left:before {
  left: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--right:after {
  right: 0;
  background-position: 100% 0, 100% 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--top:before {
  top: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--bottom:after {
  bottom: 0;
  background-position: 0 100%, 0 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__video-close.fotorama-show-control {
  opacity: 1;
  transform: translate3d(0, -10px, 0); }

.fotorama__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  display: none;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 64px;
  width: 64px;
  margin: auto;
  background-image: url("../images/loader-1.gif");
  z-index: 100; }
  .fotorama__spinner--show {
    display: block; }
  .fotorama__wrap--css3 .fotorama__spinner {
    animation: spinner 24s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__stage {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .fotorama__stage:before, .fotorama__stage:after {
    position: absolute;
    display: block;
    content: '';
    background-repeat: no-repeat;
    pointer-events: none;
    text-decoration: none;
    z-index: 10; }
  .fotorama__stage:before {
    left: -10px;
    top: -10px; }
  .fotorama__stage:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
  .fotorama__wrap--no-shadows .fotorama__stage:before,
  .fotorama__wrap--no-shadows .fotorama__stage:after,
  .fotorama__wrap--fade .fotorama__stage:before,
  .fotorama__wrap--fade .fotorama__stage:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__stage {
    max-width: 99999px !important; }

.fotorama__stage__frame {
  overflow: hidden;
  text-align: center; }
  .fotorama__wrap--rtl .fotorama__stage__frame {
    direction: rtl; }
  .fotorama__wrap--slide .fotorama__stage__frame {
    opacity: 1 !important; }
  .fotorama__wrap--fade .fotorama__stage__frame {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame iframe {
    left: 80px;
    width: calc(100% - 80px * 2); }
  .fotorama__wrap--only-active .fotorama__stage__frame {
    visibility: hidden; }

.fotorama__stage__shaft {
  position: relative; }
  .fotorama__stage__shaft:focus {
    box-shadow: none; }
    .fotorama__stage__shaft:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }

.fotorama__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ebebeb; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    box-shadow: none;
    z-index: 2; }
    .fotorama__nav__frame:focus .fotorama__thumb:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 3px 1px #68a8e0;
      border-radius: inherit;
      content: '';
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__thumb,
  .fotorama__nav__frame--dot:focus .fotorama__thumb {
    overflow: inherit; }
  .fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
    left: 1px; }
  .fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
    right: 1px; }
  .fotorama__thumb--icon {
    padding-bottom: 30px;
    font-size: 0.001px; }
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .ie9 .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon, .ie9
      .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
        margin: -15px 0 0 -15px; }
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
      margin: auto;
      width: 30px;
      transform: rotate(90deg); }
    .fotorama__thumb__arr--left .fotorama__thumb--icon {
      background-position: -25px -265px; }
    .fotorama__thumb__arr--right .fotorama__thumb--icon {
      background-position: -25px -350px; }

.fotorama__thumb-border {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 1px solid #2196f3;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  backface-visibility: hidden;
  z-index: 9; }
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__thumb__arr {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__thumb__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__thumb__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 1000;
    cursor: pointer; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
    left: 0; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    right: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    cursor: pointer;
    z-index: 1000; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
    top: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    bottom: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__video {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

.fotorama__video-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
  background-position: -80px 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
    top: 97px;
    right: 93px;
    opacity: 1; }
  .fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      top: 10px;
      width: 40px;
      height: 40px;
      background-position: -100px -20px; } }

.fotorama__video-play {
  width: 160px;
  height: 160px;
  background-position: -80px -160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    width: 80px;
    height: 80px;
    background-position: 0 -240px; }
  .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }

.fotorama__wrap {
  position: relative;
  direction: ltr;
  text-size-adjust: 100%;
  z-index: 0; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }
  .fotorama__wrap--pan-y {
    touch-action: pan-y; }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none;
  z-index: 1000; }
  .fotorama__zoom-in:focus,
  .fotorama__zoom-out:focus {
    box-shadow: none; }
    .fotorama__zoom-in:focus:after,
    .fotorama__zoom-out:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
  .fotorama__zoom-out {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:active {
      background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen
  .fotorama__zoom-out {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    cursor: pointer; }
    .fotorama__fullscreen .fotorama__zoom-in--disabled, .fotorama__fullscreen
    .fotorama__zoom-out--disabled {
      display: none; }

.fotorama__fullscreen .fotorama__zoom-out {
  top: 80px;
  background-position: 0 -80px !important; }

.fotorama__fullscreen .fotorama__zoom-in {
  background-position: 0 0 !important;
  top: 0; }

.old-price,
.old.price {
  text-decoration: line-through; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 11px; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%; }

.category-image, .category-description {
  margin-bottom: 20px; }

.product-image-container {
  display: inline-block;
  max-width: 100%; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #868686;
  font-size: 12px;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 4px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 4px 30px 10px 0; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }

.product-info-main .stock.available, .product-info-main .stock.unavailable {
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  vertical-align: top; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #868686; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 14px; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 14px;
  border: none;
  display: inline-block;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 12px;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 18px;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 14px; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
  content: ': '; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 14px;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .ui-dialog-titlebar-close:visited {
    color: #1c5275;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:active {
    color: #2196f3;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.block.related .action.select {
  margin: 0 5px; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }
  .sidebar .product-items .product-item-info {
    position: relative;
    width: auto; }
    .sidebar .product-items .product-item-info .product-item-photo {
      left: 0;
      position: absolute;
      top: 0; }
  .sidebar .product-items .product-item-name {
    margin-top: 0; }
  .sidebar .product-items .product-item-details {
    margin: 0 0 0 85px; }
  .sidebar .product-items .product-item-actions {
    display: block;
    margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #868686;
  font-size: 12px;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

@media only screen and (max-width: 767px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .block.related .action.select {
    display: block;
    margin: 5px 0; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 768px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 768px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: 40%; }
  .page-layout-1column .product.media {
    width: 57%; }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #d1d1d1; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #d1d1d1; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 13px; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 1.2rem;
      padding: 5px 8px;
      font-size: 1.1rem; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #ffffff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.old-price,
.old.price {
  text-decoration: line-through; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 1.1rem; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%; }

.category-image, .category-description {
  margin-bottom: 20px; }

.product-image-container {
  display: inline-block;
  max-width: 100%; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #868686;
  font-size: 1.2rem;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 4px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 4px 30px 10px 0; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }

.product-info-main .stock.available, .product-info-main .stock.unavailable {
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  vertical-align: top; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #868686; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 1.4rem; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 1.4rem;
  border: none;
  display: inline-block;
  float: none;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
  margin-top: 10px; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 1.2rem;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 1.2rem;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 1.8rem;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 1.4rem; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
  content: ': '; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 1.4rem;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .ui-dialog-titlebar-close:visited {
    color: #1c5275;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:active {
    color: #2196f3;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:hover {
    color: #006bb4; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.block.related .action.select {
  margin: 0 5px; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }

.sidebar .product-items .product-item-info {
  position: relative;
  width: auto; }
  .sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0; }

.sidebar .product-items .product-item-name {
  margin-top: 0; }

.sidebar .product-items .product-item-details {
  margin: 0 0 0 85px; }

.sidebar .product-items .product-item-actions {
  display: block;
  margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #868686;
  font-size: 1.2rem;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

@media only screen and (max-width: 767px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .block.related .action.select {
    display: block;
    margin: 5px 0; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 768px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      padding-top: 25px;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    float: none;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 768px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: 40%; }
  .page-layout-1column .product.media {
    width: 60%;
    padding-right: 20px; }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #d1d1d1; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #d1d1d1; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 1.3rem; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 1.2rem;
      padding: 5px 8px;
      font-size: 1.1rem; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #ffffff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.block-category-event {
  background: #f0f0f0;
  color: #3c3c3c;
  padding: 10px 0 15px;
  text-align: center; }
  .block-category-event.block:last-child {
    margin-bottom: 10px; }
  .block-category-event .block-title {
    margin: 0 0 10px; }
    .block-category-event .block-title strong {
      font-size: 1.5rem;
      font-weight: 300; }
  .block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .block-category-event .ticker li {
      display: none;
      margin: 0 15px; }
    .block-category-event .ticker .value {
      font-size: 1.8rem;
      font-weight: 300; }
    .block-category-event .ticker .label {
      font-size: 1rem;
      display: block;
      text-transform: lowercase; }
  .block-category-event .dates {
    display: table;
    margin: 0 auto; }
    .block-category-event .dates .start,
    .block-category-event .dates .end {
      display: table-cell;
      vertical-align: top; }
    .block-category-event .dates .date {
      font-size: 1.8rem; }
    .block-category-event .dates .time {
      display: block; }
    .block-category-event .dates .start {
      padding-right: 40px;
      position: relative; }
      .block-category-event .dates .start:after {
        font-size: 1.8rem;
        content: '\2013';
        display: block;
        position: absolute;
        right: 15px;
        top: 0; }

@media only screen and (min-width: 768px) {
  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px; }
  .block-category-event .block-title {
    margin: 0; }
    .block-category-event .block-title strong {
      font-size: 2.4rem; }
  .block-category-event .ticker li {
    display: none;
    margin: 0 50px; }
  .block-category-event .ticker .value {
    font-size: 6rem; }
  .block-category-event .ticker .label {
    font-size: 1.4rem;
    text-transform: none; }
  .block-category-event .dates .date {
    font-size: 5rem; }
  .block-category-event .dates .start {
    padding-right: 50px; }
    .block-category-event .dates .start:after {
      font-size: 5rem;
      right: 10px; } }

.block-search {
  margin-bottom: 0; }
  .block-search .block-title {
    display: none; }
  .block-search .block-content {
    margin-bottom: 0; }
  .block-search .label {
    display: inline-block;
    text-decoration: none;
    display: inline-block;
    float: right; }
    .block-search .label > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .block-search .label:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 35px;
      line-height: 33px;
      color: #3c3c3c;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .block-search .label:hover:before {
      color: #414141; }
    .block-search .label:active:before {
      color: #414141; }
    .block-search .label.active + .control input {
      position: static; }
  .block-search .action.search {
    display: none; }
  .block-search .control {
    border-top: 1px solid #d1d1d1;
    clear: both;
    margin: 0 -15px -1px;
    padding: 0 15px; }
  .block-search input {
    left: -300%;
    margin: 15px 0;
    position: absolute; }
  .block-search .nested {
    display: none; }

.search-autocomplete {
  display: none;
  margin-top: -15px;
  overflow: hidden;
  position: absolute;
  z-index: 3; }
  .search-autocomplete ul {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .search-autocomplete ul li {
      border-top: 1px solid #e5e5e5;
      cursor: pointer;
      margin: 0;
      padding: 5px 40px 5px 10px;
      position: relative;
      text-align: left;
      white-space: normal; }
      .search-autocomplete ul li:not(:empty) {
        border-top: 0;
        border: 1px solid #c2c2c2;
        background: #ffffff; }
      .search-autocomplete ul li:first-child {
        border-top: none; }
      .search-autocomplete ul li:hover, .search-autocomplete ul li.selected {
        background: #e8e8e8; }
      .search-autocomplete ul li .amount {
        color: #999;
        position: absolute;
        right: 7px;
        top: 5px; }

.form.search.advanced .fields.range .field:first-child {
  position: relative; }
  .form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px; }
    .form.search.advanced .fields.range .field:first-child .control:after {
      content: ' \2013 ';
      display: inline-block;
      position: absolute;
      right: 0;
      text-align: center;
      top: 6px;
      width: 25px; }

.form.search.advanced .fields.range .field:last-child {
  position: relative; }
  .form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px; }

.form.search.advanced .fields.range .field.with-addon .control {
  padding-right: 45px; }

.form.search.advanced .group.price .addon .addafter {
  background: none;
  border: 0;
  padding-top: 6px;
  position: absolute;
  right: 0;
  top: 0; }

.search.summary {
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .block-search .block-content {
    margin-bottom: 0; } }

@media only screen and (max-width: 639px) {
  .block-search {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4; }
    .block-search .control {
      border-top: 0;
      margin: 0;
      padding: 0 0 25px; }
    .block-search .nested {
      display: block;
      padding-top: 5px;
      position: absolute; }
    .block-search input {
      margin: 0;
      padding-right: 35px;
      position: static; }
      .block-search input::-webkit-input-placeholder {
        color: #c2c2c2; }
      .block-search input:-moz-placeholder {
        color: #c2c2c2; }
      .block-search input::-moz-placeholder {
        color: #c2c2c2; }
      .block-search input:-ms-input-placeholder {
        color: #c2c2c2; }
    .block-search .action.search {
      display: inline-block;
      display: inline-block;
      text-decoration: none;
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      padding: 5px 0;
      position: absolute;
      right: 10px;
      top: 0;
      z-index: 1; }
      .block-search .action.search > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #3c3c3c;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .block-search .action.search:hover:before {
        color: inherit; }
      .block-search .action.search:active:before {
        color: inherit; }
      .block-search .action.search:focus, .block-search .action.search:active {
        background: none;
        border: none; }
      .block-search .action.search:hover {
        background: none;
        border: none; }
      .block-search .action.search.disabled, .block-search .action.search[disabled],
      fieldset[disabled] .block-search .action.search {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5; }
      .block-search .action.search:focus:before {
        color: #333; }
  .search-autocomplete {
    margin-top: 0; } }

.cart-summary .block .title > strong {
  display: block;
  padding: 1rem 0;
  cursor: pointer;
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block;
  text-decoration: none; }
  .cart-summary .block .title > strong:hover {
    cursor: pointer;
    opacity: 0.9; }
  .cart-summary .block .title > strong:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }

.cart-summary .block.active .title > strong {
  display: inline-block;
  text-decoration: none; }
  .cart-summary .block.active .title > strong:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }

.summary {
  background: #f5f5f5;
  margin-bottom: 25px;
  padding: 1rem; }
  .summary.title {
    display: block;
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
  .summary .block {
    margin-bottom: 0; }
    .summary .block .item-options {
      margin-left: 0; }
    .summary .block .fieldset {
      display: flex;
      flex-direction: column;
      padding: 0.5rem; }
      .summary .block .fieldset .field {
        margin: 0 0 10px; }
        .summary .block .fieldset .field.note {
          font-size: 12px; }
      .summary .block .fieldset .methods .field > .label {
        display: inline; }

.cart-totals {
  border-top: 1px solid #d1d1d1;
  padding-top: 10px; }
  .cart-totals .table-wrapper {
    margin-bottom: 0;
    overflow: inherit; }

.cart.table-wrapper .items thead + .item {
  border-top: 1px solid #d1d1d1; }

.cart.table-wrapper .items > .item {
  border-bottom: 1px solid #d1d1d1;
  position: relative; }

.cart.table-wrapper .col {
  padding-top: 20px; }
  .cart.table-wrapper .col.qty .input-text {
    margin-top: -5px; }

.cart.table-wrapper .item-actions td {
  padding-bottom: 10px;
  text-align: center;
  white-space: normal; }

.cart.table-wrapper .item .col.item {
  display: block;
  min-height: 75px;
  padding: 25px 0 10px 75px;
  position: relative; }

.cart.table-wrapper .actions-toolbar > .action {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px; }
  .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .actions-toolbar > .action:focus {
    text-decoration: none; }
  .cart.table-wrapper .actions-toolbar > .action:last-child {
    margin-right: 0; }

.cart.table-wrapper .action.help.map {
  font-weight: 400; }

.cart.table-wrapper .product-item-photo {
  display: block;
  left: 0;
  max-width: 60px;
  padding: 0;
  position: absolute;
  top: 15px;
  width: 100%; }

.cart.table-wrapper .product-item-details {
  white-space: normal; }

.cart.table-wrapper .product-item-name {
  display: inline-block;
  font-weight: 400;
  margin-top: -6px; }

.cart.table-wrapper .gift-registry-name-label:after {
  content: ':'; }

.cart.table-wrapper .item-options {
  font-size: 12px;
  margin-bottom: 10px; }

.cart.table-wrapper .product-item-name + .item-options {
  margin-top: 10px; }

.cart.table-wrapper .action.configure {
  display: inline-block;
  margin: 0 0 20px; }

.cart-container .checkout-methods-items {
  margin-top: 20px;
  text-align: center; }
  .cart-container .checkout-methods-items .action.primary {
    width: 100%; }
  .cart-container .checkout-methods-items .item + .item {
    margin-top: 20px; }

.block.crosssell {
  margin-top: 70px; }

.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
  display: block; }

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.qty,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
  text-align: right; }

@media only screen and (max-width: 767px) {
  .cart.table-wrapper {
    overflow: inherit; }
    .cart.table-wrapper thead .col:not(.item) {
      display: none; }
    .cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {
      box-sizing: border-box;
      display: block;
      float: left;
      text-align: center;
      white-space: nowrap;
      width: 33%; }
      .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
        content: attr(data-th) ":";
        display: block;
        font-weight: 700;
        padding-bottom: 10px; }
    .cart.table-wrapper .col.msrp {
      white-space: normal; }
    .cart.table-wrapper .item .col.item {
      padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .cart-container .widget {
    float: left; }
  .cart-summary {
    float: right;
    position: relative;
    width: 23%; }
    .column.main .cart-summary .actions-toolbar > .secondary {
      float: none; }
    .cart-summary .block .fieldset .field {
      margin: 0 0 20px;
      margin: 0 0 10px; }
      .cart-summary .block .fieldset .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .cart-summary .block .fieldset .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .cart-summary .block .fieldset .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .cart-summary .block .fieldset .field.choice:before, .cart-summary .block .fieldset .field.no-label:before {
        display: none; }
  .cart.table-wrapper .items {
    min-width: 100%;
    width: auto; }
  .cart.table-wrapper .item .col.item {
    padding: 27px 8px 10px; }
  .cart.table-wrapper .item-actions td {
    text-align: right; }
  .cart.table-wrapper .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding-right: 20px;
    position: static;
    vertical-align: top;
    width: 1%; }
  .cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: 99%; }
  .cart.table-wrapper .item-actions .actions-toolbar {
    text-align: left; } }

.block-minicart .items-total {
  display: none;
  float: left;
  margin: 0 10px;
  margin-top: 0.25rem; }
  .block-minicart .items-total .count {
    font-weight: 700; }

.block-minicart .subtotal {
  margin: 0 10px;
  text-align: left; }
  .block-minicart .subtotal .label {
    display: none; }

.block-minicart .amount .price-wrapper:first-child .price {
  font-size: 18px;
  font-weight: 700; }

.block-minicart .subtitle {
  display: none; }
  .block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center; }

.block-minicart .text.empty {
  text-align: center; }

.block-minicart .block-content > .actions {
  margin-top: 15px;
  text-align: center; }
  .block-minicart .block-content > .actions > .primary {
    margin: 0 10px 15px; }
    .block-minicart .block-content > .actions > .primary .action.primary {
      display: block;
      margin-bottom: 15px;
      width: calc(100% + 4rem + 6px);
      margin-left: calc(-2rem - 3px);
      margin-bottom: -1rem; }
      .block-minicart .block-content > .actions > .primary .action.primary:last-child {
        margin-bottom: 0; }

.block-minicart .action.close,
.block-minicart .action.delete,
.block-minicart .action.edit,
.block-minicart .action.remove {
  transform: scale(0.6); }

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
  margin: 15px 0 0;
  text-align: center; }

.minicart-wrapper {
  display: inline-block;
  position: relative;
  float: right; }
  .minicart-wrapper:before, .minicart-wrapper:after {
    content: '';
    display: table; }
  .minicart-wrapper:after {
    clear: both; }
  .minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .minicart-wrapper .action.showcart:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 26px;
      line-height: 26px;
      color: #3c3c3c;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.showcart:hover:before {
      color: #414141; }
    .minicart-wrapper .action.showcart:active:before {
      color: #3c3c3c; }
    .minicart-wrapper .action.showcart.active {
      display: inline-block;
      text-decoration: none; }
      .minicart-wrapper .action.showcart.active:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 26px;
        line-height: 26px;
        color: #3c3c3c;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .minicart-wrapper .action.showcart.active:hover:before {
        color: #414141; }
      .minicart-wrapper .action.showcart.active:active:before {
        color: #3c3c3c; }
  .minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: none; }
    .minicart-wrapper .block-minicart li {
      margin: 0; }
      .minicart-wrapper .block-minicart li:hover {
        cursor: pointer; }
    .minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .minicart-wrapper .block-minicart:before {
      border: 6px solid;
      border-color: transparent transparent #ffffff transparent;
      z-index: 99; }
    .minicart-wrapper .block-minicart:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .minicart-wrapper .block-minicart:before {
      left: 10px;
      top: -12px; }
    .minicart-wrapper .block-minicart:after {
      left: 9px;
      top: -14px; }
  .minicart-wrapper.active {
    overflow: visible; }
    .minicart-wrapper.active .block-minicart {
      display: block; }
  .minicart-wrapper .block-minicart {
    padding: 25px 20px;
    padding-bottom: 1rem;
    right: 0;
    width: 320px; }
    .minicart-wrapper .block-minicart .block-title {
      display: none; }
    .minicart-wrapper .block-minicart:after {
      left: auto;
      right: 25px; }
    .minicart-wrapper .block-minicart:before {
      left: auto;
      right: 26px; }
  .minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0;
    text-align: right; }
    .minicart-wrapper .product .actions > .primary,
    .minicart-wrapper .product .actions > .secondary {
      display: inline; }
  .minicart-wrapper .action.close {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px; }
    .minicart-wrapper .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-wrapper .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 32px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.close:hover:before {
      color: inherit; }
    .minicart-wrapper .action.close:active:before {
      color: inherit; }
    .minicart-wrapper .action.close:focus, .minicart-wrapper .action.close:active {
      background: none;
      border: none; }
    .minicart-wrapper .action.close:hover {
      background: none;
      border: none; }
    .minicart-wrapper .action.close.disabled, .minicart-wrapper .action.close[disabled],
    fieldset[disabled] .minicart-wrapper .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .minicart-wrapper .action.showcart {
    display: flex;
    flex-direction: column;
    position: relative;
    white-space: nowrap;
    background: none;
    border: 0;
    color: inherit; }
    .minicart-wrapper .action.showcart .text {
      display: none;
      font-size: 10px;
      text-align: center; }
    .minicart-wrapper .action.showcart .counter.qty {
      position: absolute;
      top: -5px;
      right: -20px;
      left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2196f3;
      color: #ffffff;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      margin: 3px 0 0;
      min-width: 18px;
      overflow: hidden;
      padding: 0 3px;
      text-align: center;
      white-space: normal;
      transform: scale(0.75); }
      .minicart-wrapper .action.showcart .counter.qty.empty {
        display: none; }
      .minicart-wrapper .action.showcart .counter.qty .loader > img {
        max-width: 24px; }

.minicart-items-wrapper {
  border: 1px solid #ccc;
  border-top: none;
  margin: 0 -20px;
  border-left: 0;
  border-right: 0;
  overflow-x: hidden;
  padding: 15px;
  max-height: 350px; }

.minicart-items {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .minicart-items .product-item {
    padding: 20px 0; }
    .minicart-items .product-item:not(:first-child) {
      border-top: 1px solid #ccc; }
    .minicart-items .product-item:first-child {
      padding-top: 0; }
    .minicart-items .product-item:last-child {
      border-width: 0; }
  .minicart-items .product-item-pricing .label {
    display: inline-block;
    opacity: 0;
    width: 4.5rem; }
  .minicart-items .price-minicart {
    margin-bottom: 5px; }
  .minicart-items .product > .product-item-photo,
  .minicart-items .product > .product-image-container {
    float: left; }
  .minicart-items .product .toggle {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative; }
    .minicart-items .product .toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 16px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .minicart-items .product .toggle:after {
      position: static; }
  .minicart-items .product.active > .toggle:after {
    content: ""; }
  .minicart-items .product-item-name {
    font-weight: 400;
    margin: 0 0 10px; }
    .minicart-items .product-item-name a {
      color: #1c5275; }
  .minicart-items .product-item-details {
    padding-left: 88px; }
    .minicart-items .product-item-details .price {
      font-weight: 700; }
    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
      margin: 5px 0 0; }
    .minicart-items .product-item-details .weee[data-label] {
      font-size: 11px; }
    .minicart-items .product-item-details .details-qty {
      margin-top: 10px; }
  .minicart-items .product.options .tooltip.toggle {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .product.options .tooltip.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .product.options .tooltip.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      margin: -3px 0 0 7px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .minicart-items .product.options .tooltip.toggle .details {
      display: none; }
  .minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 40px; }
  .minicart-items .update-cart-item {
    font-size: 11px;
    vertical-align: top; }
  .minicart-items .subtitle {
    display: none; }
  .minicart-items .action.edit, .minicart-items .action.delete {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .action.edit > span, .minicart-items .action.delete > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .action.edit:before, .minicart-items .action.delete:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .minicart-items .action.edit:hover:before, .minicart-items .action.delete:hover:before {
      color: #303030; }
    .minicart-items .action.edit:active:before, .minicart-items .action.delete:active:before {
      color: #303030; }
  .minicart-items .action.delete:before {
    content: ""; }

@media only screen and (max-width: 479px) {
  .minicart-wrapper .block-minicart {
    width: 290px; } }

@media only screen and (max-width: 639px) {
  .minicart-wrapper {
    margin-top: 10px; } }

@media only screen and (max-width: 767px) {
  .minicart-wrapper .action.showcart {
    padding-right: 5px; }
    .minicart-wrapper .action.showcart .counter.qty {
      right: -9px; } }

@media only screen and (min-width: 768px) {
  .minicart-wrapper {
    margin-left: 13px; }
    .minicart-wrapper .action.showcart .text {
      display: block; }
    .minicart-wrapper .block-minicart {
      right: -15px;
      width: 390px; } }

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: 1px; }
  @media only screen and (max-width: 639px) {
    .field-tooltip {
      right: 0; } }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #3c3c3c; }
  .field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #3c3c3c; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #3c3c3c; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 479px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.checkout-index-index .page-wrapper,
.checkout-onepage-success .page-wrapper {
  background: #f9f9f9; }

.checkout-index-index .checkout-success,
.checkout-onepage-success .checkout-success {
  width: 300px;
  height: 300px;
  background: #ffffff;
  padding: 3rem;
  text-align: center;
  margin: 4rem auto;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }
  .checkout-index-index .checkout-success .actions-toolbar,
  .checkout-onepage-success .checkout-success .actions-toolbar {
    padding: 1rem;
    text-align: center;
    justify-content: center; }

.checkout-index-index #reference-number-form .input-text,
.checkout-onepage-success #reference-number-form .input-text {
  width: 180px; }

.checkout-container {
  margin: 0 0 20px; }

.opc-wrapper {
  margin: 0 0 20px; }
  .opc-wrapper .step-title {
    font-family: "Myriad Pro Regular", sans-serif; }
  .opc-wrapper .step-content {
    margin: 0 0 40px; }

.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
  display: none; }

.checkout-index-index .logo {
  margin-left: 0; }

@media only screen and (max-width: 639px) {
  .opc-wrapper .step-title {
    font-size: 16px;
    border-bottom: 0;
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .opc-wrapper {
    display: flex; }
    .opc-wrapper .opc {
      display: flex;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      width: 100%; }
      .opc-wrapper .opc > li {
        list-style: none;
        width: calc(50% - 2rem);
        padding: 2rem;
        margin: 1rem;
        background: #ffffff; }
  .checkout-container .opc-summary-wrapper {
    margin: 1rem 1rem;
    width: calc(100% - 2rem); }
  .checkout-container .opc-block-summary {
    background: #ffffff; } }

.opc-estimated-wrapper {
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px;
  padding: 18px 15px; }
  .opc-estimated-wrapper .estimated-block {
    font-size: 16px;
    font-weight: 700;
    float: left; }
    .opc-estimated-wrapper .estimated-block .estimated-label {
      display: block;
      margin: 0 0 5px; }
  .opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
    color: #3c3c3c; }

@media only screen and (min-width: 768px) {
  .opc-estimated-wrapper {
    display: none; } }

.opc-progress-bar {
  margin: 0 0 20px;
  counter-reset: i;
  font-size: 0; }

.opc-progress-bar-item {
  margin: 0 0 10px;
  width: 50%;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top; }
  .opc-progress-bar-item:before {
    background: #e4e4e4;
    top: 24px;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%; }
  .opc-progress-bar-item:first-child {
    margin-right: 100px; }
    .opc-progress-bar-item:first-child:before {
      border-radius: 6px 0 0 6px; }
    .opc-progress-bar-item:first-child:after {
      content: '';
      background: #000;
      width: calc(100% - -1rem);
      height: 1px;
      position: absolute;
      top: 25px;
      left: calc(100% - 3.5rem); }
  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0; }
  .opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 55px;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: 1.6;
    font-size: 1.8rem; }
    .opc-progress-bar-item > span:before, .opc-progress-bar-item > span:after {
      background: #fff;
      height: 48px;
      margin-left: -24px;
      width: 48px;
      border-radius: 50%;
      content: '';
      left: 50%;
      position: absolute;
      top: 0; }
    .opc-progress-bar-item > span:before {
      border: 1px solid #e6f0ed; }
    .opc-progress-bar-item > span:after {
      background: #ffffff;
      height: 36px;
      margin-left: -18px;
      top: 10px;
      width: 36px;
      content: counter(i);
      counter-increment: i;
      color: #3c3c3c;
      font-family: inherit;
      font-style: inherit;
      font-weight: 600;
      line-height: inherit;
      font-size: 1.8rem; }
  .opc-progress-bar-item._active:before {
    background: #e6f0ed; }
  .opc-progress-bar-item._active > span {
    color: #3c3c3c; }
    .opc-progress-bar-item._active > span:before {
      background: #e6f0ed; }
    .opc-progress-bar-item._active > span:after {
      background: transparent;
      color: #1a1a1a; }
  .opc-progress-bar-item._complete {
    cursor: pointer; }
    .opc-progress-bar-item._complete > span {
      color: #3c3c3c; }
      .opc-progress-bar-item._complete > span:after {
        content: "";
        font-family: "Material Icons";
        line-height: 1.5;
        font-size: 28px;
        top: 3px; }

@media only screen and (min-width: 768px) {
  .opc-progress-bar-item {
    width: 185px; } }

.field.choice .field-tooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px; }

.field.choice .field-tooltip-action {
  line-height: 21px; }

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
  border-color: #ed8380; }

.opc-wrapper .fieldset > .field > .label {
  font-weight: 400; }

@media only screen and (min-width: 768px) {
  .opc-wrapper .fieldset > .field.required, .opc-wrapper .fieldset > .field._required {
    position: relative; }
    .opc-wrapper .fieldset > .field.required > label, .opc-wrapper .fieldset > .field._required > label {
      padding-right: 25px; }
      .opc-wrapper .fieldset > .field.required > label:after, .opc-wrapper .fieldset > .field._required > label:after {
        margin-left: 10px;
        position: absolute;
        top: 9px; } }

@media only screen and (min-width: 768px) {
  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px; }
  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 10px; } }

@media only screen and (min-width: 1024px) {
  .checkout-index-index .modal-popup .modal-inner-wrap {
    margin-left: -400px;
    width: 800px;
    left: 50%; } }

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: 1px; }
  @media only screen and (max-width: 639px) {
    .field-tooltip {
      right: 0; } }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #3c3c3c; }
  .field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #3c3c3c; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #3c3c3c; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 479px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
  margin-top: 28px;
  margin-bottom: 20px; }
  .opc-wrapper .form-login .fieldset .note,
  .opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px; }

.opc-wrapper .shipping-address-items {
  font-size: 0;
  margin-bottom: 20px;
  max-height: 500px;
  overflow-y: scroll; }

.opc-wrapper .shipping-address-item {
  border: 2px solid transparent;
  line-height: 30px;
  margin: 0 0 20px;
  padding: 20px 35px 20px 20px;
  transition: 0.3s border-color;
  width: 50%;
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #2196f3; }
    .opc-wrapper .shipping-address-item.selected-item:after {
      background: #2196f3;
      color: #ffffff;
      content: "";
      font-family: "Material Icons";
      height: 27px;
      width: 29px;
      font-size: 27px;
      line-height: 21px;
      padding-top: 2px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0; }
    .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
      visibility: hidden; }

.opc-wrapper .action-show-popup {
  margin: 0 0 20px; }
  .opc-wrapper .action-show-popup > span:before {
    content: '+';
    padding-right: 5px; }

.opc-wrapper .action-select-shipping-item {
  float: right;
  margin: 20px 0 0; }

.opc-wrapper .edit-address-link {
  display: block;
  float: left;
  margin: 26px 5px 0 0; }

.checkout-shipping-method .step-title {
  margin-bottom: 0; }

.checkout-shipping-method .no-quotes-block {
  margin: 20px 0; }

.methods-shipping .actions-toolbar .action.primary {
  margin: 20px 0 0; }

.table-checkout-shipping-method thead th {
  display: none; }

.table-checkout-shipping-method tbody td {
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px; }
  .table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px; }

.table-checkout-shipping-method tbody tr:first-child td {
  border-top: none; }

.table-checkout-shipping-method tbody .row-error td {
  border-top: none;
  padding-bottom: 10px;
  padding-top: 0; }

@media only screen and (max-width: 639px) {
  .opc-wrapper .form-login {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px; }
  .opc-wrapper .shipping-address-items {
    max-height: 280px; }
  .opc-wrapper .shipping-address-item {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px;
    width: 100%; }
    .opc-wrapper .shipping-address-item.selected-item {
      padding: 15px 35px 15px 18px;
      border-bottom-width: 2px; }
      .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    margin-top: 28px; }
  .opc-wrapper .action-select-shipping-item {
    float: none;
    margin-top: 10px;
    width: 100%; }
  .opc-wrapper .action-show-popup {
    width: 100%; }
  .opc-wrapper .edit-address-link {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 1px; }
    .opc-wrapper .edit-address-link > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-wrapper .edit-address-link:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .opc-wrapper .edit-address-link:hover:before {
      color: #303030; }
    .opc-wrapper .edit-address-link:active:before {
      color: #303030; } }

@media only screen and (min-width: 768px) {
  .checkout-shipping-method .actions-toolbar > .primary {
    float: right; }
  .checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    max-width: 500px; }
  .table-checkout-shipping-method {
    width: auto; } }

@media only screen and (min-width: 1024px) {
  .opc-wrapper .shipping-address-item {
    width: 33.3333%; }
    .opc-wrapper .shipping-address-item:before {
      background: #ccc;
      height: calc(100% - 20px);
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 1px; }
    .opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item:before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
      display: none; }
  .table-checkout-shipping-method {
    min-width: 500px; } }

.checkout-shipping-method {
  position: relative; }

.shipping-policy-block.field-tooltip {
  top: 12px; }
  .shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #1c5275;
    cursor: pointer; }
    .shipping-policy-block.field-tooltip .field-tooltip-action:before {
      display: none; }
  .shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px; }

@media only screen and (max-width: 639px) {
  .shipping-policy-block.field-tooltip {
    margin-bottom: 20px;
    position: relative;
    right: auto;
    top: auto; }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
      width: 300px;
      right: auto; }
      .shipping-policy-block.field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto; } }

@media only screen and (min-width: 768px) {
  .opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333%;
    float: right;
    order: 2; } }

.opc-block-shipping-information {
  padding: 0 30px; }
  .opc-block-shipping-information .shipping-information-title {
    border-bottom: 1px solid #ccc;
    margin: 0 0 20px;
    position: relative; }
    .opc-block-shipping-information .shipping-information-title .action-edit {
      top: 2px;
      display: inline-block;
      text-decoration: none;
      margin: 0;
      position: absolute;
      right: 0; }
      .opc-block-shipping-information .shipping-information-title .action-edit > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .opc-block-shipping-information .shipping-information-title .action-edit:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #3c3c3c;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
        color: #3c3c3c; }
      .opc-block-shipping-information .shipping-information-title .action-edit:active:before {
        color: #3c3c3c; }
  .opc-block-shipping-information .shipping-information-content {
    line-height: 27px; }
    .opc-block-shipping-information .shipping-information-content .actions-toolbar {
      margin-left: 0; }
  .opc-block-shipping-information .ship-to,
  .opc-block-shipping-information .ship-via {
    margin: 0 0 20px; }

.opc-block-summary {
  background: #f5f5f5;
  padding: 22px 30px;
  margin: 0 0 20px; }
  .opc-block-summary > .title {
    display: block; }
  .opc-block-summary .mark .value {
    color: #999;
    display: block; }
  .opc-block-summary .grand.incl + .grand.excl .mark,
  .opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0; }
    .opc-block-summary .grand.incl + .grand.excl .mark strong,
    .opc-block-summary .grand.incl + .grand.excl .amount strong {
      font-weight: 400; }
  .opc-block-summary .not-calculated {
    font-style: italic; }
  .opc-block-summary .items-in-cart > .title {
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative; }
    .opc-block-summary .items-in-cart > .title > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-block-summary .items-in-cart > .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      line-height: 12px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      margin: 3px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .opc-block-summary .items-in-cart > .title:after {
      position: absolute;
      right: 0;
      top: 10px; }
    .opc-block-summary .items-in-cart > .title strong {
      font-size: 1.8rem;
      font-weight: 300;
      margin: 0; }
  .opc-block-summary .items-in-cart.active > .title:after {
    content: ""; }
  .opc-block-summary .items-in-cart .product {
    position: relative; }
  .opc-block-summary .minicart-items-wrapper {
    margin: -15px 0 0;
    max-height: 370px;
    padding: 15px 15px 0 0;
    border: 0; }
  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0; }
  .opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%; }
  .opc-block-summary .product-item .product-item-name-block {
    display: table-cell;
    padding-right: 5px;
    text-align: left; }
  .opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right; }
  .opc-block-summary .product-item .price {
    font-size: 1.6rem;
    font-weight: 400; }
  .opc-block-summary .product-item .price-including-tax + .price-excluding-tax {
    margin: 0; }
    .opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
      font-size: 1rem; }
  .opc-block-summary .actions-toolbar {
    position: relative;
    z-index: 1; }
    .opc-block-summary .actions-toolbar .secondary {
      border-top: 1px solid #d1d1d1;
      display: block;
      float: none;
      margin: -1px 0 0;
      padding: 15px 0 0;
      text-align: left; }
      .opc-block-summary .actions-toolbar .secondary .action {
        margin: 0; }

@media only screen and (min-width: 768px) {
  .opc-summary-wrapper .modal-header .action-close {
    display: none; } }

.authentication-dropdown {
  box-sizing: border-box; }
  .authentication-dropdown .modal-inner-wrap {
    padding: 25px; }

.authentication-wrapper {
  float: right;
  margin-top: 1rem;
  max-width: 50%;
  position: relative;
  z-index: 1; }
  ._has-auth-shown .authentication-wrapper {
    z-index: 900; }

.block-authentication .block-title {
  font-size: 18px;
  border-bottom: 0;
  margin-bottom: 25px; }
  .block-authentication .block-title strong {
    font-weight: 300; }

.block-authentication .field .label {
  font-weight: 400; }

.block-authentication .actions-toolbar {
  margin-bottom: 5px; }
  .block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left; }

.block-authentication .block[class] {
  margin: 0; }
  .block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px; }
  .block-authentication .block[class] .field .control,
  .block-authentication .block[class] .field .label {
    float: none;
    width: auto; }
  .block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative; }
    .block-authentication .block[class] + .block::before {
      height: 36px;
      line-height: 34px;
      margin: -17px 0 0 -18px;
      min-width: 36px;
      background: #ffffff;
      border: 1px solid #c1c1c1;
      border-radius: 50%;
      box-sizing: border-box;
      color: #c1c1c1;
      content: attr(data-label);
      display: inline-block;
      left: 50%;
      letter-spacing: normal;
      padding: 0 0.2rem;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0; }

@media only screen and (min-width: 768px) {
  .authentication-dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #aeaeae;
    text-align: left;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear 0.15s, visibility 0 linear 0.15s;
    visibility: hidden; }
    .authentication-dropdown._show {
      z-index: 100;
      transform: scale(1, 1);
      transition: transform linear 0.15s, visibility 0 linear 0;
      visibility: visible; }
  .authentication-wrapper {
    width: 33.33333%;
    text-align: right; }
  .block-authentication .block-title {
    border-bottom: 0;
    margin-bottom: 25px; }
  .block-authentication .actions-toolbar > .primary {
    float: right;
    margin-right: 0; }
    .block-authentication .actions-toolbar > .primary .action {
      margin-right: 0; }
  .block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem; }
  .popup-authentication .modal-inner-wrap {
    min-width: 768px;
    width: 60%; }
  .popup-authentication .block-authentication {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c1c1c1; }
  .popup-authentication .block[class],
  .popup-authentication .form-login,
  .popup-authentication .fieldset,
  .popup-authentication .block-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%; }
    .popup-authentication .block[class] + .block {
      border-left: 1px solid #c1c1c1;
      border-top: 0;
      margin: 0;
      padding: 10px 0 0 40px; }
      .popup-authentication .block[class] + .block::before {
        left: 0;
        top: 50%; }
  .popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto; } }

.checkout-payment-method .step-title {
  border-bottom: 0;
  margin-bottom: 0; }

.checkout-payment-method .payment-method:first-child .payment-method-title {
  border-top: 0; }

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block; }

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
  border-top: 1px solid #ccc; }

.checkout-payment-method .payment-method-title {
  padding: 20px 0;
  margin: 0; }
  .checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle; }
  .checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px; }

.checkout-payment-method .payment-method-content {
  display: none;
  padding: 0 0 20px 22px; }
  .checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px; }

.checkout-payment-method .payment-group + .payment-group .step-title {
  margin: 20px 0 0; }

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
  max-width: 500px; }

.checkout-payment-method .billing-address-same-as-shipping-block {
  margin: 0 0 10px; }

.checkout-payment-method .checkout-billing-address {
  margin: 0 0 20px; }
  .checkout-payment-method .checkout-billing-address .primary .action-update {
    margin-right: 0; }
  .checkout-payment-method .checkout-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px; }

.checkout-payment-method .payment-method-note + .checkout-billing-address {
  margin-top: 20px; }

.checkout-payment-method .payment-method-iframe {
  background-color: transparent;
  display: none;
  width: 100%; }

.checkout-payment-method .no-payments-block {
  margin: 20px 0; }

@media only screen and (max-width: 767px) {
  .checkout-payment-method .payment-methods {
    margin: -15px; }
  .checkout-payment-method .payment-method-title {
    padding: 15px; }
  .checkout-payment-method .payment-method-content {
    padding: 0 15px 20px; }
  .checkout-payment-method .checkout-billing-address .action-cancel {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .actions-toolbar .primary {
    float: right;
    margin: 0; }
  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%; }
  .checkout-payment-method .payment-method-content .fieldset > .field {
    margin: 0 0 20px; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
      margin-left: 25.8%; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
      margin-left: 0; }
  .checkout-billing-address .action-update {
    float: right; }
  .checkout-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0; } }

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
  content: ""; }

.checkout-payment-method .payment-option._collapsible .payment-option-title {
  cursor: pointer; }

.checkout-payment-method .payment-option._collapsible .payment-option-content {
  display: none; }

.checkout-payment-method .payment-option-title {
  border-top: 1px solid #ccc;
  padding: 20px 0; }
  .checkout-payment-method .payment-option-title .action-toggle {
    color: #1c5275;
    display: inline-block;
    text-decoration: none; }
    .checkout-payment-method .payment-option-title .action-toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: #3c3c3c;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .checkout-payment-method .payment-option-title .action-toggle:hover:after {
      color: #3c3c3c; }
    .checkout-payment-method .payment-option-title .action-toggle:active:after {
      color: #3c3c3c; }

.checkout-payment-method .payment-option-content {
  padding: 0 0 20px 22px; }

.checkout-payment-method .payment-option-inner {
  margin: 0 0 20px; }

.checkout-payment-method .credit-card-types {
  padding: 0; }
  .checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top; }
    .checkout-payment-method .credit-card-types .item._active {
      font-weight: 700; }
      .checkout-payment-method .credit-card-types .item._active img {
        filter: grayscale(0%); }
    .checkout-payment-method .credit-card-types .item._inactive {
      opacity: 0.4; }
    .checkout-payment-method .credit-card-types .item span {
      display: inline-block;
      padding-top: 6px;
      vertical-align: top; }
  .checkout-payment-method .credit-card-types img {
    filter: grayscale(100%);
    transition: all 0.6s ease; }

.checkout-payment-method .ccard .fields > .year {
  padding-left: 5px; }

.checkout-payment-method .ccard .fields .select {
  padding-left: 5px;
  padding-right: 5px; }

.checkout-payment-method .ccard .month .select {
  width: 140px; }

.checkout-payment-method .ccard .year .select {
  width: 80px; }

.checkout-payment-method .ccard .number .input-text {
  width: 225px; }

.checkout-payment-method .ccard > .field.cvv > .control {
  padding-right: 20px;
  width: auto; }

.checkout-payment-method .ccard .cvv .input-text {
  width: 55px; }

.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
  width: auto !important; }

@media only screen and (max-width: 767px) {
  .checkout-payment-method .payment-option {
    margin: -15px; }
    .checkout-payment-method .payment-option .payment-option-title {
      padding: 15px; }
    .checkout-payment-method .payment-option .payment-option-content {
      padding: 0 15px 20px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .payment-option-title {
    padding-left: 22px; }
  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0; } }

.checkout-agreements-block .checkout-agreements {
  margin-bottom: 20px; }

.checkout-agreements-block .action-show {
  vertical-align: baseline; }

.checkout-agreements-items {
  padding-bottom: 30px; }
  .checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px; }
  .checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0; }

.checkout-agreement-item-content {
  overflow: auto; }

.email-credentials {
  width: 100%; }
  .email-credentials th {
    text-align: left; }
  .email-credentials tr + tr th,
  .email-credentials tr + tr td {
    padding-bottom: 10px; }

.email-features {
  width: 100%; }
  .email-features td {
    width: 50%; }
    .email-features td table {
      width: 100%; }
      .email-features td table td {
        padding-right: 10px;
        width: 30px; }
        .email-features td table td h3 {
          margin-bottom: 0;
          margin-top: 5px; }
        .email-features td table td p {
          margin-bottom: 0; }
      .email-features td table td + td {
        padding-right: 25px;
        width: auto; }
  .email-features tr + tr > td {
    padding-top: 20px; }

@media only screen and (max-width: 479px) {
  .email-features > tbody > tr > td {
    clear: both;
    display: block;
    padding-top: 20px;
    width: auto !important; } }

.login-container .block-new-customer {
  width: auto;
  float: right; }
  .login-container .block-new-customer .actions-toolbar {
    margin-top: 25px; }

.login-container .block .block-title {
  font-size: 18px; }

.login-container .fieldset:after {
  margin: 10px 0 0;
  content: attr(data-hasrequired);
  display: block;
  letter-spacing: normal;
  word-spacing: normal;
  color: #e02b27;
  font-size: 1.2rem; }

.login-container .fieldset:after {
  margin-top: 35px; }

.block-addresses-list .items.addresses > .item {
  margin-bottom: 20px; }
  .block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0; }

.form-address-edit .region select {
  display: none; }

.form-edit-account .fieldset.password {
  display: none; }

.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
  line-height: 26px; }

.account .column.main h2 {
  margin-top: 0; }

.account .column.main .toolbar {
  text-align: center; }
  .account .column.main .toolbar .limiter-options {
    width: auto; }

.account .sidebar-additional {
  margin-top: 40px; }

.account .table-wrapper:last-child {
  margin-bottom: 0; }

.account .table-wrapper .action {
  margin-right: 15px; }
  .account .table-wrapper .action:last-child {
    margin-right: 0; }

.account .box-actions .action:not(:first-child) {
  margin-left: 0.5rem;
  border-left: 1px solid #c6c6c6;
  padding-left: 0.5rem; }

.account-nav .content {
  background: #f5f5f5;
  padding: 15px 0; }

.account-nav .item {
  margin: 3px 0 0; }
  .account-nav .item:first-child {
    margin-top: 0; }
  .account-nav .item a,
  .account-nav .item > strong {
    color: false;
    border-left: 3px solid transparent;
    display: block;
    padding: 5px 18px 5px 15px; }
  .account-nav .item a {
    text-decoration: none; }
    .account-nav .item a:hover {
      background: #e8e8e8; }
  .account-nav .item.current a,
  .account-nav .item.current strong {
    border-color: #2196f3;
    color: false;
    font-weight: 600; }
  .account-nav .item.current a {
    border-color: #2196f3; }

.column.main .block:last-child {
  margin-bottom: 0; }

.block .block-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #c6c6c6; }
  .block .block-title strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .column.main .block .block-title strong {
      font-size: 18px; }

.block p:last-child {
  margin: 0; }

.block .box-actions {
  margin-top: 5px; }

.field.password .control {
  display: flex;
  flex-direction: column; }
  .field.password .control .mage-error {
    order: 2; }
  .field.password .control .input-text {
    order: 0; }

.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1; }
  .password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .password-strength-meter-0 .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%; }
  .password-strength-meter-1 .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%; }
  .password-strength-meter-2 .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%; }
  .password-strength-meter-3 .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%; }
  .password-strength-meter-4 .password-strength-meter:before {
    background-color: #81b562;
    width: 100%; }

.control.captcha-image {
  margin-top: 10px; }
  .control.captcha-image .captcha-img {
    vertical-align: middle; }

@media only screen and (max-width: 639px) {
  .account .column.main,
  .account .sidebar-additional {
    margin: 0; } }

@media only screen and (max-width: 767px) {
  .login-container .fieldset:after {
    text-align: center; }
  .account .page.messages {
    margin-bottom: 40px; }
  .control.captcha-image .captcha-img {
    margin-bottom: 10px;
    display: block; }
  .customer-account-index .page-title-wrapper {
    position: relative; } }

@media only screen and (min-width: 768px) {
  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px; }
  .login-container .block.login .actions-toolbar > .secondary {
    float: left; }
  .login-container .fieldset > .field > .control {
    width: 55%; }
  .form-create-account .fieldset-fullname .fields .field {
    float: left;
    margin: 0 10px 10px 0; }
  .form-create-account .fieldset-fullname .field-name-prefix,
  .form-create-account .fieldset-fullname .field-name-suffix {
    width: 50px; }
  .form.password.reset,
  .form.send.confirmation,
  .form.password.forget,
  .form.create.account {
    min-width: 600px;
    width: 50%; }
  .account.page-layout-2columns-left .sidebar-main,
  .account.page-layout-2columns-left .sidebar-additional {
    width: 22.3%; }
  .account.page-layout-2columns-left .column.main {
    width: 77.7%; }
  .block-addresses-list .items.addresses > .item {
    margin-bottom: 20px; }
    .block-addresses-list .items.addresses > .item:nth-last-child(1), .block-addresses-list .items.addresses > .item:nth-last-child(2) {
      margin-bottom: 0; }
  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0; } }

.downloadable.samples {
  margin-bottom: 20px; }
  .downloadable.samples dd {
    margin: 0 0 10px; }

.table-downloadable-products .product-name {
  margin-right: 15px; }

.field.downloads .price-container {
  display: inline;
  white-space: nowrap; }
  .field.downloads .price-container:before {
    content: ' + ';
    display: inline; }

.field.downloads .price-excluding-tax {
  display: inline; }
  .field.downloads .price-excluding-tax:before {
    content: " (" attr(data-label) ": "; }
  .field.downloads .price-excluding-tax:after {
    content: ')'; }

.field.downloads .price-including-tax {
  font-size: 1.4rem;
  display: inline;
  font-weight: 600; }

.field.downloads .sample.link {
  float: right; }

.page-product-downloadable .product-add-form {
  clear: both;
  margin-bottom: 25px; }

.page-product-downloadable .product-options-bottom .field.qty {
  display: none !important; }

@media only screen and (min-width: 768px) {
  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%; }
  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%; }
    .page-product-downloadable .product-options-bottom .field.qty + .actions {
      padding-top: 0; } }

.cart-summary .block.giftcard .action.check {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem;
  margin-top: 20px; }

.page-product-giftcard .giftcard-amount {
  margin-bottom: 0;
  text-align: left; }
  .page-product-giftcard .giftcard-amount .field:last-child {
    padding-bottom: 20px; }

.page-product-giftcard .product-options-wrapper .field:first-of-type {
  margin-top: 20px; }

.giftcard-account .please-wait {
  display: none; }

.giftcard-account .form-giftcard-redeem .giftcard.info {
  margin-bottom: 30px; }

.opc-wrapper .form-giftcard-account {
  max-width: 500px; }
  .opc-wrapper .form-giftcard-account .field {
    margin: 0 0 20px; }

.opc-wrapper .giftcard-account-info {
  margin: 0 0 20px; }
  .opc-wrapper .giftcard-account-info .giftcard-account-number {
    margin: 0 0 10px; }

.gift-message .field {
  margin-bottom: 20px; }
  .gift-message .field .label {
    color: #666;
    font-weight: 400; }

.gift-options .actions-toolbar .action-cancel {
  display: none; }

.gift-options-title {
  margin: 0 0 20px; }

.gift-options-content .fieldset {
  margin: 0 0 20px; }

.gift-summary .actions-toolbar > .secondary {
  float: none; }
  .gift-summary .actions-toolbar > .secondary .action {
    margin: 10px 20px 0 0; }

.action-gift {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  text-decoration: none; }
  .action-gift:hover, .action-gift:active, .action-gift:focus {
    text-decoration: none; }
  .action-gift:last-child {
    margin-right: 0; }
  .action-gift:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .action-gift:hover:after {
    color: inherit; }
  .action-gift:active:after {
    color: inherit; }
  .action-gift.active:after, .action-gift._active:after {
    content: ""; }

.cart.table-wrapper .gift-content {
  box-sizing: border-box;
  clear: left;
  display: none;
  float: left;
  margin: 10px 0 20px;
  text-align: left;
  width: 100%; }
  .cart.table-wrapper .gift-content._active {
    display: block; }

.gift-item-block {
  margin: 0; }
  .gift-item-block._active .title:after {
    content: ""; }
  .gift-item-block .title {
    font-weight: 700;
    border-radius: 3px;
    background-image: none;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    transition: color background-color border-color 250ms ease;
    color: #1a1a1a;
    cursor: pointer;
    display: inline-block;
    font-family: "Myriad Pro Regular", sans-serif;
    font-weight: 700;
    margin: 20px 0 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none; }
    .gift-item-block .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .gift-item-block .title:hover:after {
      color: inherit; }
    .gift-item-block .title:active:after {
      color: inherit; }
    .gift-item-block .title:focus, .gift-item-block .title:active {
      background: #e2e2e2;
      border: 1px solid #cdcdcd;
      border-bottom-width: 1px;
      color: #1a1a1a; }
    .gift-item-block .title:hover {
      background: #e2e2e2;
      border: 1px solid #cdcdcd;
      border-bottom-width: 1px;
      color: #555; }
    .gift-item-block .title.disabled, .gift-item-block .title[disabled],
    fieldset[disabled] .gift-item-block .title {
      opacity: 0.5;
      cursor: default;
      pointer-events: none; }
    .gift-item-block .title:active {
      box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }
  .gift-item-block .content {
    border-bottom: 1px solid #c1c1c1;
    padding: 10px 10px 20px;
    position: relative; }

.order-details-items .order-gift-message .item-options dt,
.order-details-items .block-order-details-gift-message .item-options dt {
  font-weight: 400;
  margin: 10px 0; }

.order-details-items .order-gift-message dt:after,
.order-details-items .block-order-details-gift-message dt:after {
  content: ''; }

.order-details-items .order-gift-message .item-message,
.order-details-items .block-order-details-gift-message .item-message {
  clear: left; }

.order-details-items .order-items .action.show {
  display: inline-block;
  text-decoration: none;
  padding-right: 20px;
  position: relative; }
  .order-details-items .order-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .order-details-items .order-items .action.show:after {
    position: absolute;
    right: 0;
    top: -4px; }
  .order-details-items .order-items .action.show.expanded:after {
    content: ""; }

.block-order-details-gift-message {
  border-top: 1px solid #d1d1d1;
  padding-top: 10px; }

@media only screen and (max-width: 767px) {
  .gift-options .actions-toolbar .action-update {
    font-size: 2rem;
    padding: 15px;
    width: 100%; }
  .gift-item-block {
    border-top: 1px solid #c1c1c1;
    border-bottom: 0; }
  .cart.table-wrapper .gift-content {
    margin-right: -10px; } }

@media only screen and (max-width: 639px) {
  .cart.table-wrapper .gift-content {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 20px; } }

@media only screen and (min-width: 768px) {
  .gift-message .field {
    margin-bottom: 20px; }
  .gift-options {
    position: relative;
    z-index: 1; }
    .gift-options .actions-toolbar {
      clear: both;
      float: right;
      position: static; }
      .gift-options .actions-toolbar .secondary {
        float: right; }
        .gift-options .actions-toolbar .secondary .action {
          float: right;
          margin-left: 20px; }
        .gift-options .actions-toolbar .secondary .action-cancel {
          display: block;
          float: left;
          margin-top: 6px; }
      .gift-options .actions-toolbar:nth-child(3):before {
        border-left: 1px solid #c1c1c1;
        bottom: 5rem;
        content: '';
        display: block;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0; }
  .gift-options-title {
    font-weight: 300;
    font-size: 1.8rem; }
  .cart.table-wrapper .action-gift {
    float: left; }
  .order-options .gift-wrapping,
  .table-order-review .gift-wrapping {
    max-width: 50%; } }

.form-giftregistry-share .actions-toolbar:not(:last-child),
.form-giftregistry-edit .actions-toolbar:not(:last-child) {
  margin-bottom: 40px; }

.form-giftregistry-share .fieldset .nested .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .nested .field:not(.choice) .control {
  width: 100%; }

.table-giftregistry-items .product-image {
  float: left;
  margin-right: 10px; }

.cart-summary .form.giftregistry .fieldset {
  margin: 25px 0 5px; }
  .cart-summary .form.giftregistry .fieldset .field {
    margin: 0 0 10px; }
    .cart-summary .form.giftregistry .fieldset .field .control {
      float: none;
      width: auto; }
  .cart-summary .form.giftregistry .fieldset .action.primary {
    margin-bottom: 0; }

.form-giftregistry-search {
  margin-bottom: 60px; }
  .form-giftregistry-search .fields-specific-options .field:nth-last-of-type(1) {
    margin-bottom: 0; }

.block-giftregistry-results .toolbar-giftregistry-results {
  text-align: center; }

.block-giftregistry .actions-toolbar {
  margin: 17px 0 0; }

.block-giftregistry-shared .item-options .item-value {
  margin: 0 0 25px; }

.block-giftregistry-shared-items .item {
  border-bottom: 1px solid #d1d1d1; }

.block-giftregistry-shared-items .col {
  padding: 20px 8px 10px; }
  .block-giftregistry-shared-items .col .price-box {
    display: inline-block; }
  .block-giftregistry-shared-items .col .price-wrapper {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    white-space: nowrap; }

.block-giftregistry-shared-items .product .item-options {
  margin-bottom: 0; }

.block-giftregistry-shared-items .product-item-photo {
  display: block;
  max-width: 65px;
  padding: 10px 0; }

.block-giftregistry-shared-items .product-item-name {
  display: block;
  margin: 0 0 5px; }

@media only screen and (max-width: 639px) {
  .table-giftregistry-items .field.choice,
  .table-giftregistry-items .field.qty {
    display: inline-block; }
  .form-giftregistry-search .fieldset {
    margin-bottom: 15px; } }

@media only screen and (min-width: 768px) {
  .form-giftregistry-search .fieldset {
    margin-bottom: 29px; }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field {
      margin: 0 0 20px;
      box-sizing: border-box; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field > .label {
        margin: 0; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        content: '';
        display: table; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        clear: both; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field.choice:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field.no-label:before {
        padding: 6px 15px 0 0;
        width: 25.8%;
        box-sizing: border-box;
        content: ' ';
        float: left;
        height: 1px; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field .description {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .control {
        width: 74.2%;
        float: left; }
  .block-giftregistry-shared-items .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding: 0 20px 0 0;
    vertical-align: top;
    width: 1%; }
  .block-giftregistry-shared-items .product-item-details {
    display: table-cell;
    vertical-align: top;
    width: 99%;
    word-break: normal; }
  .block-giftregistry-shared-items .col.product {
    width: 48%; }
  .block-giftregistry-shared-items .col:not(.product) {
    text-align: center; }
  .block-giftregistry-shared-items .col.price {
    padding-top: 17px; }
  .block-giftregistry-shared-items .input-text.qty {
    margin-top: -4px; } }

.gift-wrapping .label {
  margin: 0 0 5px;
  display: inline-block; }

.gift-wrapping .field {
  margin-bottom: 10px; }

.gift-wrapping-info {
  font-weight: 300;
  font-size: 18px;
  margin: 20px 0; }

.gift-wrapping-list {
  list-style: none;
  margin: 20px 0 10px;
  padding: 0; }
  .gift-wrapping-list .no-image {
    background: #f2f2f2;
    display: inline-block;
    text-decoration: none; }
    .gift-wrapping-list .no-image > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .gift-wrapping-list .no-image:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 38px;
      line-height: 4rem;
      color: #494949;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .gift-wrapping-list .no-image:after {
      display: block; }

.gift-wrapping-preview,
.gift-wrapping-item > span {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.gift-wrapping-item {
  float: left;
  margin: 0 10px 10px 0; }
  .gift-wrapping-item:last-child {
    margin-right: 0; }
  .gift-wrapping-item._active span:before {
    border: 3px solid #333;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2; }
  .gift-wrapping-item > span {
    height: 4rem;
    width: 4rem; }

.gift-wrapping-title {
  margin: 10px 0; }
  .gift-wrapping-title .action-remove {
    margin-left: 5px; }

.gift-options-content .fieldset > .field {
  margin: 0 0 5px; }
  .gift-options-content .fieldset > .field:before {
    display: none; }

.gift-wrapping-summary .gift-wrapping-title {
  border: 0;
  display: inline-block;
  padding: 0; }

.opc-wrapper .data.table .gift-wrapping,
.table-order-items .gift-wrapping,
.table-order-review .gift-wrapping {
  margin: 20px 0; }
  .opc-wrapper .data.table .gift-wrapping .title,
  .table-order-items .gift-wrapping .title,
  .table-order-review .gift-wrapping .title {
    float: none; }
  .opc-wrapper .data.table .gift-wrapping .content,
  .table-order-items .gift-wrapping .content,
  .table-order-review .gift-wrapping .content {
    margin: 0; }
    .opc-wrapper .data.table .gift-wrapping .content > img,
    .table-order-items .gift-wrapping .content > img,
    .table-order-review .gift-wrapping .content > img {
      float: left;
      margin: 0 10px 0 0;
      max-height: 50px;
      max-width: 50px; }

.gift-summary .price-box,
.gift-options .price-box {
  margin-left: 22px; }

.gift-summary .regular-price:before,
.gift-options .regular-price:before {
  content: attr(data-label) ": "; }

.gift-summary .regular-price .price,
.gift-options .regular-price .price {
  font-weight: 700; }

.order-options .item {
  padding-bottom: 20px; }

.order-options .product {
  margin-bottom: 10px; }

.order-options .product-image-container {
  float: left;
  padding-right: 10px; }

.order-options .gift-wrapping {
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .gift-wrapping {
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 20px;
    padding-bottom: 10px; }
  .gift-wrapping-list {
    width: 100%; }
  .gift-wrapping-preview img {
    width: 100%; }
  .item-actions .actions-toolbar .gift-options .gift-wrapping,
  .item-actions .actions-toolbar .gift-options .gift-message {
    display: block; } }

@media only screen and (min-width: 768px) {
  .gift-options-cart-item .gift-wrapping,
  .cart-gift-item .gift-wrapping {
    box-sizing: border-box;
    float: left;
    padding-right: 20px;
    width: 50%; }
    .gift-options-cart-item .gift-wrapping + .gift-message,
    .cart-gift-item .gift-wrapping + .gift-message {
      border-left: 1px solid #c1c1c1;
      box-sizing: border-box;
      float: left;
      padding-left: 4.5rem;
      width: 50%; } }

.table.grouped > thead > tr > th,
.table.grouped > thead > tr > td,
.table.grouped > tbody > tr > th,
.table.grouped > tbody > tr > td,
.table.grouped > tfoot > tr > th,
.table.grouped > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.table.grouped > caption + thead > tr:first-child > th,
.table.grouped > caption + thead > tr:first-child > td,
.table.grouped > colgroup + thead > tr:first-child > th,
.table.grouped > colgroup + thead > tr:first-child > td,
.table.grouped > thead:first-child > tr:first-child > th,
.table.grouped > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table.grouped > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.table.grouped > thead > tr > th {
  border-bottom: 0; }

.table.grouped .product-item-name {
  font-weight: 400; }

.table.grouped .price-box .price {
  font-size: 14px; }

.table.grouped .stock.unavailable {
  font-weight: normal;
  margin: 0;
  text-transform: none;
  white-space: nowrap; }

.table.grouped .row-tier-price td {
  border-top: 0; }

.table.grouped .row-tier-price .prices-tier {
  margin: 0 -10px 10px; }

.form-add-invitations .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .form-add-invitations .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .form-add-invitations .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .form-add-invitations .action.add:hover:before {
    color: inherit; }
  .form-add-invitations .action.add:active:before {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 20px; } }

@media only screen and (min-width: 768px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 29px; }
  .table-invitations .col {
    width: 50%; } }

.filter.block {
  margin-bottom: 0; }

.filter:not(.active) .filter-title strong {
  width: calc(50% - 16px); }

.filter-title strong {
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  font-weight: 400;
  left: 0;
  line-height: 16px;
  padding: 7px 10px;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center; }
  .filter-title strong:before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    content: "";
    padding-right: 0.5rem;
    font-size: 1em !important;
    transform: scale(1.5); }
  .filter-title strong[data-count]:after {
    color: #ffffff;
    background: #2196f3;
    border-radius: 2px;
    content: attr(data-count);
    display: inline-block;
    font-size: 0.8em;
    line-height: 1;
    margin: 0 5px;
    min-width: 1em;
    padding: 2px; }
  .filter-title strong.disabled {
    opacity: 0.5; }

.filter .block-subtitle {
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
  padding: 10px 10px 20px; }

.filter-subtitle {
  display: none; }

.filter-current {
  margin: 0; }
  .filter-current .items {
    padding: 5px 10px; }
  .filter-current .item {
    padding-left: 17px;
    position: relative;
    z-index: 1; }
  .filter .filter-current-subtitle {
    border: none;
    display: block;
    padding-bottom: 10px; }
  .filter-current .action.remove {
    left: -5px;
    position: absolute;
    top: -1px; }
    .filter-current .action.remove:before {
      text-indent: -5px;
      font-size: 30px; }

.filter-actions {
  margin-bottom: 25px;
  padding: 0 10px; }

.filter-label {
  font-weight: 700; }
  .filter-label:after {
    content: ': '; }

.filter-value {
  color: #858585; }

.filter-options {
  display: none;
  margin: 0; }
  .filter-options-item {
    border: 1px solid #d1d1d1;
    margin-bottom: 10px; }
  .filter-options-title {
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    overflow: hidden;
    padding: 10px 40px 0 10px;
    position: relative;
    text-transform: uppercase;
    word-break: break-all;
    z-index: 1;
    display: block;
    text-decoration: none; }
    .filter-options-title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 36px;
      line-height: inherit;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .filter-options-title:after {
      position: absolute;
      right: 2px;
      top: 0; }
    .filter-options-title:hover {
      color: black; }
    .active > .filter-options-title:after {
      content: ""; }
  .filter-options-content {
    margin: 0;
    padding: 10px; }
    .filter-options-content .item {
      line-height: 1.5em;
      margin: 10px 0; }
    .filter-options-content a {
      color: #494949;
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 7px; }
      .filter-options-content a:hover {
        text-decoration: none;
        color: #636363; }
    .filter-options-content .count {
      color: #858585;
      padding-right: 5px; }
      .filter-options-content .count:before {
        content: '('; }
      .filter-options-content .count:after {
        content: ')'; }

@media only screen and (max-width: 767px) {
  body.filter-active .page-header {
    display: none; }
  body.filter-active .page-wrapper {
    height: 0;
    margin-top: -999999em;
    visibility: hidden; }
  body.filter-active .columns {
    z-index: 999; }
  .filter.active {
    position: relative;
    visibility: visible;
    z-index: 99; }
    .filter.active .filter-options-item:last-child {
      margin-bottom: 40px; }
    .filter.active .filter-title {
      border-bottom: 0 solid #d1d1d1;
      height: 48px;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 2; }
      .filter.active .filter-title strong {
        box-shadow: none;
        background: none;
        border: 0;
        color: transparent;
        left: auto;
        right: 3px;
        top: 10px;
        display: inline-block;
        text-decoration: none; }
        .filter.active .filter-title strong:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 16px;
          line-height: inherit;
          color: #868686;
          content: "";
          font-family: "Material Icons";
          vertical-align: middle;
          display: inline-block;
          font-weight: normal;
          speak: none;
          text-align: center; }
        .filter.active .filter-title strong:hover:after {
          color: #868686; }
        .filter.active .filter-title strong:active:after {
          color: #868686; }
        .filter.active .filter-title strong:after:active {
          color: #868686; }
    .filter.active .filter-subtitle {
      display: block;
      height: 50px;
      left: 0;
      line-height: 32px;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1; }
    .filter.active .filter-options {
      background: #ffffff;
      bottom: 0;
      display: block;
      left: 0;
      overflow: scroll;
      position: fixed;
      right: 0;
      top: 50px;
      z-index: 10;
      padding: 1rem; }
  .filter .filter-subtitle {
    font-size: 20px;
    font-weight: 300; }
  .filter-actions {
    margin: -45px 25px; }
  .filter-options-content {
    padding: 5px 10px; }
  .filter .filter-current {
    border: solid #d1d1d1;
    border-width: 1px 0;
    margin: -5px 0; }
    .filter .filter-current-subtitle {
      position: relative;
      text-transform: uppercase;
      z-index: 1;
      display: block;
      text-decoration: none; }
      .filter .filter-current-subtitle:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 13px;
        line-height: inherit;
        color: #a2a2a2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .filter .filter-current-subtitle:before {
        position: absolute;
        right: 10px;
        top: 10px; }
      .filter .filter-current-subtitle:after {
        color: #868686;
        content: " (" attr(data-count) ")";
        font-size: .9em; }
    .filter .filter-current.active {
      padding-bottom: 30px; }
      .filter .filter-current.active .block-subtitle:before {
        content: ""; }
      .filter .filter-current.active .items {
        display: block; }
      .filter .filter-current.active + .block-actions {
        display: block; }
    .filter .filter-current + .block-actions {
      display: none; }
  .filter-no-options .filter-title:before {
    background: rgba(255, 255, 255, 0.5);
    content: '';
    display: block;
    height: 40px;
    left: -15px;
    margin-top: -60px;
    position: relative;
    width: 100px;
    z-index: 99; }
  .filter-no-options .filter-content {
    margin-bottom: 20px; }
  .page-with-filter .columns .sidebar-main {
    order: 0; } }

/**
*
* Desktop
*
*/
@media only screen and (min-width: 768px) {
  .filter.block {
    margin-bottom: 40px; }
  .filter-title {
    display: none; }
  .filter-content .item {
    margin: 10px 0; }
  .filter-actions {
    margin-bottom: 30px; }
  .filter.active .filter-options, .filter-options {
    background: transparent;
    clear: both;
    display: block;
    overflow: initial;
    position: static; }
  .filter-subtitle {
    display: none;
    position: static; }
  .page-layout-1column .toolbar-products {
    position: absolute;
    top: 0;
    width: 100%; }
  .page-layout-1column .products ~ .toolbar-products {
    position: static; }
  .page-layout-1column.page-with-filter .column.main {
    padding-top: 45px;
    position: relative;
    z-index: 1; }
  .page-layout-1column .filter.block {
    border-top: 1px solid #d1d1d1; }
  .page-layout-1column .filter-content {
    margin-top: 10px; }
  .page-layout-1column .filter-subtitle {
    display: none; }
  .page-layout-1column .filter-options-item {
    border: 0;
    display: inline-block;
    margin-right: 25px;
    position: relative; }
    .page-layout-1column .filter-options-item.active {
      z-index: 2; }
      .page-layout-1column .filter-options-item.active .filter-options-content {
        visibility: visible; }
      .page-layout-1column .filter-options-item.active:hover {
        z-index: 3; }
      .page-layout-1column .filter-options-item.active:after, .page-layout-1column .filter-options-item.active:before {
        border: 8px solid transparent;
        height: 0;
        width: 0;
        border-bottom-color: #1a1a1a;
        bottom: -1px;
        content: '';
        display: block;
        left: 5px;
        position: absolute;
        z-index: 3; }
      .page-layout-1column .filter-options-item.active:after {
        border-bottom-color: #ffffff;
        margin-top: 2px;
        z-index: 4; }
  .page-layout-1column .filter-options-title {
    padding: 0 20px 0 0; }
    .page-layout-1column .filter-options-title:after {
      right: 2px;
      top: 3px;
      z-index: 3; }
  .page-layout-1column .filter-options-content {
    background: #ffffff;
    box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75), 1;
    border: 1px solid #d1d1d1;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 180px;
    z-index: 2; }
    .page-layout-1column .filter-options-content .item {
      margin: 0;
      padding: 5px; }
      .page-layout-1column .filter-options-content .item a {
        margin-left: 0; }
      .page-layout-1column .filter-options-content .item:hover {
        background-color: #e8e8e8; }
  .page-layout-1column .filter-current {
    display: inline;
    line-height: 35px; }
    .page-layout-1column .filter-current-subtitle {
      color: #868686;
      display: inline;
      font-size: 14px;
      font-weight: normal;
      padding: 0; }
      .page-layout-1column .filter-current-subtitle:after {
        content: ':'; }
    .page-layout-1column .filter-current .item,
    .page-layout-1column .filter-current .items {
      display: inline; }
    .page-layout-1column .filter-current .item {
      margin-right: 25px;
      white-space: nowrap; }
    .page-layout-1column .filter-current .action.remove {
      line-height: normal; }
  .page-layout-1column .filter-actions {
    display: inline;
    white-space: nowrap; }
    .page-layout-1column .filter-actions ~ .filter-options {
      margin-top: 25px; } }

.block.filter {
  margin-bottom: 40px; }
  @media only screen and (max-width: 639px) {
    .block.filter {
      display: flex;
      width: 100%; }
      .block.filter .block-title,
      .block.filter .block-content {
        width: 100%; } }
  .block.filter .filter-title {
    margin-bottom: 20px; }
    .block.filter .filter-title strong {
      font-size: 18px; }

.block-subtitle {
  display: inline-block;
  margin-bottom: 10px; }

.filter-current .item {
  margin-bottom: 10px; }

.filter-current .filter-label {
  display: block;
  font-weight: 700; }
  .filter-current .filter-label:after {
    content: ':'; }

.filter-current + .filter-actions {
  margin-bottom: 30px; }

.filter-options {
  margin: 0; }
  .filter-options .filter-options-title {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin: 0 0 10px;
    word-break: break-all; }
  .filter-options .filter-options-content {
    margin: 0 0 25px; }
    .filter-options .filter-options-content .item {
      margin-bottom: 3px; }
  .filter-options .count {
    color: #868686; }
    .filter-options .count:before {
      content: '('; }
    .filter-options .count:after {
      content: ')'; }

.filtered .items {
  margin: 15px 0; }

.filtered .item {
  margin-bottom: 6px;
  padding-left: 22px;
  position: relative; }
  .filtered .item .label {
    font-weight: 700; }
  .filtered .item .action.remove {
    left: -6px;
    position: absolute;
    top: 0; }

.filtered + .actions {
  margin-bottom: 35px; }

@media only screen and (max-width: 767px) {
  .page-with-filter .columns .sidebar-main {
    order: 0; } }

.map-popup-wrapper.popup .action.close {
  top: 10px; }

.map-popup {
  background: #ffffff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  left: 0;
  margin-top: 10px;
  position: absolute;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0;
  box-sizing: border-box; }
  .map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .map-popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .map-popup .popup-content {
    margin: 10px 0 0; }
  .map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .map-popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .map-popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .map-popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:active:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:focus, .map-popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close.disabled, .map-popup .popup-actions .action.close[disabled],
    fieldset[disabled] .map-popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .map-popup.active {
    opacity: 1; }
  .map-popup .map-info-price {
    margin-bottom: 15px; }
    .map-popup .map-info-price .price-box {
      margin: 0 0 20px; }
      .map-popup .map-info-price .price-box .label:after {
        content: ': '; }
  .map-popup .regular-price .price-label {
    display: none; }

.map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
  margin-right: 10px; }

.map-old-price {
  text-decoration: none; }
  .map-old-price .price-wrapper {
    text-decoration: line-through; }

.map-form-addtocart {
  display: inline-block;
  text-align: center; }
  .map-form-addtocart img {
    display: block;
    margin: 0 auto; }

button.map-show-info, .cart.table-wrapper .actions-toolbar > .map-show-info.action, .map-show-info.action-gift {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    background: none;
    border: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    background: none;
    border: none; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  button.map-show-info:visited, .cart.table-wrapper .actions-toolbar > .map-show-info.action:visited, .map-show-info.action-gift:visited {
    color: #1c5275;
    text-decoration: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #006bb4;
    text-decoration: underline; }
  button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    color: #2196f3;
    text-decoration: underline; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #006bb4; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active, button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus {
    background: none;
    border: 0; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .map-popup {
    max-width: 100%; }
  .map-old-price,
  .map-show-info {
    display: inline-block; }
    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
      margin-bottom: 10px; } }

.wishlist.split.button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .wishlist.split.button:before, .wishlist.split.button:after {
    content: '';
    display: table; }
  .wishlist.split.button:after {
    clear: both; }
  .wishlist.split.button .action.split {
    float: left;
    margin: 0; }
  .wishlist.split.button .action.toggle {
    float: right;
    margin: 0; }
  .wishlist.split.button button.action.split, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action.split, .wishlist.split.button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .wishlist.split.button button + .action.toggle, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action + .action.toggle, .wishlist.split.button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .wishlist.split.button .action.toggle {
    display: inline-block;
    text-decoration: none; }
    .wishlist.split.button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.split.button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: #5e5e5e;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .wishlist.split.button .action.toggle:hover:after {
      color: #6b6b6b; }
    .wishlist.split.button .action.toggle:active:after {
      color: inherit; }
    .wishlist.split.button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .wishlist.split.button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .wishlist.split.button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #5e5e5e;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .wishlist.split.button .action.toggle.active:hover:after {
        color: #6b6b6b; }
      .wishlist.split.button .action.toggle.active:active:after {
        color: inherit; }
  .wishlist.split.button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .wishlist.split.button .items li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .wishlist.split.button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .wishlist.split.button .items:before, .wishlist.split.button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .wishlist.split.button .items:before {
      border: 6px solid;
      border-color: transparent transparent #ffffff transparent;
      z-index: 99; }
    .wishlist.split.button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .wishlist.split.button .items:before {
      left: 10px;
      top: -12px; }
    .wishlist.split.button .items:after {
      left: 9px;
      top: -14px; }
  .wishlist.split.button.active {
    overflow: visible; }
    .wishlist.split.button.active .items {
      display: block; }
  .wishlist.split.button .items {
    text-align: left; }
    .wishlist.split.button .items .item:last-child:hover {
      background: #e8e8e8; }
  .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before {
    content: ""; }
  .table-comparison .wishlist.split.button > .action:active, .table-comparison .wishlist.split.button > .action:focus, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:hover {
    color: #6b6b6b; }
  .table-comparison .wishlist.split.button > .action.toggle:before,
  .product-items .wishlist.split.button > .action.toggle:before {
    border-left: 1px solid #ababab;
    content: '';
    float: left;
    height: 15px;
    margin-top: 3px; }
  .product-info-main .wishlist.split.button .action.split, .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.split,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.split,
  .product-add-form .wishlist.split.button .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem; }
  .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.toggle {
    padding: 0; }
  .cart.table-wrapper .wishlist.split.button {
    margin-bottom: 10px; }
    .cart.table-wrapper .wishlist.split.button > .action.toggle {
      padding: 4px 5px; }

.wishlist.window.popup {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0; }
  .wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .wishlist.window.popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .wishlist.window.popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.window.popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .wishlist.window.popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:active:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:focus, .wishlist.window.popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close.disabled, .wishlist.window.popup .popup-actions .action.close[disabled],
    fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .wishlist.window.popup.active {
    opacity: 1; }
  .wishlist.window.popup.active {
    display: block; }

.wishlist.overlay {
  transition: opacity 0.15s linear;
  background: #1a1a1a;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .wishlist.overlay.active {
    opacity: 0.5;
    filter: alpha(opacity=50); }
  .wishlist.overlay.active {
    display: block; }

.block-wishlist-management {
  position: relative; }
  .block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600; }
  .block-wishlist-management .wishlist-title {
    margin-bottom: 10px; }
    .block-wishlist-management .wishlist-title strong {
      font-weight: 300;
      margin-right: 10px; }
  .block-wishlist-management .wishlist-notice {
    color: #868686;
    display: inline-block;
    margin-right: 20px; }
  .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block; }
  .block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px; }
  .block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px; }

.block-wishlist-search-form .form-wishlist-search {
  margin-bottom: 60px;
  max-width: 500px; }

.block-wishlist-search-results .toolbar-wishlist-results {
  text-align: center; }

.block-wishlist-info-items .block-title {
  margin-bottom: 20px;
  font-size: 2.2rem; }
  .block-wishlist-info-items .block-title > strong {
    font-weight: 300; }

.block-wishlist-info-items .product-item {
  width: 100%; }

.block-wishlist-info-items .product-item-photo {
  margin-left: 20px; }

.block-wishlist-info-items .product-item-info {
  width: auto; }
  .block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible; }

.block-wishlist-info-items .price-wrapper {
  display: block;
  margin: 0 0 10px; }

.block-wishlist-info-items .split {
  clear: both;
  padding: 10px 0; }

.block-wishlist-info-items .action.towishlist {
  padding: 10px 0; }

.block-wishlist-info-items .input-text.qty {
  margin-right: 10px; }

@media only screen and (max-width: 767px) {
  .block-wishlist-management {
    clear: both;
    margin-top: -21px; }
    .block-wishlist-management .wishlist-select {
      margin: 0 -15px 20px; }
      .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #d1d1d1;
        background: #f5f5f5;
        display: none;
        padding: 15px 0; }
        .block-wishlist-management .wishlist-select-items.active {
          display: block; }
        .block-wishlist-management .wishlist-select-items .item {
          margin: 3px 0 0; }
          .block-wishlist-management .wishlist-select-items .item:first-child {
            margin-top: 0; }
          .block-wishlist-management .wishlist-select-items .item.current {
            display: none; }
          .block-wishlist-management .wishlist-select-items .item a {
            display: block;
            padding: 5px 18px;
            text-decoration: none; }
            .block-wishlist-management .wishlist-select-items .item a:hover {
              background: #e8e8e8; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 2.6rem; }
    .block-wishlist-management .wishlist-info {
      margin-bottom: 15px; }
    .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
      margin-bottom: 15px; }
  .products-grid.wishlist .product-item-checkbox {
    left: 20px;
    position: absolute;
    top: 20px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
    margin-left: 25px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-name, .page-multiple-wishlist .products-grid.wishlist .product-item-description, .page-multiple-wishlist
  .products-grid.wishlist .product-item .price-box, .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
    margin-left: 115px; }
  .products-grid.wishlist .wishlist-dropdown {
    display: none; } }

@media only screen and (min-width: 768px) {
  .wishlist.window.popup {
    bottom: auto;
    top: 20%;
    left: 50%;
    margin-left: -212px;
    width: 380px;
    right: auto; }
    .wishlist.window.popup .field {
      margin: 0 0 20px; }
      .wishlist.window.popup .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .wishlist.window.popup .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .wishlist.window.popup .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .wishlist.window.popup .field.choice:before, .wishlist.window.popup .field.no-label:before {
        display: none; }
  .block-wishlist-management {
    margin-bottom: 20px; }
    .block-wishlist-management .wishlist-select {
      border-bottom: 1px solid #999;
      display: table;
      margin-bottom: 15px;
      width: 100%; }
      .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        margin-right: 10px;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%; }
      .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top; }
        .block-wishlist-management .wishlist-select-items .item {
          display: inline-block;
          margin-right: 10px;
          padding: 5px 10px 10px; }
          .block-wishlist-management .wishlist-select-items .item:last-child {
            margin-right: 0; }
        .block-wishlist-management .wishlist-select-items .current {
          border-bottom: 3px solid #2196f3;
          font-weight: 600; }
      .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none; }
    .block-wishlist-management .wishlist-add.item {
      position: absolute;
      right: 0;
      top: 0; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 4rem; }
    .block-wishlist-management .wishlist-info {
      float: left; }
    .block-wishlist-management .wishlist-toolbar {
      float: right; }
  .block-wishlist-info-items .product-item-photo {
    margin-left: 0; }
  .products-grid.wishlist .product-item-checkbox {
    float: left; }
    .products-grid.wishlist .product-item-checkbox + .product-item-name {
      margin-left: 25px; } }

.multicheckout .title {
  margin-bottom: 30px; }
  .multicheckout .title strong {
    font-weight: 400; }

.multicheckout .table-wrapper {
  margin-bottom: 0; }
  .multicheckout .table-wrapper .action.delete {
    display: inline-block; }
  .multicheckout .table-wrapper .col .qty {
    display: inline-block; }
  .multicheckout .table-wrapper .col.item .action.edit {
    font-weight: 400;
    margin-left: 10px; }

.multicheckout:not(.address) .table-wrapper .product-item-name {
  margin: 0; }

.multicheckout > .actions-toolbar {
  margin-top: 40px; }

.multicheckout .actions-toolbar > .secondary {
  display: block; }
  .multicheckout .actions-toolbar > .secondary .action {
    margin-bottom: 25px; }
    .multicheckout .actions-toolbar > .secondary .action.back {
      display: block;
      margin-left: 0; }

.multicheckout .actions-toolbar > .primary {
  margin-right: 10px; }

.multicheckout .item-options {
  margin: 10px 0 0; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field:before {
  display: none; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field .control {
  display: inline-block; }

.multicheckout .block-title,
.multicheckout .block-content .title {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px; }
  .multicheckout .block-title strong,
  .multicheckout .block-content .title strong {
    font-weight: 400; }
    .multicheckout .block-title strong span,
    .multicheckout .block-content .title strong span {
      color: #afafaf; }

.multicheckout .block-content .title {
  border-bottom: none;
  padding-bottom: 0; }

.multicheckout.order-review .block-title > strong {
  font-size: 2.4rem; }

.multicheckout.order-review .block-shipping .block-content:not(:last-child) {
  margin-bottom: 40px; }

.multicheckout .box-title span {
  margin-right: 10px; }

.multicheckout .box-title > .action {
  margin: 0; }

.multicheckout .box-shipping-method .price {
  font-weight: 700; }

.multicheckout .box-billing-method .fieldset {
  margin: 0; }
  .multicheckout .box-billing-method .fieldset .legend.box-title {
    margin: 0 0 5px; }

.multicheckout .checkout-review .grand.totals {
  font-size: 2.1rem;
  margin-bottom: 40px; }
  .multicheckout .checkout-review .grand.totals .mark {
    font-weight: 400; }

[class^='multishipping-'] .logo {
  margin-left: 0; }

.multishipping-checkout-success .nav-sections {
  display: block; }

@media only screen and (max-width: 639px) {
  .multicheckout .data.table .address:before {
    margin-bottom: 5px; }
  .multicheckout .product-item-name,
  .multicheckout .price-including-tax,
  .multicheckout .price-excluding-tax {
    display: inline-block; }
  .multicheckout .block-content .box:not(:last-child) {
    margin-bottom: 40px; }
  .multicheckout .block-content .box:last-child {
    margin-bottom: 0; }
  .multicheckout.order-review .box-items .data.table thead {
    display: block; }
    .multicheckout.order-review .box-items .data.table thead tr {
      display: block; }
    .multicheckout.order-review .box-items .data.table thead .col.item {
      display: block;
      padding: 0; }
  .multicheckout .actions-toolbar .action {
    margin-bottom: 25px; }
  .multicheckout .actions-toolbar > .primary {
    margin-bottom: 25px;
    margin-right: 0; } }

@media only screen and (min-width: 640px) {
  .multicheckout .actions-toolbar .secondary {
    float: none;
    margin-top: 11px;
    text-align: right; }
    .multicheckout .actions-toolbar .secondary .action {
      margin-left: 10px; }
      .multicheckout .actions-toolbar .secondary .action.back {
        display: block;
        float: left; }
  .multicheckout .item-options {
    margin: 20px 0 0; }
  .multicheckout .block-content .box {
    margin-bottom: 0; }
  .multicheckout .block-shipping .box {
    float: left;
    width: 25%; }
  .multicheckout .block-shipping .box-shipping-method {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%; }
    .multicheckout .block-shipping .box-shipping-method .fieldset .field:before {
      display: none; }
  .multicheckout .block-billing .box-billing-address {
    float: left;
    width: 25%; }
  .multicheckout .block-billing .box-billing-method {
    float: left;
    padding-left: 25px;
    width: 50%; }
  .multicheckout.form.address .table-wrapper .applicable {
    margin: 7px 0 0; }
  .multicheckout.order-review .box-items {
    clear: left;
    float: none;
    padding-top: 40px;
    width: auto; }
  .multicheckout.order-review .col.item {
    width: 75%; }
  .multicheckout .methods-payment .item-content > .fieldset {
    width: auto; }
    .multicheckout .methods-payment .item-content > .fieldset .field.cvv {
      display: inline-block;
      width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice) > .label {
    float: none;
    margin-bottom: 8px;
    text-align: left;
    width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice):not(.cvv) .control {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .multishipping-checkout-success .nav-toggle {
    display: block; }
  .multishipping-checkout-success .logo {
    margin-left: 40px; } }

.block.newsletter {
  margin-bottom: 40px; }
  .block.newsletter .form.subscribe {
    display: table;
    width: 100%; }
  .block.newsletter .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top; }
  .block.newsletter .field {
    margin: 0; }
    .block.newsletter .field .control {
      display: inline-block;
      text-decoration: none;
      display: block; }
      .block.newsletter .field .control:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 35px;
        line-height: 33px;
        color: #c2c2c2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .block.newsletter .field .control:before {
        position: absolute; }
  .block.newsletter input {
    padding-left: 35px; }
  .block.newsletter .title {
    display: none; }
  .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%; }
  .block.newsletter .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px; }

@media only screen and (min-width: 768px) {
  .block.newsletter {
    width: 32%; }
    .block.newsletter .field {
      margin-right: 5px; }
      .block.newsletter .field .control {
        width: 100%; }
    .block.newsletter .action.subscribe {
      border-radius: 3px; } }

.paypal.after:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 5px 5px 10px;
  text-transform: uppercase; }

.paypal.before:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 10px 5px;
  text-align: center;
  text-transform: uppercase; }

.paypal.acceptance {
  display: block;
  margin: 0 0 20px; }
  .paypal.acceptance img {
    max-width: 100%; }

.box-tocart .paypal img,
.block-minicart .paypal img {
  display: block;
  margin: 0 auto; }

.paypal-review .paypa-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .items-qty .item {
  white-space: nowrap; }

.paypal-review .items-qty .title:after {
  content: ': '; }

.paypal-review .paypal-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .actions-toolbar {
  margin-top: 10px; }

.paypal-review .item-options dt {
  display: inline-block; }
  .paypal-review .item-options dt:after {
    content: ': '; }

.paypal-review .item-options dd {
  margin: 0; }

.paypal-review-discount {
  border-bottom: 1px solid #d1d1d1;
  margin: 20px 0; }
  .paypal-review-discount .block {
    margin-bottom: 0; }
    .paypal-review-discount .block .fieldset {
      padding: 10px 0 20px; }

@media only screen and (min-width: 768px) {
  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #d1d1d1; }
  .paypal-review .block-content .box-order-shipping-address,
  .paypal-review .block-content .box-order-shipping-method,
  .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%; }
  .paypal-review .block-content .box-order-shipping-address {
    padding: 0 5%;
    width: 34%; }
  .paypal-review .col.subtotal,
  .paypal-review .mark,
  .paypal-review .amount {
    text-align: right; } }

.fotorama-video-container:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama-video-container .magnify-lens {
  display: none !important; }

.fotorama-video-container.video-unplayed:hover img {
  opacity: 0.6; }

.fotorama-video-container.video-unplayed:hover:after {
  transform: scale(1.25); }

.video-thumb-icon:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
  bottom: 0;
  content: '';
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 49px; }

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama__product-video--loading:after {
  visibility: hidden; }

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%; } }

.rating-summary {
  overflow: hidden;
  white-space: nowrap; }
  .rating-summary .rating-result {
    width: 76px;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .rating-summary .rating-result:before {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      content: "star_borderstar_borderstar_borderstar_borderstar_border";
      -webkit-font-smoothing: antialiased;
      color: #ffd34e;
      font-family: "Material Icons";
      font-size: 16px;
      height: 16px;
      letter-spacing: -1px;
      line-height: 16px;
      display: block;
      font-style: normal;
      font-weight: normal;
      speak: none; }
    .rating-summary .rating-result > span {
      display: block;
      overflow: hidden; }
      .rating-summary .rating-result > span:before {
        position: relative;
        z-index: 2;
        content: "";
        -webkit-font-smoothing: antialiased;
        color: #ffd34e;
        font-family: "Material Icons";
        font-size: 16px;
        height: 16px;
        letter-spacing: -1px;
        line-height: 16px;
        display: block;
        font-style: normal;
        font-weight: normal;
        speak: none; }
      .rating-summary .rating-result > span span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
  .rating-summary .rating-result {
    margin-left: -5px; }

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.review-control-vote {
  overflow: hidden; }
  .review-control-vote:before {
    color: #ffd34e;
    font-family: "Material Icons";
    font-size: 16px;
    height: 16px;
    letter-spacing: -1px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "star_borderstar_borderstar_borderstar_borderstar_border";
    display: block;
    position: absolute;
    z-index: 1; }
  .review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
    .review-control-vote input[type="radio"]:focus + label:before, .review-control-vote input[type="radio"]:checked + label:before {
      opacity: 1; }
  .review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute; }
    .review-control-vote label span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .review-control-vote label:before {
      color: #ffd34e;
      font-family: "Material Icons";
      font-size: 16px;
      height: 16px;
      letter-spacing: -1px;
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
      speak: none;
      -webkit-font-smoothing: antialiased;
      opacity: 0; }
    .review-control-vote label:hover:before {
      opacity: 1; }
    .review-control-vote label:hover ~ label:before {
      opacity: 0; }
  .review-control-vote .rating-5 {
    z-index: 2; }
    .review-control-vote .rating-5:before {
      content: ""; }
  .review-control-vote .rating-4 {
    z-index: 3; }
    .review-control-vote .rating-4:before {
      content: ""; }
  .review-control-vote .rating-3 {
    z-index: 4; }
    .review-control-vote .rating-3:before {
      content: ""; }
  .review-control-vote .rating-2 {
    z-index: 5; }
    .review-control-vote .rating-2:before {
      content: ""; }
  .review-control-vote .rating-1 {
    z-index: 6; }
    .review-control-vote .rating-1:before {
      content: ""; }

.block.add.review {
  margin-bottom: 40px; }
  .block.add.review > .title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .block.add.review .legend {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin: 0 0 20px; }
  .block.add.review .field.rating {
    display: table-row; }
    .block.add.review .field.rating .label {
      display: table-cell;
      padding: 5px 0;
      vertical-align: middle; }
    .block.add.review .field.rating .control {
      display: table-cell;
      vertical-align: top; }

.block.reviews.list > .title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

.block.reviews.list .review.item {
  margin-bottom: 40px; }

.block.reviews.list .review.title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

.data.table.reviews .rating-summary {
  margin-top: -4px; }

.customer-review.view {
  margin-bottom: 20px; }
  .customer-review.view .review.title,
  .customer-review.view .ratings-summary,
  .customer-review.view .product-details {
    margin-bottom: 20px; }

.product-reviews-summary {
  display: table; }
  .products.wrapper.list .product-reviews-summary {
    margin: 0 auto; }
  .product-reviews-summary.empty {
    margin-left: 0; }
  .product-reviews-summary .rating-summary {
    display: table-cell;
    left: -5px;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .product-reviews-summary .reviews-actions {
    display: table-cell;
    line-height: 16px;
    vertical-align: middle; }
    .product-reviews-summary .reviews-actions a {
      margin-right: 5px; }

@media only screen and (min-width: 768px) {
  .products.wrapper.list .product-reviews-summary {
    margin: 0; } }

.customer-review .product-details {
  margin-bottom: 40px; }

.customer-review .product-media {
  float: left;
  margin-right: 3%;
  max-width: 285px;
  width: 30%; }

.customer-review .review-details .customer-review-rating {
  margin-bottom: 20px; }
  .customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px; }
    .customer-review .review-details .customer-review-rating .item:last-child {
      margin-bottom: 0; }

.customer-review .review-details .review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  margin-bottom: 20px; }

.customer-review .review-details .review-content {
  margin-bottom: 20px; }

.customer-review .product-reviews-summary .rating-summary,
.customer-review .product-reviews-summary .reviews-actions {
  display: block; }

.review-field-ratings .nested {
  display: table; }

.review-field-ratings .review-field-rating {
  display: table-row; }
  .review-field-ratings .review-field-rating .label,
  .review-field-ratings .review-field-rating .control {
    display: table-cell;
    padding-bottom: 10px;
    vertical-align: top; }
  .review-field-ratings .review-field-rating .label {
    padding-right: 10px;
    padding-top: 6px; }
  .review-field-ratings .review-field-rating .control {
    padding-top: 6px; }

.review-toolbar {
  border-top: 1px solid #c9c9c9;
  margin: 0 0 20px;
  text-align: center; }
  .review-toolbar:first-child {
    display: none; }
  .review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center; }
  .review-toolbar .limiter {
    display: none; }

.review-add .block-title {
  display: none; }

.review-form .action.submit.primary {
  background: #ffffff;
  border: 1px solid #cdcdcd;
  color: #1a1a1a; }
  .review-form .action.submit.primary:focus, .review-form .action.submit.primary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #1a1a1a; }
  .review-form .action.submit.primary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555; }

.fieldset .review-legend.legend {
  margin-bottom: 20px; }
  .fieldset .review-legend.legend strong {
    display: block;
    margin-left: 0; }

.review-field-rating .control {
  margin-bottom: 48px;
  margin-top: 10px; }

.review-list {
  margin-bottom: 30px; }
  .review-list .block-title strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }

.review-item {
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  padding: 20px 0; }
  .review-item:after {
    clear: both;
    content: '';
    display: table; }
  .review-item:last-child {
    border-width: 0; }

.review-ratings {
  display: table;
  margin-bottom: 10px;
  max-width: 100%; }

.review-author {
  display: inline; }

.review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin: 0 0 20px; }

.review-content {
  margin-bottom: 20px; }

@media only screen and (min-width: 640px) {
  .review-form {
    max-width: 500px; }
  .review-ratings {
    float: left;
    margin-bottom: 0;
    max-width: 240px; }
  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 280px; }
  .review-toolbar {
    margin: 0 0 30px; }
    .review-toolbar .pages {
      padding: 30px 0; }
  .fieldset .review-legend.legend {
    margin-bottom: 30px; }
  .review-item {
    padding: 30px 0; }
  .review-title {
    margin: 0 0 30px; } }

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
  margin-bottom: 25px; }

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
  display: inline-block;
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .reward-settings + .actions-toolbar {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .reward-settings + .actions-toolbar {
    margin-top: -32px; } }

.form-create-return .field .control > span,
.form-create-return .field address {
  display: inline-block;
  padding-top: 6px; }

.form-create-return .additional:first-child {
  margin-top: 0; }

.form-create-return .additional .field:nth-last-child(2) {
  margin-bottom: 0; }

.form-create-return .additional .field:last-child {
  margin-top: 20px; }

.form-create-return .field.comment {
  margin-top: 55px; }

.block-returns-comments .returns-comments dt,
.block-returns-comments .returns-comments dd {
  margin: 0; }

.block-returns-comments .returns-comments dt {
  font-weight: 400; }

.block-returns-comments .returns-comments dd {
  margin-bottom: 15px; }
  .block-returns-comments .returns-comments dd:last-child {
    margin-bottom: 0; }

.block-returns-comments .fieldset {
  margin-bottom: 20px; }

.magento-rma-returns-returns .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-title-wrapper .page-title,
.magento-rma-returns-view .page-title-wrapper .page-title {
  margin-right: 25px; }

@media only screen and (max-width: 767px) {
  .account .column.main .returns-details-items .block-returns-tracking .block-title > .action.track {
    display: block;
    float: none;
    margin: 10px 0 0; } }

@media only screen and (min-width: 768px) {
  .form-create-return .additional .field:last-child {
    margin-top: 29px; }
  .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; }
  .block-returns-tracking .block-title .action {
    margin: 0 0 0 30px; }
  .block-returns-tracking .block-title .actions-track {
    float: right;
    margin-top: 12px; } }

.order-links {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 10px; }
  .order-links .item {
    display: inline-block;
    margin-right: 20px; }

.order-actions-toolbar .action {
  margin: 0 20px 0 0; }

.order-details-items {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  .order-details-items .order-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block; }
  .order-details-items .items-qty .item {
    white-space: nowrap; }
  .order-details-items .items-qty .title:after {
    content: ': '; }
  .order-details-items .table-order-items .product-item-name {
    margin-top: 0; }
  .order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #d1d1d1; }
  .order-details-items .item-options dt {
    margin: 0; }
  .order-details-items .item-options dd {
    margin: 0 0 15px; }
  .order-details-items .item-options.links dt {
    display: inline-block; }
    .order-details-items .item-options.links dt:after {
      content: ': '; }
  .order-details-items .item-options.links dd {
    margin: 0; }

.order-pager-wrapper .toolbar-amount {
  padding-top: 3px; }

.order-pager-wrapper .pages-items .item:first-child, .order-pager-wrapper .pages-items .item:last-child {
  margin-right: 0; }

.order-actions-toolbar {
  margin-bottom: 25px; }
  .order-actions-toolbar .action {
    margin-right: 30px; }
    .order-actions-toolbar .action.print {
      margin: 0; }

.page-title-wrapper .order-date {
  margin: 0 0 20px; }

.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
  margin-right: 25px; }

.form-orders-search .field.email, .form-orders-search .field.zip {
  margin-bottom: 0; }

.block-reorder .product-item .checkbox {
  position: relative;
  top: 2px;
  vertical-align: baseline; }

.block-reorder .product-item .field.item {
  display: inline-block; }

.block-reorder .product-item-name {
  display: inline-block; }

.block-reorder .actions-toolbar {
  margin: 17px 0;
  max-width: 178px; }

.block-order-details-comments {
  margin: 0 0 40px; }
  .block-order-details-comments .comment-date {
    font-weight: 600; }
  .block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px; }

.block-order-details-view .box-content .payment-method .title {
  font-weight: 400; }

.block-order-details-view .box-content .payment-method .content {
  margin: 0; }
  .block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400; }
    .block-order-details-view .box-content .payment-method .content > strong:after {
      content: ': '; }

.order-tracking {
  border-bottom: 1px solid #d1d1d1;
  margin: 0;
  padding: 20px 0; }
  .order-tracking .tracking-title {
    display: inline-block; }
  .order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px; }

@media only screen and (max-width: 639px) {
  .account .order-details-items .table-order-items .product-item-name,
  .account .order-details-items .table-order-items .price-including-tax,
  .account .order-details-items .table-order-items .price-excluding-tax,
  .account .order-details-items .table-order-items .items-qty {
    display: inline-block;
    vertical-align: top; }
  .account .toolbar .pages {
    float: right; }
  .account .toolbar .limiter {
    clear: both; }
  .order-details-items thead {
    display: block; }
    .order-details-items thead tr,
    .order-details-items thead td {
      display: block; }
  .table-wrapper .table.table-order-items tfoot td.order-pager-wrapper {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .order-pager-wrapper .toolbar-amount {
    left: inherit;
    position: relative;
    text-align: center;
    top: inherit; }
  .order-pager-wrapper .pages {
    text-align: center; }
  .order-pager-wrapper .action.previous,
  .order-pager-wrapper .action.next {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .table-order-items .subtotal,
  .table-order-items .amount {
    text-align: right; }
  .table-order-items.creditmemo .col.qty, .table-order-items.creditmemo .col.discount, .table-order-items.creditmemo .col.subtotal {
    text-align: center; }
  .table-order-items.creditmemo .col.total {
    text-align: right; }
  .order-pager-wrapper .order-pager-wrapper-top {
    padding-left: 0;
    padding-right: 0; }
  .order-pager-wrapper .pages {
    float: right; }
  .order-actions-toolbar .action.print {
    display: block;
    float: right; }
  .page-title-wrapper .order-date {
    margin-top: -20px; }
  [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; } }

.opc-wrapper .form-discount {
  max-width: 500px; }

.action.mailto.friend {
  margin-left: -7px;
  display: inline-block;
  text-decoration: none; }
  .action.mailto.friend:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .action.mailto.friend span {
    display: inline-block;
    line-height: 28px;
    vertical-align: top; }

.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.swatch-attribute-label {
  font-weight: bold;
  position: relative; }
  .swatch-attribute-label.required {
    padding-right: 10px; }
  .swatch-attribute-label[data-required="1"]::after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em; }

.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px; }

.swatch-option {
  padding: 1px 2px;
  min-width: 30px;
  max-width: 90px;
  height: 20px;
  margin: 0 10px 5px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid #dadada;
  overflow: hidden;
  text-overflow: ellipsis; }
  .swatch-option.text {
    box-sizing: content-box;
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
    color: #7f7f7f;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 4px 4px;
    min-width: 22px;
    margin-right: 7px;
    border-radius: 2px; }
  .swatch-option.selected {
    border: 3px solid #2196f3;
    color: #333; }
  .swatch-option.text.selected {
    background-color: #fff; }
  .swatch-option:not(.disabled):hover {
    border: 1px solid #f0f0f0;
    color: #333; }
  .swatch-option.color, .swatch-option.image {
    width: 24px;
    height: 24px;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    max-height: 24px;
    min-height: 24px;
    border: 2px solid #dadada;
    border-radius: 50%; }
    .swatch-option.color.selected, .swatch-option.image.selected {
      border: 3px solid #2196f3;
      color: #333; }
    .swatch-option.color:not(.disabled):hover, .swatch-option.image:not(.disabled):hover {
      outline: 0;
      border: 2px solid #dadada;
      opacity: 0.8; }
  .swatch-option.disabled {
    cursor: default; }
    .swatch-option.disabled::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, white 43%, white 46%, #ff5216 47%, #ff5216 53%, white 54%, white 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%); }

.swatch-attribute.manufacturer .swatch-option, .swatch-attribute.size .swatch-option {
  background: #ffffff;
  color: #949494; }
  .swatch-attribute.manufacturer .swatch-option.selected, .swatch-attribute.size .swatch-option.selected {
    color: black;
    background: #f3f3f3;
    border: 1px solid #f0f0f0; }

.swatch-option-tooltip {
  display: none;
  z-index: 999;
  max-width: 140px;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 5px;
  color: #949494;
  text-align: center; }

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: -1rem;
  height: 8px; }
  .swatch-option-tooltip .corner::before,
  .swatch-option-tooltip-layered .corner::before {
    content: '';
    position: relative;
    top: 2px;
    left: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #e0e0e0 transparent transparent transparent;
    font-size: 1px; }
  .swatch-option-tooltip .corner::after,
  .swatch-option-tooltip-layered .corner::after {
    content: '';
    position: relative;
    top: 1px;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px; }

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  position: relative;
  bottom: -0.75rem;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: 5px solid #f0f0f0;
  margin: 0 auto; }

.swatch-option-tooltip .title {
  display: block;
  position: relative;
  bottom: -19px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 1rem;
  overflow: hidden;
  max-width: 140px;
  min-height: 20px;
  max-height: 200px;
  color: #282828;
  text-align: center; }

.swatch-opt {
  margin: 20px 0; }

.swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.swatch-option-link-layered {
  position: relative;
  margin: 0 !important;
  padding: 0 !important; }

.swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.swatch-option-disabled:after {
  content: '';
  position: absolute;
  width: 42px;
  height: 2px;
  background: red;
  transform: rotate(-30deg);
  z-index: 995;
  left: -4px;
  top: 10px; }

.swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.swatch-opt-listing {
  margin-bottom: 10px; }

.swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden; }

body {
  background-color: #ffffff; }

.ie9 .page-wrapper {
  background-color: #ffffff;
  min-height: 0; }

.page-header {
  background-color: #ffffff;
  border-bottom: 1px solid #d1d1d1; }
  .page-header .panel.wrapper {
    background-color: #ffffff;
    color: #626262; }

.header.panel > .header.links {
  margin: 0;
  padding: 0;
  list-style: none none;
  float: right;
  font-size: 0;
  margin-right: 20px; }
  .header.panel > .header.links > li {
    display: inline-block;
    vertical-align: top; }
  .header.panel > .header.links > li {
    font-size: 11px;
    margin: 0 0 0 15px; }
    .header.panel > .header.links > li > a {
      color: #626262;
      text-decoration: none; }
      .header.panel > .header.links > li > a:visited {
        color: #626262;
        text-decoration: none; }
      .header.panel > .header.links > li > a:hover {
        color: #626262;
        text-decoration: underline; }
      .header.panel > .header.links > li > a:active {
        color: #626262;
        text-decoration: underline; }

.header.content {
  padding-top: 10px;
  position: relative; }

.logo {
  margin: 0 0 10px 40px;
  max-width: 50%;
  position: relative;
  z-index: 5; }
  .logo img {
    display: block; }
  .page-print .logo {
    display: inline-block;
    float: none; }

.page-main {
  margin-bottom: 3rem;
  padding: 0;
  position: relative; }
  .page-main > .page-title-wrapper .page-title + .action {
    margin-top: 30px; }

@media only screen and (max-width: 767px) {
  body:not(.cms-index-index) .page-main {
    padding: 1rem;
    padding-top: 3rem; } }

.action.skip:focus {
  background: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 15; }

.message.global p {
  margin: 0; }

.message.global.noscript, .message.global.cookie {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  margin: 0; }
  .message.global.noscript a, .message.global.cookie a {
    color: #1c5275; }
    .message.global.noscript a:hover, .message.global.cookie a:hover {
      color: #006bb4; }
    .message.global.noscript a:active, .message.global.cookie a:active {
      color: #006bb4; }

.message.global.cookie {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 3;
  background-color: #f7f7f7;
  color: #3C3C3C;
  font-size: 17px;
  line-height: 1.55;
  text-align: center; }
  .message.global.cookie p a {
    text-decoration: underline; }
  .message.global.cookie .actions {
    margin-top: 10px; }
    .message.global.cookie .actions #btn-cookie-allow {
      border-radius: 4px;
      box-shadow: 0 3px 0 #12354C;
      border-color: #1c5275;
      font-size: 17px; }

.message.global.demo {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  margin-bottom: 0;
  text-align: center; }
  .message.global.demo a {
    color: #1c5275; }
    .message.global.demo a:hover {
      color: #006bb4; }
    .message.global.demo a:active {
      color: #006bb4; }

.page-footer {
  margin-top: 25px; }

.footer.content {
  background: #3c3c3c;
  border-top: 1px solid #cecece;
  padding-top: 20px; }
  @media only screen and (max-width: 767px) {
    .footer.content .links {
      display: flex;
      flex-direction: column; } }
  .footer.content .links > li {
    background: #3c3c3c;
    font-size: 14px;
    border-top: 1px solid #cecece;
    margin: -15px;
    padding: 0 15px; }
    @media only screen and (max-width: 767px) {
      .footer.content .links > li {
        margin: 0; } }
  .footer.content .links a,
  .footer.content .links strong {
    display: block;
    padding: 10px 0; }
  .footer.content .links strong {
    color: #afafaf;
    font-weight: normal; }
  .footer.content .links a {
    color: #f9f9f9;
    text-decoration: none; }
    .footer.content .links a:visited {
      color: #f9f9f9;
      text-decoration: none; }
    .footer.content .links a:hover {
      color: lightgray;
      text-decoration: underline; }
    .footer.content .links a:active {
      color: lightgray;
      text-decoration: underline; }

.copyright {
  background-color: #1C1C1C;
  color: #9e9e9e;
  display: block;
  padding: 10px;
  text-align: center; }

.page-header .switcher .options,
.page-footer .switcher .options {
  display: inline-block;
  position: relative; }
  .page-header .switcher .options:before, .page-header .switcher .options:after,
  .page-footer .switcher .options:before,
  .page-footer .switcher .options:after {
    content: '';
    display: table; }
  .page-header .switcher .options:after,
  .page-footer .switcher .options:after {
    clear: both; }
  .page-header .switcher .options .action.toggle,
  .page-footer .switcher .options .action.toggle {
    padding: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .page-header .switcher .options .action.toggle > span,
    .page-footer .switcher .options .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .page-header .switcher .options .action.toggle:after,
    .page-footer .switcher .options .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 10px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      speak: none;
      text-align: center; }
    .page-header .switcher .options .action.toggle:hover:after,
    .page-footer .switcher .options .action.toggle:hover:after {
      color: inherit; }
    .page-header .switcher .options .action.toggle:active:after,
    .page-footer .switcher .options .action.toggle:active:after {
      color: inherit; }
    .page-header .switcher .options .action.toggle.active,
    .page-footer .switcher .options .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .page-header .switcher .options .action.toggle.active > span,
      .page-footer .switcher .options .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .page-header .switcher .options .action.toggle.active:after,
      .page-footer .switcher .options .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .page-header .switcher .options .action.toggle.active:hover:after,
      .page-footer .switcher .options .action.toggle.active:hover:after {
        color: inherit; }
      .page-header .switcher .options .action.toggle.active:active:after,
      .page-footer .switcher .options .action.toggle.active:active:after {
        color: inherit; }
  .page-header .switcher .options ul.dropdown,
  .page-footer .switcher .options ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 160px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    right: -8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .page-header .switcher .options ul.dropdown li,
    .page-footer .switcher .options ul.dropdown li {
      margin: 0;
      padding: 0; }
      .page-header .switcher .options ul.dropdown li:hover,
      .page-footer .switcher .options ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .page-header .switcher .options ul.dropdown:before, .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .page-header .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #ffffff transparent;
      z-index: 99; }
    .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .page-header .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:before {
      right: 10px;
      top: -12px; }
    .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:after {
      right: 9px;
      top: -14px; }
  .page-header .switcher .options.active,
  .page-footer .switcher .options.active {
    overflow: visible; }
    .page-header .switcher .options.active ul.dropdown,
    .page-footer .switcher .options.active ul.dropdown {
      display: block; }
  .page-header .switcher .options ul.dropdown a,
  .page-footer .switcher .options ul.dropdown a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 8px; }
    .page-header .switcher .options ul.dropdown a:visited,
    .page-footer .switcher .options ul.dropdown a:visited {
      color: #333;
      text-decoration: none; }
    .page-header .switcher .options ul.dropdown a:hover,
    .page-footer .switcher .options ul.dropdown a:hover {
      color: #333;
      text-decoration: none; }
    .page-header .switcher .options ul.dropdown a:active,
    .page-footer .switcher .options ul.dropdown a:active {
      color: #333;
      text-decoration: none; }

.page-header .switcher strong,
.page-footer .switcher strong {
  font-weight: 400; }

.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
  margin-bottom: 40px; }

.widget {
  clear: both; }

.page-header .widget.block,
.page-footer .widget.block {
  margin: 20px 0; }

.ui-datepicker td {
  padding: 0; }

.ui-tooltip {
  background: #ffffff;
  border: 1px solid #bbb;
  padding: 10px; }

@media only screen and (max-width: 767px) {
  .customer-name,
  .customer-welcome + .authorization-link {
    display: none; }
  .nav-sections .switcher-trigger strong:after {
    font-size: 14px;
    right: -25px;
    top: -2px; }
  .navigation .parent .level-top:after {
    font-size: 14px;
    right: 10px;
    top: 7px; }
  .page-footer .footer.content .switcher-store {
    background: #3c3c3c;
    font-size: 14px;
    margin: -15px; }
    .page-footer .footer.content .switcher-store .switcher-options {
      display: block; }
      .page-footer .footer.content .switcher-store .switcher-options ul.dropdown {
        background: none;
        border: 0;
        box-shadow: none;
        margin: 0;
        position: relative; }
        .page-footer .footer.content .switcher-store .switcher-options ul.dropdown:before, .page-footer .footer.content .switcher-store .switcher-options ul.dropdown:after {
          display: none; }
      .page-footer .footer.content .switcher-store .switcher-options .switcher-trigger,
      .page-footer .footer.content .switcher-store .switcher-options .switcher-option {
        border-top: 1px solid #cecece;
        display: block;
        padding: 10px 1rem; }
      .page-footer .footer.content .switcher-store .switcher-options .switcher-trigger strong {
        padding: 10px 0; }
      .page-footer .footer.content .switcher-store .switcher-options .switcher-option a {
        padding: 0; } }

.account-link {
  display: none;
  margin-left: 1rem; }
  .account-link a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit; }
    .account-link a:hover {
      text-decoration: none; }
      .account-link a:hover .account-text {
        text-decoration: underline; }
  .account-link .account-icon {
    line-height: 10px; }
    .account-link .account-icon:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 26px; }
  .account-link .account-text {
    font-size: 10px;
    font-weight: 500; }

@media only screen and (max-width: 639px) {
  .logo {
    margin-bottom: 13px;
    margin-top: 4px; } }

@media only screen and (min-width: 768px) {
  html,
  body {
    height: 100%; }
  .ie9 body {
    background-color: #1C1C1C; }
  .account-link {
    display: block; }
  .navigation ul {
    padding: 0 8px;
    display: flex; }
  .page-header .panel.wrapper {
    border-bottom: 0px solid #e0e0e0;
    background-color: #ffffff; }
  .page-header .header.panel {
    padding: 8px 0; }
  .page-header .switcher {
    float: right;
    margin-left: 15px;
    margin-right: -6px;
    order: 1; }
  .page-main > .page-title-wrapper .page-title {
    display: inline-block; }
  .page-main > .page-title-wrapper .page-title + .action {
    float: right;
    margin-top: 20px; }
  .customer-welcome {
    display: inline-block;
    position: relative; }
    .customer-welcome:before, .customer-welcome:after {
      content: '';
      display: table; }
    .customer-welcome:after {
      clear: both; }
    .customer-welcome .action.switch {
      padding: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none; }
      .customer-welcome .action.switch > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .customer-welcome .action.switch:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .customer-welcome .action.switch:hover:after {
        color: inherit; }
      .customer-welcome .action.switch:active:after {
        color: inherit; }
      .customer-welcome .action.switch.active {
        display: inline-block;
        text-decoration: none; }
        .customer-welcome .action.switch.active > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px; }
        .customer-welcome .action.switch.active:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 10px;
          line-height: 22px;
          color: inherit;
          content: "";
          font-family: "Material Icons";
          margin: 0;
          vertical-align: top;
          display: inline-block;
          font-weight: normal;
          speak: none;
          text-align: center; }
        .customer-welcome .action.switch.active:hover:after {
          color: inherit; }
        .customer-welcome .action.switch.active:active:after {
          color: inherit; }
    .customer-welcome ul {
      margin: 0;
      padding: 0;
      list-style: none none;
      background: #ffffff;
      border: 1px solid #bbb;
      margin-top: 4px;
      min-width: 100%;
      z-index: 101;
      box-sizing: border-box;
      display: none;
      position: absolute;
      top: 100%;
      right: -8px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
      .customer-welcome ul li {
        margin: 0;
        padding: 0; }
        .customer-welcome ul li:hover {
          background: #e8e8e8;
          cursor: pointer; }
      .customer-welcome ul:before, .customer-welcome ul:after {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0; }
      .customer-welcome ul:before {
        border: 6px solid;
        border-color: transparent transparent #ffffff transparent;
        z-index: 99; }
      .customer-welcome ul:after {
        border: 7px solid;
        border-color: transparent transparent #bbb transparent;
        z-index: 98; }
      .customer-welcome ul:before {
        right: 10px;
        top: -12px; }
      .customer-welcome ul:after {
        right: 9px;
        top: -14px; }
    .customer-welcome.active {
      overflow: visible; }
      .customer-welcome.active ul {
        display: block; }
    .customer-welcome li a {
      color: #333;
      text-decoration: none;
      display: block;
      line-height: 1.4;
      padding: 8px; }
      .customer-welcome li a:visited {
        color: #333;
        text-decoration: none; }
      .customer-welcome li a:hover {
        color: #333;
        text-decoration: none; }
      .customer-welcome li a:active {
        color: #333;
        text-decoration: none; }
    .customer-welcome .customer-name {
      cursor: pointer; }
    .customer-welcome .customer-menu {
      display: none; }
    .customer-welcome .action.switch {
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      color: #ffffff; }
      .customer-welcome .action.switch:focus, .customer-welcome .action.switch:active {
        background: none;
        border: none; }
      .customer-welcome .action.switch:hover {
        background: none;
        border: none; }
      .customer-welcome .action.switch.disabled, .customer-welcome .action.switch[disabled],
      fieldset[disabled] .customer-welcome .action.switch {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5; }
    .customer-welcome .header.links {
      min-width: 175px; }
    .customer-welcome.active .action.switch:before {
      content: ""; }
    .customer-welcome.active .customer-menu {
      display: block; }
    .customer-welcome .greet {
      display: none; }
  .header.panel > .header.links {
    margin: 0;
    padding: 0;
    list-style: none none;
    float: right;
    display: none; }
    .header.panel > .header.links > li {
      display: inline-block;
      vertical-align: top; }
    .header.panel > .header.links > li {
      margin: 0 15px 0 0; }
      .header.panel > .header.links > li.welcome a {
        color: #ffffff;
        padding-left: 5px; }
    .header.panel > .header.links > .authorization-link:after {
      content: attr(data-label); }
    .header.panel > .header.links > .customer-welcome + .authorization-link {
      display: none; }
  .header.content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    min-height: 48px; }
  .logo {
    margin: 0 0 0 0; }
    .logo img {
      max-height: inherit; }
  .page-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: 100%;
    position: relative;
    transition: margin 0.3s ease-out 0s; }
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
      box-sizing: border-box;
      width: 100%; }
    .ie10 .page-wrapper,
    .ie11 .page-wrapper {
      height: 100%; }
  .page-footer {
    background: #3c3c3c;
    margin-top: auto;
    padding-bottom: 25px; }
    .page-footer .switcher .options ul.dropdown {
      bottom: -10px;
      left: 100%;
      margin: 0 0 0 20px;
      top: auto; }
      .page-footer .switcher .options ul.dropdown:before, .page-footer .switcher .options ul.dropdown:after {
        bottom: 13px;
        left: auto;
        right: 100%;
        top: auto; }
      .page-footer .switcher .options ul.dropdown:before {
        border-color: transparent #ffffff transparent transparent; }
      .page-footer .switcher .options ul.dropdown:after {
        border-color: transparent #bbb transparent transparent;
        margin: 0 0 -1px -1px; }
  .footer.content {
    border-top: none; }
    .footer.content ul {
      padding-right: 50px; }
    .footer.content .switcher.store {
      display: block;
      margin: 0; }
    .footer.content .links {
      display: inline-block;
      padding-right: 50px;
      vertical-align: top; }
      .footer.content .links li {
        background: transparent;
        border: none;
        font-size: 14px;
        margin: 0 0 8px;
        padding: 0; }
      .footer.content .links a,
      .footer.content .links strong {
        display: inline; } }

.block-collapsible-nav .content {
  background: #f5f5f5;
  padding: 15px 0; }

.block-collapsible-nav .item {
  margin: 3px 0 0; }
  .block-collapsible-nav .item:first-child {
    margin-top: 0; }
  .block-collapsible-nav .item a,
  .block-collapsible-nav .item > strong {
    border-left: 3px solid transparent;
    color: #575757;
    display: block;
    padding: 5px 18px 5px 15px; }
  .block-collapsible-nav .item a {
    text-decoration: none; }
    .block-collapsible-nav .item a:hover {
      background: #e8e8e8; }
  .block-collapsible-nav .item.current a,
  .block-collapsible-nav .item.current > strong {
    border-color: #2196f3;
    color: #1a1a1a;
    font-weight: 600; }
  .block-collapsible-nav .item.current a {
    border-color: #2196f3; }

@media only screen and (max-width: 767px) {
  .block-collapsible-nav {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    background: #ffffff; }
    .block-collapsible-nav .content {
      border-bottom: 1px solid #d1d1d1;
      display: none; }
      .block-collapsible-nav .content.active {
        display: block; } }

.my-credit-cards .status {
  font-style: italic; }

.my-credit-cards .action.delete {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .my-credit-cards .action.delete:focus, .my-credit-cards .action.delete:active {
    background: none;
    border: none; }
  .my-credit-cards .action.delete:hover {
    background: none;
    border: none; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards .action.delete:visited {
    color: #1c5275;
    text-decoration: none; }
  .my-credit-cards .action.delete:hover {
    color: #006bb4;
    text-decoration: underline; }
  .my-credit-cards .action.delete:active {
    color: #2196f3;
    text-decoration: underline; }
  .my-credit-cards .action.delete:hover {
    color: #006bb4; }
  .my-credit-cards .action.delete:hover, .my-credit-cards .action.delete:active, .my-credit-cards .action.delete:focus {
    background: none;
    border: 0; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.my-credit-cards-popup .modal-footer {
  text-align: right; }

.my-credit-cards-popup .action.secondary {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  margin-right: 20px; }
  .my-credit-cards-popup .action.secondary:focus, .my-credit-cards-popup .action.secondary:active {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary:hover {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards-popup .action.secondary:visited {
    color: #1c5275;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #006bb4;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:active {
    color: #2196f3;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #006bb4; }
  .my-credit-cards-popup .action.secondary:hover, .my-credit-cards-popup .action.secondary:active, .my-credit-cards-popup .action.secondary:focus {
    background: none;
    border: 0; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
    display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
      display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
      display: block; }
  .my-credit-cards .card-type img {
    vertical-align: middle; } }

@media only screen and (min-width: 768px) {
  .my-credit-cards .card-type img {
    display: block; } }

.minilist .weee {
  display: table-row;
  font-size: 1.1rem; }
  .minilist .weee:before {
    display: none; }

.price-container.weee:before {
  display: none; }

.product-items .action.towishlist:before,
.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
  content: ""; }

.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
  border-top: 0; }

.account .table-wrapper .data.table.wishlist > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist thead > tr > th {
  border-bottom: 0; }

.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
  border-bottom: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist .product.name {
  display: inline-block;
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .box-tocart {
  margin: 10px 0; }
  .account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle; }

.account .table-wrapper .data.table.wishlist .col.item {
  width: 50%; }

.account .table-wrapper .data.table.wishlist .col.photo {
  max-width: 150px; }

.account .table-wrapper .data.table.wishlist .col.selector {
  max-width: 15px; }

.account .table-wrapper .data.table.wishlist textarea {
  margin: 10px 0; }

.account .table-wrapper .data.table.wishlist .input-text.qty {
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .action.primary {
  vertical-align: top; }

.account .table-wrapper .data.table.wishlist .price {
  font-weight: 700; }

.block-wishlist .product-item-name {
  margin-right: 25px; }

.products-grid.wishlist .product-item-photo {
  display: block;
  margin-bottom: 10px; }

.products-grid.wishlist .product-item-name {
  margin-top: 0; }

.products-grid.wishlist .product-item .price-box {
  margin: 0; }

.products-grid.wishlist .product-item-comment {
  display: block;
  height: 42px;
  margin: 10px 0; }

.products-grid.wishlist .product-item-actions > * {
  margin-right: 15px; }
  .products-grid.wishlist .product-item-actions > *:last-child {
    margin-right: 0; }

.products-grid.wishlist .product-item .box-tocart input.qty {
  height: 32px; }

@media only screen and (max-width: 639px) {
  .products-grid.wishlist .product-item {
    border-bottom: 1px solid #999; }
    .products-grid.wishlist .product-item:first-child {
      border-top: 1px solid #999; } }

@media only screen and (max-width: 767px) {
  .products-grid.wishlist {
    margin-bottom: 30px;
    margin-right: -10px; }
    .products-grid.wishlist .product-item {
      padding: 20px 10px 20px 20px;
      position: relative; }
      .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px; }
      .products-grid.wishlist .product-item-name {
        font-size: 1.6rem; }
      .products-grid.wishlist .product-item-actions {
        display: block; }
        .products-grid.wishlist .product-item-actions .action {
          margin-right: 15px; }
          .products-grid.wishlist .product-item-actions .action:last-child {
            margin-right: 0; }
          .products-grid.wishlist .product-item-actions .action.edit {
            float: left; }
          .products-grid.wishlist .product-item-actions .action.delete {
            float: right; }
          .products-grid.wishlist .product-item-actions .action.edit, .products-grid.wishlist .product-item-actions .action.delete {
            margin-top: 7px; }
      .products-grid.wishlist .product-item-name, .products-grid.wishlist .product-item-description,
      .products-grid.wishlist .product-item .price-box, .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px; }
      .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px; }
        .products-grid.wishlist .product-item .box-tocart .stock {
          margin-top: 7px; }
      .products-grid.wishlist .product-item .giftregisty-dropdown,
      .products-grid.wishlist .product-item .field.qty {
        display: none; }
    .products-grid.wishlist .product-image-container {
      max-width: 80px; }
  .wishlist-index-index .product-item {
    width: 100%; }
    .wishlist-index-index .product-item-info {
      width: auto; } }

@media only screen and (min-width: 640px) {
  .wishlist-index-index .products-grid .product-item {
    margin-bottom: 20px; }
  .wishlist-index-index .products-grid .product-item-actions {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .products-grid.wishlist .product-item-tooltip {
    display: inline-block; }
  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0; }
  .products-grid.wishlist .product-item .fieldset .field.qty {
    margin-bottom: 10px;
    padding-right: 10px; }
    .products-grid.wishlist .product-item .fieldset .field.qty .label {
      width: auto; }
  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0; }
  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0; }
  .wishlist-index-index .product-item-info {
    width: 240px; } }

@media only screen and (min-width: 1024px) {
  .wishlist-index-index .products-grid .product-items {
    margin: 0; }
  .wishlist-index-index .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

@font-face {
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  src: url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot");
  src: url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot?#iefix") format("embedded-opentype"), url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.woff") format("woff"), url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.ttf") format("truetype"); }

.pagebuilder-icon, .slick-prev:before, .slick-prev:after,
.slick-next:before,
.slick-next:after {
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none; }

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
  display: inline-block;
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle; }

.pagebuilder-icon-down:before {
  content: ""; }

.pagebuilder-icon-next:before {
  content: ""; }

.pagebuilder-icon-prev:before {
  content: ""; }

.pagebuilder-icon-up:before {
  content: ""; }

.product-full-width-section {
  padding: 0.5em 0; }
  .product-full-width-section .block.review-add {
    margin-top: 2.7rem; }

.page-layout-product-full-width .block.related {
  margin-top: 2.7rem; }

.page-main-details .product-section-title {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 15px;
  padding-bottom: 12px; }

.additional-attributes-wrapper .additional-attributes {
  border: none;
  width: auto; }
  .additional-attributes-wrapper .additional-attributes > tbody > tr > th {
    border: none;
    padding: 5.5px 30px 10px 0; }
  .additional-attributes-wrapper .additional-attributes > tbody > tr > td {
    border: none;
    padding: 5.5px 5px 10px; }

.cms-index-index.page-layout-cms-full-width .nav-sections {
  margin-bottom: 0; }

.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none; }

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }
  [dir='rtl'] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev {
  left: 2rem; }
  .slick-prev:before {
    content: "";
    padding-right: 5px; }

.slick-next {
  right: 2rem; }
  .slick-next:before {
    content: "";
    padding-left: 5px; }

.slick-prev,
.slick-next {
  background: rgba(252, 252, 252, 0.6);
  border: none;
  border-radius: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 60px;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 60px;
  z-index: 101; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.2; }
  .slick-prev:hover,
  .slick-next:hover {
    background: #fcfcfc;
    border: none; }
    .slick-prev:hover.slick-disabled,
    .slick-next:hover.slick-disabled {
      opacity: 0.2; }
    .slick-prev:hover:before,
    .slick-next:hover:before {
      color: #494949; }
  .slick-prev:before, .slick-prev:after,
  .slick-next:before,
  .slick-next:after {
    color: #555;
    font-size: 38px;
    opacity: 1; }
  .slick-prev:active, .slick-prev:focus, .slick-prev:not(.primary),
  .slick-next:active,
  .slick-next:focus,
  .slick-next:not(.primary) {
    background: rgba(252, 252, 252, 0.7);
    border: none;
    box-shadow: none; }
    .slick-prev:active:active, .slick-prev:focus:active, .slick-prev:not(.primary):active,
    .slick-next:active:active,
    .slick-next:focus:active,
    .slick-next:not(.primary):active {
      box-shadow: none; }

.slick-dots {
  font-size: 1.4rem;
  font-size: 14px;
  letter-spacing: normal;
  line-height: normal;
  display: block;
  list-style: none;
  margin: 0;
  padding: 15px 0;
  text-align: center;
  width: 100%; }
  .slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 14px; }
    .slick-dots li button, .slick-dots li .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li .actions-toolbar > .action, .slick-dots li .action-gift {
      background: #ffffff;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: none;
      cursor: pointer;
      display: block;
      height: 14px;
      padding: 0;
      text-indent: -99999px;
      transition: 0.3s;
      width: 14px; }
      .slick-dots li button:hover, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover, .slick-dots li .action-gift:hover {
        border: 1px solid #b3b3b3; }
      .slick-dots li button:active, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:active, .slick-dots li .action-gift:active, .slick-dots li button:focus, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus, .slick-dots li .action-gift:focus, .slick-dots li button:not(.primary), .slick-dots li .cart.table-wrapper .actions-toolbar > .action:not(.primary), .cart.table-wrapper .slick-dots li .actions-toolbar > .action:not(.primary), .slick-dots li .action-gift:not(.primary) {
        box-shadow: none; }
    .slick-dots li.slick-active button, .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li.slick-active .actions-toolbar > .action, .slick-dots li.slick-active .action-gift {
      background: #1c1918; }

[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
  color: inherit;
  text-decoration: inherit; }
  [data-content-type='banner'] > [data-element='link']:hover,
  [data-content-type='banner'] > [data-element='empty_link']:hover {
    color: inherit;
    text-decoration: inherit; }

.pagebuilder-banner-wrapper {
  background-clip: padding-box;
  border-radius: inherit;
  overflow-wrap: break-word;
  box-sizing: border-box;
  word-wrap: break-word; }
  .pagebuilder-banner-wrapper .pagebuilder-overlay {
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
      align-items: center;
      display: flex;
      justify-content: center; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 540px; }
  .pagebuilder-banner-wrapper [data-element='content'] {
    min-height: 50px; }
  .pagebuilder-banner-wrapper .pagebuilder-banner-button {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word; }

@media only screen and (max-width: 766px) {
  .pagebuilder-banner-wrapper {
    background-attachment: scroll !important; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: none; } }

.pagebuilder-banner-wrapper .pagebuilder-poster-content {
  width: 100%; }

[data-appearance='collage-centered'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto; }

[data-appearance='collage-left'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-right: auto; }

[data-appearance='collage-right'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto; }

[data-content-type$='block'] .block p:last-child {
  margin-bottom: 1rem;
  margin-top: 0; }

[data-content-type='buttons'] {
  max-width: 100%; }

[data-content-type='button-item'] {
  max-width: 100%; }
  [data-content-type='button-item'] [data-element='link'],
  [data-content-type='button-item'] [data-element='empty_link'] {
    max-width: 100%;
    word-wrap: break-word; }
  [data-content-type='button-item'] [data-element='empty_link'] {
    cursor: default; }

a.pagebuilder-button-link,
button.pagebuilder-button-link,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action,
.pagebuilder-button-link.action-gift,
div.pagebuilder-button-link {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  line-height: 1.42857;
  margin: 0 10px 10px 0;
  padding: 14px 17px;
  color: #1c5275;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  font-weight: 600;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block; }
  a.pagebuilder-button-link:visited,
  button.pagebuilder-button-link:visited,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:visited,
  .pagebuilder-button-link.action-gift:visited,
  div.pagebuilder-button-link:visited {
    color: #1c5275;
    text-decoration: none; }
  a.pagebuilder-button-link:hover,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  div.pagebuilder-button-link:hover {
    color: #006bb4;
    text-decoration: underline; }
  a.pagebuilder-button-link:active,
  button.pagebuilder-button-link:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:active,
  .pagebuilder-button-link.action-gift:active,
  div.pagebuilder-button-link:active {
    color: #2196f3;
    text-decoration: underline; }
  a.pagebuilder-button-link:hover,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  div.pagebuilder-button-link:hover {
    color: #006bb4; }
  a.pagebuilder-button-link:hover, a.pagebuilder-button-link:active, a.pagebuilder-button-link:focus,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  button.pagebuilder-button-link:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:active,
  .pagebuilder-button-link.action-gift:active,
  button.pagebuilder-button-link:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:focus,
  .pagebuilder-button-link.action-gift:focus,
  div.pagebuilder-button-link:hover,
  div.pagebuilder-button-link:active,
  div.pagebuilder-button-link:focus {
    background: none;
    border: 0; }
  a.pagebuilder-button-link.disabled, a.pagebuilder-button-link[disabled],
  fieldset[disabled] a.pagebuilder-button-link,
  button.pagebuilder-button-link.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.disabled.action,
  .pagebuilder-button-link.disabled.action-gift,
  button.pagebuilder-button-link[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action[disabled],
  .pagebuilder-button-link.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-link,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-link.action,
  fieldset[disabled] .pagebuilder-button-link.action-gift,
  div.pagebuilder-button-link.disabled,
  div.pagebuilder-button-link[disabled],
  fieldset[disabled]
  div.pagebuilder-button-link {
    color: #1c5275;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action,
.pagebuilder-button-primary.action-gift,
div.pagebuilder-button-primary {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #ffffff;
  border: 1px solid #1a1a1a;
  border-bottom-width: 2px;
  transition: color background-color border-color 250ms ease;
  color: #1a1a1a;
  cursor: pointer;
  display: inline-block;
  font-family: "Myriad Pro Regular", sans-serif;
  font-weight: 700;
  margin: 0 10px 10px 0;
  padding: 7px 15px;
  width: 260px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  border-radius: 0;
  box-shadow: none; }
  a.pagebuilder-button-primary:hover, a.pagebuilder-button-primary:active, a.pagebuilder-button-primary:focus,
  button.pagebuilder-button-primary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:hover,
  .pagebuilder-button-primary.action-gift:hover,
  button.pagebuilder-button-primary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:active,
  .pagebuilder-button-primary.action-gift:active,
  button.pagebuilder-button-primary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:focus,
  .pagebuilder-button-primary.action-gift:focus,
  div.pagebuilder-button-primary:hover,
  div.pagebuilder-button-primary:active,
  div.pagebuilder-button-primary:focus {
    text-decoration: none; }
  a.pagebuilder-button-primary:focus, a.pagebuilder-button-primary:active,
  button.pagebuilder-button-primary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:focus,
  .pagebuilder-button-primary.action-gift:focus,
  button.pagebuilder-button-primary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:active,
  .pagebuilder-button-primary.action-gift:active,
  div.pagebuilder-button-primary:focus,
  div.pagebuilder-button-primary:active {
    background: #1a1a1a;
    border: 1px solid #1a1a1a;
    border-bottom-width: 2px;
    color: #ffffff; }
  a.pagebuilder-button-primary:hover,
  button.pagebuilder-button-primary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:hover,
  .pagebuilder-button-primary.action-gift:hover,
  div.pagebuilder-button-primary:hover {
    background: #1a1a1a;
    border: 1px solid #1a1a1a;
    border-bottom-width: 2px;
    color: #ffffff; }
  a.pagebuilder-button-primary.disabled, a.pagebuilder-button-primary[disabled],
  fieldset[disabled] a.pagebuilder-button-primary,
  button.pagebuilder-button-primary.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.disabled.action,
  .pagebuilder-button-primary.disabled.action-gift,
  button.pagebuilder-button-primary[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action[disabled],
  .pagebuilder-button-primary.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-primary,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-primary.action,
  fieldset[disabled] .pagebuilder-button-primary.action-gift,
  div.pagebuilder-button-primary.disabled,
  div.pagebuilder-button-primary[disabled],
  fieldset[disabled]
  div.pagebuilder-button-primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action,
.pagebuilder-button-secondary.action-gift,
div.pagebuilder-button-secondary {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-bottom-width: 1px;
  transition: color background-color border-color 250ms ease;
  color: #1a1a1a;
  cursor: pointer;
  display: inline-block;
  font-family: "Myriad Pro Regular", sans-serif;
  font-weight: 700;
  margin: 0 10px 10px 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  border-radius: 0;
  box-shadow: none; }
  a.pagebuilder-button-secondary:hover, a.pagebuilder-button-secondary:active, a.pagebuilder-button-secondary:focus,
  button.pagebuilder-button-secondary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:hover,
  .pagebuilder-button-secondary.action-gift:hover,
  button.pagebuilder-button-secondary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:active,
  .pagebuilder-button-secondary.action-gift:active,
  button.pagebuilder-button-secondary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:focus,
  .pagebuilder-button-secondary.action-gift:focus,
  div.pagebuilder-button-secondary:hover,
  div.pagebuilder-button-secondary:active,
  div.pagebuilder-button-secondary:focus {
    text-decoration: none; }
  a.pagebuilder-button-secondary:focus, a.pagebuilder-button-secondary:active,
  button.pagebuilder-button-secondary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:focus,
  .pagebuilder-button-secondary.action-gift:focus,
  button.pagebuilder-button-secondary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:active,
  .pagebuilder-button-secondary.action-gift:active,
  div.pagebuilder-button-secondary:focus,
  div.pagebuilder-button-secondary:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #1a1a1a; }
  a.pagebuilder-button-secondary:hover,
  button.pagebuilder-button-secondary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:hover,
  .pagebuilder-button-secondary.action-gift:hover,
  div.pagebuilder-button-secondary:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    border-bottom-width: 1px;
    color: #555; }
  a.pagebuilder-button-secondary.disabled, a.pagebuilder-button-secondary[disabled],
  fieldset[disabled] a.pagebuilder-button-secondary,
  button.pagebuilder-button-secondary.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.disabled.action,
  .pagebuilder-button-secondary.disabled.action-gift,
  button.pagebuilder-button-secondary[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action[disabled],
  .pagebuilder-button-secondary.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-secondary,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-secondary.action,
  fieldset[disabled] .pagebuilder-button-secondary.action-gift,
  div.pagebuilder-button-secondary.disabled,
  div.pagebuilder-button-secondary[disabled],
  fieldset[disabled]
  div.pagebuilder-button-secondary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.pagebuilder-column {
  box-sizing: border-box; }

@media only screen and (max-width: 766px) {
  .pagebuilder-column {
    background-attachment: scroll !important;
    flex-basis: 100%; } }

@media only screen and (max-width: 766px) {
  .pagebuilder-column-group {
    flex-wrap: wrap; } }

[data-content-type='heading'] {
  word-wrap: break-word;
  font-family: "Adobe Garamond Pro Regular"; }
  [data-content-type='heading'] h1 {
    font-size: 50px; }

.panel .header [data-content-type='row'][data-appearance='contained'] {
  margin-left: initial !important;
  margin-right: initial !important; }

[data-content-type='html'] {
  word-wrap: break-word; }

figure[data-content-type='image'] {
  box-sizing: border-box; }
  figure[data-content-type='image'] > [data-element='link'],
  figure[data-content-type='image'] > [data-element='link'] img {
    border-radius: inherit; }
  figure[data-content-type='image'] figcaption {
    word-wrap: break-word; }

[data-content-type='map'] {
  box-sizing: border-box;
  height: 300px; }

.pagebuilder-column .widget-product-grid .product-item {
  max-width: 100%;
  min-width: 50px; }
  .pagebuilder-column .widget-product-grid .product-item .price-box {
    word-wrap: break-word; }
    .pagebuilder-column .widget-product-grid .product-item .price-box .price {
      white-space: normal; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-primary {
    display: inline-block;
    max-width: 100%; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
    display: inline-block;
    width: auto; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .tocart {
    max-width: 100%;
    white-space: normal; }

.pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 200px; }

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item {
  display: block; }
  [data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item:first-child {
    visibility: visible; }

[data-content-type='products'][data-appearance='carousel'] .product-item-info {
  width: auto; }

[data-content-type='products'][data-appearance='carousel'] li.product-item {
  display: none; }
  [data-content-type='products'][data-appearance='carousel'] li.product-item:first-child {
    display: block;
    visibility: hidden; }

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item {
  opacity: 0.5;
  transition: all 300ms ease; }
  [data-content-type='products'][data-appearance='carousel'].center-mode .product-item:hover {
    opacity: 1; }

[data-content-type='products'][data-appearance='carousel'].center-mode .slick-current .product-item {
  opacity: 1; }

[data-content-type='row'][data-appearance='contained'] {
  box-sizing: border-box;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1440px; }
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    box-sizing: border-box; }

@media only screen and (max-width: 766px) {
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    background-attachment: scroll !important; } }

[data-content-type='row'][data-appearance='full-bleed'] {
  box-sizing: border-box;
  width: calc(100vw - 8px);
  margin-left: calc((100% / 2) - 50vw) !important; }

@media only screen and (max-width: 766px) {
  [data-content-type='row'][data-appearance='full-bleed'] {
    background-attachment: scroll !important; } }

[data-content-type='row'][data-appearance='full-width'] {
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc((100% / 2) - 50vw) !important; }
  [data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 100%; }

@media only screen and (max-width: 766px) {
  [data-content-type='row'][data-appearance='full-width'] {
    background-attachment: scroll !important; } }

[data-content-type='slide'] {
  box-sizing: border-box;
  line-height: 20px;
  min-height: inherit;
  overflow: hidden; }
  [data-content-type='slide'] > [data-element='link'],
  [data-content-type='slide'] > [data-element='empty_link'] {
    color: inherit;
    min-height: inherit;
    text-decoration: inherit; }
    [data-content-type='slide'] > [data-element='link']:hover,
    [data-content-type='slide'] > [data-element='empty_link']:hover {
      color: inherit;
      text-decoration: inherit; }

.pagebuilder-slide-wrapper {
  border-radius: inherit;
  box-sizing: border-box;
  min-height: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word; }
  .pagebuilder-slide-wrapper .pagebuilder-overlay {
    border-radius: inherit;
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease; }
    .pagebuilder-slide-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: inherit; }
    .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 540px; }
  .pagebuilder-slide-wrapper [data-element='content'] {
    min-height: 50px; }
  .pagebuilder-slide-wrapper .pagebuilder-slide-button {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word; }

@media only screen and (max-width: 766px) {
  .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
    max-width: none; } }

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: 100%; }

[data-appearance='collage-centered'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto; }

[data-appearance='collage-left'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-right: auto; }

[data-appearance='collage-right'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto; }

div[data-content-type='slider'] {
  visibility: hidden; }
  div[data-content-type='slider'].slick-initialized {
    visibility: visible; }
  div[data-content-type='slider'] .slick-list,
  div[data-content-type='slider'] .slick-track,
  div[data-content-type='slider'] .slick-slide {
    min-height: inherit; }
    div[data-content-type='slider'] .slick-list > div,
    div[data-content-type='slider'] .slick-track > div,
    div[data-content-type='slider'] .slick-slide > div {
      line-height: 0;
      min-height: inherit;
      overflow: hidden;
      width: 100%; }

a.button {
  appearance: button;
  color: initial;
  padding: 10px;
  text-decoration: none; }

div[data-content-type='tabs'] .tabs-navigation {
  display: block;
  font-size: 0;
  margin: 0 0 -1px;
  padding: 0; }
  div[data-content-type='tabs'] .tabs-navigation li:first-child {
    margin-left: 0 !important; }
  div[data-content-type='tabs'] .tabs-navigation li.tab-header {
    background: #f6f6f6;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow-wrap: break-word;
    position: relative;
    word-wrap: break-word;
    z-index: 1; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header:not(:first-child) {
      margin-left: -1px; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title {
      border-right: 0;
      color: #1a1a1a;
      cursor: pointer !important;
      display: block;
      font-size: 14px;
      font-weight: 600;
      padding: 1.4rem 2rem;
      position: relative;
      transition: all 0.3s;
      vertical-align: middle;
      white-space: normal; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title span.tab-title {
        display: block; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title:hover {
        text-decoration: none; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header:last-child {
      border-right: 1px solid #ccc; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active {
      background: #ffffff;
      z-index: 19; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active a.tab-title {
        position: relative;
        transition: all 0.3s; }

div[data-content-type='tabs'] .tabs-content {
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 9; }
  div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item'] {
    box-sizing: border-box;
    min-height: inherit; }
    div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item']:not(:first-child) {
      display: none; }

div[data-content-type='tabs'].tab-align-left .tabs-content {
  border-top-left-radius: 0 !important; }

div[data-content-type='tabs'].tab-align-right .tabs-content {
  border-top-right-radius: 0 !important; }

@media only screen and (max-width: 766px) {
  [data-content-type='tab-item'] {
    background-attachment: scroll !important; } }

div[data-content-type='text'] {
  word-wrap: break-word; }
  div[data-content-type='text'] h1, div[data-content-type='text'] h2 {
    font-family: "Adobe Garamond Pro Regular"; }

div[data-content-type='video'] {
  font-size: 0; }
  div[data-content-type='video'] .pagebuilder-video-inner {
    box-sizing: border-box;
    display: inline-block;
    width: 100%; }
  div[data-content-type='video'] .pagebuilder-video-container {
    border-radius: inherit;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative; }
  div[data-content-type='video'] iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.cms-content-important {
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
  margin: 0 0 35px -20px;
  padding: 20px; }

.pagebuilder-full-width {
  float: left;
  width: 100%; }

.pagebuilder-content-type {
  box-sizing: border-box;
  margin-bottom: 20px; }

.pagebuilder-accordion {
  margin: 0;
  padding: 0; }
  .pagebuilder-accordion > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .pagebuilder-accordion > .item.title > .switch {
      display: block; }
  .pagebuilder-accordion > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .pagebuilder-accordion > .item.content:before, .pagebuilder-accordion > .item.content:after {
      content: '';
      display: table; }
    .pagebuilder-accordion > .item.content:after {
      clear: both; }
    .pagebuilder-accordion > .item.content.active {
      display: block; }
  .pagebuilder-accordion > .item.title {
    margin: 0 0 5px; }
  .pagebuilder-accordion > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #868686;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #868686;
    text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:visited {
      color: #868686;
      text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:hover {
      color: #868686;
      text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:active {
      color: #434343;
      text-decoration: none; }
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:focus,
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:active,
  .pagebuilder-accordion > .item.title.active > .switch,
  .pagebuilder-accordion > .item.title.active > .switch:focus,
  .pagebuilder-accordion > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .pagebuilder-accordion > .item.content {
    background: white;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

@media only screen and (min-width: 768px) {
  .pagebuilder-mobile-only {
    display: none !important; } }

@media only screen and (max-width: 766px) {
  .pagebuilder-mobile-hidden {
    display: none !important; } }

.sidebar .block-addbysku .fieldset {
  margin: 0; }
  .sidebar .block-addbysku .fieldset .fields {
    position: relative; }
    .sidebar .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
      .sidebar .block-addbysku .fieldset .fields .field.sku {
        margin-right: -85px;
        padding-right: 90px;
        width: 100%; }
      .sidebar .block-addbysku .fieldset .fields .field.qty .qty + .mage-error {
        width: 80px; }
    .sidebar .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 6px; }

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
  margin-bottom: 20px; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left; }
    .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
      width: auto; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left; }

.sidebar .block-addbysku .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .sidebar .block-addbysku .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.add:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.add:active:before {
    color: inherit; }

.sidebar .block-addbysku .action.links {
  display: inline-block;
  margin: 20px 0 0; }

.sidebar .block-addbysku .action.reset {
  display: block;
  margin: 10px 0; }

.sidebar .block-addbysku .action.remove {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .sidebar .block-addbysku .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.remove:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:active:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:focus, .sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove.disabled, .sidebar .block-addbysku .action.remove[disabled],
  fieldset[disabled] .sidebar .block-addbysku .action.remove {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.block-banners .banner-item,
.block-banners-inline .banner-item {
  display: block; }

.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
  margin-bottom: 20px; }
  .block-banners .banner-item-content img,
  .block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto; }

.block-category-link.widget,
.block-product-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-product-link-inline.widget {
  margin: 0; }

.block.widget {
  padding: 0; }
  .block.widget .product-item-info {
    width: 100%; }
  .block.widget .pager {
    padding: 0; }
    .block.widget .pager .toolbar-amount {
      float: none;
      font-size: 12px; }
    .block.widget .pager .pages-item-previous {
      padding-left: 0; }
    .block.widget .pager .pages-item-next {
      position: relative; }
    .block.widget .pager .items {
      white-space: nowrap; }

@media only screen and (min-width: 640px) {
  .block.widget .products-grid .product-item,
  .page-layout-1column .block.widget .products-grid .product-item,
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 33.3333%; } }

@media only screen and (min-width: 768px) {
  .block.widget .products-grid .product-item {
    width: 33.3333%; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 25%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .block.widget .products-grid .product-item {
    width: 20%; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 24%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%; }
  .block.widget .products-grid .product-items {
    margin: 0;
    padding: 0; }
  .block.widget .products-grid .product-item {
    width: 25%;
    padding: 0; } }

@media only screen and (min-width: 768px) {
  .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none; }
  .sidebar .block.widget .pager .pages-item-next {
    padding: 0; }
    .sidebar .block.widget .pager .pages-item-next .action {
      margin: 0; } }

.columns .block-event {
  position: relative;
  z-index: 2; }

.block-event .block-content {
  position: relative; }
  .block-event .block-content > .action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3; }
    .block-event .block-content > .action.backward, .block-event .block-content > .action.forward {
      opacity: 0.5;
      overflow: hidden;
      width: 20px;
      display: inline-block;
      text-decoration: none; }
      .block-event .block-content > .action.backward > span, .block-event .block-content > .action.forward > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 55px;
        line-height: 30px;
        color: #a2a2a2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        margin-left: -15px; }
    .block-event .block-content > .action.backward {
      left: 0; }
    .block-event .block-content > .action.forward {
      right: 0; }
      .block-event .block-content > .action.forward:before {
        content: ""; }
      .block-event .block-content > .action.forward:before {
        margin-left: -20px; }
    .block-event .block-content > .action.inactive {
      cursor: default;
      display: none; }
    .block-event .block-content > .action:hover {
      opacity: 1; }

.block-event .slider-panel {
  overflow: hidden;
  position: relative;
  z-index: 2; }
  .block-event .slider-panel .slider {
    white-space: nowrap; }
    .block-event .slider-panel .slider .item {
      background: #f0f0f0;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      white-space: normal;
      width: 16.35%; }
      .sidebar-main .block-event .slider-panel .slider .item,
      .sidebar-additional .block-event .slider-panel .slider .item {
        width: 100%; }
      .block-event .slider-panel .slider .item img {
        max-width: 100%; }
      .block-event .slider-panel .slider .item .category-name {
        display: inline-block;
        font-size: 1.8rem;
        padding: 15px 5px 0;
        word-break: break-all; }
      .block-event .slider-panel .slider .item .ticker {
        margin: 0;
        padding: 0;
        list-style: none none; }
        .block-event .slider-panel .slider .item .ticker li {
          display: none;
          margin: 0 5px; }
        .block-event .slider-panel .slider .item .ticker .value {
          font-size: 2.6rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .ticker .label {
          display: block;
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .dates {
        font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .start,
        .block-event .slider-panel .slider .item .dates .end,
        .block-event .slider-panel .slider .item .dates .date {
          display: block; }
        .block-event .slider-panel .slider .item .dates .start:after {
          font-size: 1.8rem;
          content: '\2013';
          display: block; }
        .block-event .slider-panel .slider .item .dates .date {
          font-size: 1.8rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .time {
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .box-event .box-content {
        padding: 5px 0 10px; }
      .block-event .slider-panel .slider .item .box-event .box-title {
        display: block;
        font-weight: 300;
        margin: 10px 0 0; }
      .block-event .slider-panel .slider .item .action.show {
        display: block;
        padding: 0; }
        .block-event .slider-panel .slider .item .action.show .category.name {
          display: inline-block;
          padding: 30px 10px 0; }
        .block-event .slider-panel .slider .item .action.show img {
          display: block; }
      .block-event .slider-panel .slider .item .action.event {
        background-image: none;
        background: #ffffff;
        border: 1px solid #1a1a1a;
        border-bottom-width: 2px;
        transition: color background-color border-color 250ms ease;
        color: #1a1a1a;
        cursor: pointer;
        display: inline-block;
        font-family: "Myriad Pro Regular", sans-serif;
        font-weight: 700;
        padding: 7px 15px;
        width: 260px;
        font-size: 1.4rem;
        box-sizing: border-box;
        vertical-align: middle;
        margin-top: 15px; }
        .block-event .slider-panel .slider .item .action.event:focus, .block-event .slider-panel .slider .item .action.event:active {
          background: #1a1a1a;
          border: 1px solid #1a1a1a;
          border-bottom-width: 2px;
          color: #ffffff; }
        .block-event .slider-panel .slider .item .action.event:hover {
          background: #1a1a1a;
          border: 1px solid #1a1a1a;
          border-bottom-width: 2px;
          color: #ffffff; }
        .block-event .slider-panel .slider .item .action.event.disabled, .block-event .slider-panel .slider .item .action.event[disabled],
        fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
          opacity: 0.5;
          cursor: default;
          pointer-events: none; }
        .block-event .slider-panel .slider .item .action.event:hover {
          text-decoration: none; }

.block-static-block.widget,
.block-cms-link.widget {
  margin-bottom: 20px; }
  .links .block-static-block.widget, .links
  .block-cms-link.widget {
    margin-bottom: 0; }

.block-cms-link-inline.widget {
  margin: 0; }

.block-wishlist-search .form-wishlist-search {
  margin: 20px 0 0; }

.block-cms-hierarchy-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-cms-hierarchy-link-inline.widget {
  margin: 0; }

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px; }

.product.data.items > .item.title > .switch {
  box-sizing: content-box; }

html button, html .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper html .actions-toolbar > .action, html .action-gift,
html select,
html .cart.table-wrapper .actions-toolbar > .action,
html .cart.table-wrapper .action-gift,
html [type="reset"],
html [type="submit"],
html [type="button"], body button, body .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body .actions-toolbar > .action, body .action-gift,
body select,
body .cart.table-wrapper .actions-toolbar > .action,
body .cart.table-wrapper .action-gift,
body [type="reset"],
body [type="submit"],
body [type="button"] {
  appearance: none !important; }

/*
*
* Reset the static block widget so the breadcrumbs does not look off
*
*/
.top-container {
  padding: 0;
  z-index: 1;
  max-width: none; }
  .top-container .block-static-block.widget {
    margin: 0;
    width: 100%;
    max-width: none; }

input[type="radio"]:checked {
  background: #2196f3; }

input[type="radio"] {
  background: #ffffff;
  border: 2px solid #3b3f49;
  width: 1rem;
  height: 1rem;
  border-radius: 50%; }

.footer-logo .nav-toggle {
  display: none !important; }

.footer-logo .logo {
  display: block;
  margin: 2rem 0; }

/**
*
* Newsletter block/componenet
*
*/
.block .field.newsletter div.control:before {
  line-height: 44px; }

#newsletter {
  height: 44px; }

/*
 see Snowdog/blank/styles/mixins/_media-queries.scss
 screen range for tablets/halfscreen
*/
@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .header.content {
    padding: 0 1rem !important; }
  .page-wrapper .breadcrumbs,
  .page-header .panel.wrapper .header.panel {
    padding-left: 1rem;
    padding-right: 1rem; }
  .breadcrumbs {
    padding-top: 1rem; }
  .page-main {
    padding: 0 1rem !important; } }

@media only screen and (min-width: 768px) and (max-width: 1240px) {
  .block.block-search {
    order: 100;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 3rem;
    padding: 0;
    max-height: 40px;
    height: 40px; }
    .block.block-search #search {
      height: 40px; } }

@media only screen and (min-width: 440px) and (max-width: 639px) {
  .products-grid .product-item.item.product {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  /*
     *
     *  Patch for mobile modal slide error
     *  @see _modals.scss
     *
     */
  body .custom-slide {
    left: 0 !important; }
  #opc-sidebar .minicart-items {
    background: white; }
  #opc-sidebar .product-item > .product {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem; }
    #opc-sidebar .product-item > .product .product-item-details {
      padding-left: 0; } }

.modal-popup._show, .modal-slide._show {
  background: rgba(0, 0, 0, 0.2); }

.polarcore__full-width-top-container .widget.block-static-block {
  padding: 0;
  margin: 0;
  margin-bottom: 0; }

.polarcore__full-width-top-container img {
  width: 100%; }

@media only screen and (max-width: 767px) {
  header .header.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    min-height: 50px; }
    header .header.content > .nav-toggle {
      position: static;
      padding: 0;
      margin: 0;
      display: block; }
    header .header.content > .minicart-wrapper {
      position: static;
      padding: 0;
      margin: 0; }
    header .header.content > .logo {
      margin: 0rem auto 0rem 1rem;
      padding: 0rem; }
      header .header.content > .logo img {
        width: 110px; }
    header .header.content > .block.block-search {
      width: 100%;
      margin-top: 0rem;
      order: 25;
      z-index: 9; }
      header .header.content > .block.block-search .control {
        border-width: 0; }
      header .header.content > .block.block-search .field.search {
        position: relative; }
        header .header.content > .block.block-search .field.search .label {
          top: 0.1rem; }
    header .header.content .polarcore-header-links .minicart-wrapper {
      margin-top: 0rem; }
  header .nav-toggle:before {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: transparent;
    box-shadow: 0 0px 0 4px transparent; } }

.rating-summary .rating-label {
  padding-right: 0.5rem; }

.rating-summary .rating-result {
  min-width: 72px;
  margin-left: 0 !important; }

/**
*
* PolarCore Grid/Listings enhancements
*
*/
.toolbar select.sorter-options {
  text-align: center;
  text-align-last: center; }

/**
*
* Mobile
*
*/
@media only screen and (max-width: 767px) {
  .filter-title strong {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; }
  .toolbar select.sorter-options {
    height: 50px;
    width: calc(100% - 3rem); } }

/*
*
* Common Product View Extensions
*
*/
.product-info-main .product-reviews-summary {
  padding: 0; }

.product-info-main .data.table.additional-attributes {
  background-color: #f7f7f7;
  margin-top: 1.5rem; }
  .product-info-main .data.table.additional-attributes thead tr th,
  .product-info-main .data.table.additional-attributes thead tr td,
  .product-info-main .data.table.additional-attributes tbody tr th,
  .product-info-main .data.table.additional-attributes tbody tr td {
    padding: 1rem; }
    .product-info-main .data.table.additional-attributes thead tr th:nth-child(2),
    .product-info-main .data.table.additional-attributes thead tr td:nth-child(2),
    .product-info-main .data.table.additional-attributes tbody tr th:nth-child(2),
    .product-info-main .data.table.additional-attributes tbody tr td:nth-child(2) {
      display: none; }
  .toprode .product-info-main .data.table.additional-attributes,
  .toprochfr .product-info-main .data.table.additional-attributes,
  .toprochde .product-info-main .data.table.additional-attributes {
    background: none; }
    .toprode .product-info-main .data.table.additional-attributes thead,
    .toprochfr .product-info-main .data.table.additional-attributes thead,
    .toprochde .product-info-main .data.table.additional-attributes thead {
      display: none; }
    .toprode .product-info-main .data.table.additional-attributes td,
    .toprochfr .product-info-main .data.table.additional-attributes td,
    .toprochde .product-info-main .data.table.additional-attributes td {
      padding: 0; }
    .toprode .product-info-main .data.table.additional-attributes a,
    .toprochfr .product-info-main .data.table.additional-attributes a,
    .toprochde .product-info-main .data.table.additional-attributes a {
      padding: 1rem;
      background-color: #00C853;
      border-color: #00af48;
      color: #ffffff;
      text-transform: uppercase; }
      .toprode .product-info-main .data.table.additional-attributes a:hover,
      .toprochfr .product-info-main .data.table.additional-attributes a:hover,
      .toprochde .product-info-main .data.table.additional-attributes a:hover {
        background-color: #00af48;
        text-decoration: none; }
      .toprode .product-info-main .data.table.additional-attributes a:active,
      .toprochfr .product-info-main .data.table.additional-attributes a:active,
      .toprochde .product-info-main .data.table.additional-attributes a:active {
        background-color: #00af48; }

.product-info-main .bundle-actions {
  margin-top: 1.5rem; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  width: 100%; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    border-radius: 0; }
  .product-info-main .box-tocart .fieldset,
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .fieldset,
  .product-options-bottom .box-tocart .actions {
    display: flex;
    width: 100%;
    padding-top: 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: auto; }
    .product-info-main .box-tocart .field.qty .label,
    .product-options-bottom .box-tocart .field.qty .label {
      display: none; }

.product-social-links .action.towishlist span {
  display: flex;
  justify-content: center;
  align-items: center; }

.product-social-links .action.towishlist span:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  content: "";
  font-size: 1em !important;
  transform: scale(1.5);
  padding-right: 0.5rem; }

.product.info.detailed .data.item.title {
  border: 0;
  margin: 1rem 2rem 0; }
  .product.info.detailed .data.item.title .switch {
    position: relative;
    font-size: 20px;
    color: #1a1a1a; }
    .product.info.detailed .data.item.title .switch .counter {
      display: none; }
  .product.info.detailed .data.item.title.active {
    border-bottom: 3px solid #11525d; }
    .product.info.detailed .data.item.title.active .switch:after {
      transform: rotate(180deg); }

.product.info.detailed h2 {
  width: 20%; }

.product.info.detailed .data.item.content {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  border: 0;
  margin: auto; }
  .product.info.detailed .data.item.content p {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .product.info.detailed .data.item.content p,
  .product.info.detailed .data.item.content span,
  .product.info.detailed .data.item.content strong {
    line-height: 1.2em; }
  .product.info.detailed .data.item.content#reviews\.tab {
    margin-top: 0; }
  .product.info.detailed .data.item.content[aria-hidden="false"] {
    display: block; }

.product.info.detailed .review-add,
.product.info.detailed #product-review-container {
  padding: 1rem;
  width: 100%; }

/*
*
* Mobile only
*
*/
@media only screen and (max-width: 1023px) {
  .product.info.detailed .data.item.content {
    width: 100% !important; }
    .product.info.detailed .data.item.content h2 {
      width: 100%; }
  .product.info.detailed #reviews {
    flex-direction: column;
    margin-top: 0; }
    .product.info.detailed #reviews .review-add {
      padding-top: 0; }
      .product.info.detailed #reviews .review-add .action.submit.primary span {
        font-size: 14px; } }

/*
*
* Common Footer
*
*/
.footer.content {
  display: flex;
  flex-wrap: wrap; }
  .footer.content > * {
    flex-grow: 1;
    padding: 1rem 2rem; }
    .footer.content > * .block-static-block {
      display: flex;
      flex-wrap: wrap; }
      .footer.content > * .block-static-block .footer-social li {
        padding-bottom: 1rem; }
      .footer.content > * .block-static-block .footer-social a img {
        width: 20px;
        vertical-align: middle;
        margin-right: 1rem; }
      .footer.content > * .block-static-block .footer-social a:before {
        display: none; }
    .footer.content > * h2 {
      width: 100%;
      color: #ffffff;
      padding-bottom: 2rem;
      font-size: 14px; }
    .footer.content > * li {
      padding-bottom: 0.5rem;
      font-size: 12px; }
    .footer.content > * a {
      font-weight: 300;
      color: #f0f0f0; }
      .footer.content > * a:before {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        content: "";
        vertical-align: middle;
        font-size: 20px;
        margin-left: -5px; }
  .footer.content .full-width {
    width: 100%; }
  .footer.content .customer-service .block-static-block ul {
    width: 50%; }

.page-footer {
  color: #f0f0f0; }
  .page-footer .copyright {
    padding: 20px;
    border-top: 1px solid #666666; }

/*
*
* Footer Mobile up
*
*/
@media only screen and (min-width: 768px) {
  .page-footer .editorial {
    border-left: 1px solid #666666;
    border-right: 1px solid #666666; } }

.wishlist-link {
  margin-left: 1rem; }
  .wishlist-link .wishlist-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit; }
  .wishlist-link .wishlist-icon {
    line-height: 10px; }
    .wishlist-link .wishlist-icon:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 26px; }
  .wishlist-link .wishlist-text {
    font-size: 10px;
    font-weight: 500; }

.products-grid.wishlist .product-item .price-box {
  justify-content: center; }

.sidebar .block-wishlist .product-items {
  padding: 0; }
  .sidebar .block-wishlist .product-items .product-item {
    width: 100%; }
    .sidebar .block-wishlist .product-items .product-item .price-box {
      text-align: right; }
    .sidebar .block-wishlist .product-items .product-item-name {
      text-align: left; }

.sidebar .block-wishlist .actions-primary .action.primary.tocart {
  margin-left: auto; }

.polarcore-header-links {
  display: flex; }

.wishlist-link {
  display: none;
  margin-left: 1rem; }
  .wishlist-link a:hover {
    text-decoration: none; }
    .wishlist-link a:hover .wishlist-text {
      text-decoration: underline; }
  .wishlist-link .wishlist-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit; }
  .wishlist-link .wishlist-icon {
    line-height: 10px; }
    .wishlist-link .wishlist-icon:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 26px; }
  .wishlist-link .wishlist-text {
    font-size: 10px;
    font-weight: 500; }
  @media only screen and (min-width: 768px) {
    .wishlist-link {
      display: block; } }

.page-main h1,
.page-main h2,
.page-main h3 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem; }

.cms-index-index .columns .column.main {
  margin-top: -4px; }
  .cms-index-index .columns .column.main .tocart {
    background: #fff;
    color: #000;
    border-width: 1px 1px 2px 1px;
    border-color: #1c5275; }

.columns .column.main {
  padding-bottom: 10rem; }

.field-tooltip span#tooltip-label {
  display: none; }

body button, body .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body .actions-toolbar > .action, body .action-gift,
body a.button {
  text-transform: none; }

/* Remove price filter on INT, SE, DK & NL storeviews */
body.int #layered-filter-block .filter-options .price, body.topronl #layered-filter-block .filter-options .price, body.toprose #layered-filter-block .filter-options .price, body.toprodk #layered-filter-block .filter-options .price, body.topronlfr #layered-filter-block .filter-options .price {
  display: none; }

/* weird scrolling issue (mainly mobile) */
.page-products {
  overflow-x: hidden; }

@media screen and (max-width: 767px) {
  .hide_on_phone {
    display: none; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hide_on_tablet {
    display: none; } }

@media screen and (min-width: 1024px) {
  .hide_on_desktop {
    display: none !important; } }

/* Mobile */
@media only screen and (max-width: 767px) {
  #maincontent .columns .column.main {
    padding-bottom: 2rem; } }

.page-bottom .content .block.newsletter {
  width: auto;
  position: relative;
  margin: -110px 0 0 0; }
  .page-bottom .content .block.newsletter .title {
    display: none; }
  .page-bottom .content .block.newsletter .content {
    width: 100%;
    max-width: 700px;
    border-radius: 3px;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    z-index: 1;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2); }
  .page-bottom .content .block.newsletter .form.subscribe {
    display: flex;
    display: -ms-flexbox;
    align-items: flex-end;
    padding: 16px 30px 30px 30px; }
    .page-bottom .content .block.newsletter .form.subscribe .field.newsletter {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .page-bottom .content .block.newsletter .form.subscribe .field.newsletter .material-icons, .page-bottom .content .block.newsletter .form.subscribe .field.newsletter body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .action-edit:before, body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .page-bottom .content .block.newsletter .form.subscribe .field.newsletter .action-edit:before {
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 15px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
        font-size: 45px;
        margin-top: -4rem;
        margin-bottom: 2rem;
        background-color: #fff; }
      .page-bottom .content .block.newsletter .form.subscribe .field.newsletter h2 {
        margin: 0 0 0.5rem 0;
        font-weight: 600; }
      .page-bottom .content .block.newsletter .form.subscribe .field.newsletter p {
        margin-bottom: 1rem;
        text-align: left;
        font-weight: 300;
        font-size: 15px;
        width: 100%; }
      .page-bottom .content .block.newsletter .form.subscribe .field.newsletter .control {
        display: flex; }
        .page-bottom .content .block.newsletter .form.subscribe .field.newsletter .control .action.subscribe.primary {
          margin-left: 10px;
          border-radius: 3px;
          padding: calc(1rem - 4px) 1rem; }

.page-bottom {
  background-color: #474747; }
  .page-wrapper .page-bottom {
    width: 100%;
    margin: 0;
    max-width: none; }

.page-footer {
  background-color: #474747; }
  .page-footer .footer.content {
    background-color: #474747;
    flex-grow: 1; }
    .page-footer .footer.content .full-width {
      color: #fff; }
      .page-footer .footer.content .full-width .widget.block.block-static-block {
        justify-content: center; }
        .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container {
          display: flex;
          width: 100%; }
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container ul:last-child {
            padding-right: 0; }
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container li {
            font-size: unset; }
            .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container li a {
              font-size: 1.2em; }
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .social-media,
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .extra-links {
            grid-column: 3 / span 2;
            -ms-grid-column: 3;
            -ms-grid-column-span: 2;
            text-align: center; }
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .social-media {
            display: grid;
            display: -ms-grid;
            grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
            align-items: center; }
            .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .social-media a {
              padding: 0 1rem; }
            .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .social-media a:first-child {
              text-align: right; }
            .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .social-media a:last-child {
              text-align: left; }
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container a:before {
            display: none; }

.copyright {
  background: #fff;
  color: #000; }

/* MOBILE */
@media only screen and (max-width: 767px) {
  .page-bottom {
    padding-left: 0;
    padding-right: 0; }
    .page-bottom .content .block.newsletter {
      margin: 0; }
      .page-bottom .content .block.newsletter .content {
        width: 100%; }
        .page-bottom .content .block.newsletter .content .control {
          display: block !important; }
          .page-bottom .content .block.newsletter .content .control .subscribe {
            margin-left: 0 !important;
            margin-top: 0.5rem; }
  .page-footer {
    margin-top: 0; }
    .page-footer .footer.content {
      border-top: none;
      padding-top: 5px; }
      .page-footer .footer.content .full-width .widget.block.block-static-block {
        margin: 0;
        justify-content: flex-start; }
        .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container {
          display: block; }
          .page-footer .footer.content .full-width .widget.block.block-static-block .footer-links-container .social-media {
            display: block;
            margin-top: 1.25rem;
            margin-bottom: 0.75rem; }
  .full-width .widget.block.block-static-block .footer-links-container {
    flex-wrap: wrap; } }

/* TABLET */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .page-bottom .content .block.newsletter .content {
    width: 60%; } }

.cms-index-index .columns .column.main {
  margin-top: -4px; }
  .cms-index-index .columns .column.main .tocart {
    background: #fff;
    color: #000;
    border-width: 1px 1px 2px 1px;
    border-color: #1c5275; }

.columns .column.main {
  padding-bottom: 10rem; }

.field-tooltip span#tooltip-label {
  display: none; }

body button, body .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body .actions-toolbar > .action, body .action-gift,
body a.button {
  text-transform: none; }

/* Remove price filter on INT, SE, DK & NL storeviews */
body.int #layered-filter-block .filter-options .price, body.topronl #layered-filter-block .filter-options .price, body.toprose #layered-filter-block .filter-options .price, body.toprodk #layered-filter-block .filter-options .price, body.topronlfr #layered-filter-block .filter-options .price {
  display: none; }

/* weird scrolling issue (mainly mobile) */
.page-products {
  overflow-x: hidden; }

@media screen and (max-width: 767px) {
  .hide_on_phone {
    display: none; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hide_on_tablet {
    display: none; } }

@media screen and (min-width: 1024px) {
  .hide_on_desktop {
    display: none !important; } }

/* Mobile */
@media only screen and (max-width: 767px) {
  #maincontent .columns .column.main {
    padding-bottom: 2rem; } }

.page-header {
  border: none;
  z-index: 99;
  background-color: transparent;
  transition: all 0.5s ease; }
  .page-header.header-background {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    animation: smoothScroll 500ms forwards;
    padding-bottom: 0.25rem;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    top: 0px; }
  .page-header .widget.block {
    margin: 0; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px); }
  100% {
    transform: translateY(0px); } }

/* breadcrumbs */
.page-wrapper .breadcrumbs {
  width: 100%;
  max-width: 100%;
  background: #f7f7f7;
  margin-bottom: 1rem;
  padding: 0.25rem; }
  .page-wrapper .breadcrumbs .items {
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem 0.25rem;
    display: flex;
    align-items: center; }
    .page-wrapper .breadcrumbs .items > li {
      font-size: 12px;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      padding-right: 0.25rem; }
      .page-wrapper .breadcrumbs .items > li:last-of-type strong {
        padding: 0; }

.header.content {
  padding: 0.5rem 0; }
  .header.content .logo {
    order: 1;
    padding-bottom: 0.5rem;
    max-width: 225px; }
  .header.content .header-links-static-block {
    display: flex;
    order: 4; }
    .header.content .header-links-static-block ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0; }
      .header.content .header-links-static-block ul li {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-right: 2px solid #808080;
        margin: 0;
        line-height: 1.4; }
        .header.content .header-links-static-block ul li a {
          font-size: 18px;
          color: #1a1a1a;
          padding: 0rem 0.75rem;
          vertical-align: middle; }
  .header.content .block.block-search {
    order: 2;
    margin-left: auto;
    margin-right: 1rem;
    max-width: 325px;
    width: 100%; }
    .header.content .block.block-search .input-text {
      height: auto;
      padding: 0.75rem; }
    .header.content .block.block-search #minisearch-input-top-search {
      background-color: transparent; }
    .header.content .block.block-search .control {
      border: none; }
      .header.content .block.block-search .control input:focus {
        box-shadow: none; }
    .header.content .block.block-search .actions button.action.search, .header.content .block.block-search .actions .cart.table-wrapper .actions-toolbar > .action.search, .cart.table-wrapper .header.content .block.block-search .actions .actions-toolbar > .action.search, .header.content .block.block-search .actions .action.search.action-gift {
      opacity: 1 !important; }
      .header.content .block.block-search .actions button.action.search:before, .header.content .block.block-search .actions .cart.table-wrapper .actions-toolbar > .action.search:before, .cart.table-wrapper .header.content .block.block-search .actions .actions-toolbar > .action.search:before, .header.content .block.block-search .actions .action.search.action-gift:before {
        color: #595959;
        line-height: unset; }
  .header.content .header-links-meganav {
    order: 3;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    border-left: 2px solid #808080;
    border-right: 2px solid #808080; }
    .header.content .header-links-meganav > ul {
      padding: 0;
      margin: 0; }
      .header.content .header-links-meganav > ul > li {
        margin: 0;
        line-height: 1.4; }
        .header.content .header-links-meganav > ul > li a:after {
          line-height: 0; }
    .header.content .header-links-meganav .meganav-wrapper {
      border-top: none; }
  .header.content .polarcore-header-links {
    order: 5;
    z-index: 9; }
    .header.content .polarcore-header-links .wishlist-link {
      display: none; }
    .header.content .polarcore-header-links .account-link {
      display: none; }
  .header.content .minicart-wrapper {
    padding-left: 1rem;
    margin: 0; }
    .header.content .minicart-wrapper .action.showcart {
      display: flex;
      flex-direction: unset;
      padding-right: 6px; }
      .header.content .minicart-wrapper .action.showcart:before {
        width: 27px;
        height: 27px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 27' %3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        content: ''; }
      .header.content .minicart-wrapper .action.showcart .text {
        display: none; }
      .header.content .minicart-wrapper .action.showcart .counter.qty {
        display: flex;
        top: -8px;
        right: 2px;
        border-radius: 4px;
        width: 17px;
        height: 17px;
        background: #40c353;
        transform: unset; }
        .header.content .minicart-wrapper .action.showcart .counter.qty.empty {
          display: none; }
        .header.content .minicart-wrapper .action.showcart .counter.qty .counter-number {
          display: flex;
          width: 100%;
          height: 100%;
          text-align: center;
          justify-content: center;
          align-items: center;
          margin-top: 0.25rem;
          font-size: 12px; }

#tooltip {
  top: 3rem; }

@media only screen and (min-width: 1024px) and (max-width: 1258px) {
  body .header.content .logo img {
    width: 18vw; }
  body .header.content .header-links-static-block ul li {
    padding-right: 1.1rem;
    padding-left: 1.1rem; }
    body .header.content .header-links-static-block ul li a {
      font-size: 16px;
      padding: 0.6rem; }
  body .header.content .header-links-meganav {
    padding-right: 1.6rem;
    padding-left: 1.6rem; }
    body .header.content .header-links-meganav .meganav-trigger {
      font-size: 16px; } }

@media only screen and (min-width: 768px) and (max-width: 1258px) {
  .panel.header .switcher {
    margin-right: 0; }
  .header.content {
    display: flex;
    justify-content: flex-end; }
    .header.content .logo {
      margin-right: auto; }
      .header.content .logo img {
        width: 14vw; }
    .header.content .header-links-static-block ul li {
      padding-left: 0.8rem;
      padding-right: 0.8rem; }
      .header.content .header-links-static-block ul li a {
        font-size: 13px;
        padding: 0.3rem; }
    .header.content .header-links-meganav {
      padding-right: 1.3rem;
      padding-left: 1.3rem; }
      .header.content .header-links-meganav ul > li {
        line-height: 1.3; }
      .header.content .header-links-meganav .meganav-trigger {
        font-size: 13px;
        vertical-align: middle; }
        .header.content .header-links-meganav .meganav-trigger:after {
          font-size: 19px; }
    .header.content .block.block-search {
      max-width: 23vw;
      margin-left: 0.5rem;
      height: auto;
      min-height: unset;
      margin-right: 0; }
      .header.content .block.block-search #algolia-searchbox #algolia-glass {
        bottom: 6px; }
        .header.content .block.block-search #algolia-searchbox #algolia-glass:before {
          width: 19px;
          height: 19px; }
      .header.content .block.block-search #algolia-searchbox .clear-cross {
        bottom: 8px;
        width: 19px;
        height: 19px; }
      .header.content .block.block-search #algolia-searchbox input#search {
        height: 34px; }
    .header.content .minicart-wrapper .action.showcart {
      padding-left: 0.5rem;
      padding-right: 0; }
      .header.content .minicart-wrapper .action.showcart:before {
        width: 22px;
        height: 22px; } }

/* MOBILE */
@media only screen and (max-width: 767px) {
  /* breadcrumbs */
  .page-wrapper .breadcrumbs {
    margin-top: 20px; }
    .page-wrapper .breadcrumbs .items {
      padding-left: 1rem;
      padding-right: 1rem; }
  #maincontent {
    padding-top: 0.5rem; }
  .header.content .header-links-static-block {
    display: none; }
  .header.content .minicart-wrapper .action.showcart {
    padding-right: 8px; }
  .header.content .block.block-search {
    width: 100%;
    max-width: none;
    margin-right: 0;
    margin-top: 0;
    min-height: 43px; }
    .header.content .block.block-search .control #search {
      background: transparent;
      padding: 0;
      padding: 1.25rem;
      margin: auto 0.25rem; }
    .header.content .block.block-search .field.search label {
      top: 3px !important; } }

.filter:not(.active) .filter-subtitle {
  display: none; }

.filter:not(.active) .block-title {
  border-bottom: none; }

.filter:not(.active) .filter-title strong {
  width: 100%; }

.filter-current .action.remove {
  left: unset; }

.filter-options-item {
  border: 0;
  border-top: 1px solid #bababa; }

.filter.active .filter-title strong {
  margin: 0; }

/* Desktop */
@media only screen and (min-width: 768px) {
  .header-links-meganav > ul {
    list-style: none; }
  .header-links-meganav .meganav-trigger {
    text-decoration-line: none;
    font-size: 18px;
    color: #1a1a1a;
    vertical-align: middle; }
    .header-links-meganav .meganav-trigger:after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      position: relative;
      top: 5px;
      left: 5px;
      transition: transform 500ms ease; }
    .header-links-meganav .meganav-trigger.open:after {
      transform: rotate(180deg); }
  .header-links-meganav .meganav-wrapper {
    display: none;
    position: absolute;
    width: auto;
    left: 0;
    top: 100%;
    margin-top: 0.25rem;
    z-index: 15;
    background: #ffffff;
    border-top: 1px solid #e2e2e2; }
    .header-links-meganav .meganav-wrapper.meganav-open {
      display: block; }
      .header-links-meganav .meganav-wrapper.meganav-open .meganav {
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25); }
    .header-links-meganav .meganav-wrapper .category-image {
      margin: 0;
      height: calc(100% - 2rem);
      overflow: hidden;
      text-align: center;
      border-top: 1px solid #161d36;
      margin-top: 0.9rem;
      padding-top: 0.9rem;
      display: flex;
      align-items: center; }
      .header-links-meganav .meganav-wrapper .category-image img {
        height: auto;
        max-height: 100%;
        width: auto;
        max-width: 100%;
        margin: 0 auto; }
    .header-links-meganav .meganav-wrapper .meganav__list-root {
      flex-direction: column;
      width: 300px;
      margin-left: 0; }
      .header-links-meganav .meganav-wrapper .meganav__list-root a.meganav__anchor.level0 {
        color: #ffffff !important;
        background-color: #1c5275; }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0 {
        padding: 0;
        width: 100%; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0[data-id="61"] {
          display: none; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0 > a {
          display: flex;
          justify-content: flex-start;
          text-decoration-line: none;
          width: 100%;
          font-size: 16px;
          color: #1a1a1a;
          padding: 0.5rem 1rem;
          min-height: 40px;
          display: flex;
          align-items: center;
          border-top: 1px solid #fff; }
          .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0 > a:after {
            font-size: 20px;
            margin: -6px 0; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0:not(.has-children) {
          border: none; }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level1 > a {
        text-decoration-line: none;
        font-size: 16px;
        color: #1a1a1a;
        font-weight: bold;
        display: block;
        height: 100%;
        padding-top: 0; }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level2 > a {
        text-decoration-line: none;
        font-size: 14px;
        color: #1a1a1a; }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children > a {
        position: relative;
        font-size: 16px; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children > a:first-of-type {
          border-top: none; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children > a:after {
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          /* Preferred icon size */
          display: inline-block;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga';
          content: "";
          position: absolute;
          top: 15px;
          right: 10px; }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children.meganav__item--open {
        border-bottom: 2px solid transparent; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children.meganav__item--open > a:after {
          transform: rotateZ(-90deg); }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1 {
        display: none; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open {
          /* changing menu to flex-based since Grid doesn't work in Internet Explorer */
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          width: 1140px;
          max-width: 76vw;
          position: absolute;
          top: 0;
          left: 300px;
          right: 0;
          margin: 0;
          border-top: 0;
          border-left: 1px solid #e2e2e2;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          padding: 1rem;
          height: auto;
          min-height: 100%; }
          .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open li {
            margin: 0rem 1.5rem;
            padding-top: 0;
            padding-bottom: 0;
            max-width: 20%;
            /* max-width not working in IE */
            width: 20%; }
      .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open .meganav__item.level1.has-children {
        flex-grow: 1; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open .meganav__item.level1.has-children > a {
          display: block;
          height: auto;
          border-bottom: 1px solid #1a1a1a;
          margin-bottom: 1rem; }
        .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open .meganav__item.level1.has-children ul {
          -ms-grid-columns: 1fr;
          grid-template-columns: 1fr; }
          .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open .meganav__item.level1.has-children ul li {
            margin: 0;
            width: 100%;
            display: block;
            max-width: none; }
            .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open .meganav__item.level1.has-children ul li a {
              line-height: 2; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header-links-meganav .meganav-wrapper .meganav__list-root {
    width: 28vw; }
    .header-links-meganav .meganav-wrapper .meganav__list-root .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open {
      width: 72vw;
      left: 28vw; } }

@media only screen and (max-width: 767px) {
  .header-links-meganav > ul {
    display: none; }
  .header-links-meganav .category-image {
    display: none; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list-root {
    right: 0;
    overflow-y: auto;
    height: 100vh; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__item--back {
    display: flex;
    justify-content: flex-end;
    font-size: 0;
    color: transparent;
    padding-bottom: 2rem; }
    .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__item--back:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "clear";
      font-size: 25px;
      color: #000; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__item--title {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    font-size: 20px; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list-root .meganav__anchor.level0 {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ffffff;
    text-decoration: none;
    color: #1a1a1a;
    background: #ffffff; }
    .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list-root .meganav__anchor.level0:after {
      transform: rotateZ(90deg);
      font-size: 20px; }
    .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list-root .meganav__anchor.level0 > a:last-of-type {
      border-bottom: none; }
    .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list-root .meganav__anchor.level0.meganav__list--open:after {
      transform: rotateZ(270deg); }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list.level1 > li a {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    font-size: 1em;
    color: #000;
    text-decoration-line: none;
    padding: 1rem 2rem;
    line-height: 1; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list.level1 .meganav__item:not(.level1):not(.level2):not(.meganav__item--back):not(.meganav__item--title) {
    display: none; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list.level1 .meganav__anchor.level1:after {
    transform: rotateZ(90deg);
    font-size: 20px; }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list.level1 .meganav__anchor.level1.meganav__list--open:after {
    transform: rotateZ(270deg); }
  .header-links-meganav .meganav-wrapper .meganav--mobile .meganav__list.level2 > li a {
    padding-left: 3rem; }
  .header-links-meganav .meganav-wrapper .meganav__list--open + .meganav__list {
    transform: none;
    position: static;
    transition: none; }
  .header-links-meganav .meganav-wrapper .meganav__list {
    transition: none; }
    .header-links-meganav .meganav-wrapper .meganav__list .meganav__item--back,
    .header-links-meganav .meganav-wrapper .meganav__list .meganav__item--title {
      display: none !important; } }

.meganav .category-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem; }

/* Obfuscator */
.obfuscator {
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 30;
  visibility: hidden;
  transition: background 0.2s; }
  .obfuscator.is-visible {
    visibility: visible; }
  @media only screen and (max-width: 767px) {
    .obfuscator {
      top: 0;
      height: 100%;
      display: none; } }

/* START OF MODULE REWRITES */
.product-item {
  width: 100%;
  border: none; }
  .product-item .product-item-actions .action.tocart.primary {
    transition: all 200ms ease;
    background: #1c5275;
    color: #fff;
    border-bottom: none;
    padding: 1rem 2rem;
    width: 100%;
    line-height: normal; }
  .product-item-link {
    white-space: normal !important;
    text-overflow: none;
    text-align: left; }
  .product-item-details {
    justify-content: space-between; }
    .product-item-details__top {
      display: flex;
      flex-direction: column;
      flex: 1; }
  .product-item-name {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    padding-bottom: 10px;
    margin-top: 0.5rem; }
  .product-item-description {
    font-size: 14px;
    padding-bottom: 5px;
    padding-right: 0;
    margin: 0; }
  .product-item-actions .button-link {
    width: 100%; }
  .product-item .product-reviews-summary .reviews-actions {
    display: flex; }
  .product-item .price-box {
    justify-content: flex-start;
    order: 20;
    margin-top: auto;
    margin-bottom: 1rem; }
    .product-item .price-box .exclusive-tax-wrapper {
      order: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0.7rem; }
      .product-item .price-box .exclusive-tax-wrapper .extra-text {
        margin-left: 0.5rem;
        font-weight: 600; }
    .product-item .price-box .price {
      font-size: 20px;
      font-weight: 700; }
  .product-item .customergroup-b2b-other-price {
    display: none; }
  .product-item .action.tocompare {
    display: none; }

.products {
  margin: 30px -0.5rem; }

.category-view .page-main h1 {
  margin-top: 7.5rem; }

/* remove buy button from product list in WPC */
.page-layout-1column .wpcloud-root .product-items {
  padding: 0;
  justify-content: space-between; }
  .page-layout-1column .wpcloud-root .product-items .product-item {
    border-right: 1px solid #c9c9c9;
    width: 24%;
    padding-right: 1rem; }
    .page-layout-1column .wpcloud-root .product-items .product-item-actions {
      display: none; }
    .page-layout-1column .wpcloud-root .product-items .product-item-link {
      white-space: normal;
      text-align: left; }
    .page-layout-1column .wpcloud-root .product-items .product-item:last-of-type {
      border-right: none; }

.toolbar-products {
  text-align: left; }
  .toolbar-products .toolbar-sorter {
    display: none; }

.products-grid.grid .product-items {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: inherit; }
  .products-grid.grid .product-items .product-item {
    display: flex;
    padding: 1rem; }
    .products-grid.grid .product-items .product-item-details .vat-relief {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative; }
      .products-grid.grid .product-items .product-item-details .vat-relief .price {
        font-size: 20px;
        font-weight: 700;
        margin-right: 0.5rem; }
      .products-grid.grid .product-items .product-item-details .vat-relief .vatRelief {
        font-size: 14px;
        margin: 0; }
      .products-grid.grid .product-items .product-item-details .vat-relief .vatReliefPopup {
        top: 2.25rem; }
    .products-grid.grid .product-items .product-item-details .vat-relief-other-price {
      display: none; }
    .products-grid.grid .product-items .product-item .product-item-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 100%; }
      .products-grid.grid .product-items .product-item .product-item-info .product-item-photo {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center; }

.products-grid.grid .product-item-inner .product-item-actions {
  display: block; }

.products-grid.grid .toolbar .field.limiter {
  padding-right: 0; }

.products-grid.grid .toolbar-sorter #sorter {
  height: 40px;
  line-height: 20px; }

.products-grid .products.list.items.product-items .item.product.product-item {
  border-right: 1px solid #c9c9c9; }

.products-grid .products.list.items.product-items .product-item {
  border-bottom: 0; }

.products-grid .product-item-details {
  display: flex;
  flex-grow: 1;
  flex-direction: column; }
  .products-grid .product-item-details .product-item-inner {
    margin-top: auto; }
  .products-grid .product-item-details__top .b2b-category-price {
    display: none; }

.products-grid .filter-content .filter-current .items {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .products-grid .filter-content .filter-current .items .item {
    border: 1px solid #5e5e5e;
    border-radius: 15px;
    padding-right: 2px; }
    .products-grid .filter-content .filter-current .items .item .filter-label {
      display: none; }
    .products-grid .filter-content .filter-current .items .item .action.remove {
      position: static;
      padding-bottom: 2px;
      padding-right: 5px; }
      .products-grid .filter-content .filter-current .items .item .action.remove:before {
        color: #fff;
        background-color: #474747;
        border: 1px solid #474747;
        border-radius: 12px;
        margin-left: 10px;
        margin-bottom: 1px;
        padding-left: 4px;
        font-size: 18px;
        font-weight: 700; }

.products-grid .product-reviews-summary {
  display: flex;
  margin-left: 0.5rem; }
  .products-grid .product-reviews-summary .reviews-actions {
    margin-top: 2px; }

.products-grid .price-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse; }
  .products-grid .price-box .old-price {
    position: initial !important; }
    .products-grid .price-box .old-price .price {
      font-size: 20px;
      position: initial !important;
      margin-right: 0.5rem; }
  .products-grid .price-box .special-price .price {
    font-weight: initial; }

@media only screen and (min-width: 768px) {
  .category-view .page-main h1 {
    margin-top: 0.5rem; }
  .product-item {
    width: 50%; }
    .product-item .price-box {
      order: 10; }
      .product-item .price-box .price {
        padding-left: 0; }
    .product-item .product-item-actions {
      order: 20; }
      .product-item .product-item-actions .actions-primary {
        display: flex; }
      .product-item .product-item-actions .action.tocart.primary {
        margin-left: 0; } }

/* Desktop */
@media only screen and (min-width: 1024px) {
  .products-grid .product-item {
    width: 30%; }
  .page-products .products-grid .product-item {
    width: 33.33%;
    margin-left: 0px; }
    .page-products .products-grid .product-item .product-item-actions {
      order: 20; }
      .page-products .products-grid .product-item .product-item-actions .action.tocart.primary {
        background: transparent;
        color: #1c5275; }
        .page-products .products-grid .product-item .product-item-actions .action.tocart.primary:hover {
          background: #1c5275;
          color: #fff; } }

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .page-layout-1column .column.main .product-info-main {
    width: 52%; }
  .page-layout-1column .column.main .product.media {
    width: 48%; }
  .page-products .products-grid .product-item {
    width: 50%; }
  .products-grid.grid .product-items.widget-product-grid .product-item {
    width: 33.33%;
    padding: 1rem; }
    .products-grid.grid .product-items.widget-product-grid .product-item:nth-of-type(1n+4) {
      display: none; }
    .products-grid.grid .product-items.widget-product-grid .product-item-details .vat-relief {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative; }
      .products-grid.grid .product-items.widget-product-grid .product-item-details .vat-relief .price {
        font-size: 20px;
        font-weight: 700; }
      .products-grid.grid .product-items.widget-product-grid .product-item-details .vat-relief .vatRelief {
        font-size: 14px; }
      .products-grid.grid .product-items.widget-product-grid .product-item-details .vat-relief .vatReliefPopup {
        top: 2.25rem; } }

/* Mobile */
@media only screen and (max-width: 767px) {
  .products-grid.grid .product-items .product-item-details .vat-relief .price {
    font-size: 14px; }
  .products-grid.grid .product-items .product-item-details .vat-relief .vatRelief {
    font-size: 10px; }
    .products-grid.grid .product-items .product-item-details .vat-relief .vatRelief:after {
      display: none; }
  .products-grid.grid .product-items .product-item-details .vat-relief .vatReliefPopup {
    top: 2.75rem; }
  .products-grid .filter .filter-title strong {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #474747;
    border-bottom: 2px solid #474747; }
  .products-grid .filter .filter-content .filter-current {
    margin-top: 20px; }
  .products-grid .toolbar-products .toolbar-sorter.sorter {
    display: none; }
  .products-grid .product-item-details__top .product-item-name .product-item-link {
    font-size: 14px; }
  .products-grid .product-item .product-reviews-summary {
    display: flex;
    order: 20;
    padding-left: 0.5rem; }
    .products-grid .product-item .product-reviews-summary .rating-summary {
      margin: 0; }
    .products-grid .product-item .product-reviews-summary .reviews-actions {
      margin-top: 2px; }
      .products-grid .product-item .product-reviews-summary .reviews-actions a {
        font-size: 10px; }
  .products-grid .product-item .price-box .old-price {
    color: #474747;
    position: relative;
    top: 10px; }
  .products-grid .product-item .price-box .price {
    font-size: 14px;
    padding-left: 0; }
  .page-layout-1column .wpcloud-root .product-items .product-item {
    width: 49%;
    padding-right: 1rem; }
    .page-layout-1column .wpcloud-root .product-items .product-item:nth-child(even) {
      border-right: none; } }

.data.item.title {
  margin: 0;
  padding: 1rem;
  font-size: 17px; }
  .data.item.title a {
    color: #1a1a1a;
    font-weight: 400; }
  .data.item.title.active {
    border-bottom: 3px solid #0072a0; }

.data.item.navigation {
  width: 100%; }
  .data.item.navigation .inner {
    display: flex;
    justify-content: center;
    background: #fff;
    width: 100%; }
    .data.item.navigation .inner.fixed {
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0;
      right: 0; }

.data.item.content {
  width: 80%;
  min-height: 200px;
  margin-top: 0.5rem; }
  .data.item.content .counter:before {
    content: '('; }
  .data.item.content .counter:after {
    content: ')'; }
  .data.item.content h2 {
    font-size: 22px;
    color: #1a1a1a;
    font-weight: 500;
    text-align: left;
    width: 20%; }
  .data.item.content table#product-attribute-specs-table {
    width: 100%; }
  .data.item.content.wrapper.fixed {
    padding-top: 78px; }

@media only screen and (max-width: 767px) {
  .product.info.detailed .navigation {
    display: none !important; }
  .data.item.title {
    padding: 0; }
  .data.item #product.info.accessories {
    margin-top: 0; }
  .data.item.content {
    width: 100% !important;
    min-height: unset;
    margin-bottom: 2rem; }
    .data.item.content.wrapper {
      width: 100%; }
      .data.item.content.wrapper.fixed {
        padding-top: 0; } }

.old-price,
.old.price {
  color: #000; }

.product-info-main {
  position: relative;
  top: 4rem; }
  .product-info-main .page-title-wrapper .page-title {
    font-size: 32px;
    font-weight: 700; }
  .product-info-main .product-info-price {
    display: inline-flex;
    flex-direction: column; }
    .product-info-main .product-info-price .price-box {
      margin-top: 0.25rem;
      margin-bottom: 0.5rem; }
  .product-info-main .product-info-stock-sku {
    display: inline-flex;
    margin-top: 0rem;
    padding: 0.75rem 0.7rem 1rem;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 0.5rem;
    background-color: #f7f7f7; }
    .product-info-main .product-info-stock-sku .configurable-variation-qty {
      display: none; }
    .product-info-main .product-info-stock-sku .stock {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      /* Amasty Stock Status */ }
      .product-info-main .product-info-stock-sku .stock .amstockstatus {
        text-indent: initial;
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
        font-weight: 500;
        font-size: 14px;
        margin-top: -2px; }
        .product-info-main .product-info-stock-sku .stock .amstockstatus:before {
          content: "";
          font-family: "Material Icons";
          display: block;
          color: #00C853;
          margin-right: 0.4rem;
          font-size: 16px;
          margin-top: 0.1rem; }
        .product-info-main .product-info-stock-sku .stock .amstockstatus.out-of-stock:before {
          content: "close";
          color: #FF0000; }
      .product-info-main .product-info-stock-sku .stock .amstockstatus_icon {
        display: none;
        height: 10px;
        margin-top: 1px;
        width: 10px; }
  .product-info-main .prices-tier .price-container {
    display: inline-block !important; }
    .product-info-main .prices-tier .price-container .price {
      font-size: 14px !important; }
  .product-info-main .prices-tier .item .extra-text {
    display: none; }
  .product-info-main .price-box.price-tier_price div {
    width: 100%; }

.product-info-main .price-box .price-container,
.product-options-bottom .price-box .price-container {
  display: flex;
  flex-direction: column; }
  .product-info-main .price-box .price-container .price-including-tax,
  .product-options-bottom .price-box .price-container .price-including-tax {
    order: 2; }
  .product-info-main .price-box .price-container .price-excluding-tax,
  .product-options-bottom .price-box .price-container .price-excluding-tax {
    order: 1;
    color: #00C853; }
    .product-info-main .price-box .price-container .price-excluding-tax .price,
    .product-options-bottom .price-box .price-container .price-excluding-tax .price {
      font-size: 28px; }
  .product-info-main .price-box .price-container .price-excluding-tax-container,
  .product-options-bottom .price-box .price-container .price-excluding-tax-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 0.5rem; }
    .product-info-main .price-box .price-container .price-excluding-tax-container .price-excluding-tax-vat-container-exempt,
    .product-options-bottom .price-box .price-container .price-excluding-tax-container .price-excluding-tax-vat-container-exempt {
      font-size: 16px; }
    .product-info-main .price-box .price-container .price-excluding-tax-container .price-excluding-tax-vat-container-tooltip,
    .product-options-bottom .price-box .price-container .price-excluding-tax-container .price-excluding-tax-vat-container-tooltip {
      margin-left: 1.5rem;
      font-size: 16px; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 24px; }

.product-page-info ul {
  padding-left: 0;
  list-style: none;
  margin: 1rem 0 2rem 0; }
  .product-page-info ul li {
    display: flex;
    align-items: baseline; }
    .product-page-info ul li:before {
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin-right: 8px;
      background-color: #11525d; }

.additional-attributes-wrapper table tr:nth-child(even) {
  background: transparent; }

.additional-attributes-wrapper table tr:nth-child(odd) {
  background: #f0f0f0; }

.product-add-form form {
  background-color: #f7f7f7; }
  .product-add-form form .product-options-wrapper {
    padding: 0.5rem 1rem; }

.price-box {
  display: flex;
  flex-direction: row; }
  .price-box.price-tier_price {
    margin: 0; }
  .price-box .price-container.price-final_price {
    display: flex;
    align-items: baseline;
    flex-direction: row; }
  .price-box .price-container .price-label {
    display: flex;
    font-size: 14px;
    font-weight: 500; }
    .price-box .price-container .price-label:after {
      content: ":";
      display: block;
      margin-right: 0.5rem; }
  .price-box .old-price .price-container .price-label {
    display: none; }
  .price-box .special-price {
    margin: 0;
    margin-right: 1rem; }

.product-info-main {
  position: initial;
  margin-top: 1rem; }
  .product-info-main .product-reviews-summary {
    display: flex;
    align-items: center; }
    .product-info-main .product-reviews-summary .reviews-actions {
      font-size: 11px;
      line-height: 11px; }

.review-form-actions .action.submit.primary {
  border-width: 1px 1px 2px 1px;
  border-color: #1c5275;
  background: #fff;
  color: #000;
  transition: all 200ms ease; }
  .review-form-actions .action.submit.primary:hover {
    border: 1px solid #1c5275;
    color: #fff;
    border-width: 1px 1px 2px 1px;
    background: #1c5275; }

.data.table.additional-attributes tbody tr .col.label {
  border: 1px solid #dedede;
  padding-left: 10px; }

.data.table.additional-attributes tbody tr .col.data {
  border: 1px solid #dedede;
  padding-left: 10px; }

.fotorama-item .fotorama__nav-wrap--vertical {
  margin-top: 2rem; }

.fotorama__caption {
  display: none; }

.product-info-main .box-tocart {
  margin-bottom: 0; }

.product-info-main .field.qty {
  margin: 0; }
  .product-info-main .field.qty .control {
    display: flex;
    padding-right: 1rem; }
    .product-info-main .field.qty .control input,
    .product-info-main .field.qty .control button,
    .product-info-main .field.qty .control .cart.table-wrapper .actions-toolbar > .action,
    .cart.table-wrapper .product-info-main .field.qty .control .actions-toolbar > .action,
    .product-info-main .field.qty .control .action-gift {
      background: #fff;
      height: 52px;
      width: 52px;
      border: 1px solid #E0E6EE;
      padding: 7px 25px 7px 20px; }
    .product-info-main .field.qty .control input.qty {
      padding: 0;
      width: 52px;
      height: 52px; }
    
    .product-info-main .field.qty .control button,
    .product-info-main .field.qty .control .cart.table-wrapper .actions-toolbar > .action,
    .cart.table-wrapper .product-info-main .field.qty .control .actions-toolbar > .action,
    .product-info-main .field.qty .control .action-gift {
      width: 40px;
      padding: 0;
      border-radius: 0; }
      .product-info-main .field.qty .control button[data-bind="click: decreaseQty"], .product-info-main .field.qty .control .cart.table-wrapper .actions-toolbar > .action[data-bind="click: decreaseQty"], .cart.table-wrapper .product-info-main .field.qty .control .actions-toolbar > .action[data-bind="click: decreaseQty"], .product-info-main .field.qty .control .action-gift[data-bind="click: decreaseQty"] {
        border-right: 0; }
      .product-info-main .field.qty .control button[data-bind="click: increaseQty"], .product-info-main .field.qty .control .cart.table-wrapper .actions-toolbar > .action[data-bind="click: increaseQty"], .cart.table-wrapper .product-info-main .field.qty .control .actions-toolbar > .action[data-bind="click: increaseQty"], .product-info-main .field.qty .control .action-gift[data-bind="click: increaseQty"] {
        border-left: 0; }

.product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart {
  width: 100%;
  background-color: #ff7600;
  border-color: #cc5e00;
  color: #ffffff; }

.actions .action.primary.tocart {
  line-height: 10px;
  font-family: "Roboto";
  max-height: 51px;
  background-color: #00C853;
  border: none;
  border-bottom: 2px solid #008b3a; }
  .actions .action.primary.tocart:hover {
    background-color: #00be4f; }
  .actions .action.primary.tocart span {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px; }

.block.upsell #block-upsell-heading {
  display: none; }

.block.upsell .product-items .item.product.product-item {
  border-right: 0 !important; }

.block.upsell .product-items .product-item-details__top .product-item-name {
  font-weight: 400;
  font-size: 16px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.block.upsell .product-items .product-item-details__top .price-box .special-price {
  margin: 0; }

.block.upsell .product-items .product-item-details__top .price-box .price {
  font-weight: 500; }

.block.upsell .product-items .actions-primary {
  width: 100%; }
  .block.upsell .product-items .actions-primary .action.tocart.primary {
    border: 1px solid #ff7600;
    border-bottom: 2px solid #ff7600;
    color: #ff7600;
    font-weight: 400;
    transition: all 250ms ease;
    height: 48px;
    margin-top: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem; }
    .block.upsell .product-items .actions-primary .action.tocart.primary span {
      font-weight: 400;
      font-size: 14px; }
    .block.upsell .product-items .actions-primary .action.tocart.primary:hover {
      background: #ff7600;
      color: #fff; }

/* Magiczoom video overlay fix */
.product.media {
  margin-top: 2px; }
  .product.media .video-selector:before, .product.media .video-selector:after {
    z-index: 10 !important; }

.product-page-usp {
  display: flex;
  background-color: #f7f7f7; }
  .product-page-usp ul {
    display: flex;
    width: 100%;
    padding-left: 0;
    justify-content: space-around;
    list-style: none;
    margin: 7px 0; }
    .product-page-usp ul li {
      position: relative;
      padding-right: 10px;
      font-size: 12px;
      margin: 7px 0; }
      .product-page-usp ul li .material-icons, .product-page-usp ul li body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .action-edit:before, body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .product-page-usp ul li .action-edit:before {
        position: relative;
        top: 4px;
        padding-right: 5px;
        color: #00C853;
        font-size: 14px; }

/* B2B storeview (show price excl. VAT) */
.product-info-main .product-info-price .special-price .price-container .price-label {
  display: none; }

.product-info-main .product-info-price .special-price .price-container .price {
  color: #474747; }

.product-info-main .product-info-price .exclusive-tax-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .product-info-main .product-info-price .exclusive-tax-wrapper .price-excluding-tax {
    order: 0;
    color: #474747; }
    .product-info-main .product-info-price .exclusive-tax-wrapper .price-excluding-tax .price {
      font-size: 24px;
      color: #474747; }
  .product-info-main .product-info-price .exclusive-tax-wrapper .extra-text {
    margin-left: 0.7rem;
    font-weight: 600;
    font-size: 15px; }

.product-info-main .product-info-price .customergroup-b2b-other-price {
  display: none; }

/* VAT (only on UK storeview) */
.price-box.vat-relief {
  align-items: center;
  margin-top: 0; }
  .price-box.vat-relief .base-price .price {
    color: #40c353;
    font-size: 2em;
    font-weight: 600; }
  .price-box.vat-relief > span:not(.base-price) {
    margin: 0 0.5rem;
    font-size: 1.2em;
    display: flex;
    align-items: center; }
    .price-box.vat-relief > span:not(.base-price):after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      margin-left: 0.5rem;
      font-size: 1.3rem;
      vertical-align: top;
      color: #1c5275; }
    .price-box.vat-relief > span:not(.base-price):hover {
      cursor: help; }
      .price-box.vat-relief > span:not(.base-price):hover + .vatReliefPopup {
        background-size: 100% 100%;
        color: white;
        height: auto;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.5);
        transition: all .3s ease-in-out; }
  .price-box.vat-relief .vatReliefPopup {
    position: absolute;
    padding: 1rem;
    top: 10rem;
    display: inline-block;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(#1c5275, #1c5275);
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 100% 0;
    transition: all .3s ease-in-out;
    width: auto;
    height: 0;
    overflow: hidden;
    z-index: 999;
    border-radius: 0 0 0.2rem 0.2rem; }
    .price-box.vat-relief .vatReliefPopup .text {
      width: 100%; }
  .price-box.vat-relief + .price-final_price {
    order: -1; }
    .price-box.vat-relief + .price-final_price .normal-price .price-container .price-wrapper .price {
      font-size: 1.7em; }

/* Desktop */
@media only screen and (min-width: 768px) {
  .block.upsell .product-items .product-item {
    width: 25%; }
    .block.upsell .product-items .product-item-info {
      width: 100%; } }

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .block.upsell .product-items .product-item {
    width: 33.33%; } }

/* Mobile */
@media only screen and (max-width: 767px) {
  .fotorama-item .fotorama__nav__frame--dot .fotorama__dot {
    width: 10px;
    height: 10px; }
  .fotorama__nav__frame--dot.fotorama__active .fotorama__dot {
    background-color: #8c8c8c;
    border-color: #8c8c8c; }
  .product.info.detailed {
    display: flex;
    justify-content: flex-start;
    padding-top: 4rem; }
    .product.info.detailed .product.data.items {
      width: 100%; }
      .product.info.detailed .product.data.items .data.item.title {
        width: auto; }
  .price-box .price-container .price-label {
    font-size: 12px; }
  .price-box .old-price {
    top: 18px; }
  .box-tocart {
    margin: 0 !important; }
  .product-page-usp ul {
    display: flex;
    flex-direction: column;
    padding-left: 1rem; }
  .product.attribute.description {
    padding-left: 1rem; }
  .product-info-main {
    margin-top: 0; }
    .product-info-main .product-info-stock-sku {
      display: block;
      text-align: right;
      margin-top: 0; }
      .product-info-main .product-info-stock-sku .stock .amstockstatus {
        margin-right: 0; }
      .product-info-main .product-info-stock-sku .product.attribute.sku {
        margin: 0.5rem 0rem 2rem; }
  .product.info.detailed {
    padding-top: 0; }
  .block.upsell .product-items {
    display: flex;
    flex-direction: column; }
    .block.upsell .product-items .product-item {
      width: 100%; }
      .block.upsell .product-items .product-item .product-item-info {
        width: 100%; }
    .block.upsell .product-items .product-item-details .product-item-details__top .product-item-name .product-item-link {
      font-size: 16px;
      font-weight: 600; }
    .block.upsell .product-items .product-item-details .product-item-details__top .price-box .price {
      font-weight: 600; }
    .block.upsell .product-items .product-item-actions {
      display: block; }
      .block.upsell .product-items .product-item-actions .actions-primary .action.tocart.primary {
        border: 1px solid #ff7600;
        border-bottom: 2px solid #ff7600;
        color: #ff7600;
        margin-left: 0; } }

@media only screen and (max-width: 639px) {
  .box-tocart .actions .action.primary.tocart {
    font-size: 15px; } }

body.not-logged-in .product-info-main .product-info-stock-sku .stock {
  display: none; }

.product-reviews-summary .reviews-actions a {
  color: #a3a3a3; }
  .product-reviews-summary .reviews-actions a:hover {
    text-decoration: none; }
  .product-reviews-summary .reviews-actions a:visited {
    color: #a3a3a3;
    text-decoration: none; }

.product-reviews-summary .reviews-actions .action.add {
  display: none; }

@media only screen and (min-width: 640px) {
  .review-form {
    max-width: unset; } }

.catalog-product-view .clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.catalog-product-view .swatch-opt {
  margin: 10px 0px; }

.catalog-product-view .swatch-attribute-label {
  font-weight: bold;
  position: relative; }
  .catalog-product-view .swatch-attribute-label.required {
    padding-right: 10px; }
  .catalog-product-view .swatch-attribute-label[data-required="1"]::after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em; }

.catalog-product-view .swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.catalog-product-view .swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 10px; }

.catalog-product-view .swatch-attribute-options:last-child {
  margin-bottom: 0; }

.catalog-product-view #swatches-content {
  display: none; }

.catalog-product-view .swatch-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative; }
  .catalog-product-view .swatch-option .inner {
    width: 100%; }
  .catalog-product-view .swatch-option.text {
    box-sizing: content-box;
    background: #f7f7f7;
    border: 1px solid #f0f0f0;
    color: #7f7f7f;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 4px 4px;
    min-width: 22px;
    min-height: 30px;
    margin-right: 7px;
    margin-top: 7px;
    border-radius: 0; }
  .catalog-product-view .swatch-option.selected .inner {
    border: 3px solid #2196f3;
    color: #333; }
    .catalog-product-view .swatch-option.selected .inner .value {
      position: relative; }
      .catalog-product-view .swatch-option.selected .inner .value:after {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        color: white; }
  .catalog-product-view .swatch-option.text.selected {
    background-color: #fff; }
  .catalog-product-view .swatch-option:not(.disabled):hover .inner {
    border: 1px solid #f0f0f0;
    color: #333; }
  .catalog-product-view .swatch-option.color, .catalog-product-view .swatch-option.image {
    min-height: 95px;
    min-width: 75px;
    border-radius: 0;
    background-size: auto !important;
    background-position: top !important; }
    .catalog-product-view .swatch-option.color .value, .catalog-product-view .swatch-option.image .value {
      display: flex;
      width: 100%; }
    .catalog-product-view .swatch-option.color img, .catalog-product-view .swatch-option.image img {
      height: 100%;
      width: 100%; }
    .catalog-product-view .swatch-option.color p, .catalog-product-view .swatch-option.image p {
      background: white;
      margin: 0;
      padding: 5px;
      font-size: 12px;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .catalog-product-view .swatch-option.color.selected .inner, .catalog-product-view .swatch-option.image.selected .inner {
      color: #333;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
      border: none; }
      .catalog-product-view .swatch-option.color.selected .inner:hover, .catalog-product-view .swatch-option.image.selected .inner:hover {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2); }
    .catalog-product-view .swatch-option.color.selected .inner, .catalog-product-view .swatch-option.image.selected .inner {
      opacity: 1; }
    .catalog-product-view .swatch-option.color:not(.disabled):hover, .catalog-product-view .swatch-option.image:not(.disabled):hover {
      opacity: 0.8;
      border: 2px solid transparent; }
      .catalog-product-view .swatch-option.color:not(.disabled):hover.selected, .catalog-product-view .swatch-option.image:not(.disabled):hover.selected {
        opacity: 1;
        border: 2px solid #11525d; }
        .catalog-product-view .swatch-option.color:not(.disabled):hover.selected .inner, .catalog-product-view .swatch-option.image:not(.disabled):hover.selected .inner {
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
          outline: 0;
          border: 0; }
      .catalog-product-view .swatch-option.color:not(.disabled):hover .inner, .catalog-product-view .swatch-option.image:not(.disabled):hover .inner {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        border: 0; }
  .catalog-product-view .swatch-option.disabled {
    cursor: default;
    opacity: 0.2; }
    .catalog-product-view .swatch-option.disabled:after {
      display: none; }

.catalog-product-view .swatch-attribute .swatch-option {
  background: #ffffff;
  color: #1a1a1a;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.33);
  font-weight: 500;
  width: fit-content;
  padding: 0.5rem 1rem;
  border: 2px solid transparent; }
  .catalog-product-view .swatch-attribute .swatch-option.selected {
    border: 2px solid #11525d; }
    .catalog-product-view .swatch-attribute .swatch-option.selected:before {
      position: absolute;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "done";
      bottom: -1px;
      right: -1px;
      color: #fff;
      z-index: 9;
      font-size: 1rem;
      font-weight: 600; }
    .catalog-product-view .swatch-attribute .swatch-option.selected:after {
      content: '';
      position: absolute;
      bottom: -3px;
      width: 0;
      height: 0;
      right: -10px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #11525d;
      clear: both;
      transform: rotate(-45deg); }

.catalog-product-view .swatch-option-tooltip {
  display: none !important; }

.catalog-product-view .swatch-option-tooltip .corner,
.catalog-product-view .swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: 0;
  height: 13px; }
  .catalog-product-view .swatch-option-tooltip .corner::before,
  .catalog-product-view .swatch-option-tooltip-layered .corner::before {
    content: '';
    position: relative;
    top: 2px;
    left: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #1672a0 transparent transparent transparent;
    font-size: 1px; }
  .catalog-product-view .swatch-option-tooltip .corner::after,
  .catalog-product-view .swatch-option-tooltip-layered .corner::after {
    content: '';
    position: relative;
    top: 1px;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #1672a0 transparent transparent transparent;
    font-size: 1px; }

.catalog-product-view .swatch-option-tooltip .image,
.catalog-product-view .swatch-option-tooltip-layered .image {
  display: block;
  position: relative;
  height: 150px;
  width: 150px;
  min-width: 150px;
  background-size: cover !important;
  margin: 0 auto; }

.catalog-product-view .swatch-option-tooltip .title {
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 20px;
  color: #282828; }

.catalog-product-view .swatch-option-tooltip .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  text-align: left;
  color: #1d1d1d; }

.catalog-product-view .swatch-attribute {
  margin: 25px 0; }
  .catalog-product-view .swatch-attribute:first-of-type {
    margin-top: 0;
    margin-bottom: 10px;
    display: block; }
    .catalog-product-view .swatch-attribute:first-of-type .swatch-option.disabled {
      display: block; }
  .catalog-product-view .swatch-attribute:last-of-type {
    margin-bottom: 10px; }

.catalog-product-view .swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.catalog-product-view .swatch-option-link-layered {
  position: relative; }

.catalog-product-view .swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.catalog-product-view .swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.catalog-product-view .swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.catalog-product-view .swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.catalog-product-view .swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.catalog-product-view .swatch-option-disabled:after {
  display: none; }

.catalog-product-view .swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.catalog-product-view .swatch-opt-listing {
  margin-bottom: 10px; }

.catalog-product-view .swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.catalog-product-view .swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden; }

.toolbar select {
  background-color: #fff; }

.toolbar-amount {
  margin: 0 auto 0 0;
  font-size: 20px; }

.toolbar-products .toolbar-amount {
  margin: 0 auto 0 0; }

.sorter-options {
  border: 1px solid #595959;
  border-bottom-width: 2px;
  border-radius: 3px; }

@media only screen and (min-width: 768px) {
  .modes {
    display: none; }
  .products.wrapper ~ .toolbar .limiter {
    padding-right: 20rem; } }

.message.company-warning {
  display: none; }

.page-header {
  box-shadow: none;
  background-color: transparent; }
  .page-header .panel.wrapper {
    background-color: transparent;
    border: none; }
    .page-header .panel.wrapper .panel.header {
      padding: 0.75rem 1rem 0.25rem; }
      .page-header .panel.wrapper .panel.header .widget.block.block-static-block {
        margin-bottom: 0;
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end; }
        .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .left-side {
          display: flex; }
          .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .left-side ul {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin: 0 0 0 1rem; }
            .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .left-side ul li {
              display: flex;
              align-items: center;
              padding-right: 0.5rem;
              font-size: 13px; }
              .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .left-side ul li:first-of-type {
                font-weight: 900; }
                .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .left-side ul li:first-of-type a {
                  color: inherit; }
              .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .left-side ul li a {
                margin-left: 0.25rem; }
        .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .right-side {
          display: flex;
          margin-left: auto; }
          .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .right-side ul {
            display: flex;
            list-style: none;
            padding-right: 1rem;
            margin: 0; }
            .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .right-side ul li {
              margin: 0; }
              .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .right-side ul li:first-of-type a {
                display: flex;
                align-items: center;
                line-height: 1; }
                .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .right-side ul li:first-of-type a:before {
                  margin-right: 5px;
                  font-family: 'Material Icons';
                  font-weight: normal;
                  font-style: normal;
                  font-size: 24px;
                  /* Preferred icon size */
                  display: inline-block;
                  line-height: 1;
                  text-transform: none;
                  letter-spacing: normal;
                  word-wrap: normal;
                  white-space: nowrap;
                  direction: ltr;
                  /* Support for all WebKit browsers. */
                  -webkit-font-smoothing: antialiased;
                  /* Support for Safari and Chrome. */
                  text-rendering: optimizeLegibility;
                  /* Support for Firefox. */
                  -moz-osx-font-smoothing: grayscale;
                  /* Support for IE. */
                  font-feature-settings: 'liga';
                  content: "person"; }
              .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp .right-side ul li a {
                color: #595959;
                text-decoration-line: none;
                font-size: 12px; }

@media only screen and (max-width: 767px) {
  .page-header .panel.wrapper .panel.header .widget.block.block-static-block .header-usp {
    display: none; } }

body #ajaxcart .inner {
  max-width: 40rem; }

body #ajaxcart .success {
  background-color: #009f42;
  color: #fff; }

body #ajaxcart .button-close-top {
  color: #fff; }

body #ajaxcart .ajaxcart-product-item {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 80px;
  text-align: center;
  margin: 1rem 1rem 0rem;
  width: auto; }

body #ajaxcart .product-image {
  position: absolute;
  left: 0;
  top: 1.5rem; }

body #ajaxcart .product-name {
  font-weight: bold;
  font-size: 17px; }

body #ajaxcart .price-box {
  margin: 0.5rem 0rem;
  display: block; }

body #ajaxcart .option {
  margin: 0.25rem 0; }

body #ajaxcart .cartamount {
  background: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  padding: 1rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  body #ajaxcart .cartamount .remaining-amount {
    margin-bottom: 10px; }
  body #ajaxcart .cartamount .amount-percent {
    width: 450px;
    height: 1rem;
    -webkit-appearance: none; }
    body #ajaxcart .cartamount .amount-percent::-webkit-progress-bar {
      background-color: rgba(204, 206, 206, 0.78);
      border-radius: 10px; }
    body #ajaxcart .cartamount .amount-percent::-webkit-progress-value {
      background-color: #3fc353;
      border-radius: 10px; }

body #ajaxcart #ajax-slots-wrapper {
  margin-bottom: 1rem; }
  body #ajaxcart #ajax-slots-wrapper .block {
    margin: 0; }
    body #ajaxcart #ajax-slots-wrapper .block-actions {
      display: none; }
    body #ajaxcart #ajax-slots-wrapper .block-title {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0.5rem; }
      body #ajaxcart #ajax-slots-wrapper .block-title strong {
        font-size: 20px;
        font-weight: 700; }
  body #ajaxcart #ajax-slots-wrapper .products-grid {
    margin: 0; }
    body #ajaxcart #ajax-slots-wrapper .products-grid .product-items {
      margin: 0; }
      body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item {
        padding: 0.25rem;
        padding-left: 0.5rem;
        min-height: unset;
        margin: 0.5rem !important; }
        body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item-photo {
          width: 100px;
          height: 100px;
          margin: 0 auto; }
        body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item-name {
          display: flex;
          justify-content: center;
          padding-bottom: 0;
          margin-top: 0;
          font-size: 16px; }
        body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item-details .field.choice {
          display: none; }
        body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item .price-box .price {
          font-size: 16px; }
        body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item-actions {
          /* Hidden pr request */
          display: none;
          background: transparent;
          padding: 0.25rem; }
          body #ajaxcart #ajax-slots-wrapper .products-grid .product-items .product-item-actions .action.tocart {
            font-size: 14px; }

body #ajaxcart .actions {
  flex-direction: column-reverse;
  align-items: center; }
  body #ajaxcart .actions #button-checkout {
    display: none; }
  body #ajaxcart .actions #button-close {
    width: fit-content;
    border: none;
    background: transparent;
    font-weight: 400; }
  body #ajaxcart .actions #button-cart {
    font-size: 20px;
    font-weight: 500;
    background: #00C853;
    border: none;
    border-bottom: 2px solid #008b3a;
    width: fit-content;
    color: #fff;
    font-size: 1.2rem;
    margin: 0.5rem 0;
    padding: 0.8rem 2rem; }
    body #ajaxcart .actions #button-cart:hover {
      background: #00be4f; }

/* MOBILE */
@media only screen and (max-width: 767px) {
  body #ajaxcart .inner {
    transform: none;
    top: 0;
    left: 0; }
    body #ajaxcart .inner .success {
      background-color: #00C853;
      color: #fff; }
    body #ajaxcart .inner .ajaxcart-product-item {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 6rem;
      min-height: 80px;
      text-align: left;
      width: 90%; }
    body #ajaxcart .inner .product-image {
      margin-right: auto;
      position: absolute;
      left: 0;
      top: 0; }
    body #ajaxcart .inner .product-name {
      font-size: 14px; }
    body #ajaxcart .inner .price-box .price {
      font-size: 14px; }
    body #ajaxcart .inner .option {
      margin: 0.25rem 0; }
    body #ajaxcart .inner .cartamount {
      background: #ffffff;
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      padding: 1rem 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      body #ajaxcart .inner .cartamount .remaining-amount {
        margin-bottom: 10px; }
      body #ajaxcart .inner .cartamount .amount-percent {
        width: 315px;
        height: 1rem;
        -webkit-appearance: none; }
        body #ajaxcart .inner .cartamount .amount-percent::-webkit-progress-bar {
          background-color: rgba(204, 206, 206, 0.78);
          border-radius: 5px; }
        body #ajaxcart .inner .cartamount .amount-percent::-webkit-progress-value {
          background-color: #00C853;
          border-radius: 5px; }
    body #ajaxcart .inner .actions #button-close {
      display: none; }
    body #ajaxcart .inner .actions #button-checkout,
    body #ajaxcart .inner .actions #button-cart {
      font-size: 18px;
      font-weight: 500; } }

body.toprode #ajaxcart {
  display: none; }

/* header USP styling */
.usp-menu-wrapper, .usp-product-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 3; }
  .usp-menu-wrapper ul.usp-wrapper, .usp-product-icons ul.usp-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    max-width: 1440px; }
    .usp-menu-wrapper ul.usp-wrapper li, .usp-product-icons ul.usp-wrapper li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #595959; }
      .usp-menu-wrapper ul.usp-wrapper li i, .usp-product-icons ul.usp-wrapper li i {
        margin-right: 0.75rem; }

.usp-menu-wrapper li {
  font-size: 12px;
  font-weight: 500;
  margin: 10px; }

.usp-product-icons .usp-wrapper .usp-list__item i {
  color: #468966; }

@media only screen and (max-width: 767px) {
  .usp-product-icons {
    box-shadow: none !important; }
    .usp-product-icons .usp-wrapper .usp-list__item {
      flex-direction: column;
      text-align: center; }
      .usp-product-icons .usp-wrapper .usp-list__item i {
        margin: 0 !important; } }

.wpcloud-root.wpcloud-page .wpb_wrapper > h2 {
  margin-bottom: 2rem !important;
  padding-bottom: 1.25rem !important;
  position: relative;
  /*&:before {
			    content: '';
				margin: auto;
				height: 2px;
				background: #161d36;
				position: absolute;
				top: 100%;
				left: 50%;
				-webkit-transform: translate(-50%,0%);
				transform: translate(-50%,0%);
				width: 20%;
			 }*/ }

.cms-home .card-wrapper.wpcmdl-grid--width-full {
  width: 100vw;
  margin: 0 calc((100% - 100vw)/ 2);
  height: unset; }

.MagicScroll .mcs-item img {
  box-shadow: none !important;
  border: 1px solid #ededed !important; }

@media only screen and (max-width: 767px) {
  .MagicToolboxSelectorsContainer .MagicScroll {
    height: 17px !important; }
  .MagicToolboxSelectorsContainer .mcs-button-arrow-prev {
    left: -10px !important; }
  .MagicToolboxSelectorsContainer .mcs-button-arrow-next {
    right: -10px !important; }
  .MagicToolboxSelectorsContainer .mcs-item {
    padding: 0px 5px; }
  .MagicToolboxSelectorsContainer a {
    width: 14px;
    height: 14px !important;
    border-radius: 8px;
    background: none repeat scroll 0 0 #aaa;
    margin: 2px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-indent: 9999px;
    vertical-align: middle;
    font-size: 0px;
    line-height: 0px; }
    .MagicToolboxSelectorsContainer a.active-selector:before, .MagicToolboxSelectorsContainer a:hover:before {
      width: 8px;
      height: 8px;
      border: 1px solid transparent;
      border-radius: 8px;
      margin: 3px 3px;
      content: '';
      position: relative;
      display: block;
      text-indent: 9999px;
      background: #fff !important; }
    .MagicToolboxSelectorsContainer a img {
      display: none !important;
      visibility: hidden !important; }
    .MagicToolboxSelectorsContainer a.mt-thumb-switcher.video-selector:before {
      background-color: rgba(0, 0, 0, 0.2) !important;
      border-radius: unset;
      margin: 0; }
    .MagicToolboxSelectorsContainer a.mt-thumb-switcher.video-selector:after {
      height: 12px !important;
      width: auto;
      border-top: 6px solid transparent;
      border-left: 9px solid rgba(255, 255, 255, 0.9);
      border-bottom: 6px solid transparent; }
  .MagicToolboxSelectorsContainer img {
    display: none;
    box-shadow: none; } }

.tw-productlabels__label--discount .tw-productlabels__text {
  background: #d60606 !important;
  color: #fff; }

.tw-productlabels .b2b-label {
  display: none; }

.tw-productlabels__text {
  padding: 0.6rem 2rem !important;
  opacity: 0.85;
  text-transform: uppercase;
  letter-spacing: 0.5px; }

.tw-productlabels__label--custom-designed-in-norway {
  width: 150px;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85); }
  .tw-productlabels__label--custom-designed-in-norway img {
    width: 134px;
    margin-left: 8px; }

.tw-productlabels__label--custom-designed-in-norway-germany {
  width: 165px;
  height: 34px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85); }
  .tw-productlabels__label--custom-designed-in-norway-germany img {
    width: 100%;
    margin-left: 8px; }

/* product page */
.product.media .tw-productlabels {
  left: 7.5rem;
  top: -0.25rem; }

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .product.media .tw-productlabels {
    left: 6.5rem; } }

/* Mobile only */
@media only screen and (max-width: 767px) {
  .product.media .tw-productlabels {
    left: -0.25rem; } }

.cart-container table tr:nth-child(even) {
  background: #fff; }

.cart-container #shopping-cart-table .cart.item .col.qty input {
  width: 45px;
  height: 35px; }

.cart-container #shopping-cart-table .cart.item tr .col.price {
  text-align: center; }

.cart-container #shopping-cart-table .cart.item tr .col.subtotal .price-box.vat-relief {
  justify-content: flex-end; }

.cart-container #shopping-cart-table .cart.item tr .col.price .vat-relief,
.cart-container #shopping-cart-table .cart.item tr .col.subtotal .vat-relief {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 32px;
  position: relative; }
  .cart-container #shopping-cart-table .cart.item tr .col.price .vat-relief .price,
  .cart-container #shopping-cart-table .cart.item tr .col.subtotal .vat-relief .price {
    font-size: 18px;
    font-weight: 700; }
  .cart-container #shopping-cart-table .cart.item tr .col.price .vat-relief .vatRelief,
  .cart-container #shopping-cart-table .cart.item tr .col.subtotal .vat-relief .vatRelief {
    font-size: 14px; }
  .cart-container #shopping-cart-table .cart.item tr .col.price .vat-relief .vatReliefPopup,
  .cart-container #shopping-cart-table .cart.item tr .col.subtotal .vat-relief .vatReliefPopup {
    top: 4rem;
    width: 35vw; }

.cart-container #shopping-cart-table .cart.item tr .col.price .vatRelief-checkout-description,
.cart-container #shopping-cart-table .cart.item tr .col.subtotal .vatRelief-checkout-description {
  font-size: 14px;
  white-space: normal;
  margin: 0; }

.cart-container #shopping-cart-table .cart.item .price-excluding-tax {
  display: inline-flex; }

.cart-container #shopping-cart-table .cart.item .extra-text {
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  margin-left: 0.3rem;
  vertical-align: top; }

.cart-container #shopping-cart-table .cart.item .product-item-details .item-options:last-of-type {
  margin-bottom: 0; }

.cart-container #shopping-cart-table .cart.item .product-item-details .sku-container {
  display: flex;
  align-items: center; }
  .cart-container #shopping-cart-table .cart.item .product-item-details .sku-container strong {
    margin-right: 10px;
    font-size: 12px; }
  .cart-container #shopping-cart-table .cart.item .product-item-details .sku-container p {
    margin: 0;
    font-size: 12px; }

.cart-container #shopping-cart-table thead .col.price {
  text-align: center; }

.cart-container .cart-summary #block-shipping {
  display: none; }

.cart-container .cart-summary .summary.title {
  display: none; }

.cart-container .cart-summary .cart-totals {
  padding-top: 0; }
  .cart-container .cart-summary .cart-totals .grand.totals td,
  .cart-container .cart-summary .cart-totals .grand.totals th {
    padding: 10px 0px; }

.cart-container .cart-summary .block.active {
  margin-bottom: 1.5rem; }

.cart-container .cart-summary .block {
  margin-bottom: 35px; }
  .cart-container .cart-summary .block strong {
    margin: 0;
    font-weight: 600; }
  .cart-container .cart-summary .block #block-shipping-heading {
    padding-bottom: 0; }
  .cart-container .cart-summary .block #block-summary .legend {
    display: none; }

.cart-container .cart-summary .block.discount {
  margin-bottom: 20px; }

.cart-container .cart-summary .checkout .checkout {
  background-color: #00C853;
  border: none;
  border-bottom: 2px solid #009f42; }
  .cart-container .cart-summary .checkout .checkout:hover {
    background: #00be4f; }

.cart-container #gift-options-cart,
.cart-container #block-giftcard {
  display: none; }

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cart.table-wrapper .item .col.item {
    width: 265px; } }

/* Mobile only */
@media only screen and (max-width: 767px) {
  .cart-container #shopping-cart-table .cart.item .price-excluding-tax {
    display: block; }
  .cart-container #shopping-cart-table .cart.item .extra-text {
    display: block;
    margin-top: 0.3rem; }
  .cart-container #shopping-cart-table thead .col.price {
    text-align: center; }
  .cart-container #shopping-cart-table tbody .col.subtotal .vat-relief {
    justify-content: center !important; } }

body.checkout-index-index .header.content {
  padding: 1rem !important; }
  body.checkout-index-index .header.content .action.nav-toggle {
    display: none; }

body.checkout-index-index .modal-popup .form-shipping-address {
  max-width: initial; }

body.checkout-index-index .algolia-instant-selector-results {
  display: none !important; }

body.checkout-index-index .authentication-action button, body.checkout-index-index .authentication-action .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body.checkout-index-index .authentication-action .actions-toolbar > .action, body.checkout-index-index .authentication-action .action-gift {
  background: transparent;
  color: #2980b9;
  border: none;
  margin: 0 0.5rem 0 0;
  padding: 0;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 600;
  text-transform: unset; }

body.checkout-index-index .action.action-cancel {
  background: #ddd !important;
  border-color: #bbb !important;
  color: #2a2a2a !important;
  padding: 0.7rem 1.5rem;
  border-radius: 3px; }

body.checkout-index-index .fieldset > .field:not(.choice) > .label {
  padding-left: 0;
  padding-bottom: 0.5rem;
  font-family: inherit;
  font-weight: initial;
  width: 100%;
  text-align: left;
  font-size: 16px; }
  body.checkout-index-index .fieldset > .field:not(.choice) > .label:after {
    display: none; }

body.checkout-index-index .fieldset > .field:not(.choice) > .control {
  width: 100%; }
  body.checkout-index-index .fieldset > .field:not(.choice) > .control select,
  body.checkout-index-index .fieldset > .field:not(.choice) > .control input {
    height: 40px; }
  body.checkout-index-index .fieldset > .field:not(.choice) > .control .field-error span {
    font-size: 12px;
    color: #e02b27;
    display: block;
    margin-top: 7px; }

body.checkout-index-index .fieldset .field {
  margin: 0 0 20px; }
  body.checkout-index-index .fieldset .field[name="shippingAddress.street.0"] label.label {
    display: none; }

body.checkout-index-index .fieldset .field.choice {
  width: 100%;
  text-align: left;
  order: 20;
  margin-top: 2rem; }
  body.checkout-index-index .fieldset .field.choice:before {
    display: none; }

body.checkout-index-index .fieldset .field-tooltip-action:before {
  font-size: 30px;
  line-height: 1.2; }

body.checkout-index-index .fieldset > .field .note {
  display: none !important; }

body.checkout-index-index .fieldset.address {
  display: flex;
  flex-wrap: wrap; }
  body.checkout-index-index .fieldset.address [name$=".telephone"] {
    width: 100%;
    margin: 0 0 29px;
    order: 1; }
  body.checkout-index-index .fieldset.address [name$=".firstname"] {
    width: 48%;
    margin-right: 3%;
    order: 2;
    margin-top: 0; }
  body.checkout-index-index .fieldset.address [name$=".lastname"] {
    width: 49%;
    order: 3;
    margin-top: 0; }
  body.checkout-index-index .fieldset.address fieldset.field.street {
    width: 100%;
    order: 4; }
  body.checkout-index-index .fieldset.address [name$="shippingAddress.street.1"],
  body.checkout-index-index .fieldset.address [name$="shippingAddress.street.2"],
  body.checkout-index-index .fieldset.address [name$="shippingAddress.fax"] {
    display: none; }
  body.checkout-index-index .fieldset.address [name$=".postcode"] {
    width: 32%;
    margin-right: 3%;
    margin-bottom: 0;
    order: 5; }
  body.checkout-index-index .fieldset.address [name$=".city"] {
    width: 65%;
    order: 6;
    margin-bottom: 0; }
  body.checkout-index-index .fieldset.address [name$=".country_id"] {
    width: 100%;
    order: 7;
    display: none; }
  body.checkout-index-index .fieldset.address [name$=".company"] {
    order: 10;
    margin-top: 1rem;
    width: 49%; }
  body.checkout-index-index .fieldset.address [name$=".region"] {
    display: none; }

body.checkout-index-index .page-wrapper {
  background: #f9f9f9; }

body.checkout-index-index .page-header {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1); }

body.checkout-index-index .header.content {
  justify-content: space-between;
  flex-direction: row-reverse; }
  body.checkout-index-index .header.content .customer-support {
    font-size: 16px; }
    body.checkout-index-index .header.content .customer-support p {
      margin: 5px 0 0 0;
      text-align: right; }
    body.checkout-index-index .header.content .customer-support strong {
      font-family: inherit; }

body.checkout-index-index .zopim {
  display: none !important; }

body.checkout-index-index .checkout-container {
  display: grid;
  grid-template-areas: 'progressbar progressbar' 'login login' 'adress-shipping adress-shipping';
  grid-template-columns: 50% 50%;
  grid-template-rows: auto max-content max-content max-content; }
  body.checkout-index-index .checkout-container .opc-progress-bar {
    grid-area: progressbar;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2rem 0; }
    body.checkout-index-index .checkout-container .opc-progress-bar-item:before {
      display: none; }
  body.checkout-index-index .checkout-container .modal-custom-overlay {
    display: none; }
  body.checkout-index-index .checkout-container .opc-summary-wrapper {
    display: none; }
  body.checkout-index-index .checkout-container .authentication-wrapper {
    width: 100%;
    grid-area: login;
    display: flex;
    justify-content: space-between;
    max-width: initial;
    padding: 0rem 0 1.5rem 1rem;
    margin: 0; }
    body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action {
      display: flex;
      align-items: center; }
      body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action > span {
        color: #595959;
        font-size: 16px; }
    body.checkout-index-index .checkout-container .authentication-wrapper .secure-payment {
      display: flex;
      align-items: center;
      padding-right: 1rem; }
      body.checkout-index-index .checkout-container .authentication-wrapper .secure-payment i {
        color: #fda429;
        margin-right: 3px; }
      body.checkout-index-index .checkout-container .authentication-wrapper .secure-payment span {
        height: 24px;
        display: flex;
        align-items: center;
        font-family: inherit; }
  body.checkout-index-index .checkout-container .authentication-dropdown._show {
    max-width: 50%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px; }
  body.checkout-index-index .checkout-container .opc-wrapper {
    grid-area: adress-shipping;
    padding: 0; }
    body.checkout-index-index .checkout-container .opc-wrapper .step-content {
      margin: 0; }
    body.checkout-index-index .checkout-container .opc-wrapper .shipping-address-item {
      margin: 1rem 0;
      width: 100%;
      border-color: #1c5275; }
      body.checkout-index-index .checkout-container .opc-wrapper .shipping-address-item:after {
        background: #1c5275; }
  body.checkout-index-index .checkout-container table.table-totals {
    display: none;
    grid-area: totals;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    height: fit-content;
    margin: 0; }
    body.checkout-index-index .checkout-container table.table-totals tbody {
      width: 100%;
      display: block; }
    body.checkout-index-index .checkout-container table.table-totals tr.totals {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-family: inherit;
      background: #fff; }
      body.checkout-index-index .checkout-container table.table-totals tr.totals th {
        padding: 8px 10px; }
      body.checkout-index-index .checkout-container table.table-totals tr.totals-tax {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        body.checkout-index-index .checkout-container table.table-totals tr.totals-tax th, body.checkout-index-index .checkout-container table.table-totals tr.totals-tax td {
          padding: 8px 10px;
          display: flex;
          align-items: center;
          font-family: inherit; }
      body.checkout-index-index .checkout-container table.table-totals tr.totals.sub th, body.checkout-index-index .checkout-container table.table-totals tr.totals.sub td {
        padding-bottom: 4px; }
      body.checkout-index-index .checkout-container table.table-totals tr.totals.shipping span.value {
        font-family: inherit;
        font-size: 13px; }
      body.checkout-index-index .checkout-container table.table-totals tr.totals.shipping th, body.checkout-index-index .checkout-container table.table-totals tr.totals.shipping td {
        display: flex;
        flex-direction: column; }
      body.checkout-index-index .checkout-container table.table-totals tr.totals.grand {
        border-top: 1px solid #bababa;
        margin-top: 0.5rem;
        font-size: 20px; }
        body.checkout-index-index .checkout-container table.table-totals tr.totals.grand th, body.checkout-index-index .checkout-container table.table-totals tr.totals.grand td {
          border-top: none;
          padding-bottom: 0;
          padding-top: 1rem; }
  body.checkout-index-index .checkout-container .block.items-in-cart {
    display: none;
    grid-area: items;
    height: fit-content;
    margin: 1rem 0rem;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem; }
    body.checkout-index-index .checkout-container .block.items-in-cart .title {
      border: none;
      cursor: auto; }
      body.checkout-index-index .checkout-container .block.items-in-cart .title:after {
        display: none; }
      body.checkout-index-index .checkout-container .block.items-in-cart .title strong {
        display: flex;
        font-size: 22px;
        font-family: inherit;
        font-weight: 600;
        margin: 0.5rem 0 1.5rem 0; }
        body.checkout-index-index .checkout-container .block.items-in-cart .title strong > span {
          margin-right: 0.5rem; }
      body.checkout-index-index .checkout-container .block.items-in-cart .title .product-qty {
        display: flex;
        margin: 0 5px; }
        body.checkout-index-index .checkout-container .block.items-in-cart .title .product-qty:before {
          content: '('; }
        body.checkout-index-index .checkout-container .block.items-in-cart .title .product-qty:after {
          content: ')'; }
        body.checkout-index-index .checkout-container .block.items-in-cart .title .product-qty span {
          margin: 0 2px; }
    body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper {
      padding: 0;
      margin: 0;
      grid-area: items;
      height: fit-content;
      border: none; }
      body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items {
        padding-right: 1rem; }
        body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item {
          margin: 0; }
          body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item:last-of-type {
            border-top: 1px solid #ccc; }
          body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item .product-image-container {
            max-height: 90px;
            max-width: 90px;
            margin-top: 0.75rem; }
          body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-name {
            font-weight: 600;
            font-size: 1rem;
            margin: 1rem 0 0 0; }
          body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-details {
            padding-left: 100px; }
            body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-details .product.options {
              margin-top: 0.6rem; }
              body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-details .product.options.active .content {
                margin-top: 0.6rem; }
            body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-details .details-qty {
              display: flex;
              margin-top: 0; }
              body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-details .details-qty span {
                border: none !important;
                height: initial !important;
                width: initial !important; }
                body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item-details .details-qty span.label {
                  display: flex;
                  margin-right: 0.25rem; }
          body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item .subtotal {
            position: absolute;
            top: 0.85rem;
            right: 0; }
            body.checkout-index-index .checkout-container .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item .subtotal span {
              font-size: 14px; }
  body.checkout-index-index .checkout-container .opc-block-shipping-information {
    display: none;
    grid-area: shipping;
    height: fit-content;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem; }
    body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title {
      border-bottom: none;
      font-family: inherit;
      font-size: 22px;
      font-weight: 600;
      margin: 0; }
      body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .action-edit {
        top: 0; }
        body.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title .action-edit:before {
          content: 'edit'; }
  body.checkout-index-index .checkout-container.stepTwo {
    grid-template-areas: 'progressbar progressbar' 'login login' 'payment totals' 'payment items' 'payment shipping'; }
    body.checkout-index-index .checkout-container.stepTwo .opc-wrapper {
      display: initial;
      width: 100%;
      padding: 0;
      grid-area: payment;
      /* VAT exempt on step two */ }
      body.checkout-index-index .checkout-container.stepTwo .opc-wrapper div#giftcardaccount-placer {
        display: none; }
      body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .form-discount {
        max-width: initial; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .form-discount button.action.action-apply, body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .form-discount .cart.table-wrapper .actions-toolbar > .action.action-apply, .cart.table-wrapper body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .form-discount .actions-toolbar > .action.action-apply, body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .form-discount .action.action-apply.action-gift {
          margin-left: auto;
          display: block; }
      body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li {
        width: 100%;
        margin: 0 1rem 1rem; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option-title {
          margin-top: 0;
          margin-bottom: 1rem;
          padding-bottom: 0; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option .form-discount .payment-option-inner,
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option .form-discount .field {
          margin-bottom: 0.75rem; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option .form-discount .actions-toolbar .action {
          margin-right: 0; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option-content .ordercomments-inner {
          margin-top: 2.5rem; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option-content .ordercomments-inner .label {
            display: block;
            margin-bottom: 0.5rem; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-option-content .shipcomplete.field {
          display: block; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method-title {
          margin-top: 0;
          padding: 0;
          position: relative;
          font-weight: 600;
          min-height: 40px;
          margin-bottom: 0;
          border-top: none; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method-title input.radio {
            margin: 0 1rem 0 0; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method-title img {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0%, -50%);
            padding: 0; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method-title div.adyen-sprite {
            position: absolute;
            top: -1px;
            right: 0;
            padding: 0; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method-content {
          padding: 0 1rem; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc {
          position: relative;
          padding: 1rem;
          background: #f9f9f9;
          border-radius: 10px;
          box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2); }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .credit-card-types {
            display: flex;
            position: absolute;
            right: 0;
            top: -1rem;
            margin: 0;
            justify-content: flex-end; }
            body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .credit-card-types .item {
              display: none; }
              body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .credit-card-types .item._active {
                display: block; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.choice {
            display: flex;
            align-items: center;
            margin: 1rem 0rem; }
            body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.choice label {
              margin-bottom: 0;
              margin-top: 2px; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc label.label {
            font-size: 12px;
            padding: 0;
            font-weight: 600;
            margin-bottom: 6px; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc label[for=adyen_cc_cc_type] {
            display: none; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.type.required:first-of-type {
            margin: 0; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.number {
            margin-top: -10px; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.cvv {
            margin: 0 0 10px; }
            body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.cvv .field-tooltip {
              position: relative;
              display: inline-block; }
              body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .field.cvv .field-tooltip-action {
                margin-top: -4px; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc .fieldset > .field {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 0 10px; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc select,
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method #payment_form_adyen_cc input {
            height: 32px; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper #checkoutSteps > li .payment-method .fieldset > .field.type .control {
          margin: 0; }
      body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper {
        padding: 2rem;
        margin: 0rem 1rem 1rem;
        height: fit-content;
        background: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
        width: 100%; }
        body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper .vat-exempt-wrapper {
          display: block !important; }
          body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper .vat-exempt-wrapper #vat-exempt {
            display: block !important;
            grid-area: vat;
            margin-bottom: 1rem; }
            body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper .vat-exempt-wrapper #vat-exempt .step-title {
              margin-bottom: 1rem; }
            body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper .vat-exempt-wrapper #vat-exempt-declains #co-vatexempt {
              padding: 0;
              border: none; }
              body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper .vat-exempt-wrapper #vat-exempt-declains #co-vatexempt .label {
                margin-bottom: 0.25rem;
                display: block;
                font-weight: 600; }
              body.checkout-index-index .checkout-container.stepTwo .opc-wrapper .vat-exempt-outer-wrapper .vat-exempt-wrapper #vat-exempt-declains #co-vatexempt ul {
                margin-top: 1.5rem; }
    body.checkout-index-index .checkout-container.stepTwo table.table-totals {
      display: block; }
    body.checkout-index-index .checkout-container.stepTwo .block.items-in-cart {
      display: block; }
    body.checkout-index-index .checkout-container.stepTwo .opc-block-shipping-information {
      display: block; }
  body.checkout-index-index .checkout-container .opc-wrapper {
    width: 100%;
    display: flex; }
    body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps {
      width: 100%;
      display: flex;
      /* VAT Exempt hide on step 1 */ }
      body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps .vat-exempt-wrapper {
        display: none; }
        body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps .vat-exempt-wrapper #vat-exempt {
          display: none; }
      body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps > li {
        width: 48%;
        padding: 2rem;
        margin: 0 1%;
        height: fit-content;
        background: #fff;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1); }
        body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps > li .actions-toolbar {
          margin: 0;
          padding: 0; }
          body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps > li .actions-toolbar .primary {
            width: 100%; }
          body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps > li .actions-toolbar .action.primary {
            width: 100%;
            background-color: #00C853;
            border-color: #008b3a; }
            body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps > li .actions-toolbar .action.primary:hover {
              background-color: #00b94d; }
    body.checkout-index-index .checkout-container .opc-wrapper .step-title {
      font-family: inherit;
      font-weight: 600;
      border: none;
      padding-top: 10px; }
    body.checkout-index-index .checkout-container .opc-wrapper .form-login,
    body.checkout-index-index .checkout-container .opc-wrapper .form-shipping-address {
      max-width: initial;
      margin: 20px 0 0 0;
      border: none; }
      body.checkout-index-index .checkout-container .opc-wrapper .form-login .hidden-fields,
      body.checkout-index-index .checkout-container .opc-wrapper .form-shipping-address .hidden-fields {
        display: none !important; }
      body.checkout-index-index .checkout-container .opc-wrapper .form-login .field.required,
      body.checkout-index-index .checkout-container .opc-wrapper .form-shipping-address .field.required {
        margin-bottom: 0; }
    body.checkout-index-index .checkout-container .opc-wrapper #newsletter {
      display: none; }
    body.checkout-index-index .checkout-container .opc-wrapper.fieldset {
      display: none; }
  body.checkout-index-index .checkout-container #customer-lookup-form fieldset {
    max-width: initial; }
  body.checkout-index-index .checkout-container #customer-lookup-form button, body.checkout-index-index .checkout-container #customer-lookup-form .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body.checkout-index-index .checkout-container #customer-lookup-form .actions-toolbar > .action, body.checkout-index-index .checkout-container #customer-lookup-form .action-gift {
    height: 40px;
    padding: 0 3rem; }
  body.checkout-index-index .checkout-container #customer-lookup-form label.label {
    padding-left: 0;
    padding-bottom: 0.5rem;
    font-family: inherit;
    font-weight: initial;
    width: 100%;
    text-align: left;
    font-size: 16px; }
  body.checkout-index-index .checkout-container #customer-lookup-form input {
    height: 40px; }
  body.checkout-index-index .checkout-container .table-checkout-shipping-method {
    max-width: initial;
    width: 100%;
    min-width: initial; }
    body.checkout-index-index .checkout-container .table-checkout-shipping-method tbody:first-of-type {
      display: block;
      margin-top: 1rem; }
    body.checkout-index-index .checkout-container .table-checkout-shipping-method thead {
      display: none; }
    body.checkout-index-index .checkout-container .table-checkout-shipping-method #vconnect_allinone_tr {
      display: flex;
      width: 100%; }
      body.checkout-index-index .checkout-container .table-checkout-shipping-method #vconnect_allinone_tr td {
        width: 100%; }
    body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border: 1px solid #bababa;
      border-radius: 10px;
      margin: 1rem 0;
      position: relative; }
      body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td {
        border: none;
        height: fit-content;
        padding: 15px 15px 0 15px; }
        body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td.col:nth-child(1) {
          order: 1; }
        body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td.col:nth-child(2) {
          order: 5;
          width: 100%;
          padding: 0 15px 15px 45px; }
        body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td.col:nth-child(3) {
          order: 2;
          font-family: inherit; }
        body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td.col:nth-child(4) {
          display: none; }
        body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td.col:nth-child(5) {
          order: 4;
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: translate(0%, -50%);
          padding: 0; }
          body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row td.col:nth-child(5) img {
            margin: 0; }
      body.checkout-index-index .checkout-container .table-checkout-shipping-method tr.row:first-of-type {
        margin-top: 0; }
  body.checkout-index-index .checkout-container .payment-method {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #bababa;
    border-radius: 5px;
    display: flex;
    flex-direction: column-reverse; }
    body.checkout-index-index .checkout-container .payment-method._active {
      border-color: #000; }
    body.checkout-index-index .checkout-container .payment-method-title.field.choice .klarna-payments-method-cell {
      float: left; }
  body.checkout-index-index .checkout-container #giftcard-form {
    display: flex;
    flex-wrap: wrap; }
    body.checkout-index-index .checkout-container #giftcard-form .payment-option-inner {
      margin: 0;
      width: 100%; }
    body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%; }
      body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .primary {
        width: fit-content !important; }
        body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .primary button, body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .primary .actions-toolbar > .action, body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .primary .action-gift {
          width: fit-content;
          padding: 0.75rem 2rem; }
      body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .secondary button, body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .secondary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .secondary .actions-toolbar > .action, body.checkout-index-index .checkout-container #giftcard-form .actions-toolbar .secondary .action-gift {
        height: 39px;
        border-bottom: 2px solid #585858; }
  body.checkout-index-index .checkout-container .radio {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    position: relative;
    transition: all 0.15s ease-out 0s;
    background: #fff;
    color: #fff;
    cursor: pointer;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    body.checkout-index-index .checkout-container .radio:focus {
      box-shadow: none; }
    body.checkout-index-index .checkout-container .radio:hover {
      background: #eaeaea; }
    body.checkout-index-index .checkout-container .radio:checked {
      border: 2px solid #2980b9 !important; }
      body.checkout-index-index .checkout-container .radio:checked:before {
        content: " ";
        height: 10px;
        width: 10px;
        background: #2980b9;
        border-radius: 50%; }
    body.checkout-index-index .checkout-container .radio.radio {
      border-radius: 50%;
      border: 2px solid #5e5e5e; }
      body.checkout-index-index .checkout-container .radio.radio:after {
        border-radius: 4px; }
  body.checkout-index-index .checkout-container.customergroup-b2b #checkout-step-shipping .new-address-popup {
    display: none; }

body.checkout-index-index .stormberg-footer-content,
body.checkout-index-index .page-footer,
body.checkout-index-index .page-bottom,
body.checkout-index-index .copyright {
  display: none; }

@media only screen and (max-width: 767px) {
  body.checkout-index-index .page-main {
    margin-bottom: 0; }
  body.checkout-index-index .columns .column.main {
    padding-bottom: 0; }
  body.checkout-index-index .modal-popup.modal-slide {
    left: 0; }
  body.checkout-index-index .nav-toggle {
    display: none; }
  body.checkout-index-index .header.content .customer-support {
    width: 50%; }
    body.checkout-index-index .header.content .customer-support p:first-child {
      display: none; }
  body.checkout-index-index .header.content #menu-button {
    display: none; }
  body.checkout-index-index .opc-progress-bar {
    margin: 0.5rem 0 0 0; }
    body.checkout-index-index .opc-progress-bar-item:after {
      left: calc(100% - 1.15rem); }
  body.checkout-index-index .checkout-container .authentication-wrapper {
    padding: 0 0.5rem; }
    body.checkout-index-index .checkout-container .authentication-wrapper .secure-payment {
      display: none; }
    body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action {
      display: block; }
      body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action button, body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action .actions-toolbar > .action, body.checkout-index-index .checkout-container .authentication-wrapper .authentication-action .action-gift {
        white-space: nowrap;
        margin-top: -5px;
        margin-right: 4px; }
  body.checkout-index-index .checkout-container .authentication-dropdown._show {
    max-width: 100%; }
  body.checkout-index-index .checkout-container .opc-estimated-wrapper {
    display: none; }
  body.checkout-index-index .checkout-container .opc-wrapper .step-content {
    margin: 0; }
  body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps {
    flex-direction: column;
    list-style: none;
    padding: 0; }
    body.checkout-index-index .checkout-container .opc-wrapper #checkoutSteps > li {
      width: calc(100% - -2rem);
      margin: 1rem -1rem;
      padding: 1rem; }
  body.checkout-index-index .checkout-container .opc-wrapper .form-shipping-address {
    margin-bottom: 0.25rem; }
  body.checkout-index-index .checkout-container #checkout-step-shipping_method {
    margin: 0; }
    body.checkout-index-index .checkout-container #checkout-step-shipping_method .table-checkout-shipping-method {
      margin-bottom: 0; }
    body.checkout-index-index .checkout-container #checkout-step-shipping_method .actions-toolbar button, body.checkout-index-index .checkout-container #checkout-step-shipping_method .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body.checkout-index-index .checkout-container #checkout-step-shipping_method .actions-toolbar > .action, body.checkout-index-index .checkout-container #checkout-step-shipping_method .actions-toolbar .action-gift {
      margin: 0; }
  body.checkout-index-index .checkout-container .checkout-payment-method .payment-method-title {
    padding: 15px 0; }
  body.checkout-index-index .checkout-container.stepTwo {
    grid-template-areas: 'progressbar' 'login' 'vat' 'totals' 'payment' 'items' 'shipping';
    grid-template-columns: 100%; }
    body.checkout-index-index .checkout-container.stepTwo .block.items-in-cart,
    body.checkout-index-index .checkout-container.stepTwo .opc-block-shipping-information,
    body.checkout-index-index .checkout-container.stepTwo table.table-totals {
      padding: 1.25rem;
      width: calc(100% - -2rem);
      max-width: initial; }
    body.checkout-index-index .checkout-container.stepTwo table.table-totals {
      margin: 2rem -1rem; }
    body.checkout-index-index .checkout-container.stepTwo #payment_form_adyen_cc .credit-card-types {
      right: 0.25rem;
      top: 0.5rem; }
    body.checkout-index-index .checkout-container.stepTwo .block.items-in-cart {
      margin: 0rem -1rem 2.5rem -1rem; }
      body.checkout-index-index .checkout-container.stepTwo .block.items-in-cart .minicart-items-wrapper .minicart-items .product-item .subtotal {
        position: initial;
        margin: 0.5rem 0; }
    body.checkout-index-index .checkout-container.stepTwo .opc-block-shipping-information {
      margin: 0rem -1rem; }
    body.checkout-index-index .checkout-container.stepTwo .opc-wrapper {
      width: 100%; }
    body.checkout-index-index .checkout-container.stepTwo #checkoutSteps > li {
      justify-content: center; }
      body.checkout-index-index .checkout-container.stepTwo #checkoutSteps > li.checkout-payment-method, body.checkout-index-index .checkout-container.stepTwo #checkoutSteps > li#vat-exempt {
        padding: 1rem 2rem;
        width: calc(100% - -2rem);
        margin: 0 -1rem 1rem -1rem; } }

/* Related Products */
.block.related {
  flex-grow: 1; }
  .block.related .block-actions {
    font-size: 16px; }
  .block.related .products-related .product-item {
    box-shadow: 0 0 0 1px #e0e6ee;
    border-right: none !important;
    /* preventing safari bugs */
    -webkit-box-shadow: 0 0 0 1px #e0e6ee;
    -webkit-appearance: none; }
    .block.related .products-related .product-item-info {
      width: 100%; }
    .block.related .products-related .product-item-details__top .price-box {
      order: 0; }
    .block.related .products-related .product-item-details__top .field.related {
      margin-top: 1rem;
      text-align: left;
      cursor: pointer; }
    .block.related .products-related .product-item-details__top .exclusive-tax-wrapper {
      display: flex; }
      .block.related .products-related .product-item-details__top .exclusive-tax-wrapper .extra-text {
        margin-left: 0.4rem;
        font-weight: 600; }
    .block.related .products-related .product-item-inner {
      display: none; }
    .block.related .products-related .product-item-link {
      display: -webkit-box;
      width: 100%;
      max-width: 100%;
      margin-top: 0.5rem;
      -webkit-line-clamp: 1;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis; }

/* Upsell */
.column.main .block.upsell {
  float: none; }

.block.upsell .upsell-heading {
  font-size: 22px;
  color: #1a1a1a;
  font-weight: 500; }

.block.upsell .products-upsell {
  width: 75%;
  margin: 0 auto; }
  .block.upsell .products-upsell .product-items {
    display: flex;
    justify-content: center; }
    .block.upsell .products-upsell .product-items .product-item {
      box-shadow: 0 0 0 1px #e0e6ee;
      -webkit-box-shadow: 0 0 0 1px #e0e6ee;
      -webkit-appearance: none; }
      .block.upsell .products-upsell .product-items .product-item-info .product-image-container {
        z-index: 0; }

/* Desktop */
@media only screen and (min-width: 768px) {
  /* Related Products */
  .block.related .products-related .product-item {
    width: calc(31.9% - 10px);
    margin: 0 .75rem 1.5rem .75rem; }
  /* Upsell */
  .block.upsell .upsell-heading {
    width: 75%;
    margin: 0 auto; }
  .block.upsell .products-upsell .product-item {
    width: calc(23.9% - 10px);
    margin: 0 .75rem 1.5rem .75rem; } }

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .block.related .products-related .product-item {
    width: calc(31.5% - 10px); }
  .block.upsell .upsell-heading {
    width: 100%;
    margin: 0;
    padding-left: 1rem; }
  .block.upsell .products-upsell {
    width: 100%; }
    .block.upsell .products-upsell .product-items {
      flex-direction: row;
      flex-wrap: wrap; }
      .block.upsell .products-upsell .product-items .product-item {
        width: calc(23.5% - 10px); } }

/* Mobile */
@media only screen and (max-width: 767px) {
  .block.related .products-related .product-item {
    width: calc(50.7% - 10px);
    margin: 0 0.25rem 0.5rem 0.25rem; }
  .block.upsell .products-upsell {
    width: 100%;
    margin: 0; }
    .block.upsell .products-upsell .product-items {
      flex-direction: row;
      flex-wrap: wrap; }
      .block.upsell .products-upsell .product-items .product-item {
        width: calc(49.9% - 10px);
        margin: 0 0.3rem 0.6rem 0.3rem; } }

.minicart-wrapper.active .block-minicart {
  background: #f2f2f2;
  z-index: 10000; }
  .minicart-wrapper.active .block-minicart:before {
    right: 31px; }
  .minicart-wrapper.active .block-minicart:after {
    right: 30px; }
  .minicart-wrapper.active .block-minicart.empty {
    background: #fafafa; }
  .minicart-wrapper.active .block-minicart .block-title {
    display: block;
    text-transform: uppercase;
    margin: -0.5rem 0 1rem 0;
    border: none; }
    .minicart-wrapper.active .block-minicart .block-title span.qty:before {
      content: "( ";
      display: inline-block;
      margin-right: 0.15rem; }
    .minicart-wrapper.active .block-minicart .block-title span.qty:after {
      content: " )";
      display: inline-block;
      margin-left: 0.15rem; }
    .minicart-wrapper.active .block-minicart .block-title > strong {
      letter-spacing: 0.5px; }
  .minicart-wrapper.active .block-minicart .block-content {
    display: flex;
    flex-direction: column;
    margin-top: -2rem;
    margin-bottom: 0; }
  .minicart-wrapper.active .block-minicart .details-qty {
    display: flex; }
    .minicart-wrapper.active .block-minicart .details-qty .label {
      display: none; }
    .minicart-wrapper.active .block-minicart .details-qty button, .minicart-wrapper.active .block-minicart .details-qty .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .minicart-wrapper.active .block-minicart .details-qty .actions-toolbar > .action, .minicart-wrapper.active .block-minicart .details-qty .action-gift {
      font-size: 12px;
      line-height: 12px; }
    .minicart-wrapper.active .block-minicart .details-qty .p2-qty-wrapper .qty-decrease,
    .minicart-wrapper.active .block-minicart .details-qty .p2-qty-wrapper .qty-increase {
      width: 30px;
      height: 35px; }
    .minicart-wrapper.active .block-minicart .details-qty .p2-qty-wrapper .item-qty {
      width: 35px;
      height: 35px;
      border-left: none;
      border-right: none;
      margin-right: 0;
      border-color: #E0E6EE; }
  .minicart-wrapper.active .block-minicart .block-content .subtotal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 0; }
    .minicart-wrapper.active .block-minicart .block-content .subtotal .label {
      display: block; }
      .minicart-wrapper.active .block-minicart .block-content .subtotal .label span {
        font-size: 1.2rem;
        margin-right: 0; }
        .minicart-wrapper.active .block-minicart .block-content .subtotal .label span:after {
          content: ":"; }
  .minicart-wrapper.active .block-minicart .block-content .actions .action.viewcart button, .minicart-wrapper.active .block-minicart .block-content .actions .action.viewcart .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .minicart-wrapper.active .block-minicart .block-content .actions .action.viewcart .actions-toolbar > .action, .minicart-wrapper.active .block-minicart .block-content .actions .action.viewcart .action-gift {
    text-transform: uppercase; }
  .minicart-wrapper.active .block-minicart .product.actions .primary {
    display: none; }
  .minicart-wrapper.active .block-minicart .product-item-details .product-item-name a {
    color: #1a1a1a;
    font-size: 1rem; }
  .minicart-wrapper.active .block-minicart .product-item-details .price-container {
    margin-bottom: 1rem; }
  .minicart-wrapper.active .block-minicart .product-item-details .product.options.list {
    margin-top: 0.5rem; }
  .minicart-wrapper.active .block-minicart button#btn-minicart-close, .minicart-wrapper.active .block-minicart .cart.table-wrapper .actions-toolbar > #btn-minicart-close.action, .cart.table-wrapper .minicart-wrapper.active .block-minicart .actions-toolbar > #btn-minicart-close.action, .minicart-wrapper.active .block-minicart #btn-minicart-close.action-gift {
    visibility: hidden; }
  .minicart-wrapper.active .block-minicart button#top-cart-btn-checkout, .minicart-wrapper.active .block-minicart .cart.table-wrapper .actions-toolbar > #top-cart-btn-checkout.action, .cart.table-wrapper .minicart-wrapper.active .block-minicart .actions-toolbar > #top-cart-btn-checkout.action, .minicart-wrapper.active .block-minicart #top-cart-btn-checkout.action-gift {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 42%;
    padding: .5rem;
    font-size: 0.9rem;
    font-weight: 700; }
  .minicart-wrapper.active .block-minicart .minicart-items-wrapper {
    background: #fff;
    height: inherit !important;
    max-height: 350px;
    overflow-x: hidden; }
  .minicart-wrapper.active .block-minicart .items-total {
    display: none; }
  .minicart-wrapper.active .block-minicart .subtotal {
    order: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -0.5rem 0 -1rem 0; }
    .minicart-wrapper.active .block-minicart .subtotal span.label {
      margin-right: .5rem;
      font-weight: 600; }
      .minicart-wrapper.active .block-minicart .subtotal span.label span {
        margin-right: -.5rem; }
  .minicart-wrapper.active .block-minicart .minicart-wrapper .minicart-widgets {
    display: none;
    margin: 0; }
  .minicart-wrapper.active .block-minicart .secondary span {
    font-size: 16px;
    letter-spacing: 0.5px; }
  .minicart-wrapper.active .block-minicart div#minicart-widgets {
    display: none;
    margin-top: 0; }

.minicart-wrapper .block-minicart {
  display: none; }

@media only screen and (max-width: 767px) {
  .minicart-wrapper.active .block-minicart .block-title {
    font-size: 0.8rem; }
  .minicart-wrapper.active .block-minicart:before {
    right: 18px; }
  .minicart-wrapper.active .block-minicart:after {
    right: 17px; }
  .minicart-wrapper.active .block-minicart .actions .primary #top-cart-btn-checkout {
    width: 45%;
    padding: 0.4rem 0 0.2rem;
    line-height: 20px; } }

.productfinder__filter {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 40px;
  padding: 24px 300px 24px 200px;
  border-radius: 500px;
  background: #f0f0f0; }
  .productfinder__filter--pf_age {
    background-image: url(../Trollweb_ProductFinder/images/pf_age.svg);
    background-repeat: no-repeat;
    background-position: right 80px center; }
  .productfinder__filter--pf_weight {
    background-image: url(../Trollweb_ProductFinder/images/pf_weight.svg);
    background-repeat: no-repeat;
    background-position: right 80px center; }
  .productfinder__filter--pf_height {
    background-image: url(../Trollweb_ProductFinder/images/pf_height.svg);
    background-repeat: no-repeat;
    background-position: right 80px center; }
  .productfinder__filter--pf_environment {
    background-image: url(../Trollweb_ProductFinder/images/pf_environment.svg);
    background-repeat: no-repeat;
    background-position: right 80px center; }
  .productfinder__filter--pf_purpose {
    background-image: url(../Trollweb_ProductFinder/images/pf_purpose.svg);
    background-repeat: no-repeat;
    background-position: right 80px center; }
  .productfinder__filter--pf_important {
    background-image: url(../Trollweb_ProductFinder/images/pf_important.svg);
    background-repeat: no-repeat;
    background-position: right 80px center; }

.productfinder__counter {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 120px;
  margin-left: 20px; }
  .productfinder__counter span {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background: #c5f0bd;
    font-size: 30px; }
  .productfinder__counter:after {
    content: "";
    position: absolute;
    left: 40px;
    top: -20px;
    z-index: 2;
    display: flex;
    width: 40px;
    height: calc(100% + 40px);
    background: #c5f0bd; }

.productfinder__filter:first-of-type .productfinder__counter:after {
  top: 50%;
  height: calc(50% + 20px); }

.productfinder__search-and-results-wrapper .productfinder__counter {
  width: 150px;
  margin-left: 5px; }
  .productfinder__search-and-results-wrapper .productfinder__counter span {
    width: 150px;
    height: 150px;
    color: #ffffff;
    background: #2a8039; }
  .productfinder__search-and-results-wrapper .productfinder__counter:after {
    left: 55px;
    bottom: 50%;
    height: calc(50% + 20px); }

.productfinder__filter-title {
  width: 100%;
  margin: 0 0 12px;
  font-size: 30px;
  font-weight: normal; }

.productfinder__filter-subtitle {
  width: 100%; }

.productfinder__filter-option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .productfinder .productfinder__filter-option {
    margin: 12px 12px 0 0; }
    .productfinder .productfinder__filter-option:last-of-type {
      margin: 12px 0 0 0; }
  .productfinder__filter-option input {
    position: absolute;
    visibility: hidden;
    left: -1000px; }
    .productfinder__filter-option input:checked + label {
      color: #ffffff;
      background-color: #37a447; }
  .productfinder__filter-option label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.35);
    border-radius: 5px;
    padding: 2px;
    text-align: center;
    font-size: 20px;
    background: #ffffff; }
    .productfinder__filter-option label:hover {
      cursor: pointer; }
  .productfinder__filter-option--checkbox label {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 16px; }
    .productfinder__filter-option--checkbox label:before {
      content: "check_box_outline_blank";
      font-family: Material Icons;
      font-size: 30px;
      line-height: 1; }
  .productfinder__filter-option--checkbox input:checked + label:before {
    content: "check_box"; }
  .productfinder__filter-option--has-swatch label {
    width: 200px;
    padding-right: 100px;
    background-repeat: no-repeat;
    background-position: right center; }

.productfinder__search-and-results-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 160px;
  margin: 40px;
  padding: 24px 24px 24px 200px; }

.productfinder__button--search {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  color: #ffffff;
  background: #2a8039;
  font-size: 30px; }
  .productfinder__button--search:hover, .productfinder__button--search:active, .productfinder__button--search:focus {
    color: #ffffff;
    background: #2a8039; }

.productfinder__button--gotoproduct {
  height: 60px;
  padding: 10px 40px;
  color: #ffffff;
  background: #ed1b2f;
  font-size: 24px;
  font-weight: normal; }
  .productfinder__button--gotoproduct:hover {
    cursor: pointer; }
  .productfinder__button--gotoproduct:hover, .productfinder__button--gotoproduct:active, .productfinder__button--gotoproduct:focus, .productfinder__button--gotoproduct:visited {
    color: #ffffff;
    background: #ed1b2f;
    text-decoration: none; }

.productfinder__validation-errors ul {
  margin: 0;
  padding: 0; }

.productfinder__result-title {
  font-weight: normal; }

.productfinder__result-list {
  margin: 40px 0 0;
  padding: 0;
  list-style-type: none; }

.productfinder__result-item {
  display: flex;
  flex-direction: row;
  margin: 20px 0; }

.productfinder__result-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 60%; }

.productfinder__result-item-image-wrapper {
  display: flex;
  align-items: flex-start; }

.productfinder__result-item-name {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 20px; }

.productfinder__result-item-description {
  margin: 20px; }

.cms-no-route #maincontent {
  height: 842px;
  max-width: 100vw; }
  .cms-no-route #maincontent .column.main {
    max-width: 100vw;
    width: calc(100vw - 15px); }
  .cms-no-route #maincontent .page-title-wrapper {
    display: none; }
  .cms-no-route #maincontent .page-404 img {
    position: absolute;
    left: 0;
    z-index: -1;
    height: auto;
    width: 100vw;
    object-fit: cover;
    object-position: 35% 15%; }
  .cms-no-route #maincontent .page-404 .title-404 {
    position: absolute;
    top: 10rem;
    left: 5rem;
    padding: 2.5rem 1.5rem;
    background: rgba(255, 255, 255, 0.75); }
    .cms-no-route #maincontent .page-404 .title-404-whoops {
      font-size: 24px;
      color: #1a1a1a;
      font-weight: 700;
      margin-top: 0; }
    .cms-no-route #maincontent .page-404 .title-404-description {
      font-size: 16px;
      color: #1a1a1a;
      margin-top: 1.5rem;
      max-width: 600px;
      line-height: 24px; }
    .cms-no-route #maincontent .page-404 .title-404 .button-wrapper {
      margin-top: 2rem; }
      .cms-no-route #maincontent .page-404 .title-404 .button-wrapper .action.primary {
        padding: 1rem 2rem;
        background: #00C853;
        border-radius: 3px;
        border: none;
        border-bottom: 2px solid #00a946;
        transition: all 100ms ease; }
        .cms-no-route #maincontent .page-404 .title-404 .button-wrapper .action.primary:hover {
          background: #00b44b; }
        .cms-no-route #maincontent .page-404 .title-404 .button-wrapper .action.primary .home {
          color: #fff;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: 500;
          text-transform: uppercase;
          padding: 1.5rem; }

/* remove padding on smaller devices */
@media only screen and (max-width: 1439px) {
  .cms-no-route #maincontent {
    padding: 0 !important; } }

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cms-no-route #maincontent {
    height: 700px;
    margin-bottom: 0; }
    .cms-no-route #maincontent .page-404 img {
      height: 700px;
      object-position: 50% 15%; }
    .cms-no-route #maincontent .page-404 .title-404 {
      left: 0;
      right: 0;
      top: 25%;
      width: 65%;
      top: 5rem;
      margin: 0 auto; } }

/* Mobile */
@media only screen and (max-width: 767px) {
  .cms-no-route #maincontent {
    height: 500px; }
    .cms-no-route #maincontent .page-404 img {
      height: 545px; }
    .cms-no-route #maincontent .page-404 .title-404 {
      top: 1rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%; }
      .cms-no-route #maincontent .page-404 .title-404-whoops {
        font-size: 24px;
        line-height: 32px; }
      .cms-no-route #maincontent .page-404 .title-404-description {
        padding: 0rem; }
      .cms-no-route #maincontent .page-404 .title-404 .button-wrapper {
        margin-top: 1.5rem; }
        .cms-no-route #maincontent .page-404 .title-404 .button-wrapper .action.primary {
          padding: 1rem 1.75rem; }
          .cms-no-route #maincontent .page-404 .title-404 .button-wrapper .action.primary .home {
            font-size: 16px; } }

.block.filter {
  border-top: none;
  margin-bottom: 1rem; }
  .block.filter .filter-actions {
    display: none; }
  .block.filter .filter-content .filter-options-item {
    border-bottom: 1px solid #bbb;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0.75rem 1rem;
    margin-bottom: 0; }
  .block.filter .filter-content .filter-options-title {
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    font-size: 18px;
    overflow: hidden;
    padding: 10px 40px 0 0;
    position: relative;
    text-transform: capitalize;
    word-break: break-all;
    z-index: 1;
    white-space: nowrap;
    padding-bottom: .5rem;
    display: block;
    text-decoration: none; }
    .block.filter .filter-content .filter-options-title:after {
      top: 0;
      right: -7px; }
  .block.filter .filter-content .filter-options-content {
    padding: 0; }
    .block.filter .filter-content .filter-options-content .item a {
      padding-left: 0;
      font-size: 14px;
      margin: 0;
      color: #1a1a1a; }
      .block.filter .filter-content .filter-options-content .item a .label {
        margin-right: 2px; }
      .block.filter .filter-content .filter-options-content .item a .count {
        white-space: nowrap; }
    .block.filter .filter-content .filter-options-content .item input {
      -webkit-appearance: button;
      border-radius: 2px;
      border: 1px solid #1a1a1a;
      cursor: pointer;
      margin-top: -2px;
      vertical-align: middle;
      background: #ffffff;
      width: 1rem;
      height: 1rem; }
      .block.filter .filter-content .filter-options-content .item input:hover {
        background: #eaeaea; }
      .block.filter .filter-content .filter-options-content .item input:checked {
        position: relative; }
        .block.filter .filter-content .filter-options-content .item input:checked:before {
          content: "✔";
          position: absolute;
          color: #fff;
          font-size: 8px;
          top: -4px;
          left: 3px; }
    .block.filter .filter-content .filter-options-content .ui-slider {
      height: 5px; }
      .block.filter .filter-content .filter-options-content .ui-slider-range {
        background: #666;
        height: 5px; }
      .block.filter .filter-content .filter-options-content .ui-slider-handle {
        margin-top: -7px;
        cursor: pointer; }
  .block.filter .filter-current {
    border-bottom: 1px solid #bbb; }
    .block.filter .filter-current-subtitle {
      color: #1a1a1a;
      font-size: 16px;
      padding-bottom: 1rem; }
      .block.filter .filter-current-subtitle:before {
        display: none; }
      .block.filter .filter-current-subtitle:after {
        content: ":";
        display: inline-block; }
    .block.filter .filter-current .items {
      display: inline-block;
      padding: 0;
      border: 1px solid #ededed;
      margin-bottom: 1rem; }
      .block.filter .filter-current .items .item {
        display: flex;
        margin-right: 0.5rem;
        border-radius: 15px;
        align-items: center;
        position: relative;
        z-index: 1;
        margin: 0.5rem 0;
        padding: 0.5rem 1rem; }
        .block.filter .filter-current .items .item .filter-label {
          margin-right: 0.5rem; }
        .block.filter .filter-current .items .item .filter-value {
          color: #474747;
          font-size: 14px;
          word-break: break-all;
          margin-right: 0.75rem; }
        .block.filter .filter-current .items .item .action.remove {
          z-index: 1;
          padding: 0;
          display: flex;
          align-items: center;
          position: unset; }
          .block.filter .filter-current .items .item .action.remove:before {
            order: 1;
            margin: 0;
            text-align: center;
            background-color: #1a1a1a;
            border-radius: 50%;
            padding: 0.25rem;
            color: white;
            content: '\E5CD';
            font-weight: 900;
            font-size: 11px;
            text-indent: unset;
            line-height: 11px; }

/* Mobile */
@media only screen and (max-width: 767px) {
  .filter-title strong {
    color: #1a1a1a;
    font-size: 13px;
    background: #fff;
    top: 1rem; }
  .filter-title strong[aria-expanded='false'] {
    margin-top: -60px; }
  .filter-current {
    margin-top: 1rem !important; } }

.smile-es-range-slider .ui-slider {
  margin-top: 10px; }

.smile-es-range-slider .actions-toolbar {
  margin-top: 10px; }
  .smile-es-range-slider .actions-toolbar .actions-primary {
    float: right; }
    .smile-es-range-slider .actions-toolbar .actions-primary a:hover {
      color: #ffffff;
      cursor: pointer; }

.page-header .switcher,
.page-footer .switcher {
  color: #1a1a1a;
  margin-left: 2.5rem !important; }
  .page-header .switcher .options,
  .page-footer .switcher .options {
    display: inline-block;
    position: relative; }
    .page-header .switcher .options:before, .page-header .switcher .options:after,
    .page-footer .switcher .options:before,
    .page-footer .switcher .options:after {
      content: '';
      display: table; }
    .page-header .switcher .options:after,
    .page-footer .switcher .options:after {
      clear: both; }
    .page-header .switcher .options .action.toggle,
    .page-footer .switcher .options .action.toggle {
      padding: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none; }
      .page-header .switcher .options .action.toggle > span,
      .page-footer .switcher .options .action.toggle > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .page-header .switcher .options .action.toggle:after,
      .page-footer .switcher .options .action.toggle:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center; }
      .page-header .switcher .options .action.toggle:hover:after,
      .page-footer .switcher .options .action.toggle:hover:after {
        color: inherit; }
      .page-header .switcher .options .action.toggle:active:after,
      .page-footer .switcher .options .action.toggle:active:after {
        color: inherit; }
      .page-header .switcher .options .action.toggle.active,
      .page-footer .switcher .options .action.toggle.active {
        display: inline-block;
        text-decoration: none; }
        .page-header .switcher .options .action.toggle.active > span,
        .page-footer .switcher .options .action.toggle.active > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px; }
        .page-header .switcher .options .action.toggle.active:after,
        .page-footer .switcher .options .action.toggle.active:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 22px;
          line-height: 22px;
          color: inherit;
          content: "";
          font-family: "Material Icons";
          margin: 0;
          vertical-align: top;
          display: inline-block;
          font-weight: normal;
          speak: none;
          text-align: center; }
        .page-header .switcher .options .action.toggle.active:hover:after,
        .page-footer .switcher .options .action.toggle.active:hover:after {
          color: inherit; }
        .page-header .switcher .options .action.toggle.active:active:after,
        .page-footer .switcher .options .action.toggle.active:active:after {
          color: inherit; }
    .page-header .switcher .options ul.dropdown,
    .page-footer .switcher .options ul.dropdown {
      margin: 0;
      padding: 0;
      list-style: none none;
      background: #ffffff;
      border: none;
      margin-top: 0.39rem;
      min-width: unset;
      width: calc(100% + 1rem + 2em);
      z-index: 100;
      box-sizing: border-box;
      display: none;
      position: absolute;
      top: 100%;
      right: -1.55em;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
      .page-header .switcher .options ul.dropdown li,
      .page-footer .switcher .options ul.dropdown li {
        margin: 0;
        padding: 0; }
        .page-header .switcher .options ul.dropdown li:hover,
        .page-footer .switcher .options ul.dropdown li:hover {
          background: transparent;
          cursor: pointer; }
    .page-header .switcher .options.active,
    .page-footer .switcher .options.active {
      overflow: visible; }
      .page-header .switcher .options.active ul.dropdown,
      .page-footer .switcher .options.active ul.dropdown {
        display: block; }
    .page-header .switcher .options ul.dropdown:before, .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:after {
      display: none; }
    .page-header .switcher .options ul.dropdown .switcher-option,
    .page-footer .switcher .options ul.dropdown .switcher-option {
      border-bottom: 1px solid #f0f0f0; }
      .page-header .switcher .options ul.dropdown .switcher-option:hover,
      .page-footer .switcher .options ul.dropdown .switcher-option:hover {
        background-color: #f7f7f7; }
    .page-header .switcher .options ul.dropdown a,
    .page-footer .switcher .options ul.dropdown a {
      display: flex;
      align-items: center;
      padding: 0.8rem 1.5rem; }
      .page-header .switcher .options ul.dropdown a:hover,
      .page-footer .switcher .options ul.dropdown a:hover {
        text-decoration: none; }
  .page-header .switcher li,
  .page-footer .switcher li {
    margin: 0; }
  .page-header .switcher .switcher-label,
  .page-footer .switcher .switcher-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    clip: unset;
    overflow: visible;
    position: static;
    height: auto;
    margin: 0; }
    .page-header .switcher .switcher-label span,
    .page-footer .switcher .switcher-label span {
      margin: 0.2rem 0.25rem 0 0.5rem; }
  .page-header .switcher .label:after,
  .page-footer .switcher .label:after {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    content: "";
    font-size: 1.5em;
    line-height: 1.25;
    transition: transform .5s ease; }
  .page-header .switcher .label.open,
  .page-footer .switcher .label.open {
    position: relative; }
    .page-header .switcher .label.open + .switcher-options,
    .page-footer .switcher .label.open + .switcher-options {
      color: #1a1a1a;
      width: 100%; }
      .page-header .switcher .label.open + .switcher-options ul,
      .page-footer .switcher .label.open + .switcher-options ul {
        display: block;
        margin-top: 0.65rem; }
        .page-header .switcher .label.open + .switcher-options ul li span,
        .page-footer .switcher .label.open + .switcher-options ul li span {
          color: #1a1a1a;
          text-transform: uppercase; }
    .page-header .switcher .label.open:after,
    .page-footer .switcher .label.open:after {
      transform: rotate(180deg); }
  .page-header .switcher img,
  .page-footer .switcher img {
    max-height: 17px;
    box-shadow: 0 0 0 1px #ffffff; }
  .page-header .switcher .ch img,
  .page-footer .switcher .ch img {
    margin: 0 5px; }

.page-header .switcher-language,
.page-footer .switcher-language {
  margin-right: 1rem; }

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .page-header .switcher {
    margin-right: 1.4rem; }
  .page-header .right-side ul {
    padding-left: 0rem; } }

/* Mobile */
@media only screen and (max-width: 767px) {
  .page-header .panel.wrapper .panel.header {
    padding: 0; }
    .page-header .panel.wrapper .panel.header .switcher .label {
      margin: 0;
      font-size: 12px; }
    .page-header .panel.wrapper .panel.header .switcher-store {
      display: block;
      position: absolute;
      top: 0.65rem;
      right: 8.5rem;
      z-index: 100; }
    .page-header .panel.wrapper .panel.header .switcher-language {
      display: block;
      position: absolute;
      right: 0.5rem;
      top: 0.65rem;
      margin: 0;
      z-index: 100; } }

@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"), url("../fonts/MYRIADPRO-REGULAR.woff") format("woff"); }

@font-face {
  font-family: 'Adobe Garamond Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Adobe Garamond Pro Regular"), url("../fonts/AGaramondPro-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Adobe Garamond Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Adobe Garamond Pro Italic"), url("../fonts/AGaramondPro-Italic.woff") format("woff"); }

body h1 {
  font-family: "Myriad Pro Regular"; }

body .wpcloud-root .wpcloud-banner2__headline {
  font-family: "Adobe Garamond Pro Regular"; }

body h2 {
  font-family: "Myriad Pro Regular"; }

body h3 {
  font-family: "Myriad Pro Regular"; }

body h4 {
  font-family: "Myriad Pro Regular"; }

@media only screen and (min-width: 1025px) {
  body h1 {
    font-size: 60px; }
  body h3 {
    font-size: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body h1 {
    font-size: 40px; }
  body h3 {
    font-size: 25px; } }

@media only screen and (max-width: 639px) {
  body h1 {
    font-size: 25px; }
  body h3 {
    font-size: 20px; } }

body {
  /* Will contain algolia styles */ }
  body #algolia-searchbox input#search {
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #808080;
    border-left: none;
    background-color: transparent !important; }
  body #algolia-searchbox .clear-cross {
    bottom: 9px;
    width: 24px;
    height: 24px;
    right: 13px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 400ms; }
    body #algolia-searchbox .clear-cross.visible {
      opacity: 1;
      display: block; }
  body #algolia-searchbox #algolia-glass {
    bottom: 11px;
    right: 13px;
    transition: opacity 400ms;
    opacity: 1; }
    body #algolia-searchbox #algolia-glass.hidden {
      opacity: 0; }
  body #algolia-autocomplete-container .aa-dropdown-menu {
    min-width: unset;
    max-width: 600px;
    border-top: none;
    border: none;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2); }
    body #algolia-autocomplete-container .aa-dropdown-menu .col9 {
      width: 100%;
      border-left: none; }
      body #algolia-autocomplete-container .aa-dropdown-menu .col9 .aa-dataset-products .aa-suggestions {
        margin: 0px auto 50px; }
      body #algolia-autocomplete-container .aa-dropdown-menu .col9 .aa-dataset-products .aa-suggestion {
        width: 100%; }
        body #algolia-autocomplete-container .aa-dropdown-menu .col9 .aa-dataset-products .aa-suggestion .algoliasearch-autocomplete-hit {
          padding: 15px; }
          body #algolia-autocomplete-container .aa-dropdown-menu .col9 .aa-dataset-products .aa-suggestion .algoliasearch-autocomplete-hit .info {
            margin-left: 70px; }
            body #algolia-autocomplete-container .aa-dropdown-menu .col9 .aa-dataset-products .aa-suggestion .algoliasearch-autocomplete-hit .info div.algoliasearch-autocomplete-category {
              white-space: normal; }
            body #algolia-autocomplete-container .aa-dropdown-menu .col9 .aa-dataset-products .aa-suggestion .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-price {
              color: #434343;
              font-size: 0.9rem;
              font-weight: 600; }
      body #algolia-autocomplete-container .aa-dropdown-menu .col9 #autocomplete-products-footer {
        width: 100%;
        left: 0; }
  body .algolia-instant-results-wrapper {
    margin-bottom: 150px; }
    body .algolia-instant-results-wrapper .instant-search-bar-label {
      background: none;
      border-bottom: 2px solid #1c5275;
      width: 40px !important; }
    body .algolia-instant-results-wrapper .ais-search-box--magnifier svg {
      fill: #1c5275;
      stroke: #1c5275;
      left: 5px !important; }
    body .algolia-instant-results-wrapper #instant-search-bar {
      border: none;
      border-bottom: 2px solid #1c5275;
      width: calc(100% - 40px) !important; }
    body .algolia-instant-results-wrapper .ais-range-slider .rheostat-horizontal .rheostat-progress {
      background-color: #ed1930; }
    body .algolia-instant-results-wrapper #algolia-left-container #instant-search-facets-container .facet {
      border: none;
      border-top: 1px solid #bababa;
      padding: 10px; }
      body .algolia-instant-results-wrapper #algolia-left-container #instant-search-facets-container .facet .ais-header .name {
        font-weight: 400;
        margin: 0;
        overflow: hidden;
        padding: 10px 40px 0 10px;
        position: relative;
        text-transform: uppercase;
        word-break: break-all;
        z-index: 1;
        color: #666666;
        display: block;
        text-decoration: none;
        background-color: transparent; }
    body .algolia-instant-results-wrapper #instant-search-results-container .ais-hits .ais-hits--item > div {
      padding: 0; }
    body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper .result-thumbnail img {
      width: 80%;
      height: auto; }
    body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.result {
      border: none;
      border-right: 1px solid #c9c9c9;
      padding-bottom: 6px; }
      body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.result h3.result-title {
        color: #1a1a1a;
        font-size: 18px;
        font-weight: 500;
        text-align: left; }
      body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.result .price {
        color: #1a1a1a;
        font-size: 20px;
        font-weight: 700;
        text-align: left; }
      body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.result .product-reviews-summary {
        display: none; }
    body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link button, body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link .actions-toolbar > .action, body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link .action-gift {
      margin: 0 auto;
      margin-top: -5px;
      margin-bottom: 10px;
      width: 90%;
      padding: 1rem 2rem;
      background: transparent;
      color: #1c5275;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px; }
      body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link button:hover, body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link .actions-toolbar > .action:hover, body .algolia-instant-results-wrapper #instant-search-results-container .result-wrapper a.button-link .action-gift:hover {
        background: #1c5275;
        color: #fff; }
  body.int .autocomplete-wrapper .algoliasearch-autocomplete-price, body.topronl .autocomplete-wrapper .algoliasearch-autocomplete-price, body.toprose .autocomplete-wrapper .algoliasearch-autocomplete-price, body.toprodk .autocomplete-wrapper .algoliasearch-autocomplete-price, body.topronlfr .autocomplete-wrapper .algoliasearch-autocomplete-price {
    display: none; }
  body.int .autocomplete-wrapper .aa-suggestion, body.topronl .autocomplete-wrapper .aa-suggestion, body.toprose .autocomplete-wrapper .aa-suggestion, body.toprodk .autocomplete-wrapper .aa-suggestion, body.topronlfr .autocomplete-wrapper .aa-suggestion {
    padding: 0.5rem 0rem; }
    body.int .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit, body.topronl .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit, body.toprose .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit, body.toprodk .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit, body.topronlfr .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit {
      padding-bottom: 2.5rem !important;
      padding-top: 0.2rem !important; }
      body.int .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit .info, body.topronl .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit .info, body.toprose .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit .info, body.toprodk .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit .info, body.topronlfr .autocomplete-wrapper .aa-suggestion .algoliasearch-autocomplete-hit .info {
        white-space: normal !important; }
  body.int #instant-search-results-container .ais-hits--item .price-wrapper, body.topronl #instant-search-results-container .ais-hits--item .price-wrapper, body.toprose #instant-search-results-container .ais-hits--item .price-wrapper, body.toprodk #instant-search-results-container .ais-hits--item .price-wrapper, body.topronlfr #instant-search-results-container .ais-hits--item .price-wrapper {
    display: none; }
  body.int .product-info-main .data.table.additional-attributes, body.topronl .product-info-main .data.table.additional-attributes, body.toprose .product-info-main .data.table.additional-attributes, body.toprodk .product-info-main .data.table.additional-attributes, body.topronlfr .product-info-main .data.table.additional-attributes {
    display: none; }

@media only screen and (max-width: 769px) {
  body .algolia-search-block .block-content {
    margin-top: 0; }
    body .algolia-search-block .block-content #algolia-searchbox {
      height: auto;
      overflow: hidden; }
      body .algolia-search-block .block-content #algolia-searchbox input#search {
        max-height: unset;
        margin: 0; }
    body .algolia-search-block .block-content #algolia-autocomplete-container .aa-dropdown-menu {
      right: 7px !important;
      width: auto !important; } }

.meganav .meganav__list-root > .meganav__item.level0 a.meganav__anchor.level0.meganav__list--open,
.meganav .meganav__list-root > .meganav__item.level0.info-link a.meganav__anchor.level0,
.meganav .meganav__list-root > .meganav__item.level0.meganav__item--open a.meganav__anchor.level0 {
  color: #ffffff;
  background-color: #1c5275; }
  .meganav .meganav__list-root > .meganav__item.level0 a.meganav__anchor.level0.meganav__list--open img,
  .meganav .meganav__list-root > .meganav__item.level0.info-link a.meganav__anchor.level0 img,
  .meganav .meganav__list-root > .meganav__item.level0.meganav__item--open a.meganav__anchor.level0 img {
    filter: grayscale(1) contrast(0) brightness(2); }

.block-search .block-content .minisearch .search {
  margin-top: 16px; }
  .block-search .block-content .minisearch .search .control .input-text {
    border: none;
    border-bottom: 1px solid #808080;
    margin-top: 2px; }
  .block-search .block-content .minisearch .search .control .search-autocomplete {
    width: 100%;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
    margin: 0px auto 50px;
    background-color: #ffffff; }
    .block-search .block-content .minisearch .search .control .search-autocomplete#minisearch-autocomplete-top-search {
      width: 600px !important;
      display: block !important; }
    .block-search .block-content .minisearch .search .control .search-autocomplete .smile-elasticsuite-autocomplete-result .autocomplete-list:first-child {
      display: none; }
    .block-search .block-content .minisearch .search .control .search-autocomplete .smile-elasticsuite-autocomplete-result .autocomplete-list .title-product {
      display: none; }
    .block-search .block-content .minisearch .search .control .search-autocomplete .smile-elasticsuite-autocomplete-result .autocomplete-list [role="option"] {
      padding: 15px;
      display: flex;
      cursor: pointer; }
      .block-search .block-content .minisearch .search .control .search-autocomplete .smile-elasticsuite-autocomplete-result .autocomplete-list [role="option"]:hover {
        background-color: #f2f2f2; }
      .block-search .block-content .minisearch .search .control .search-autocomplete .smile-elasticsuite-autocomplete-result .autocomplete-list [role="option"] .product-shop {
        margin-left: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
        .block-search .block-content .minisearch .search .control .search-autocomplete .smile-elasticsuite-autocomplete-result .autocomplete-list [role="option"] .product-shop .price {
          font-size: 0.9rem;
          font-weight: 600;
          height: 22px; }

.block-search .block-content .minisearch .actions .search {
  margin-top: 16px; }
  .block-search .block-content .minisearch .actions .search :before {
    width: 19px;
    height: 19px; }

@media only screen and (max-width: 767px) {
  .block-content .minisearch .search .label {
    position: absolute;
    right: 7px;
    top: -6px !important; }
    .block-content .minisearch .search .label:before {
      font-size: 26px; }
  .block-content .minisearch .search .control {
    margin-top: -10px; }
    .block-content .minisearch .search .control .input-text {
      margin: 0px; } }

body[class*='b2b'].cms-index-index .breadcrumbs {
  display: none; }

.product-info-main .product-info-stock-sku .stock {
  display: none; }
